import React from "react";
import { Typography, Box } from "@material-ui/core";

export function FormDropdownProviderData(props) {
  return (
    <>
      <Typography variant="caption">
        <Box fontWeight={"bold"}>{`Item selezionabili (Tech key - Label)`}</Box>
      </Typography>
      {props.data ? (
        <ul style={{ margin: 0, paddingLeft: "1.5em" }}>
          {props.data?.dropdownItems &&
            props.data?.dropdownItems.map((item) => {
              return (
                <li>
                  <Typography variant="body2">{`${item.techKey} - ${item.label}`}</Typography>
                </li>
              );
            })}
        </ul>
      ) : (
        "-"
      )}
    </>
  );
}
