import React, { useState, useEffect } from 'react';
import { usePermissions, useDataProvider } from 'react-admin';
import { Permissions, CrudActions, MissionRiderActions } from '../../../../permissions/permissions';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Toolbar, AppBar, Card} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useHistory, useParams } from "react-router-dom";
import KitchenIcon from '@material-ui/icons/Kitchen';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import PanToolIcon from '@material-ui/icons/PanTool';
import { GET_OFF_RESOURCE, POST_OFF_RESOURCE, GET_OFF_RESOURCE_PLAIN } from '../../../../providers/nestjs_crud';
import CachedIcon from '@material-ui/icons/Cached';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { DDTTransfer } from '../../../warehouse/ddt/DDTTransfer';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import StorefrontIcon from '@material-ui/icons/Storefront';
import DeleteIcon from '@material-ui/icons/Delete';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';

const useStyles = makeStyles((theme) => ({
  	root: {
      flexGrow: 1,
      padding: theme.spacing(1)
    },
	mainTitle: {
		flexGrow: 1,
    	display: 'flex',
    	alignItems: 'center',
	},
	title: {
		flexGrow: 1,
	},
	backdrop: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'column',
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
	appbar: {
		alignItems: 'center',
	},
	infoContainer: {
		padding: theme.spacing(1)
	},
	backdropConfirm: {
		backgroundColor: '#fff',
		display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'column',
        zIndex: theme.zIndex.drawer + 1,
	},
	card: {
		minHeight: '60px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: theme.spacing(1),
		backgroundColor: '#f5f5f5',
	},
	appBarTop: {
		position: 'relative',
	},
	cancelButton: {
		margin: theme.spacing(1, 0, 0),
	}
}));
const backdropOpenStatusList = [
	'FETCHING_DATA',
	'PERMISSION_REQUIRED',
	'FETCH_DATA',
	'FETCHING_DATA_ERROR',
	'FETCHING_DATA_REQUESTEDITEMTRANSFER',
	'FETCHING_DATA_ERROR_REQUESTEDITEMTRANSFER',
]

const confirmStatusList = [
	'REQUEST_START_UN_LOAD',
	'REQUEST_CONTINUE_UN_LOAD',
	'REQUEST_COMPLETE_UN_LOAD',
	'REQUEST_COMPLETE_LOAD'
]

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export function MissionShow(props) {
	const classes = useStyles();
	const history = useHistory();
	const dataProvider = useDataProvider();

	const [status, setStatus] = useState('FETCH_DATA')
	const [documentStatus, setDocumentStatus] = useState('HIDE')
	const [data, setData] = useState(null);
	const [actionStatus, setActionStatus] = useState('INITIAL');

	const { permissions } = usePermissions();
	const permission = `${Permissions.mission.feature}_${Permissions.mission.subFeatures.rider.name}`;
	
	const { id } = useParams();

    useEffect(() => {
		if(status === 'FETCH_DATA' && id && permissions) {
			if(permissions.includes(`${permission}-${CrudActions.ReadOne}`)) {
				setStatus('FETCHING_DATA');
				dataProvider(GET_OFF_RESOURCE, `mission/rider/${id}`)
                .then(({ data }) => {
                    if(data) {
						setData({
							...data,
							date: data.date ? new Date(data.date) : new Date()
						});
						setStatus('DATA_FETCHED');
					} else {
						setStatus('FETCHING_DATA_ERROR');	
					}
                })
				.catch(error => {
					if(error && error.statusCode && error.statusCode === 401) {
						history.push('/login')
					} else {
						setStatus('FETCHING_DATA_ERROR');
					}
				})
			} else {
				setStatus('PERMISSION_REQUIRED');
			}
		}
    }, [dataProvider, id, status, permissions, permission, history]);

    useEffect(() => {
		if((actionStatus === 'START_UN_LOAD' || actionStatus === 'CONTINUE_UN_LOAD') && id && permissions) {
			if(permissions.includes(`${permission}-${actionStatus === 'START_UN_LOAD' ? MissionRiderActions.startUnload : MissionRiderActions.continueUnload}`)) {
				setStatus('FETCHING_DATA');
				dataProvider(POST_OFF_RESOURCE, `mission/rider/${id}/${actionStatus === 'START_UN_LOAD' ? 'start-unload' : 'continue-unload'}`, {
					data: {}
				})
				.then(({ data }) => {
					if(data && data.unLoadingTransferId) {
						history.push(`/picking/${data.unLoadingTransferId}`);
					} else {
						setStatus('FETCHING_DATA_ERROR');
					}
				})
				.catch((e) => {
					setStatus('FETCHING_DATA_ERROR');
				});
			} else {
				setStatus('PERMISSION_REQUIRED');
			}
		}
    }, [actionStatus, dataProvider, history, id, permission, permissions]);


	useEffect(() => {
		if(actionStatus === 'COMPLETE_UN_LOAD' && id && permissions) {
			if(permissions.includes(`${permission}-${MissionRiderActions.completeUnload}`)) {
				setStatus('FETCHING_DATA');
				dataProvider(POST_OFF_RESOURCE, `mission/rider/${id}/complete-unload`, {
					data: {}
				})
				.then(({ data }) => {
					history.goBack();
				})
				.catch((e) => {
					setStatus('FETCHING_DATA_ERROR');
				});
			} else {
				setStatus('PERMISSION_REQUIRED');
			}
		}
    }, [actionStatus, dataProvider, history, id, permission, permissions]);

	useEffect(() => {
		if(actionStatus === 'COMPLETE_LOAD' && id && permissions) {
			if(permissions.includes(`${permission}-${MissionRiderActions.completeLoad}`)) {
				setStatus('FETCHING_DATA');
				dataProvider(POST_OFF_RESOURCE, `mission/rider/${id}/complete-load`, {
					data: {}
				})
				.then(({data}) => {
					setStatus('FETCH_DATA');
				})
				.catch((e) => {
					setStatus('FETCHING_DATA_ERROR');
				});
			} else {
				setStatus('PERMISSION_REQUIRED');
			}
		}
    }, [dataProvider, history, id, permission, permissions, actionStatus]);

	useEffect(() => {
		if(actionStatus === 'START_DELIVERY' && id && permissions) {
			if(permissions.includes(`${permission}-${MissionRiderActions.deliverUnload}`)) {
				setStatus('FETCHING_DATA');
				dataProvider(POST_OFF_RESOURCE, `mission/rider/${id}/deliver-unload`, {
					data: {}
				})
				.then(({data}) => {
					history.goBack();
				})
				.catch((e) => {
					setStatus('FETCHING_DATA_ERROR');
				});
			} else {
				setStatus('PERMISSION_REQUIRED');
			}
		}
    }, [dataProvider, history, id, permission, permissions, actionStatus]);

	return (
		<Dialog fullScreen open={true} onClose={() => history.goBack()} TransitionComponent={Transition}>
			<AppBar className={classes.appBarTop} >
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => history.goBack()} aria-label="close">
                        <CloseIcon />
                    </IconButton>	
                    <Typography variant="h6" className={classes.mainTitle} >
                        Missione {data && data.type && <strong style={{margin: '0 8px'}}>{data.type.toUpperCase()}</strong>}
						{data && data.type && {
							'smartfridge': <KitchenIcon />,
							'coffee': <LocalCafeIcon />,
							'delivery': <LocalShippingIcon />,
							'catering': <RestaurantIcon />,
							'store-delivery': <StorefrontIcon />,
							'waste': <DeleteIcon />,
							'other': <SyncAltIcon />,
						}[data.type]}
                    </Typography>
                </Toolbar>
            </AppBar>
			<Container className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
					<Card>
						{status === 'DATA_FETCHED' && data && (data.sellingPoint || (data.place && data.place.name)) && <AppBar color="default" position="static">
							<Toolbar>
								<Typography variant="h6" className={classes.title}>
						 			{data.sellingPoint ? data.sellingPoint.name : data.place.name}
								</Typography>
								{data && data.destinationWarehouseLocation && data.destinationWarehouseLocation.locker && data.destinationWarehouseLocation.locker.lockId && <Button variant="contained" color="primary" endIcon={<KitchenIcon/>} onClick={() => {
										dataProvider(GET_OFF_RESOURCE_PLAIN, `lock/${data.destinationWarehouseLocation.locker.lockId}/open`)
											.then(() => {})
											.catch(() => {});
								}}>APRI</Button>}
							</Toolbar>
						</AppBar>}
						{status === 'DATA_FETCHED' && data && <div className={classes.infoContainer}>
							<Typography variant="h6">
								<small>consegna</small> {data.place.address}
							</Typography>
							<Typography variant="h6">
								<small>ore</small> {`${data.date.getHours().toString().padStart(2, '0')}:${data.date.getMinutes().toString().padStart(2, '0')}`} {`${data.date.getDate().toString().padStart(2, '0')}/${(data.date.getMonth() + 1).toString().padStart(2, '0')}/${data.date.getFullYear()}`}
							</Typography>
							<div>
								<div dangerouslySetInnerHTML={{ __html: data.comment }} />
							</div>
						</div>}
					</Card>
				</Grid>
				{status === 'DATA_FETCHED' && data && ['smartfridge', 'coffee'].includes(data.type) && data && data.destinationWarehouseLocationId && <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
					<Card>
						<AppBar color="default" position="static">
							<Toolbar>
								<div style={{
									display: 'flex',
									flex: 1,
									alignItems: 'center'
								}}>
								<Typography variant="h5">
									Inventario - {data.inventory === 'mandatory' ? 'obbligatorio' : 'non obbligatorio'}
								</Typography>
								<Button style={{margin: '0 0 0 16px'}} endIcon={<DeveloperModeIcon />} size="large" disabled={status !== 'DATA_FETCHED'} variant="contained" color="primary" onClick={() => {
									const jsonData = encodeURIComponent(JSON.stringify({
										itemCountMode: 'full',
										lotCountMode: 'full',
										cycleCountType: 'mission',
									}));
									history.push(`/inventory-complete/${data.destinationWarehouseLocationId}?json_data=${jsonData}`)
								}}>COMPLETO</Button>
								</div>
								<Button endIcon={<AddIcon /> } size="large" style={{margin: '0 16px'}} color="primary" variant='outlined' onClick={() => {
									history.push(`/create-warehouse-acc-account/${data.destinationWarehouseLocationId}`);
								}}>Aggiungi</Button>
								<Button size="large" disabled={status !== 'DATA_FETCHED'} variant="contained" color="primary" onClick={() => {
									const data2Send = {
										itemCountMode:'cycleCount',
										cycleCountType:'mission',
										lotCountMode:'partial',
									};
									if(data.unLoadingTransfer) {
										setStatus('FETCHING_DATA_REQUESTEDITEMTRANSFER');
										dataProvider(GET_OFF_RESOURCE, `requestedItemTransfer?filter=transactionParentId||eq||${data.unLoadingTransfer.transactionId}`)
											.then((response) => {
												if(!response || !response.data) {
													setStatus('FETCHING_DATA_ERROR_REQUESTEDITEMTRANSFER')
												} else {
													data2Send['fullItemIds'] = response.data.map(dataItem => dataItem.itemId);
													history.push(`/inventory-complete/${data.destinationWarehouseLocationId}?json_data=${encodeURIComponent(JSON.stringify(data2Send))}`)
												}
											})
											.catch(() => {
												setStatus('FETCHING_DATA_ERROR_REQUESTEDITEMTRANSFER');
											})
									} else {
										history.push(`/inventory-complete/${data.destinationWarehouseLocationId}?json_data=${encodeURIComponent(JSON.stringify(data2Send))}`)
									}
								}}>INVENTARIO</Button>
							</Toolbar>
						</AppBar>
					</Card>
				</Grid>}
				{status === 'DATA_FETCHED' && data && ['smartfridge','store-delivery','other','coffee'].includes(data.type) && <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
					<Card>
						<AppBar color="default" position="static">
							<Toolbar>
								<Typography variant="h5" className={classes.title}>
									Scarico
								</Typography>
								{data.unLoadingTransfer && data.unLoadingTransfer.transferStateMachine &&
									<UnloadTransfer
										startUnload={() => {
											setActionStatus('REQUEST_START_UN_LOAD')
										}}
										continueUnload={() => {
											setActionStatus('CONTINUE_UN_LOAD')
										}}
										status={data.unLoadingTransfer.transferStateMachine.status}/>}
								{!data.unLoadingTransfer && <Typography variant="subtitle1">
									nulla da scaricare
								</Typography>}
							</Toolbar>
						</AppBar>
					</Card>
				</Grid>}
				{status === 'DATA_FETCHED' && data && <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
					<Card>
						<AppBar color="default" position="static">
							<Toolbar>
								<Typography variant="h5" className={classes.title}>
									Carico
								</Typography>
								{data.sellingPoint && data.sellingPoint.layoutId && 
									<Button
										style={{marginRight: '16px'}}
										color='primary' 
										size="large" 
										variant='contained' 
										onClick={() => history.push(`/mission_rider/layout-list/${data.sellingPoint.layoutId}/show`)}>
										Apri layout
									</Button>
								}
								{data.loadingTransfer && data.loadingTransfer.transferStateMachine &&
									<LoadTransfer
										completeLoad={() => {
											setActionStatus('REQUEST_COMPLETE_LOAD')
										}}
										status={data.loadingTransfer.transferStateMachine.status}/>}
								{!data.loadingTransfer && <Typography variant="subtitle1">
									nulla da caricare
								</Typography>}
							</Toolbar>
						</AppBar>
					</Card>
				</Grid>}
				{status === 'DATA_FETCHED' && data && ['smartfridge','store-delivery','other','coffee'].includes(data.type) && <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
					<Card>
						<AppBar color="default" position="static">
							<Toolbar>
								<Typography variant="h5" className={classes.title}>
									Inizia Trasporto
								</Typography>
								{status === 'DATA_FETCHED' && data && data.unLoadingTransfer && data.unLoadingTransfer.transferStateMachine &&
									<DeliverUnLoad
										deliverUnLoad={() => {
											setActionStatus('START_DELIVERY')
										}}
										status={data.unLoadingTransfer.transferStateMachine.status}/>
								}
								{status === 'DATA_FETCHED' && data && !data.unLoadingTransfer && <Typography variant="subtitle1">
								nulla da trasportare
								</Typography>}
							</Toolbar>
						</AppBar>
					</Card>
				</Grid>}
				{status === 'DATA_FETCHED' && data && ['smartfridge','store-delivery','other','coffee'].includes(data.type) && <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
					<Card>
						<AppBar color="default" position="static">
							<Toolbar>
								<Typography variant="h5" className={classes.title}>
									Scarico in HUB
								</Typography>
								{status === 'DATA_FETCHED' && data && data.unLoadingTransfer && data.unLoadingTransfer.transferStateMachine &&
									<UnloadTransferFinal
										completeUnload={() => {
											setActionStatus('REQUEST_COMPLETE_UN_LOAD')
										}}
										status={data.unLoadingTransfer.transferStateMachine.status}/>
								}
								{status === 'DATA_FETCHED' && data && !data.unLoadingTransfer && <Typography variant="subtitle1">
								nulla da scaricare
								</Typography>}
							</Toolbar>
						</AppBar>
					</Card>
				</Grid>}
				{((status === 'DATA_FETCHED' && data && data.loadingTransfer) || (status === 'DATA_FETCHED' && data && data.unLoadingTransfer)) && <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
					<Card>
						<AppBar color="default" position="static">
							<Toolbar>
								<Typography variant="h5" className={classes.title}>
									DDT
								</Typography>
								{documentStatus === 'HIDE' && status === 'DATA_FETCHED' && data && (
									(data.loadingTransfer && data.loadingTransfer.transferStateMachine && ['delivering','completed'].includes(data.loadingTransfer.transferStateMachine.status))
										||
									(data.unLoadingTransfer && data.unLoadingTransfer.transferStateMachine && ['delivering','completed'].includes(data.unLoadingTransfer.transferStateMachine.status))
								) && <Button
									size="small"
									onClick={() => {
										setDocumentStatus('SHOW')
									}}
									variant="contained"
									color="primary"
									startIcon={<VisibilityIcon />}>
									VISUALIZZA DDT
								</Button>}
								{documentStatus === 'SHOW' && status === 'DATA_FETCHED' && data && data.loadingTransfer && data.loadingTransfer.transferStateMachine && ['delivering','completed'].includes(data.loadingTransfer.transferStateMachine.status) && <DDTTransfer title="DDT CARICO" transferId={data.loadingTransfer.id} />}
								{documentStatus === 'SHOW' && status === 'DATA_FETCHED' && data && data.unLoadingTransfer && data.unLoadingTransfer.transferStateMachine && ['delivering','completed'].includes(data.unLoadingTransfer.transferStateMachine.status) && <div style={{marginLeft: '8px'}}><DDTTransfer title="DDT SCARICO" transferId={data.unLoadingTransfer.id} /></div>}
							</Toolbar>
						</AppBar>
					</Card>
				</Grid>}
			</Grid>
			<Backdrop className={classes.backdrop} open={backdropOpenStatusList.includes(status)} onClick={null}>
				{['FETCHING_DATA_REQUESTEDITEMTRANSFER', 'FETCHING_DATA'].includes(status) && <Typography variant="h4">
					Caricamento dati in corso
				</Typography>}
				{['FETCHING_DATA_ERROR_REQUESTEDITEMTRANSFER', 'FETCHING_DATA_ERROR'].includes(status) && <Typography variant="h4">
					Errore caricamento dati
				</Typography>}
				{['FETCHING_DATA_REQUESTEDITEMTRANSFER', 'FETCHING_DATA'].includes(status) && <CircularProgress color="inherit" />}
				{status === 'PERMISSION_REQUIRED' && <Typography variant="subtitle1">
					<PanToolIcon></PanToolIcon> Missing permission
				</Typography>}
				{status === 'FETCHING_DATA_ERROR' && <Button
						style={{margin: '8px 0'}}
                        size="small"
                        onClick={() => {
							setStatus('FETCH_DATA');
							setActionStatus('INITIAL');
                        }}
                        variant="contained"
                        color="primary"
                        startIcon={<CachedIcon />}>
                        RICARICA
                    </Button>}
				{['FETCHING_DATA_ERROR_REQUESTEDITEMTRANSFER'].includes(status) &&  <Button
					className={classes.cancelButton}
					size="small"
					onClick={() => {
						setStatus('DATA_FETCHED')
					}}
					variant="contained"
					color="primary">
					Annulla
				</Button>}
				{(status === 'PERMISSION_REQUIRED' || status === 'FETCHING_DATA_ERROR') && <Button variant="contained" color="default" onClick={() => { history.goBack()}}>ESCI</Button>}
			</Backdrop>
			<Backdrop className={classes.backdropConfirm} open={confirmStatusList.includes(actionStatus)} onClick={null}>
				<Typography variant="h4">
					{actionStatus === 'REQUEST_START_UN_LOAD' && 'Conferma inizio scarico' }
					{actionStatus === 'REQUEST_CONTINUE_UN_LOAD' && 'Conferma continua scarico' }
					{actionStatus === 'REQUEST_COMPLETE_UN_LOAD' && 'Conferma completa scarico' }
					{actionStatus === 'REQUEST_COMPLETE_LOAD' && 'Conferma completa carico' }
				</Typography>
				<Button
					style={{margin: '8px 0'}}
					size="large"
					onClick={() => {
						switch (actionStatus) {
							case 'REQUEST_START_UN_LOAD':
								setActionStatus('START_UN_LOAD');
								break;
							case 'REQUEST_CONTINUE_UN_LOAD':
								setActionStatus('CONTINUE_UN_LOAD');
								break;
							case 'REQUEST_COMPLETE_UN_LOAD':
								setActionStatus('COMPLETE_UN_LOAD');
								break;
							case 'REQUEST_COMPLETE_LOAD':
								setActionStatus('COMPLETE_LOAD');
								break;
							default:
								break;
						}
					}}
					variant="contained"
					color="primary">
					Conferma
				</Button>
				<Button size="small" variant="contained" color="default" onClick={() => { setActionStatus('INITIAL');}}>ANNULLA</Button>
			</Backdrop>
		</Container>
		</Dialog>
	)
};

function UnloadTransfer(props) {
	switch (props.status) {
		case 'todo':
			return (
				<Button
					size="large"
					onClick={props.startUnload}
					variant="contained"
					color="primary">
					COMINCIA
                </Button>
			);
		case 'to_finish':
			return (
				<Button
					size="large"
					onClick={props.startUnload}
					variant="contained"
					color="primary">
					CONTINUA
				</Button>
			);
		case 'wip':
			return (
				<Button
					size="large"
					onClick={props.continueUnload}
					variant="contained"
					color="primary">
					CONTINUA
				</Button>
			);
		case 'delivering':
		case 'picked':
		case 'completed':
			return (
				<Typography variant="subtitle1">
					Completato
				</Typography>
			);
		default:
			return (
				<Typography variant="subtitle1">
					Errore contattare Hub
				</Typography>
			);
	}
}

function LoadTransfer(props) {
	switch (props.status) {
		case 'delivering':
			return (
				<Button
				size="large"
				onClick={props.completeLoad}
				variant="contained"
				color="primary">
					COMPLETA
				</Button>
			);
		case 'completed':
			return (
				<Typography variant="subtitle1">
					Completato
				</Typography>
			);
		default:
			return (
				<Typography variant="subtitle1">
					Errore contattare Hub
				</Typography>
			);
	}
}

function DeliverUnLoad(props) {
	switch (props.status) {
		case 'picked':
			return (
				<Button	
					size="large"
					onClick={props.deliverUnLoad}
					variant="contained"
					color="primary">
					Inizia
				</Button>
			);
		case 'delivering':
		case 'completed':
			return (
				<Typography variant="subtitle1">
					Completato
				</Typography>
			);
		case 'to_finish':
		case 'todo':
		case 'wip':
			return (
				<Typography variant="subtitle1">
					non disponibile
				</Typography>
			);
		default:
			return (
				<Typography variant="subtitle1">
					Errore contattare Hub
				</Typography>
			);
	}
}

function UnloadTransferFinal(props) {
	switch (props.status) {
		case 'delivering':
			return (
				<Button	
					size="large"
					onClick={props.completeUnload}
					variant="contained"
					color="primary">
					COMPLETA
				</Button>
			);
		case 'completed':
			return (
				<Typography variant="subtitle1">
					Completato
				</Typography>
			);
		case 'to_finish':
		case 'picked':
		case 'todo':
		case 'wip':
			return (
				<Typography variant="subtitle1">
					non disponibile
				</Typography>
			);
		default:
			return (
				<Typography variant="subtitle1">
					Errore contattare Hub
				</Typography>
			);
	}
}