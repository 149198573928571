import React from 'react';
import { Toolbar, SaveButton, DeleteButton, Edit, SimpleForm, FormDataConsumer, NumberInput, BooleanInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import BaseEdit from '../inheritance/BaseEdit';
import { required } from 'react-admin';
import { DIMENSIONS } from './enum';
import { Permissions } from '../../permissions/permissions';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

function CustomToolbar(props) {
    let history = useHistory();
    return (
        <Toolbar {...props} classes={useStyles()}>
            <SaveButton />
            <DeleteButton redirect={() => {
                history.goBack();
                return '';
            }} />
        </Toolbar>
    );
}

export const ItemSaleablePriceListEditComponent = props => {
    let history = useHistory();
    return (
        <Edit {...props}>
            <SimpleForm toolbar={<CustomToolbar/>} redirect={() => {
                history.goBack();
                return '';
            }}>
                <ReferenceInput
                    fullWidth
                    filterToQuery={searchText => {
                    if(!searchText)
                        return {};

                        return {
                            "item.name": searchText,
                            "item.saleable": true
                        }
                    }}
                    label="Item" source="itemSaleableId" reference={`${Permissions.itemSaleable.feature}`} validate={[required("Indicare l'item è obbligatorio")]}>
                    <AutocompleteInput
                        shouldRenderSuggestions={val => val && val.length >= 2 }
                        options={{
                            suggestionsContainerProps: {disablePortal: true},
                            disabled: true,
                        }}
                        optionText={(record)=> `${record ? record.item.id : ''} ${record ? record.item.name : ''}`}
                        optionValue="id" />
                </ReferenceInput>
                <NumberInput
                    label="Ordine"
                    source="order"
                    validate={[required("L'ordine è obbligatorio")]}
                />
                <BooleanInput fullWidth label="Prezzo differente" source="differentPrice" ></BooleanInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return formData.differentPrice ? (
                            [
                                <NumberInput
                                    validate={[required("Il prezzo è obbligatorio")]}
                                    key={`price-formDataConsumer`}
                                    fullWidth
                                    label="Prezzo"
                                    source="price"
                                    {...rest}
                                />
                            ]
                        ) : null
                    }}
                </FormDataConsumer>
                <BooleanInput fullWidth label="Nuovo" source="new"></BooleanInput>
                <BooleanInput fullWidth label="Visualizza in Impulso" source="showInImpulse"></BooleanInput>
                <BooleanInput fullWidth label="Visualizza in Schermo Menu" source="showInScreenMenu"></BooleanInput>
                <AutocompleteInput
                    allowEmpty
                    fullWidth
                    label="Visualizza su menu da app"
                    source="menuDisplayType"
                    validate={[required("Obbligatorio")]}
                    options={{suggestionsContainerProps: {disablePortal: true}}}
                    choices={DIMENSIONS.map(dimension => {
                        return {
                            id: dimension,
                            name: dimension
                        }
                    
                })} />
                <BaseEdit></BaseEdit>
            </SimpleForm>
        </Edit>
    )
};

export const ItemSaleablePriceListEdit = connect((state, props) => {
    return {
        priceListId: state.admin.resources.itemSaleablePriceList && state.admin.resources.itemSaleablePriceList.data[props.id] ? state.admin.resources.itemSaleablePriceList.data[props.id].priceListId : null
    }
})(ItemSaleablePriceListEditComponent)