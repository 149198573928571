import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import Aside from '../inheritance/BaseAside';

export const GatewayShow = (props) => {
    return (
    <Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Nome" source="name" />
            <TextField label="MainUserId" source="mainUserId" />
        </SimpleShowLayout>
    </Show>
)};
