import React from 'react';
import BaseEdit from '../inheritance/BaseEdit';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';

export const ForecastCategoryEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput lable="Name" source="name" validate={[required()]}/>
            <BaseEdit />
        </SimpleForm>
    </Edit>
);