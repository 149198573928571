import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import Aside from '../inheritance/BaseAside';

export const ClientCategoryShow = (props) => {
    return (
    <Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Nome Interno" source="internalName" />
            <TextField label="Nome Cliente" source="clientName" />
            <TextField label="URL Icona" source="iconURL" />
            <TextField label="Photo URL" source="photoURL" />
            <TextField label="Ordine" helperText="numero da 1 a x" source="order" />
        </SimpleShowLayout>
    </Show>
)};
