import React from 'react';
import { Datagrid, List, TextField, NumberInput, ReferenceInput, AutocompleteInput, ReferenceField, DateField } from 'react-admin';
import Filter from '../../inheritance/BaseFilter';
import ListActions from '../../../components/ListActions';
import { Permissions } from '../../../permissions/permissions';
const WarehouseInventoryFilter = (props) => (
    <Filter {...props}>
       <NumberInput label="ID" source="id" alwaysOn />
       <ReferenceInput
            alwaysOn
            enableGetChoices={val => {
				return !!(val && val.label && val.label.length >= 2);
			}}
            filterToQuery={searchText => {
                if (!searchText)
                    return {
                        "type": "RibesUser",
                    };

                return {
                    "label": searchText,
                    "type": "RibesUser"
                }
            }}
            label="Editor"
            source="last_update_user||eq"
            reference={Permissions.mainUser.feature}>
            <AutocompleteInput
                optionText={(record) => {
                    if (!record || !record.id) {
                        return 'cancel';
                    }
                return `${record ? `${record.id} - ${record.label}` : ''}`
            }}
            optionValue="id" />
        </ReferenceInput>
       <ReferenceInput
            alwaysOn
            emptyText="cancel"
            filterToQuery={searchText => {
                if (!searchText)
                    return {};

                return {
                    "name": searchText,
                }
                }}
                label="Warehouse location"
                source="locationId"
                reference={Permissions.warehouseLocation.feature}
			>
            <AutocompleteInput
                optionText="name"
                optionValue="id" />
        </ReferenceInput>
        <NumberInput label="Transaction ID" source="transactionId||eq" />
        <NumberInput label="transactionParentId" source="transactionParentId||eq"/>
    </Filter>
);

export const WarehouseInventoryList  = props => (
    <List {...props} actions={<ListActions />} filters={< WarehouseInventoryFilter />} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="show">
            <TextField label="ID" source="id"  />
            <ReferenceField link='show' label='Editor' source="last_update_user" reference={Permissions.mainUser.feature}>
                    <TextField source="label" />
            </ReferenceField>
            <ReferenceField link='show'  label='Warehouse location' source="locationId" reference={Permissions.warehouseLocation.feature}> 
                    <TextField source="name" />
            </ReferenceField>
            <DateField showTime label="Aggiornato il" source="updated_at" locales="it-IT" />
        </Datagrid>
    </List>
);