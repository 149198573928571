import React from 'react';
import { Fragment } from 'react';
import { BooleanInput } from 'react-admin';
import { useForm } from 'react-final-form';

export default ({ ...rest }) => {
    const form = useForm();
    return (
        <Fragment>
            <BooleanInput
            {...rest}
            onChange={() => {
                form.change('supplierPriceListId', undefined);
                form.change('unitPriceNoTax', undefined);
                form.change('taxId', undefined);
                form.change('minimumOrder', undefined);
                form.change('rounding', undefined);
            }}
            defaultValue={true}
            fullWidth
            label="Cerca per Id"
            source="searchForId" />
        </Fragment>
    )
}
