import React from 'react';
import { Edit, SimpleForm, TextInput, AutocompleteInput, AutocompleteArrayInput, DateInput, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import BaseEdit from '../inheritance/BaseEdit';
import { required } from 'react-admin';
import { useHistory } from "react-router-dom";

export function TimeSlotRuleEdit(props) {
    const history = useHistory();

    return (
        <Edit undoable={false} {...props}>
            <SimpleForm toolbar={<CustomToolbar />} redirect={history.goBack} >
                <TextInput fullWidth disabled={true} label="entityType" source="entityType" validate={[required('campo obbligatorio')]} />
                <TextInput fullWidth disabled={true} label="entityId" source="entityId" validate={[required('campo obbligatorio')]} />
                <AutocompleteInput
                    validate={[required('campo obbligatorio')]}
                    initialValue='Available'
                    fullWidth
                    label="Disponibilità"
                    source="availability"
                    choices={['Available', 'NotAvailable'].map(type => {
                        return {
                            id: type,
                            name: type
                        }
                    })} />
                <AutocompleteArrayInput
                    validate={[required('campo obbligatorio')]}
                    fullWidth
                    label="Giorni della settimana"
                    source="weekdays"
                    choices={['1', '2', '3', '4', '5', '6', '0'].map(weekday => {
                        return {
                            id: weekday,
                            name: {
                                '1': 'LUN',
                                '2': 'MAR',
                                '3': 'MER',
                                '4': 'GIO',
                                '5': 'VEN',
                                '6': 'SAB',
                                '0': 'DOM',
                            }[weekday],
                        }
                    })} />
                    <TextInput
                        validate={[required('campo obbligatorio')]}
                        fullWidth
                        initialValue={"09:00"}
                        helperText="Inizio Formato: HH:MM"
                        label=""
                        source="startTime" type="time"/>
                    <TextInput
                        validate={[required('campo obbligatorio')]}
                        fullWidth
                        initialValue={"18:00"}
                        helperText="Fine Formato: HH:MM"
                        label=""
                        source="endTime"  type="time"/>
                    <DateInput
                        fullWidth
                        label="Data inizio"
                        source="startDate"
                        helperText="non obbligatoria"
                    />
                    <DateInput
                        fullWidth
                        label="Data fine"
                        source="endDate"
                        helperText="non obbligatoria"
                    />
                <BaseEdit />
            </SimpleForm>
        </Edit>
    );
}

function CustomToolbar(props) {
    let history = useHistory();

    return (
        <Toolbar {...props} style={{
            display: 'flex',
            justifyContent: 'space-between',
        }}>
            <SaveButton />
            <DeleteButton mutationMode="pessimistic" redirect={history.goBack} />
        </Toolbar>
    );
}