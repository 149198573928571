import { CorporateCreditEdit } from "./CorporateCreditEdit";
import { CorporateCreditList } from "./CorporateCreditList";
import { CorporateCreditCreate } from "./CorporateCreditCreate";
import { CorporateCreditShow } from "./CorporateCreditShow";

export default { edit:CorporateCreditEdit, list:CorporateCreditList, create:CorporateCreditCreate, show: CorporateCreditShow};

export const TIME_INTERVAL_TYPE_VALUES = [
  'daily',
  'weekly',
  'monthly',
];
export const ENABLE_VALUES = ['DISABLED', 'ENABLED'];

