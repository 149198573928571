import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useHistory, useParams, useLocation } from "react-router-dom";

import { AddLot } from './AddLot';

const useStyles = makeStyles((theme) => ({
  media: {
    height: '100%',
  },
  itemContent: {
    minHeight: '140px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flex: 1,
    overflow: 'scroll',
    padding: theme.spacing(0, 1),
  },
  itemContainer: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    display: 'flex',
    height: '100%',
    padding: theme.spacing(7, 0, 0),
    margin: theme.spacing(-7, 0),
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      margin: theme.spacing(-6, 0),
      padding: theme.spacing(6, 0, 0),
    },
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(-8, 0),
      padding: theme.spacing(8, 0, 0),
    },
  },
  paginationContainer: {
    padding: theme.spacing(0, 1, 1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paginationButton: {
    width: '100%'
  },
  paginationText: {
    width: '80%' 
  },
  terminateButton: {
    margin: theme.spacing(0, 1, 1),
  },
}));

export default function Item(props) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const params = new URLSearchParams(location.search);
  const item = (params.get('item') === null || params.get('item') === undefined) ? 1 : (params.get('item') > 0 ? params.get('item') : 1);
  return (
    <Container className={classes.itemContainer} disableGutters>
      <CardMedia
        className={classes.media}
        image={props.item.image}
        title={props.item.title}
      />
      <div className={classes.itemContent}>
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
        }}>
          <Typography variant="h6">
          ID <strong>{props.item.id}</strong>
          </Typography>
          <Typography variant="h6">
          UoM <strong>{props.item.uom}</strong>
          </Typography>
        </div>
        <Typography>
          {props.item.title}
        </Typography>
      </div>
      <div className={classes.paginationContainer}>
        <Button className={classes.paginationButton} disabled={Number.parseInt(item) <= 1} variant="outlined" color="primary" onClick={() => {
          history.replace(`/inventory/${id}?item=${Number.parseInt(item) - 1}`)
        }}>Precedente</Button>
        <Typography align="center" className={classes.paginationText}>
          {item} di {props.itemLenght}
        </Typography>
        <Button className={classes.paginationButton} disabled={(props.itemLenght === 1) || (item && Number.parseInt(item) === props.itemLenght)} variant="outlined" color="primary" onClick={() => {
          history.replace(`/inventory/${id}?item=${Number.parseInt(item) + 1}`)
        }}>Successivo</Button>
      </div>
      <Button className={classes.terminateButton} size='large' variant="outlined" color="primary" onClick={props.terminate}>Termina</Button>
      {props.showAddButton && <AddLot orderItem={Number.parseInt(item)} orderLot={props.lotLength} refreshData={props.refreshData} warehouseInventoryId={id} itemId={props.item.id} />}
    </Container>
  );
}
