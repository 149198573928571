import React, {  useEffect, useState } from 'react';
import { Edit, SimpleForm, TextInput, ReferenceArrayInput, AutocompleteArrayInput, SelectArrayInput, NumberInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import BaseEdit from '../../inheritance/BaseEdit';
import { required } from 'react-admin';
import { useForm } from 'react-final-form';
import { Permissions } from '../../../permissions/permissions';

export const SupplierEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="Nome" source="name" validate={[required('Il nome è obbligatorio')]} />
            <TextInput label="Ragione sociale" source="businessName" validate={[required('Ragione sociale obbligatoria')]} />
            <TextInput label="Partita IVA" source="vatNumber" validate={[required('Partita IVA obbligatoria')]} />
            <TextInput label="Metodo di Pagamento" source="paymentMethod" validate={[required('Metodo di Pagamento obbligatorio')]} />
            <TextInput label="Email di invio Ordini Acquisto" source="email" type="email" />
            <TextInput label="Telefono" source="telephone" />
            <TextInput label="Cellulare" source="phone" />
            <TextInput label="Città" source="city" />
            <ReferenceArrayInput
                filter={{"canPurchase||eq": true}}
				filterToQuery={searchText => {
					if(!searchText)
						return null;

					return {
                        name: searchText,
                        "canPurchase||eq": true
                    }
				}} label="Lista Luoghi consegna serviti" source="placesIds" reference={Permissions.place.feature}>
				<AutocompleteArrayInput optionText="name" />
			</ReferenceArrayInput>
            <NumberInput label="Minimo d’Ordine (€) per consegna gratuita" source="minimumOrderForFreeDelivery"></NumberInput>
            <NumberInput label="Costo consegna €" source="deliveryCost"></NumberInput>
            <NumberInput label="Minimo d’Ordine (€) assoluto" source="minimumOrder"></NumberInput>
            <NumberInput label="Tempo di consegna in giorni" source="deliveryDays"></NumberInput>
            <SelectArrayInput label="Giorni di consegna" source="deliveryWeekDays" choices={[
                {id: 'monday', name: 'Lunedì'},
                {id: 'tuesday', name: 'Martedí'},
                {id: 'wednesday', name: 'Mercoledí'},
                {id: 'thursday', name: 'Giovedì'},
                {id: 'friday', name: 'Venerdì'},
                {id: 'saturday', name: 'Sabato'},
                {id: 'sunday', name: 'Domenica'},
            ]} ></SelectArrayInput>
            <ReferenceInput
                filter={{"usage||eq": "Supplier"}}
                filterToQuery={searchText => {
                    if(!searchText)
                        return {};

                    return {
                        "name": searchText
                    }
                }}
                label="Magazzino Virtuale"
                source="warehouseLocationId"
                reference={Permissions.warehouseLocation.feature}>
                    <AutocompleteInput
                        optionText="name"
                        optionValue="id" />
            </ReferenceInput>
            <TextInput label="Note" source="note" />
            <Places />
            <BaseEdit />
        </SimpleForm>
    </Edit>
);
export function Places(props) {
	const form = useForm();
	const [status, setStatus] = useState('INIT');

	useEffect(() => {
        if(status === 'INIT') {
			setStatus('LOADED');
            form.change('placesIds', props.record.places.map(place => place.id));
        }
	}, [form, props.record.places, status])

	return null;
}