import { Permissions } from "../../../permissions/permissions";

/*
When adding a new key to this file, please ensure to include the following filters in the feature listing.

```jsx
<NumberInput label="Transaction ID" source="transactionId||eq" />
<NumberInput label="transactionParentId" source="transactionParentId||eq"/>
```
*/

export const transactionToObjectLinkMap = {
  mission: {
    feature: Permissions.mission.feature,
    objectLabel: "Missione",
    objectListLabel: "Lista Missioni",
  },
  missionJob: {
    feature: Permissions.missionJob.feature,
    objectLabel: "Servizio missione",
    objectListLabel: "Lista servizi missione",
  },
  payment: {
    feature: Permissions.payment.feature,
    objectLabel: "Pagamento",
    objectListLabel: "Lista Pagamenti",
  },
  paymentAttempt: {
    feature: Permissions.paymentAttempt.feature,
    objectLabel: "Tentativo di pagamento",
    objectListLabel: "Lista tentativi di pagamento",
  },
  purchaseOrder: {
    feature: Permissions.purchaseOrder.feature,
    objectLabel: "ODA",
    objectListLabel: "Lista ODA",
  },
  sellingOrder: {
    feature: Permissions.sellingOrder.feature,
    objectLabel: "ODV",
    objectListLabel: "Lista ODV",
  },
  transfer: {
    feature: Permissions.transfer.feature,
    objectLabel: "Trasferimento",
    objectListLabel: "Lista Trasferimenti",
  },
  walletTransfer: {
    feature: Permissions.walletTransfer.feature,
    objectLabel: "Wallet transfer",
    objectListLabel: "Lista Wallet Transfer",
  },
  walletManualTransfer: {
    feature: Permissions.walletManualTransfer.feature,
    objectLabel: "Wallet manual transfer",
    objectListLabel: "Lista Wallet Manual Transfer",
  },
  warehouseInventory: {
    feature: Permissions.warehouseInventory.feature,
    objectLabel: "Inventario magazzino",
    objectListLabel: "Lista inventari magazzino",
  },
  // TODO: Can we uncomment this?
  // walletRecharge: {
  //   feature: Permissions.walletRecharge.feature,
  //   objectLabel: "Ricarica wallet",
  //   objectListLabel: "Lista ricariche wallet",
  // },
};
