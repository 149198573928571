import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField } from 'react-admin';
import Aside from '../../inheritance/BaseAside';
import { Permissions } from '../../../permissions/permissions';

export const PurchasePointShow = (props) => {
    return (
    <Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Nome" source="name" />
            <ReferenceField
				label="Luogo"
				source="placeId"
                reference={Permissions.place.feature}
                link="show"
				>
				<TextField source="name" />
			</ReferenceField>
        </SimpleShowLayout>
    </Show>
)};
