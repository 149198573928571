import React from 'react';
import { Edit, SimpleForm, TextInput, AutocompleteInput, required, BooleanInput, FormDataConsumer } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import BaseEdit from '../inheritance/BaseEdit';
import { useHistory } from "react-router-dom";

export function MainUserEdit(props) {
    let history = useHistory();
    return (
        <Edit {...props}>
            <SimpleForm
                redirect={() => {
                    history.goBack();
                    return '';
                }}>
                <TextInput
                    disabled
                    source="type" />
                <TextInput
                    validate={[required()]}
                    source="label" />
                <TextInput source="sdi" />
                <AutocompleteInput
                    validate={[required()]}
                    source="registryType"
                    choices={['Company', 'PhysicalPerson'].map(type => {
                        return {
                            id: type,
                            name: type
                        }
                })} />
                <TextInput source="contactPerson" />
                <TextInput source="vatNumber" />
                <TextInput source="taxCode" />
                <TextInput source="country" />
                <TextInput source="address" />
                <TextInput source="city" />
                <TextInput source="cap" />
                <TextInput source="province" />
                <TextInput source="addressNote" />
                <TextInput source="emails" />
                <TextInput source="pec" />
                <TextInput source="phone" />
                <RichTextInput source="extraNote" />
                <FormDataConsumer>
                    {({ formData }) => {
                        if('RibesUser' === formData.type) {
                            return (
                                <>
                                    <BooleanInput source="planner" />
                                    <BooleanInput source="deliveryMan" />
                                    <BooleanInput source="accountManager" />
                                </>
                            );
                        } else {
                            return null;
                        }
                    }}
                </FormDataConsumer>
                <BaseEdit />
            </SimpleForm>
        </Edit>
    );
}