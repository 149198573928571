import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import BaseCreate from '../../inheritance/BaseCreate';
import { required } from 'react-admin';
import { Permissions } from '../../../permissions/permissions';

export const PurchasePointCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Nome" source="name" validate={[required('Il nome è obbligatorio')]} />
            <ReferenceInput
                filter={{ "canPurchase||eq": true }} 
				filterToQuery={searchText => {
					if(!searchText)
						return {};

					return { "name": searchText }
				}}
				label="Il Luogo di Acquisto" source="placeId" reference={Permissions.place.feature} validate={[required('Il luogo è obbligatorio')]}>
                <AutocompleteInput
                    optionText="name"
                    optionValue="id" />
            </ReferenceInput>
            <BaseCreate />
        </SimpleForm>
    </Create>
);