import React, { cloneElement, useState, useEffect } from 'react';
import { useDataProvider, useNotify, TopToolbar, CreateButton, ExportButton, Button, sanitizeListRestProps, usePermissions, useRefresh } from 'react-admin';
import { Permissions, CorporateCreditActions } from '../../../permissions/permissions';
import PublishIcon from '@material-ui/icons/Publish';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { GET_OFF_RESOURCE } from '../../../providers/nestjs_crud';

function CorporateCreditListActions({
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter,
    filterValues,
    permanentFilter,
    hasCreate,
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    ...rest
}) {
    return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {hasCreate && <CreateButton basePath={basePath} /> }
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={{ ...filterValues, ...permanentFilter }}
            exporter={exporter}
            maxResults={maxResults}
        />
        <Publish />
    </TopToolbar>
)};

CorporateCreditListActions.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
};

export default CorporateCreditListActions;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
        width: '35px',
        padding: '8px',
        height: '20px',
    },
  }),
);

function Publish() {
    const classes = useStyles();

    const [status, setStatus] = useState('LOADING');

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh(); 
    let { permissions } = usePermissions();

    useEffect(() => {
        let timer = setTimeout(() => {
            setStatus('LOADED');
        }, 4000);
        return () => {
            clearTimeout(timer);
        };
    })

    if(permissions && !permissions.includes(`${Permissions.corporateCredit.feature}_${Permissions.corporateCredit.subFeatures.ruleEngine.name}-${CorporateCreditActions.publish}`)) {
        return null;
    }

    if(status === 'LOADING') {
        return (
            <div className={classes.root}>
              <LinearProgress />
            </div>
          );
    }

    return (
        <Button
        onClick={() => {          
            dataProvider(GET_OFF_RESOURCE, `${Permissions.corporateCredit.feature}/${CorporateCreditActions.publish}`)
            .then(({ data }) => {
                if(data.message === 'success') {
                    refresh();
                    notify(`sync effettuato`);
                    return;
                }
                throw data;
            })
            .catch(error => {
                alert(error);
            })

        }}
        label="Publish"
    >
        <PublishIcon />
    </Button>
    )
}