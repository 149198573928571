import React, { useEffect, useState } from "react";
import { useDataProvider, showNotification } from "react-admin";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  AppBar,
  Toolbar,
  Card,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Box,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import { Permissions } from "../../../../../permissions/permissions";
import { POST_OFF_RESOURCE } from "../../../../../providers/nestjs_crud";

import { UploadPhotoCard } from "./photo/UploadPhotoCard";
import { CameraCard } from "./photo/CameraCard";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },

  media: {
    height: 100,
  },
  fullImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  closeIcon: {
    width: "1.5em",
    height: "1.5em",
    marginRight: "0.5em",
    marginTop: "0.5em",
    position: "absolute",
    right: "0",
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-focusVisible": { backgroundColor: theme.palette.grey[200] },
  },
  deleteIcon: {
    width: "1.2em",
    height: "1.2em",
    marginRight: "0.1em",
    marginTop: "0.1em",
    position: "absolute",
    right: "0",
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-focusVisible": { backgroundColor: theme.palette.grey[200] },
  },
}));

function MediaCard(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Card className={classes.rootCard} onClick={handleOpenDialog}>
        <CardActionArea>
          <IconButton
            className={classes.deleteIcon}
            edge="start"
            onClick={(e) => {
              e.stopPropagation();
              props.onRemove();
            }}
          >
            <DeleteIcon />
          </IconButton>
          <CardMedia className={classes.media} image={props.image} />
        </CardActionArea>
      </Card>

      <Dialog open={open} onClose={handleCloseDialog} maxWidth="xl">
        <IconButton
          className={classes.closeIcon}
          edge="start"
          color="default"
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </IconButton>
        <img src={props.image} alt="Full Size" className={classes.fullImage} />
      </Dialog>
    </>
  );
}

export function FormPhotosTask(props) {
  const { task, config } = props;
  const [photoUrls, setPhotoUrls] = useState(
    config && config.photoUrls ? config.photoUrls : []
  );

  const [state, setState] = useState("INITIAL");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();

  useEffect(() => {
    if (state === "SEND_DATA") {
      setState("SENDING_DATA");

      dataProvider(
        POST_OFF_RESOURCE,
        `${Permissions.deliveryManFormPhotosTask.feature}/${task.id}`,
        {
          data: { photoUrls },
        }
      )
        .then((rawResponse) => {
          const response = rawResponse.data;
          if (response.success) {
            props.onDone && props.onDone();
            dispatch(showNotification(`Azione: ${task.type} completata`));
            setState("INITIAL");
            setIsDialogOpen(false);
          } else {
            let message = "Errore sconosciuto.";

            if (response.errors) {
              message = response.errors
                .map(({ constraints }) => {
                  return Object.keys(constraints)
                    .map((key) => constraints[key])
                    .join(", ");
                })
                .join(", ");
            }
            dispatch(showNotification(message, "error"));
          }
        })
        .catch((error) => {
          dispatch(
            showNotification(`Errore durante azione: ${error}`, "error")
          );
        });
    }
  }, [
    dataProvider,
    dispatch,
    props,
    props.onDone,
    photoUrls,
    state,
    task.id,
    task.type,
  ]);

  return (
    <>
      <Card>
        <AppBar color="default" position="static">
          <Toolbar>
            <Typography variant="h5" className={classes.title}>
              {task.label}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              disabled={state !== "INITIAL"}
              onClick={() => {
                setIsDialogOpen(true);
              }}
            >
              {"Carica"}
            </Button>
          </Toolbar>
        </AppBar>
      </Card>
      <Dialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          setPhotoUrls(config.photoUrls);
        }}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">
          <Box display="flex" alignItems="center">
            <Typography style={{ width: "100%" }} align="center" variant="h6">
              {task.label}
            </Typography>
            <Box>
              <IconButton onClick={() => setIsDialogOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent style={{ height: "20em" }}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Container maxWidth="xl" fullWidth={"lg"} className={classes.root}>
              <Grid container className={classes.container} spacing={3}>
                {photoUrls &&
                  photoUrls.map((photoUrl, index) => {
                    return (
                      <Grid key={`${photoUrl}-${index}`} item xs={6} lg={3}>
                        <MediaCard
                          image={photoUrl}
                          onRemove={() => {
                            const urls = photoUrls.filter(
                              (url) => url !== photoUrl
                            );
                            setPhotoUrls(urls);
                          }}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Container>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            borderTop: "0.1em solid #00000020",
            justifyContent: "space-between",
          }}
        >
          <Box style={{ align: "left" }}>
            <span style={{ margin: "1em" }}>
              <UploadPhotoCard
                onUrlsReady={(url) => {
                  setState("INITIAL");
                  setPhotoUrls(photoUrls.concat([url]));
                }}
                onUploading={() => {
                  setState("UPLOADING");
                }}
                onError={(error) => {
                  dispatch(showNotification("Error", error));
                }}
              />
            </span>

            <CameraCard
              onUrlsReady={(url) => {
                setState("INITIAL");
                setPhotoUrls(photoUrls.concat([url]));
              }}
              onUploading={() => {
                setState("UPLOADING");
              }}
              onError={(error) => {
                dispatch(showNotification("Error", error));
              }}
            />
          </Box>

          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setState("SEND_DATA");
            }}
          >
            {"Invia"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
