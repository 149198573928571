import { useEffect, useState } from "react";
import { useDataProvider, useRecordContext } from "react-admin";
import { POST_OFF_RESOURCE } from "../../../providers/nestjs_crud";

export function useFormatOperatorLabel() {
	const record = useRecordContext();
	const dataProvider = useDataProvider();
	const [status, setStatus] = useState("INITIAL");
	const [label, setLabel] = useState("");

	useEffect(() => {
		if (!record || status !== "INITIAL") {
			return;
		}

		setStatus("FETCHING");

		dataProvider(POST_OFF_RESOURCE, `condition/fieldOptions/operator`, {
			data: {
				factType: record.factType,
			},
		})
			.then(({ data }) => {
                setStatus("FETCHED");
				const name =
					data.choices.find(
						(element) => element.id === record.operator
					).name || record.operator;
				setLabel(name);
			})
			.catch((e) => {
				console.log(e);
				setStatus("FETCHING_ERROR");
			});
	}, [dataProvider, record, status]);

	if (!record || status !== "FETCHED") {
		return "";
	}

	return label;
}
