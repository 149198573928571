import React from 'react';
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, DeleteButton, NumberInput, SaveButton, Toolbar, required} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { Permissions } from '../../../permissions/permissions';
import BaseEdit from '../../inheritance/BaseEdit';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const CustomToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
        {props.data && !props.data.default && <DeleteButton redirect={props.redirect} />}
    </Toolbar>
);

export const SupplierPriceListEditComponent = props => {
    return (
	<Edit {...props}>
		<SimpleForm redirect={() => (`/supplier/${props.supplierId}/show`)} toolbar={<CustomToolbar data={props.data} redirect={() => (`/supplier/${props.supplierId}/show`)}/>}>
			<ReferenceInput disabled label="Item" source="itemPurchasableId" reference={`${Permissions.itemPurchasable.feature}`} >
				<SelectInput optionText="item.name" />
			</ReferenceInput>
			<ReferenceInput label="Fornitore" disabled source="supplierId" reference={Permissions.supplier.feature} initialValue={props.supplierId} >
				<SelectInput optionText="name" />
			</ReferenceInput>
			<ReferenceInput
				disabled
				label="Unità di fabbisogno"
				source="orderUnitId"
				reference={Permissions.unitOfMeasurement.feature}
				>
				<SelectInput optionText="name"/>
			</ReferenceInput>

			<ReferenceInput
				disabled
				label="Unità di costo/ordine"
				source="costUnitId"
				reference={Permissions.unitOfMeasurement.feature}
				>
				<SelectInput optionText="name"/>
			</ReferenceInput>

			<ReferenceInput
				disabled
				label="Item"
				source="itemPurchasableId"
				reference={Permissions.itemPurchasable.feature}>
				<SelectInput optionText="name"/>
			</ReferenceInput>

			<TextInput label="Referenza Item fornitore" source="supplierReference"  validate={[required("Referenza Item fornitore obbligatoria")]}></TextInput>
			<TextInput label="Codice Item fornitore" source="supplierCode"></TextInput>

			<NumberInput label="Costo senza IVA" source="costNoTax" helperText="Costo senza IVA per una Unità di costo/ordine/ordine/fornitore"  validate={[required("Costo senza IVA obbligatoria")]}></NumberInput>
			<NumberInput label="Minimo di ordine" helperText="in unità di costo/ordine/fornitore" source="minimumOrder" validate={[required("Minimo di ordine obbligatoria")]}></NumberInput>
			<NumberInput label="Arrotondamento" helperText="in unità di costo/ordine/fornitore" source="rounding" validate={[required("Arrotondamento obbligatoria")]}></NumberInput>

			<TextInput label="Nota" source="note"></TextInput>
			<BaseEdit />
		</SimpleForm>
	</Edit>
	);
}

export const SupplierPriceListEdit = connect((state, props) => {
    return {
        supplierId: state.admin.resources.supplierPriceList && state.admin.resources.supplierPriceList.data[props.id] ? state.admin.resources.supplierPriceList.data[props.id].supplierId : null,
        data: state.admin.resources.supplierPriceList && state.admin.resources.supplierPriceList.data[props.id] ? state.admin.resources.supplierPriceList.data[props.id] : null
    }
})(SupplierPriceListEditComponent)