import { LockEdit } from "./LockEdit";
import { LockList } from "./LockList";
import { LockCreate } from "./LockCreate";
import { LockShow } from "./LockShow";


export default {edit:LockEdit, list:LockList, create:LockCreate, show: LockShow};

export const ASSET_TYPE = [
    { id: 'Locker', name: 'Locker' },
    { id: 'Coffee-Fridge', name: 'Coffee-Fridge' },
    { id: 'Delivery-Truck', name: 'Furgone' },
    { id: 'Cold-Storage-Positive-0-4', name: 'Cella-Fresco' },
    { id: 'Cold-Storage-Positive-4-8', name: 'Cella-Frutta' },
    { id: 'Cold-Storage-Negative', name: 'Cella-Gelo' },
]
export const TEMPERATURE_TRAKING_STATE = [
    { id: 'enabled', name: 'Enabled' },
    { id: 'disabled', name: 'Disabled' },
]