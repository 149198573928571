import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import Aside from '../../inheritance/BaseAside';
import { Permissions } from '../../../permissions/permissions';
import StateMachineReferenceField from '../../../components/StateMachineReferenceField';

export const SellingOrderImpulseShow = (props) => {
	return (
	<Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
		<SimpleShowLayout>
			<StateMachineReferenceField
				label="Stato"
				source="orderStateMachine"
				reference={`${Permissions.sellingOrderImpulse.feature}_${Permissions.sellingOrderImpulse.subFeatures.orderStateMachine.name}`}>
			</StateMachineReferenceField>
			<TextField label="ServiceData" source="serviceData" />
		</SimpleShowLayout>
	</Show>
)};