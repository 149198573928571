import React from 'react';
import { Create, SimpleForm, TextInput, NumberInput } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { required } from 'react-admin';

export const ClientCategoryCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Nome Interno" source="internalName" validate={[required('Il nome interno è obbligatorio')]} />
            <TextInput label="Nome Cliente" source="clientName" validate={[required('Il nome cliente è obbligatorio')]} />
            <TextInput label="URL Icona" source="iconURL" />
            <TextInput label="Photo URL" source="photoURL" />
            <NumberInput label="Ordine" helperText="numero da 1 a x" source="order" />
            <BaseCreate />
        </SimpleForm>
    </Create>
);