import React, { Fragment, useState } from 'react';
import { Create, SimpleForm, ReferenceInput, AutocompleteInput, FormDataConsumer, DateInput, TextInput } from 'react-admin';
import BaseCreate from '../../inheritance/BaseCreate';
import { required } from 'react-admin';
import { Permissions } from '../../../permissions/permissions';
import { useSelector } from 'react-redux';
import { useForm } from 'react-final-form';
import { ModalCreate } from '../../../layouts/ModalCreate';

const ExpirationDate = ({ formData, ...rest }) => {
	const [itemStorableId, setItemStorableId] = useState(null);
	const form = useForm();
	const itemsStorable = useSelector(state => state.admin.resources.itemStorable.data);

	if(
		!form.getFieldState('itemStorableId') ||
		(form.getFieldState('itemStorableId') && form.getFieldState('itemStorableId').value === undefined)||
		(form.getFieldState('itemStorableId') && form.getFieldState('itemStorableId').value !== undefined && itemsStorable[form.getFieldState('itemStorableId').value] === undefined) ||
		(form.getFieldState('itemStorableId') && form.getFieldState('itemStorableId').value !== undefined && itemsStorable[form.getFieldState('itemStorableId').value] !== undefined && itemsStorable[form.getFieldState('itemStorableId').value].hasShelfLife === false)) {
		return null;
	}

	let date = new Date();
	date.setDate(date.getDate() + itemsStorable[form.getFieldState('itemStorableId').value].minimumShelfLife);

	if(itemStorableId && formData.itemStorableId !== itemStorableId) {
		setItemStorableId(formData.itemStorableId)
		formData.expirationDate = date;
	}
	else if(!itemStorableId) {
		setItemStorableId(formData.itemStorableId)
	}

	return (
		<Fragment>
			<DateInput
				fullWidth
				initialValue={date.toDateString()}
				validate={[required('campo obbligatorio')]}
				label="Data di Scadenza"
				source="expirationDate" />
		</Fragment>
	);
}

const SupplierLot = () => {
	const form = useForm();
	const itemsStorable = useSelector(state => state.admin.resources.itemStorable.data);

	if(
		!form.getFieldState('itemStorableId') ||
		(form.getFieldState('itemStorableId') && form.getFieldState('itemStorableId').value === undefined)||
		(form.getFieldState('itemStorableId') && form.getFieldState('itemStorableId').value !== undefined && itemsStorable[form.getFieldState('itemStorableId').value] === undefined) ||
		(form.getFieldState('itemStorableId') && form.getFieldState('itemStorableId').value !== undefined && itemsStorable[form.getFieldState('itemStorableId').value] !== undefined && itemsStorable[form.getFieldState('itemStorableId').value].isPlottable === false)) {
		return null;
	}

	return (
		<Fragment>
			<TextInput
				fullWidth
				label="Lotto fornitore"
				source="supplierLot" />
		</Fragment>
	);
}

export function LotCreate(props) {
	return (
		<Create {...props}>
			<CreateForm redirect="list" />
		</Create>
	);
}

export function ModalLotCreate(rawProps) {
	let props = {
		...rawProps,
		basePath: `/${Permissions.lot.feature}`,
		resource: `${Permissions.lot.feature}`,
		title: "Aggiungi Lotto"
	}
	return (
		<Create
			title=" "
			basePath={`/${Permissions.lot.feature}`}
			resource={`${Permissions.lot.feature}`}
		>
			<ModalCreate>
				<CreateForm {...props}/>
			</ModalCreate>
		</Create>
	);
}

function CreateForm(props) {
	return (
		<SimpleForm {...props}>
			<div style={{ minHeight: '350px', width: '100%' }}>
			<AutocompleteInput
				fullWidth
				defaultValue="compliant"
				source="compliantStatus"
				choices={['compliant', 'not-compliant'].map(choice => {
					return {
						id: choice,
						name: choice
					}
				})} />
			<ReferenceInput
				fullWidth
				initialValue={props.requestedItemTransfer && props.requestedItemTransfer.itemStorableId ? props.requestedItemTransfer.itemStorableId : undefined}
				validate={[required('campo obbligatorio')]}
        filter={{ "item.active||eq": 1 }}
				filterToQuery={searchText => {
					if(!searchText)
						return null;

					return { "item.name": searchText }
				}}
				label="ItemStorable"
				source="itemStorableId"
				reference={`${Permissions.itemStorable.feature}`}
			>
				<AutocompleteInput
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true,
								modifiers: {
									flip: {
										enabled: false,
									},
									preventOverflow: {
										enabled: true,
										boundariesElement: 'scrollParent',
									},
								}
							},
							disabled: props.requestedItemTransfer && props.requestedItemTransfer.itemStorableId ? true : false
						}
					}
					shouldRenderSuggestions={val => val && val.length >= 2 }
					optionText={(record)=> {
						if(!record) {
							return null;
						}

						if(!record.item) {
							return record.itemId;
						}

						return `${record.item.id} ${record.item.name}`;
					}}
					optionValue="id"
				/>
			</ReferenceInput>
			<FormDataConsumer>
				{formDataProps => (
					<ExpirationDate {...formDataProps} />
				)}
			</FormDataConsumer>
			<br></br>
			<FormDataConsumer>
				{formDataProps => (
					<SupplierLot {...formDataProps} />
				)}
			</FormDataConsumer>
			<DateInput fullWidth source="notCompliantDate" />
			<br></br>
			<TextInput fullWidth source="notCompliantComment" />
			<BaseCreate />
			</div>
		</SimpleForm>
	);
}