import React from 'react';

import { TextField, ReferenceField, BooleanField, NumberField, Filter, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { ListPreview } from '../../../layouts/ListPreview';
import { ItemSaleablePriceListCreate } from '../../itemSaleablePriceList/ItemSaleablePriceListCreate';
import { Permissions } from '../../../permissions/permissions';

export function FilterListPreview(props) {
  return (
    <div style={{ padding: '16px' }}>
      <Filter {...props}>
        <TextInput type="tel" label="item Id" source="item.id" alwaysOn />
        <TextInput label="item name" source="item.name" alwaysOn />
        <ReferenceInput
          alwaysOn
          filterToQuery={searchText => {
            if (!searchText)
              return {};

            return {
              internalName: searchText
            }
          }}
          label="Categoria ecommerce"
          source="itemSaleable.clientCategoryId||eq"
          reference={`${Permissions.clientCategory.feature}`} >
          <AutocompleteInput
            emptyText="Tutte"
            optionText="internalName"
            optionValue="id" />
        </ReferenceInput>
      </Filter>
    </div>
  );
}

export function CustomListPreview(props) {
  return (
    <ListPreview
      filters={props.enableFilter ? <FilterListPreview /> : null}
      title={`${props.title ? props.title + ' > ' : ''}Item Vendibili`}
      basePath={"/itemSaleablePriceList"}
      resource={"itemSaleablePriceList"}
      filterList={{ "priceListId||eq": props.parentId }}
      redirect={props.redirect}
      hasCreateModal={true}
      createProps={{ priceListId: props.parentId }}
      create={ItemSaleablePriceListCreate}
      reactAdminListProps={{
        sort: { field: 'item.name', order: 'ASC' },
        perPage: 50,
        bulkActionButtons: props.bulkActionButtons === true ? undefined : false,
      }}
    >
      <TextField sortable={props.sortable} source="order" label="Ordine" />
      <TextField sortable={props.sortable} source="item.id" label="Item ID" />
      <TextField sortable={props.sortable} source="item.name" label="Item" />
      <ReferenceField sortable={props.sortable} label="Categoria Ecommerce" source="itemSaleable.clientCategoryId" reference="clientCategory" link="show">
        <TextField source="internalName" />
      </ReferenceField>
      <NumberField sortable={props.sortable} locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Prezzo di default" source="itemSaleable.price"></NumberField>
      <BooleanField sortable={props.sortable} source="differentPrice" label="Prezzo differente" />
      <NumberField sortable={props.sortable} locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Prezzo" source="price"></NumberField>
    </ListPreview>
  )
}