import React, { useEffect, useState } from 'react';
import BaseEdit from '../inheritance/BaseEdit';
import { Edit, SimpleForm, ReferenceInput, AutocompleteInput, DateInput, required, ReferenceArrayInput, AutocompleteArrayInput  } from 'react-admin';
import { Permissions } from '../../permissions/permissions';
import { useForm } from 'react-final-form';
export const DeliveryManEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput
                enableGetChoices={val => {
                    return !!(val && val.name && val.name.length >= 2);
                }}
                validate={[required('campo obbligatorio')]}
                filterToQuery={searchText => {
                    if (!searchText)
                        return {};

                    return {
                        "name": searchText,
                    }
                }}
                label="Hub di riferimento"
                source="hubId"
                reference={Permissions.hub.feature}>
                <AutocompleteInput
                    optionText="name"
                    optionValue="id" />
            </ReferenceInput>
            <DateInput
                label="Data assunzione"
                source="assumptionDate" />
            <DateInput
                label="Data scadenza contratto"
                source="contractExpiryDate" />
            <ReferenceInput
                    validate={[required('campo obbligatorio')]}
                    enableGetChoices={val => {
                        return !!(val && val.label && val.label.length >= 2);
                    }}
                    filterToQuery={searchText => {
                        if (!searchText)
                            return {
                                "type": "RibesUser",
                                "planner||eq": true
                            }

                        return {
                            "label": searchText,
                            "type": "RibesUser",
                            "planner||eq": true
                        }
                    }}
                    label="Planner"
                    source="plannerMainUserId"
                    reference={Permissions.mainUser.feature}>
                    <AutocompleteInput
                        optionText={(record) => {
                            if (!record || !record.id) {
                                return 'cancel';
                            }
                            return `${record ? `${record.id} - ${record.label}` : ''}`
                        }}
                        optionValue="id" />
                </ReferenceInput>
            <ReferenceArrayInput 
                filterToQuery={searchText => {
                    if(!searchText)
                        return null;

                    return {
                        name: searchText
                    }
                }} 
                source="skillsIds" 
                reference={Permissions.deliveryManSkill.feature}>
                <AutocompleteArrayInput   optionText="name"/>     
            </ReferenceArrayInput>
            <Skills />
            <BaseEdit />
        </SimpleForm>
    </Edit>
);

export function Skills(props) {
	const form = useForm();
	const [status, setStatus] = useState('INIT');

	useEffect(() => {
        if(status === 'INIT') {
			setStatus('LOADED');
            form.change('skillsIds', props.record.skills ? props.record.skills.map(place => place.id) : []);
        }
	}, [form,  props.record.skills, status])

	return null;
}