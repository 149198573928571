import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField, NumberField } from 'react-admin';
import Aside from '../../../inheritance/BaseAside';

export const WalletAccountTypeShow = (props) => {
	return (
	<Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
		<SimpleShowLayout>
			<TextField source="name"/>
			<TextField source="accountCategory"/>
			<TextField source="canBeNegative"/>
			<TextField source="owner"/>
			<TextField source="removeFromAccountingRevenuesAndTVA"/>
			<TextField source="removeFromBIRevenues"/>
			<TextField source="externalName"/>
			<DateField source="expirationDate"/>
			<NumberField source="usagePriority"/>			
		</SimpleShowLayout>
	</Show>
)};
