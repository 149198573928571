import React from 'react';
import { ListPreview } from '../../../layouts/ListPreview';
import { Permissions } from '../../../permissions/permissions';
import { LayoutPhotoCreate } from "./LayoutPhotoCreate"
import {  TextField, NumberField, ImageField } from 'react-admin';
function LayoutPhotoList (props) {
    return (
        <ListPreview
        title="Layout Photo"
        basePath = {`/${Permissions.layoutPhoto.feature}`}
        resource = {`${Permissions.layoutPhoto.feature}`}
        filterList={{ "layoutId||eq": props.id}}
        hasCreateModal={true}
        create={LayoutPhotoCreate}
        rowClick="edit"
        redirect={`/${Permissions.layout.feature}/${props.id}/show`}
        hasEditP={true}
        createProps={{
            layoutId: props.id
        }}
        reactAdminListProps={{
            sort: { field: 'order', order: 'ASC' },
        }}
        >
        <NumberField label="Ordine" source="order" textAlign='left' />
        <TextField label="Commento" source="comment"/>
        <TextField source="url" title="title" />
        <ImageField source="url" label="Immagine" />
    </ListPreview>
    )
}

export default LayoutPhotoList;