import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField, NumberField, EditButton, TopToolbar } from 'react-admin';
import Aside from '../inheritance/BaseAside';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';
import SellingOrderRowList  from './sellingOrderRow/SellingOrderRowList';
import SellingOrderImpulseShow  from './sellingOrderImpulse/SellingOrderImpulseShow';
import LockerSellingOrderShow  from './lockerSellingOrder/LockerSellingOrderShow';
import CoffeeSellingOrderShow  from './coffeeSellingOrder/CoffeeSellingOrderShow';
import { connect } from 'react-redux';
import { SELLING_POINT_TYPE_IMPULSE, SELLING_ORDER_TYPOLOGY_B2C_LOCKER, SELLING_POINT_TYPE_COFFEE } from '../../utils/enum/Typology';
import { Permissions } from '../../permissions/permissions';
import StateMachineReferenceField from '../../components/StateMachineReferenceField';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1)
  }
}));

const SellingOrderShowActions = ({ basePath, data, typology}) => {
  if(typology && typology === SELLING_POINT_TYPE_IMPULSE)
    return null;

  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
}

const SellingOrderShowComponent = (props) => {
    const classes = useStyles();
    return (
        <Container maxWidth="xl" className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} xs={12}>
            <Show
              {...props}
              actions={<SellingOrderShowActions typology={props.sellingOrder ? props.sellingOrder.typology : null} />}
              aside={<Aside props={props} record={props.record}></Aside>}
            >
                <SimpleShowLayout>
                    <TextField label="ID" source="id" />
                    <StateMachineReferenceField
                      label="Stato"
                      source="orderStateMachine"
                      reference={`${Permissions.sellingOrder.feature}_${Permissions.sellingOrder.subFeatures.orderStateMachine.name}`}>
                    </StateMachineReferenceField>
                    <TextField label="Nome" source="name" />
                    <ReferenceField label="Luogo di Vendita" source="placeId" reference="place" link="show">
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField label="Punto di Vendita" source="sellingPointId" reference="sellingPoint" link="show">
                        <TextField source="name" />
                    </ReferenceField>
                    <NumberField label="Totale prima di aggiustamenti" locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} source="totalBeforeAdjustmentsWithTax" />
                    <NumberField label="Totale prima di aggiustamenti (no-tax)" locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} source="totalBeforeAdjustmentsNoTax" />
                    <NumberField label="Aggiustamenti" locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} source="adjustmentsWithTax" />
                    <NumberField label="Aggiustamenti (no-tax)" locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} source="adjustmentsNoTax" />
                    <NumberField label="Totale finale" locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} source="totalWithTax" />
                    <NumberField label="Totale finale (no-tax)" locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} source="totalNoTax" />
                    <NumberField label="Tasse su totale finale" locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} source="totalTax" />
                    <TextField source="mainUserId" />
                </SimpleShowLayout>
            </Show>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
            <SellingOrderRowList
              disableAdd={[SELLING_POINT_TYPE_IMPULSE, SELLING_ORDER_TYPOLOGY_B2C_LOCKER, SELLING_POINT_TYPE_COFFEE].includes(props.sellingOrder.typology)}
              disableClickRow={props.sellingOrder.typology === SELLING_POINT_TYPE_IMPULSE}
              reactAdminListProps={props.sellingOrder.typology === SELLING_POINT_TYPE_IMPULSE ? {bulkActionButtons: false} : {}}
              sellingOrderId={props.id}
              sellingOrder={props.sellingOrder}
            ></SellingOrderRowList>
          </Grid>
        </Grid>
        {props.sellingOrderImpulse ? <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
            <SellingOrderImpulseShow
              sellingOrderImpulse={props.sellingOrderImpulse}
            ></SellingOrderImpulseShow>
          </Grid>
        </Grid> : null }
        {props.sellingOrder.typology === SELLING_ORDER_TYPOLOGY_B2C_LOCKER && <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
            <LockerSellingOrderShow
              sellingOrderId={props.id}
            ></LockerSellingOrderShow>
          </Grid>
        </Grid>}
        {props.sellingOrder.typology === SELLING_POINT_TYPE_COFFEE && <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
            <CoffeeSellingOrderShow
              sellingOrderId={props.id}
           />
          </Grid>
        </Grid>}
      </Container>
)};

export const SellingOrderShow = connect((state, props) => {
  return {
    sellingOrder: state.admin.resources.sellingOrder && state.admin.resources.sellingOrder.data[props.id] ? state.admin.resources.sellingOrder.data[props.id] : {},
    sellingOrderImpulse: state.admin.resources.sellingOrder && state.admin.resources.sellingOrder.data[props.id] && state.admin.resources.sellingOrder.data[props.id].sellingOrderImpulse ? state.admin.resources.sellingOrder.data[props.id].sellingOrderImpulse : null,
  }
})(SellingOrderShowComponent)