import React from 'react';
import { Datagrid, List, TextField, TextInput, ReferenceInput, AutocompleteInput, ReferenceField } from 'react-admin';
import Filter from '../../inheritance/BaseFilter';
import ListActions from '../../../components/ListActions';
import { Permissions } from '../../../permissions/permissions';

function HubFilter(props) {
	return (
		<Filter {...props}>
			<TextInput label="Nome" source="name" alwaysOn />
				<ReferenceInput
					filterToQuery={searchText => {
						if(!searchText)
							return {};

						return { "name": searchText }
					}}
					label="Luogo" source="placeId||eq" reference={Permissions.place.feature}>
				<AutocompleteInput
					optionText="name"
					optionValue="id" />
			</ReferenceInput>
		</Filter>
	);
}

export function HubList(props) {
	return (
		<List {...props} actions={<ListActions />} filters={<HubFilter />} sort={{ field: 'id', order: 'DESC' }}>
			<Datagrid rowClick="show">
				<TextField label="Nome" source="name" />
				<ReferenceField
				label="Luogo"
				source="placeId"
                reference={Permissions.place.feature}
                link={"show"}
				>
					<TextField source="name" />
				</ReferenceField>
			</Datagrid>
		</List>
	);
}