import React from 'react';
import { Chip, Typography } from '@material-ui/core';
import { useHistory } from "react-router-dom";

export function CustomArrayField(props) {
    const history = useHistory();

    if(!props || !props.record || !props.record[props.source]) {
        return null;
    }

    return (
        <div style={{marginBottom: '4px'}}>
            <Typography variant="caption" >
                {props.label}
            </Typography>
            <div style={{display: 'flex'}}>
                {props.record[props.source].map(item => (
                    <Chip style={{marginRight: '4px'}} key={item.id} label={item[props.subLabel]} onClick={() => history.push(`/${props.reference}/${item.id}/show`)}/>
                ))}
            </div>
        </div>
    )
};
