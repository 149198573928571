import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import Aside from '../inheritance/BaseAside';
import { makeStyles } from '@material-ui/core/styles';

import { Container, Grid } from '@material-ui/core';

import { CustomListPreview }  from './itemSaleablePriceList/ItemSaleablePriceListList';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import ExploreIcon from '@material-ui/icons/Explore';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1)
      },
    title: {
      flexGrow: 1,
    },
    rootSaleable: {
      flexGrow: 1,
    }
  }));
export const PriceListShow = (props) => {
    const classes = useStyles();
    let history = useHistory();

    return (
        <Container maxWidth="xl" className={classes.root}>
        <Grid container spacing={3}>
        <Grid item xl={12} lg={12} md={12} xs={12}>
        <Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
                <SimpleShowLayout>
                    <TextField label="Nome" source="name" />
                    <TextField label="UUID" source="uuid" />
                </SimpleShowLayout>
            </Show>
        </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
              <Button variant="contained" color="primary" style={{margin: '16px 0'}} endIcon={<ExploreIcon/>} onClick={() => {
                  history.push(`/itemSaleablePriceList?filter={"priceListId||eq":${props.id}}`);
              }}>LISTA COMPLETA</Button>
              <CustomListPreview bulkActionButtons={true} redirect={`/priceList/${props.id}/show`} parentId={props.id} enableFilter={false} sortable={false} />
          </Grid>
        </Grid>
        </Container>
)};