import React, { useState } from 'react';
import { FormDataConsumer } from 'react-admin';
import { ArrayInput } from 'react-admin';
import { useForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import GoogleSheetIcon from '../../../warehouse/transfer/components/GoogleSheetIcon';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    table: {},
    actionContainer: {
        display: 'flex',
        flexFlow: 'column',
        padding: theme.spacing(2, 8)
    },
    actionContainerSecondary: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2, 0)
    },
    validationError: {
        backgroundColor: 'rgba(255, 0, 0, .6)',
    },
    buttonIconRemove: {
        margin: theme.spacing(0, 0, 0, 1)
    }
}));

export default function TransferItemsFromGoogleSheet(props) {
    return (
        <>
            <div style={{ display: 'none' }}>
                <ArrayInput source="missionItems">
                    <EmptyArrayInput />
                </ArrayInput>
            </div>
            <FormDataConsumer>
                {formDataProps => (
                    <BaseTransferItemsFromGoogleSheet
                        {...formDataProps}
                    />
                )}
            </FormDataConsumer>
        </>
    );
}

function BaseTransferItemsFromGoogleSheet({
    formData,
    ...rest
}) {
    const form = useForm();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState('AWAIT_PAST');
    const [previewStatus, setPreviewStatus] = useState('CLOSE');
    const [data, setData] = useState([]);

    const handleClose = () => {
        setStatus('AWAIT_PAST');
        setData([]);
        setOpen(false);
    };

    return (
        <>
            <Button
                variant="contained"
                startIcon={<GoogleSheetIcon />}
                onClick={() => setOpen(true)}>{form.getState().values.missionItems && form.getState().values.missionItems.length > 0 ? `${form.getState().values.missionItems.length} VALORI` : 'INCOLLA VALORI'}</Button>
            {form.getState().values.missionItems && form.getState().values.missionItems.length > 0 && <IconButton className={classes.buttonIconRemove} edge="start" color="inherit" onClick={() => {
                form.change('missionItems', [])
            }} aria-label="close">
                <DeleteForeverIcon />
            </IconButton>}
            <IconButton className={classes.buttonIconRemove} edge="start" color="inherit" onClick={() => {
                setPreviewStatus('OPEN')
            }} aria-label="info">
                <HelpOutlineIcon />
            </IconButton>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar} >
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title} >
                            INCOLLA VALORI
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.actionContainer} >
                <Button
                    size="large"
                    className={classes.buttonUseValue}
                    disabled={status !== 'PASTED'}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        form.change('missionItems', data.map(row => {
                            return {
                                type: row[0],
                                itemId: row[1],
                                lotId: row[2],
                                quantity: row[3],
                                value: row[4],
                                comment: row[5],
                                missionType: row[6],
                                uom: row[7] ? {
                                    type: 'specific',
                                    uomId: row[7]
                                } : {
                                    type: 'default',
                                }
                            }
                        }))
                        setOpen(false);
                        setStatus('AWAIT_PAST');
                        setData([]);
                    }}>USA VALORI</Button>
                <div className={classes.actionContainerSecondary}>
                {status === 'VALIDATION_ERROR' && <Typography variant="h6">
                    Valori non validi controlla le righe in rosso
                </Typography>}
                {status === 'INVALID_DATA_PASTED' && <Typography variant="h6">
                    Valori non validi
                </Typography>}
                {(status === 'INVALID_DATA_PASTED' || status === 'VALIDATION_ERROR') && <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        setStatus('AWAIT_PAST');
                        setData([]);
                    }}>RIPROVA</Button>}    
                </div>
                </div>            

                <Container className={classes.container} maxWidth="md" disableGutters>
                    {status === 'AWAIT_PAST' && <TextField onChange={(e) => {
                        const data_raw = e.target.value.split("§ ").map(r => r.split("\t").slice(0, 7));
                        if(!data_raw[0] || data_raw.length < 2 || JSON.stringify(data_raw[0]) !== JSON.stringify(['type', 'itemId', 'lotId', 'quantity', 'value', 'comment', 'missionType'])) {
                            setStatus('INVALID_DATA_PASTED');
                        } else {
                            const data_ready = data_raw.slice(1, 101).map(row => {
                                const single_data_raw = [];
                                if(row.length < 7) {
                                    return null;
                                }
                                single_data_raw.push(row[0]);
                                single_data_raw.push(row[1] ? Number.parseInt(row[1]) : null);
                                single_data_raw.push(row[2] ? Number.parseInt(row[2]) : null);
                                single_data_raw.push(row[3] ? Number.parseFloat(row[3]) : null);
                                single_data_raw.push(row[4] !== 0 && !row[4] ? null : Number.parseFloat(row[4]));
                                single_data_raw.push(row[5]);
                                single_data_raw.push(row[6]);
                                return single_data_raw;
                            }).filter(row => row);
                            if(data_ready.length !== data_raw.slice(1, 101).length) {
                                setStatus('INVALID_DATA_PASTED');
                            } else {
                                setStatus(dataIsValid(data_ready) ? 'PASTED' : 'VALIDATION_ERROR');
                                setData(data_ready)
                            }
                        }
                        
                    }} autoFocus={true} />}
                    {(status === 'PASTED' || status === 'VALIDATION_ERROR') && <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Item/Lot</TableCell>
                                    <TableCell>ItemId</TableCell>
                                    <TableCell>LotId</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Value</TableCell>
                                    <TableCell>Comment</TableCell>
                                    <TableCell>Carico/Scarico</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, key) => (
                                    <TableRow key={`row-${key}`}>
                                        <TableCell className={!['item', 'lot'].includes(row[0]) ? classes.validationError : null} key={`row-${key}-${0}`}>{row[0]}</TableCell>
                                        <TableCell className={(row[0] === 'item' && (!row[1] || row[2])) ? classes.validationError : null} key={`row-${key}-${1}`}>{row[1]}</TableCell>
                                        <TableCell className={(row[0] === 'lot' && (!row[2] || row[1])) ? classes.validationError : null} key={`row-${key}-${2}`}>{row[2]}</TableCell>
                                        <TableCell className={!row[3] ? classes.validationError : null} key={`row-${key}-${3}`}>{row[3]}</TableCell>
                                        <TableCell className={row[4] !== null && row[4] !== undefined && row[4] < 0 ? classes.validationError : null} key={`row-${key}-${4}`}>{row[4]} €</TableCell>
                                        <TableCell key={`row-${key}-${5}`}>{row[5]}</TableCell>
                                        <TableCell className={!['load', 'un-load'].includes(row[6]) ? classes.validationError : null} key={`preview-row-${key}-${6}`}>{row[6]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}
                </Container>
            </Dialog>
            <Dialog fullScreen open={previewStatus === 'OPEN'} onClose={() => { setPreviewStatus('CLOSE') }} TransitionComponent={Transition}>
                <AppBar className={classes.appBar} >
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => { setPreviewStatus('CLOSE') }} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title} >
                            TABELLA ESEMPIO
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container className={classes.container} maxWidth="md" disableGutters>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>type</TableCell>
                                    <TableCell>itemId</TableCell>
                                    <TableCell>lotId</TableCell>
                                    <TableCell>quantity</TableCell>
                                    <TableCell>value</TableCell>
                                    <TableCell>comment</TableCell>
                                    <TableCell>missionType</TableCell>
                                    <TableCell>§</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[
                                    [
                                        'lot',
                                        null,
                                        299,
                                        2,
                                        null,
                                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                                        'load',
                                    ],
                                    [
                                        'item',
                                        704,
                                        null,
                                        1,
                                        null,
                                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                                        'load',
                                    ],
                                    [
                                        'item',
                                        247,
                                        null,
                                        4,
                                        null,
                                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                                        'un-load',
                                    ]
                                ].map((row, key) => (
                                    <TableRow key={`preview-row-${key}`}>
                                        <TableCell className={!['item', 'lot'].includes(row[0]) ? classes.validationError : null} key={`preview-row-${key}-${0}`}>{row[0]}</TableCell>
                                        <TableCell className={(row[0] === 'item' && (!row[1] || row[2])) ? classes.validationError : null} key={`preview-row-${key}-${1}`}>{row[1]}</TableCell>
                                        <TableCell className={(row[0] === 'lot' && (!row[2] || row[1])) ? classes.validationError : null} key={`preview-row-${key}-${2}`}>{row[2]}</TableCell>
                                        <TableCell className={!row[3] ? classes.validationError : null} key={`preview-row-${key}-${3}`}>{row[3]}</TableCell>
                                        <TableCell className={row[4] !== null && row[4] !== undefined && row[4] < 0 ? classes.validationError : null} key={`preview-row-${key}-${4}`}>{row[4]}</TableCell>
                                        <TableCell key={`preview-row-${key}-${5}`}>{row[5]}</TableCell>
                                        <TableCell className={!['load', 'un-load'].includes(row[6]) ? classes.validationError : null} key={`preview-row-${key}-${6}`}>{row[6]}</TableCell>
                                        <TableCell>§</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </Dialog>
        </>
    );
}

function EmptyArrayInput() {
    return null;
}

function dataIsValid(data) {
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if(!['item', 'lot'].includes(element[0])) {
            return false;
        }

        if(element[0] === 'item' && !element[1]) {
            return false;
        }

        if(element[0] === 'item' && element[2]) {
            return false;
        }

        if(element[0] === 'lot' && !element[2]) {
            return false;
        }

        if(element[0] === 'lot' && element[1]) {
            return false;
        }

        if(!element[3]) {
            return false;
        }
        if(element[4] !== null && element[4] !== undefined && element[4] < 0) {
            return false;
        }

        if(!element[6]) {
            return false;
        }

        if(!['load', 'un-load'].includes(element[6])) {
            return false;
        }
    }
    return true;
}