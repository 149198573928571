import React from 'react';
import { Show, SimpleShowLayout, BooleanField, TextField, NumberField, ReferenceField } from 'react-admin';
import Aside from '../../inheritance/BaseAside';
import { Permissions } from '../../../permissions/permissions';

export const AccAccountShow = (props) => {
	return (
	<Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
		<SimpleShowLayout>
		<NumberField source="balance"/>
			<TextField source="ownerId" />
			<ReferenceField source="accAccountTypeId" reference={`${Permissions.walletAccAccountType.feature}`} link="show">
				<TextField source="name" />
			</ReferenceField>
			<BooleanField source="reserved"></BooleanField> 
		</SimpleShowLayout>
	</Show>
)};
