import React from 'react';
import { Create, SimpleForm, TextInput, email, required, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { Permissions } from '../../permissions/permissions';

export const B2CUserCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Email" source="email" validate={[required('email obbligatoria'), email('formato email non valido') ]} />
            <TextInput label="Nome" source="name" />
            <TextInput label="Cognome" source="surname" />
            <TextInput label="Telefono" source="phoneNumber" />
            <ReferenceArrayInput
				filterToQuery={searchText => {
					if(!searchText)
						return null;

					return {
						name: searchText
					}
				}} label="corporateCustomers" source="corporateCustomersIds" reference={Permissions.corporateCustomers.feature}>
				<AutocompleteArrayInput optionText="name" />
			</ReferenceArrayInput>
            <BaseCreate />
        </SimpleForm>
    </Create>
);