import React, { useState, useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { AutocompleteInput, useDataProvider, Loading,ReferenceInput, SelectInput, TextInput, DateInput } from 'react-admin';
import { Typography } from '@material-ui/core';
import { POST_OFF_RESOURCE } from '../providers/nestjs_crud';

export const AutocompleteInputType = "AutocompleteInput";
export const DateInputType = "DateInput";
export const TextInputType = "TextInput";

const STATUS_FETCH_DATA = "fetch_data";
const STATUS_FETCHING_DATA = "fetching_data";
const STATUS_DATA_FETCHED = "data_fetched";
const STATUS_FETCHING_DATA_ERROR = "fetching_data_error";
const STATUS_REALOAD_CHOICE_DATA = "reaload_choice_data";

export default function InputRest(props) {
	const form = useForm();
	const { values } = useFormState({ subscription: { values: true } });
	const dataProvider = useDataProvider();

	const [status, setStatus] = useState(props._dependsOn?.length > 0 ? STATUS_DATA_FETCHED : STATUS_FETCH_DATA);
	const [choices, setDataChoices] = useState([]);
	const [_dependsOn, set_DependsOn] = useState(props._dependsOn?.length > 0 ? props._dependsOn.reduce((finalObject, currentItem) => {
		finalObject[currentItem] = values[currentItem];
		return finalObject;
	}, {}) : {});

	const [disabled, setDisabled] = useState(false);
	const [label, setLabel] = useState(props.label);

	useEffect(() => {
        if(status === STATUS_REALOAD_CHOICE_DATA) {
			setStatus(STATUS_FETCHING_DATA);
            dataProvider(POST_OFF_RESOURCE, `${props.fieldOptions}/${props.source}`, {
                data: form.getState().values
            })
                .then(({data}) => {
					setDataChoices(data);
					setStatus(STATUS_DATA_FETCHED);
                })
                .catch((e) => {
                    setStatus(STATUS_FETCHING_DATA_ERROR)
                });
        }
	}, [setStatus, dataProvider,form, props.fieldOptions, props.source, status])

	useEffect(() => {
		if(props._dependsOn?.length > 0 && status === STATUS_DATA_FETCHED) {
			if(props._dependsOn.map(dependOn => {
				return values[dependOn] === undefined || values[dependOn] === null;
			}).includes(true)) {
				form.change(props.source, undefined);
			} else if(props._dependsOn.map(dependOn => {
				return _dependsOn[dependOn] !== values[dependOn];
			}).includes(true)) {
				set_DependsOn(props._dependsOn.reduce((finalObject, currentItem) => {
					finalObject[currentItem] = values[currentItem];
					return finalObject;
				}, {}));
				setStatus(STATUS_FETCH_DATA);
			} else if(values[props.source] === undefined && !disabled) {
				setStatus(STATUS_FETCH_DATA);
			}
		}
		return () => {};
	}, [_dependsOn, props._dependsOn, props.source, status, values, form, disabled]) // aggiunto form

	useEffect(() => {
		if(status === STATUS_FETCH_DATA) {
			setStatus(STATUS_FETCHING_DATA);
			dataProvider(POST_OFF_RESOURCE, `${props.fieldOptions}/${props.source}`, {
                data: values
            })
                .then(({data}) => {
						if(data.choices) {
							setDataChoices(data.choices);
							let value = null;
							if(props.defaultValue) {
								data.choices.forEach((singleData) => {
									if(props.defaultValue === singleData.id) {
										value = singleData.id
									}
								});
							}
							if(!value) {
								data.choices.forEach((singleData) => {
									if(singleData.selected) {
										value = singleData.id
									}
								});
							}
							form.change(props.source, value);
						}

						if(data.disabled) {
							setDisabled(true);
						}
						else {
							setDisabled(false);
						}

						if(data.label) {
							setLabel(data.label)
						}

						if(data.prefill) {
							form.change(props.source, data.initialValue);
						}
		
						setStatus(STATUS_DATA_FETCHED);
                })
                .catch(() => {
                    setStatus(STATUS_FETCHING_DATA_ERROR)
				});
			
		}
		return () => {};
	}, [status, values, props.dependOn, props.fieldOptions, props.source, dataProvider, form, props.defaultValue])

    if (status === STATUS_FETCHING_DATA_ERROR) {
        return (<Typography>Errore durante il caricamento {props.source}</Typography>);
    }

    if (status !== STATUS_DATA_FETCHED && status !== STATUS_REALOAD_CHOICE_DATA) {
        return <Loading />;
    }

	switch (props.type) {
		case DateInputType:
			return (
				<DateInput
					onChange={props.onChange}
					fullWidth
					initialValue={props.defaultValue}
					disabled={disabled}
					label={label}
					source={props.source}
					validate={props.validate}
				/>
			);
		case AutocompleteInputType:
			if(disabled && props.reference) {
				return (
					<ReferenceInput
						onChange={props.onChange}
						fullWidth
						label={label}
						source={props.source}
						reference={props.reference}>
						<SelectInput options={{disabled: true}} optionText={props.optionText ? props.optionText: "name"} />
					</ReferenceInput>
				);
			}
			else if(disabled) {
				return (
					<TextInput
						onChange={props.onChange}
						fullWidth
						initialValue={props.defaultValue}
						disabled={disabled}
						label={label}
						source={props.source}
						validate={props.validate}
					/>
				);
			}
			return (
				<AutocompleteInput
					onChange={props.onChange}
					fullWidth
					emptyText="Non specificato"
					allowEmpty={props.allowEmpty}
					options={props.options}
					validate={props.validate}	
					source={props.source}
					label={label}
					choices={choices}
				/>
			);
		case TextInputType:
        default:
			return (
				<TextInput
					onChange={props.onChange}
					fullWidth
					initialValue={props.defaultValue}
					disabled={disabled}
					label={label}
					source={props.source}
					validate={props.validate}
				/>
			);    
	}
};