import React from 'react';
import { Show, SimpleShowLayout, TextField, usePermissions } from 'react-admin';
import Aside from '../../inheritance/BaseAside';
import StateMachineReferenceField from '../../../components/StateMachineReferenceField';
import { Permissions, CrudActions } from '../../../permissions/permissions';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import { Typography } from '@material-ui/core';

function CustomTextField({ record = {} }) {
	const history = useHistory();
	const { permissions } = usePermissions();

	if(!record.paymentAttemptUUID || (permissions && !permissions.includes(`${Permissions.payment.feature}-${CrudActions.ReadOne}`))) {
		return null;
	}

    return (
		<div style={{marginTop: '8px'}}>
			<Typography>Payment Attempt</Typography>
			<Button variant="outlined" onClick={() => {
				history.push(`/paymentAttempt?filter={"uuid||eq":"${record['paymentAttemptUUID']}"}`);
			}}>{record['paymentAttemptUUID']}</Button>
		</div>
    );
};

export const WalletRechargeShow = (props) => {
    return (
    <Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="uuid" />
            <TextField source="amount" />
            <TextField source="completedAmount" />
            <TextField source="transactionId" />
            <StateMachineReferenceField
                label="Stato"
                source="walletRechargeStateMachine"
                reference={`${Permissions.walletRecharge.feature}_${Permissions.walletRecharge.subFeatures.walletRechargeStateMachine.name}`}>
            </StateMachineReferenceField>
            <CustomTextField />
        </SimpleShowLayout>
    </Show>
)};
