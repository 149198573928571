import React from 'react';

import { Create, SimpleForm, ReferenceInput, SelectInput, NumberInput, FormDataConsumer, AutocompleteInput, TextInput, required } from 'react-admin';

import BaseCreate from '../../../inheritance/BaseCreate';
import { Permissions } from '../../../../permissions/permissions';
import SupplierPriceList  from './Form/SupplierPriceList';
import UoM  from './Form/UoM';
import Quantity from './Form/Quantity';
import FinalQuantity from './Form/FinalQuantity';
import SearchForId  from './Form/SearchForId';

export const PurchaseOrderRowCreate = props => {
    return (
    <Create {...props}>
        <SimpleForm redirect={props.redirect}>
            <div style={{display: 'none' }}>
              <ReferenceInput disabled source="purchaseOrderId" reference={`${Permissions.purchaseOrder.feature}`} initialValue={props.purchaseOrderId} >
                  <SelectInput optionText="name" />
              </ReferenceInput>
            </div>
            <FormDataConsumer>
                {formDataProps => (
                    <SearchForId {...formDataProps} />
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {formDataProps => (
                    <SupplierPriceList purchaseOrderId={props.purchaseOrderId} {...formDataProps} />
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {formDataProps => (
                    <UoM {...formDataProps} />
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {formDataProps => (
                    <Quantity {...formDataProps} />
                )}
            </FormDataConsumer>
            <NumberInput
                fullWidth
                label="prezzo senza IVA"
                source="unitPriceNoTax"
                validate={[required('Indicare una prezzo è obbligatorio')]}
            />

            <ReferenceInput
                fullWidth
                label="Tassa"
                source="taxId"
                reference={`${Permissions.tax.feature}`}
                filterToQuery={searchText => {
                    if(!searchText)
                        return {};
                
                    return { "name": searchText }
                }}
                >
                <AutocompleteInput
                    optionText="name"
                    optionValue="id"
                    options={
                        {
                            suggestionsContainerProps: {
                                disablePortal: true
                            }
                        }
                    }
                />
            </ReferenceInput>
            <NumberInput
                fullWidth
                disabled
                label="minimo d’ordine in unità prezzo"
                source="minimumOrder"
                validate={[required('Indicare una prezzo è obbligatorio')]}
            />
            <NumberInput
                fullWidth
                disabled
                label="arrotondamento d’ordine in unità prezzo"
                source="rounding"
                validate={[required('Indicare l\'arrotondamento è obbligatorio')]}
            />
            <FormDataConsumer>
                {formDataProps => (
                    <FinalQuantity {...formDataProps} />
                )}
            </FormDataConsumer>
            <NumberInput
                fullWidth
                label="quantità unità prezzo dopo minimo d’ordine e arrotondamento"
                source="quantityPriceUnitAfterMinOrderAndRounding"
                validate={[required('Campo obbligatorio')]}
            />
            <NumberInput
                fullWidth
                label="quantità confermata dal fornitore"
                source="quantityConfirmedInPriceUnit"
                validate={[required('Campo obbligatorio')]}
            />
            <TextInput fullWidth label="nome aggiustamento" source="adjustmentName" />
            <NumberInput fullWidth label="sconti/aggiustamenti senza tasse" source="adjustmentNoTax" />
            <BaseCreate />
        </SimpleForm>
    </Create>
)};