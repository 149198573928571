import { JobShow } from './JobShow';
import { JobManyList } from './JobManyList'

export const JOB_TYPE = [
    { id: 'fridgeRefill', name: 'Rifornimento frigo' },
    { id: 'coffeeRefill', name: 'Rifornimento caffè'},
    { id: 'waterCheck', name: 'Water Check'},
    { id: 'fruitDelivery', name: 'Consegna Frutta'},
    { id: 'cateringSetup', name: 'Catering'},
    { id: 'clientDelivery', name: 'Consegne dirette'},
    { id: 'marketingDelivery', name: 'Consegna Omaggi/Marketing/Promo'},
    { id: 'storeTransfer', name: 'Trasferimento merce a store'},
    { id: 'coffeeMaintenance', name: 'Manutenzione macchina caffè'},
    { id: 'vehicleMaintenance', name: 'Vehicle Maintenance'},
    { id: 'cateringMaterials', name: 'Materiali catering'},
    { id: 'coffeeDelivery', name: 'Consegne dirette di caffè'},
    { id: 'deliveryOrPickUp', name: 'Ritiro o consegna materiale fuori ribes'},
    { id: 'fridgeSetup', name: 'Installazione frigo'},
    { id: 'fridgeUnistall', name: 'Ritiro frigo'},
    { id: 'hubActivities', name: 'Supporto in hub'},
    { id: 'hubBackoffice', name: 'Reportistica'},
    { id: 'hubTraining', name: 'Formazione'},
    { id: 'fridgeMantenance', name: 'Manutenzione frigo'},
]

export default { show: JobShow, list: JobManyList };

