import React from 'react';
import BaseEdit from '../inheritance/BaseEdit';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';

export const DeliveryManSkillEdit = props => (
    <Edit {...props}>
        <SimpleForm>
                <TextInput label="Name" source="name" validate={[required()]}/>
                <TextInput label="Codice tool planning" source="toolPlanningCode" validate={[required()]} />
            <BaseEdit />
        </SimpleForm>
    </Edit>
);