import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField } from 'react-admin';
import { Permissions } from '../../../permissions/permissions';

export function CoffeeRecipeShow(props) {
	return (
		<Show  {...props}>
			<SimpleShowLayout>
				<TextField source="id" />
				<ReferenceField source="itemId" reference={Permissions.item.feature} link="show">
					<TextField source="name" />
				</ReferenceField>
				<TextField source="type" />
				<ReferenceField source="coffeeTypeId" reference={Permissions.coffeeType.feature} link="show">
					<TextField source="name" />
				</ReferenceField>
				<TextField source="coffeeGrams" />
				<TextField source="waterGrams" />
				<TextField source="extraWaterGrams" />
				<TextField source="hotMilkGrams" />
				<TextField source="hotFrothedMilkGrams" />
				<TextField source="frothPercentage" />	
				<ReferenceField source="solubleType1Id" reference={Permissions.solubleType.feature} link="show">
					<TextField source="internalName" />
				</ReferenceField>
				<TextField source="soluble1WaterGrams" />
				<TextField source="soluble1Grams" />	
				<ReferenceField source="solubleType2Id" reference={Permissions.solubleType.feature} link="show">
					<TextField source="internalName" />
				</ReferenceField>
				<TextField source="soluble2WaterGrams" />
				<TextField source="soluble2Grams" />	
			</SimpleShowLayout>
		</Show>
	)
}
