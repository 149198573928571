import React from 'react';
import { Toolbar, SaveButton, DeleteButton, Edit, SimpleForm, TextInput, required, NumberInput, BooleanInput, FormDataConsumer, AutocompleteInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { STORAGE_TYPE } from './enum';
import { connect } from 'react-redux';

import BaseEdit from '../../inheritance/BaseEdit';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const CustomToolbar = props => (
	<Toolbar {...props} classes={useStyles()}>
		<SaveButton />
		{false && <DeleteButton redirect={props.redirect} />}
    </Toolbar>
);

const ItemStorableEditComponent = props => {
    return (
        <Edit {...props}>
            <SimpleForm redirect={() => (`/item/${props.itemId}/show`)} toolbar={<CustomToolbar data={props.data} redirect={() => (`/item/${props.itemId}/show`)}/>}>
				<BooleanInput disabled fullWidth label="Has Shelf Life" source="hasShelfLife" helperText="Non modificabile dopo la creazione"/>
				<FormDataConsumer>
					{({ formData, ...rest }) => {
						if(!formData.hasShelfLife) {
							return;
						}
					
						return (
							<NumberInput
							fullWidth
							label="Shelf Life minima"
							source="minimumShelfLife"
							helperText="Shelf Life minima prevista alla consegna in giorni (only if has shelf life)"
							validate={[required('Indicare una Shelf Life minima è obbligatorio')]} />
						);
					}}
				</FormDataConsumer>
				<NumberInput
					fullWidth
					label="Volume di una unità di riferimento in dm^3"
					source="volume"
					validate={[required('Indicare un volume è obbligatorio')]} />
				<NumberInput
					fullWidth
					label="Peso in KG di una unità di riferimento"
					source="weight"
					validate={[required('Indicare un peso è obbligatorio')]} />
				<AutocompleteInput label="Conservazione predefinita" source="storageType" options={{suggestionsContainerProps: {disablePortal: true}}} validate={[required('Indicare la conservazione è obbligatorio')]} choices={STORAGE_TYPE.map(storageType => {
					return {
						id: storageType,
						name: storageType
					}
				})} />
				<BooleanInput label="Utilizzabile" source="usable"  initialValue={true} />
				<TextInput fullWidth label="Nota" source="note" />
				<NumberInput
					label="Ordine Inventario"
					source="inventoryOrder"
					validate={[required('campo obbligatorio')]}
				/>
				<NumberInput
					label="Valore"
					source="value"
					validate={[required('campo obbligatorio')]} />
				<NumberInput
					fullWidth
					source="warehouseCycleCountingDays"
				></NumberInput>
				<NumberInput
					fullWidth
					source="missionCycleCountingDays"
				></NumberInput>
				<NumberInput
					fullWidth
					source="widthInmm"
				></NumberInput>
				<NumberInput
					fullWidth
					source="depthtInmm"
				></NumberInput>
				<NumberInput
					fullWidth
					source="heightInmm"
				></NumberInput>
				<NumberInput
					fullWidth
					source="maximumNumberStackableItems"
				></NumberInput>
                <BaseEdit />
            </SimpleForm>
    	</Edit>
    )
};

export const ItemStorableEdit = connect((state, props) => {
    return {
        itemId: state.admin.resources.itemStorable && state.admin.resources.itemStorable.data[props.id] ? state.admin.resources.itemStorable.data[props.id].itemId : null
    }
})(ItemStorableEditComponent)