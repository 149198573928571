import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import BaseEdit from '../inheritance/BaseEdit';
import { required } from 'react-admin';
import { Permissions } from '../../permissions/permissions';

export const LockerEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="Nome" source="name" validate={[required('Il nome è obbligatorio')]} />
            <TextInput label="Nome per cliente" source="publicName" validate={[required('Il nome è obbligatorio')]} />
            <BooleanInput label="Abilita preordini" source="preOrderPickUp"></BooleanInput> 
            <ReferenceInput
                filterToQuery={searchText => {
                    if(!searchText)
                        return {};
    
                    return { name: searchText }
                }}
                allowEmpty label="Lock" source="lockId" reference={Permissions.lock.feature} >
                <AutocompleteInput
					optionText="name"
					optionValue="id" />
            </ReferenceInput>
            <BaseEdit />
        </SimpleForm>
    </Edit>
);