import React from 'react';
import { Edit, SimpleForm, TextInput, AutocompleteInput, NumberInput, ReferenceInput } from 'react-admin';
import BaseEdit from '../../../../inheritance/BaseEdit';
import { required } from 'react-admin';
import { BeverageStateList, ModelList, PartStateList } from './';
import { Permissions } from '../../../../../permissions/permissions';
import { useHistory } from "react-router-dom";

export function CoffeeMachineCimbaliSEdit(props) {
	let history = useHistory();
	return (
		<Edit {...props}>
			<SimpleForm redirect={() => {
				history.goBack();
				return '';
			}}>
				<div style={{ display: 'none' }}>
					<TextInput source="machineId" />
				</div>
				<TextInput fullWidth validate={[required()]} source="ip" />
				<TextInput fullWidth validate={[required()]} source="port" />
				<TextInput fullWidth validate={[required()]} source="serial" />
				<AutocompleteInput fullWidth validate={[required()]} source="model" choices={ModelList.map(Model => {
					return {
						id: Model,
						name: Model
					}
				})} />
				<NumberInput fullWidth validate={[required()]} source="gramsFor100Impulse" />
				<h3>Grinder 1</h3>
				<AutocompleteInput defaultValue="OFF" fullWidth label="state" source="grinder1.state" choices={PartStateList.map(Part => {
					return {
						id: Part,
						name: Part
					}
				})} />
				<ReferenceInput
					fullWidth
					filterToQuery={searchText => {
						if (!searchText)
							return null;

						return {
							name: searchText
						}
					}}
					label="Coffee Type"
					source="grinder1coffeeTypeId"
					reference={`${Permissions.coffeeType.feature}`}
				>
					<AutocompleteInput
						optionText={(record) => record ? `${record.id} - ${record.name}` : ''}
						optionValue="id" />
				</ReferenceInput>
				<NumberInput fullWidth label="gramsForSecond" source="grinder1.gramsForSecond" />
				<h3>Grinder 2</h3>
				<AutocompleteInput defaultValue="OFF" fullWidth label="state" source="grinder2.state" choices={PartStateList.map(Part => {
					return {
						id: Part,
						name: Part
					}
				})} />
				<ReferenceInput
					fullWidth
					filterToQuery={searchText => {
						if (!searchText)
							return null;

						return {
							name: searchText
						}
					}}
					label="Coffee Type"
					source="grinder2coffeeTypeId"
					reference={`${Permissions.coffeeType.feature}`}
				>
					<AutocompleteInput
						optionText={(record) => record ? `${record.id} - ${record.name}` : ''}
						optionValue="id" />
				</ReferenceInput>
				<NumberInput fullWidth label="gramsForSecond" source="grinder2.gramsForSecond" />
				<h3>Grinder 3</h3>
				<AutocompleteInput defaultValue="OFF" fullWidth label="state" source="grinder3.state" choices={PartStateList.map(Part => {
					return {
						id: Part,
						name: Part
					}
				})} />
				<ReferenceInput
					fullWidth
					filterToQuery={searchText => {
						if (!searchText)
							return null;

						return {
							name: searchText
						}
					}}
					label="Coffee Type"
					source="grinder3coffeeTypeId"
					reference={`${Permissions.coffeeType.feature}`}
				>
					<AutocompleteInput
						optionText={(record) => record ? `${record.id} - ${record.name}` : ''}
						optionValue="id" />
				</ReferenceInput>
				<NumberInput fullWidth label="gramsForSecond" source="grinder3.gramsForSecond" />
				<h4>Latte</h4>
				<AutocompleteInput defaultValue="OFF" fullWidth source="milkState" choices={PartStateList.map(Part => {
					return {
						id: Part,
						name: Part
					}
				})} />
				<AutocompleteInput defaultValue="NO" fullWidth  source="milk" choices={BeverageStateList.map(BeverageState => {
					return {
						id: BeverageState,
						name: BeverageState
					}
				})} />
				<h4>Soluble</h4>
				<AutocompleteInput defaultValue="NO" fullWidth source="soluble" choices={BeverageStateList.map(BeverageState => {
					return {
						id: BeverageState,
						name: BeverageState
					}
				})} />
				<NumberInput fullWidth label="watergramsForSecond" source="waterGramsForSolubleForSecond" />
				<h4>Soluble 1</h4>
				<AutocompleteInput defaultValue="OFF" fullWidth source="solubleState" choices={PartStateList.map(Part => {
					return {
						id: Part,
						name: Part
					}
				})} />
				<NumberInput fullWidth label="gramsForSecond" source="soluble1GramsForSecond" />
				<ReferenceInput
					allowEmpty emptyText="Non specificato" fullWidth
					filterToQuery={searchText => {
						if (!searchText)
							return null;

						return {
							name: searchText
						}
					}}
					label="Type"
					source="solubleType1Id"
					reference={`${Permissions.solubleType.feature}`}
				>
					<AutocompleteInput
						optionText="internalName"
						optionValue="id" />
				</ReferenceInput>
				<h4>Soluble 2</h4>
				<AutocompleteInput defaultValue="OFF" fullWidth source="solubleState2" choices={PartStateList.map(Part => {
					return {
						id: Part,
						name: Part
					}
				})} />
				<NumberInput fullWidth label="gramsForSecond" source="soluble2GramsForSecond" />
				<ReferenceInput
					allowEmpty emptyText="Non specificato" fullWidth
					filterToQuery={searchText => {
						if (!searchText)
							return null;

						return {
							name: searchText
						}
					}}
					label="Type"
					source="solubleType2Id"
					reference={`${Permissions.solubleType.feature}`}
				>
					<AutocompleteInput
						optionText="internalName"
						optionValue="id" />
				</ReferenceInput>
				<BaseEdit />
			</SimpleForm>
		</Edit>
	);
}
