import { ConditionEdit } from "./ConditionEdit";
import { ConditionList } from "./ConditionList";
import { ConditionCreate } from "./ConditionCreate";

export default { edit:ConditionEdit, list:ConditionList, create:ConditionCreate };

export const HOURS = [
  {
      id: '00:00',
      name: '00:00',
  },
  {
      id: '01:00',
      name: '01:00',
  },
  {
      id: '02:00',
      name: '02:00',
  },
  {
      id: '03:00',
      name: '03:00'
  },
  {
      id: '04:00',
      name: '04:00'
  },
  {
      id: '05:00',
      name: '05:00'
  },
  {
      id: '06:00',
      name: '06:00'
  },
  {
      id: '07:00',
      name: '07:00'
  },
  {
      id: '08:00',
      name: '08:00'
  },
  {
      id: '09:00',
      name: '09:00'
  },
  {
      id: '10:00',
      name: '10:00'
  },
  {
      id: '11:00',
      name: '11:00'
  },
  {
      id: '12:00',
      name: '12:00'
  },
  {
      id: '13:00',
      name: '13:00'
  },
  {
      id: '14:00',
      name: '14:00'
  },
  {
      id: '15:00',
      name: '15:00'
  },
  {
      id: '16:00',
      name: '16:00'
  },
  {
      id: '17:00',
      name: '17:00'
  },
  {
      id: '18:00',
      name: '18:00'
  },
  {
      id: '19:00',
      name: '19:00'
  },
  {
      id: '20:00',
      name: '20:00'
  },
  {
      id: '21:00',
      name: '21:00'
  },
  {
      id: '22:00',
      name: '22:00'
  },
  {
      id: '23:00',
      name: '23:00'
  },
];