import React from 'react';

import { Typography, Toolbar, Button as ButtonMaterial, AppBar as MUIAppBar} from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const style = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
    }
}));

const AppBarComponent = (props) => {
    const classes = style();
    return (
        <div className={classes.root}>
            <MUIAppBar position="static">
                <Toolbar>
                <Typography variant="h6" className={classes.title}>
                    {props.title}
                </Typography>
                {props.action && <ButtonMaterial color="inherit" onClick={props.action}>
                    {props.actionTitle}
                    {props.icon}
                </ButtonMaterial>}
                {props.link && <ButtonMaterial component={Link} color="inherit" to={{ pathname: props.link }}>
                    {props.linkTitle}
                    {props.linkIcon}
                </ButtonMaterial>}
                </Toolbar>
            </MUIAppBar>
        </div>
    )
}

AppBarComponent.defaultProps = {
    icon: <AddIcon></AddIcon>,
    linkIcon: <EditIcon />
}

export const AppBar = AppBarComponent