import React from "react";
import { required, TextInput } from "react-admin";
import { Box, Typography } from "@material-ui/core";

export function TimeInput(props) {	
	if (props.show || props.list) {
		const { record } = props;
		return (
			<Typography variant="caption">
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "5px",
						marginBottom: "7px",
					}}
				>
					{props.label && props.show && (
						<Typography
							variant="caption"
							style={{
								color: "rgba(0, 0, 0, 0.54)",
							}}
						>
							{props.label}
						</Typography>
					)}
					<Typography variant="body2">
						{record[props.source]}
					</Typography>
				</Box>
			</Typography>
		);
	}

	return (
		<TextInput
			{...props}
			validate={[required("campo obbligatorio")]}
			initialValue={props.initialValue || "07:00"}
			helperText="Inizio Formato: HH:MM"
			type="time"
		/>
	);
}
