import React from 'react';
import { Toolbar, SaveButton, DeleteButton, Edit, SimpleForm, NumberInput, required } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import BaseEdit from '../../../inheritance/BaseEdit';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

function CustomToolbar(props) {
    let history = useHistory();
    return (
        <Toolbar {...props} classes={useStyles()}>
            <SaveButton />
            <DeleteButton redirect={() => {
                history.goBack();
                return '';
            }} />
        </Toolbar>
    );
}

export function CoffeeRecipeListItemEdit(props) {
    let history = useHistory();
    return (
        <Edit {...props}>
            <SimpleForm toolbar={<CustomToolbar/>} redirect={() => {
                history.goBack();
                return '';
            }}>
                <NumberInput fullWidth label="Ordine" helperText="numero da 1 a xy" source="order" validate={[required("L'ordine è obbligatorio")]} />
                <BaseEdit />
            </SimpleForm>
        </Edit>
    )
};