import React from 'react';
import { Edit, SimpleForm, TextInput, AutocompleteInput, NumberInput } from 'react-admin';
import BaseEdit from '../inheritance/BaseEdit';
import { required } from 'react-admin';
import { PROMOTION_TYPE_VALUES, PROMOTION_ENABLE_VALUES } from './';

export function PromotionEdit(props) {
	return (
		<Edit {...props}>
			<SimpleForm>
				<TextInput source="name" validate={[required('campo obbligatorio')]} />
				<AutocompleteInput validate={[required('campo obbligatorio')]} source="type" choices={PROMOTION_TYPE_VALUES.map(type => {
					return {
						id: type,
						name: type
					}
				})} />
				<NumberInput validate={[required('campo obbligatorio')]} source="value" />
				<TextInput source="label" />
				<AutocompleteInput validate={[required('campo obbligatorio')]} source="enable" choices={PROMOTION_ENABLE_VALUES.map(enableValue => {
					return {
						id: enableValue,
						name: enableValue
					}
				})} />
				<BaseEdit />
			</SimpleForm>
		</Edit>
	);
}
