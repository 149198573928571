import React, { useState, useEffect } from 'react';
import { GET_LIST, useDataProvider, usePermissions } from 'react-admin';

import { Permissions } from '../../../permissions/permissions';
import { ShowPreview } from '../../../layouts/ShowPreview';
import { CoffeeSellingOrderShow } from '../../transaction/coffeeSellingOrder/CoffeeSellingOrderShow';

export default (props) => {
	const dataProvider = useDataProvider();
	const [coffeeSellingOrderData, setCoffeeSellingOrderData] = useState(null);
	const { permissions } = usePermissions();

	useEffect(() => {
		if(permissions) {
				permissions.includes(`${Permissions.coffeeSellingOrder.feature}-${Permissions.coffeeSellingOrder.actions.ReadOne}`) && props.sellingOrderId &&
				dataProvider(GET_LIST,`${Permissions.coffeeSellingOrder.feature}`, {
					filter: { "sellingOrderId||eq": props.sellingOrderId },
					pagination: {
						page: 1,
						perPage: 2
					}
				})
				.then(({data}) => {
					if(data.length > 0) {
						setCoffeeSellingOrderData(data[0]);
					}
				});
		}
	}, [dataProvider, setCoffeeSellingOrderData, props.sellingOrderId, permissions]);

	return (
		<ShowPreview
			data={coffeeSellingOrderData}
			title={"Dati Vendita Caffè"}
			basePath = {`/${Permissions.coffeeSellingOrder.feature}`}
			resource = {`${Permissions.coffeeSellingOrder.feature}`}
			show={CoffeeSellingOrderShow}
		>
		</ShowPreview>
	);
};