import React from 'react';
import { Create, SimpleForm, TextInput, BooleanInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { required } from 'react-admin';
import { Permissions } from '../../permissions/permissions';

export const LockerCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Nome" source="name" validate={[required('Il nome è obbligatorio')]} />
            <TextInput label="Nome per cliente" source="publicName" validate={[required('Il nome è obbligatorio')]} />
            <BooleanInput label="Abilita preordini" source="preOrderPickUp"></BooleanInput> 
            <ReferenceInput
                filterToQuery={searchText => {
                    if(!searchText)
                        return {};
    
                    return { name: searchText }
                }}
                allowEmpty label="Lock" source="lockId" reference={Permissions.lock.feature} >
                <AutocompleteInput
					optionText="name"
					optionValue="id" />
            </ReferenceInput>
            <BaseCreate />
        </SimpleForm>
    </Create>
);