import React from 'react';
import { Create, SimpleForm, NumberInput, ReferenceInput, SelectInput, AutocompleteInput, BooleanInput, FormDataConsumer } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { required } from 'react-admin';
import { Permissions } from '../../permissions/permissions';
import { DIMENSIONS } from './enum';

export function ItemSaleablePriceListCreate(props)  {
    return (
        <Create {...props}>
            <SimpleForm redirect={props.redirect}>
                <ReferenceInput
                    fullWidth
                    filterToQuery={searchText => {
                    if(!searchText)
                        return {};

                        return {
                            "item.name": searchText,
                            "item.saleable": true
                        }
                    }}
                    label="Item" source="itemSaleableId" reference={`${Permissions.itemSaleable.feature}`} validate={[required("Indicare l'item è obbligatorio")]}>
                    <AutocompleteInput
                        shouldRenderSuggestions={val => val && val.length >= 2 }
                        options={{suggestionsContainerProps: {disablePortal: true}}}
                        optionText={(record)=> record ? `${record.item.id} ${record.item.name}` : ''}
                        optionValue="id" />
                </ReferenceInput>
                <div style={{display: 'none' }}>
                    <ReferenceInput fullWidth disabled source="priceListId" reference="priceList" initialValue={props.priceListId}  validate={[required("E' obbligatorio agganciare un PriceList")]} >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </div>
                <NumberInput fullWidth label="Ordine" helperText="numero da 1 a x" source="order"  validate={[required("L'ordine è obbligatorio")]} />
                <BooleanInput fullWidth label="Prezzo differente" source="differentPrice"></BooleanInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return formData.differentPrice ? (
                            [
                                <NumberInput
                                    validate={[required("Il prezzo è obbligatorio")]}
                                    key={`price-formDataConsumer`}
                                    fullWidth
                                    label="Prezzo"
                                    source="price"
                                    {...rest}
                                />
                            ]
                        ) : null
                    }}
                </FormDataConsumer>

                <BooleanInput fullWidth defaultValue={false} label="Nuovo" source="new"></BooleanInput>
                <BooleanInput fullWidth defaultValue={true} label="Visualizza in Impulso" source="showInImpulse"></BooleanInput>
                <BooleanInput fullWidth defaultValue={true} label="Visualizza in Schermo Menu" source="showInScreenMenu"></BooleanInput>
                <AutocompleteInput
                    allowEmpty
                    fullWidth
                    label="Visualizza su menu da app"
                    source="menuDisplayType"
                    validate={[required("Obbligatorio")]}
                    defaultValue="GRANDE"
                    options={{suggestionsContainerProps: {disablePortal: true}}}
                    choices={DIMENSIONS.map(dimension => {
                        return {
                            id: dimension,
                            name: dimension
                        }
                    
                })} />
                <BaseCreate></BaseCreate>
            </SimpleForm>
        </Create>
    );
}