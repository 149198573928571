import React from 'react';
import { Datagrid, List, TextField, ReferenceInput, AutocompleteInput, DateField, BooleanInput, NumberInput } from 'react-admin';
import Filter from '../../inheritance/BaseFilter';
import ListActions from '../../../components/ListActions';
import { Permissions } from '../../../permissions/permissions';
import DateTimeFilter from '../../../components/DateTimeFilter';

function MissionFilter(props) {
	return (
		<Filter {...props}>
			<AutocompleteInput emptyText="Non specificato" label="Tipo" source="type" choices={[
				'smartfridge',
				'coffee',
				'delivery',
				'catering',
				'store-delivery',
				'waste',
				'other'
			].map(missioType => {
				return {
					id: missioType,
					name: missioType.charAt(0).toUpperCase() + missioType.slice(1)
				}
			})}/>
			<ReferenceInput
				alwaysOn
				emptyText="Non specificato"
				filter={{"authType||eq": "GOOGLE"}}
				filterToQuery={searchText => {
					if(!searchText)
						return null;

					return {
						"email": searchText,
						authType: "GOOGLE"
					}
				}}
				source="assigneeId"
				reference={`${Permissions.user.feature}`}
			>
				<AutocompleteInput
					optionText="email"
					optionValue="id" />
			</ReferenceInput>
			<DateTimeFilter 
				source={`date||gte`}
				label={`data Da`}
			/>
			<DateTimeFilter 
				source={`date||lte`}
				label={`data A`}
			/>
			<ReferenceInput
				alwaysOn
				emptyText="Non specificato"
				filterToQuery={searchText => {
					if(!searchText)
						return {};

					return { name: searchText }
				}}
				label="Luogo"
				source="placeId||eq"
				reference={`${Permissions.place.feature}`}
			>
				<AutocompleteInput
					optionText="name"
					optionValue="id" />
			</ReferenceInput>
			<ReferenceInput
				alwaysOn
				emptyText="Non specificato"
				filterToQuery={searchText => {
					if(!searchText)
						return {};

					return { name: searchText }
				}}
				label="Nome Punto di vendita"
				source="sellingPointId||eq"
				reference={`${Permissions.sellingPoint.feature}`}
			>
				<AutocompleteInput
					optionText="name"
					optionValue="id" />
			</ReferenceInput>
			<ReferenceInput
				alwaysOn
				emptyText="Non specificato"
				filterToQuery={searchText => {
					if(!searchText)
						return {};

					return { name: searchText }
				}}
				label="Hub"
				source="hubId||eq"
				reference={`${Permissions.hub.feature}`}
			>
				<AutocompleteInput
					optionText="name"
					optionValue="id" />
			</ReferenceInput>
			<BooleanInput		
				label="Rider Sconosciuto"
				source="assigneeId||isnull"
          		parse={() => true}
			/>
			<NumberInput label="transactionParentId" source="transactionParentId||eq"/>
			<NumberInput label="transactionId" source="transactionId||eq"/>
		</Filter>
	);
}

export function MissionList(props) {
	return (
		<List {...props} actions={<ListActions />} filters={<MissionFilter />} sort={{ field: 'date', order: 'DESC' }}>
			<Datagrid rowClick="show">
				<TextField label="Tipo" source="type" />
				<TextField label="Place" source="place.name" />
				<TextField label="SelligPoint" source="sellingPoint.name" />
				<TextField label="Rider" source="assignee.name" />
				<DateField
					showTime
					label="Data"
					source="date"
				/>
				<Comment label="Commento" />
			</Datagrid>
		</List>
	);
}

function Comment(props) {
	if(props && props.record && props.record.comment) {
		return <div style={{ maxHeight: '80px', overflow: 'scroll', backgroundColor: 'rgba(0, 0, 0, 0.09)'}} dangerouslySetInnerHTML={{ __html: props.record.comment }} />
	}
    return null;
}
