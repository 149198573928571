import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField, DateField, useShowController } from 'react-admin';
import Aside from '../../inheritance/BaseAside';
import { Permissions } from '../../../permissions/permissions';
import { Typography } from '@material-ui/core';

export function MissionShow(props) {
	const {
		loaded,
		record,
	} = useShowController(props);
	return (
		<Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
			<SimpleShowLayout>
				<TextField label="Tipo" source="type" />
				<TextField label="Inventario" source="inventory" />
				<DateField
					showTime
					source="date"
					label="data"
				/>
				<ReferenceField sortable={false} label="Place" source="placeId" reference={`${Permissions.place.feature}`} link='show'>
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField
					label="Punto di vendita"
					source="sellingPointId"
					reference={Permissions.sellingPoint.feature}
					link="show"
					>
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField
					label="Magazzino di destinazione"
					source="destinationWarehouseLocationId"
					reference={Permissions.warehouseLocation.feature}
					link="show"
					>
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField
					label="assegnato"
					source="assigneeId"
					reference={Permissions.user.feature}
					link="show"
					>
					<TextField source="email" />
				</ReferenceField>
				<ReferenceField
					label="Carico"
					source="loadingTransferId"
					reference={Permissions.transfer.feature}
					link="show"
					>
					<TextField source="id" />
				</ReferenceField>
				<ReferenceField
					label="Scarico"
					source="unLoadingTransferId"
					reference={Permissions.transfer.feature}
					link="show"
					>
					<TextField source="id" />
				</ReferenceField>
				<ReferenceField sortable={false} label="Hub" source="hubId" reference={`${Permissions.hub.feature}`} link='show'>
					<TextField source="name" />
				</ReferenceField>
				<div>
					<Typography>Comment</Typography>
					{loaded && <div dangerouslySetInnerHTML={{ __html: record.comment }} />}
				</div>
			</SimpleShowLayout>
		</Show>
	)
};