import React from 'react';

import { ShowPreview } from '../../../layouts/ShowPreview';
import { SellingPointImpulseShow } from '../../sellingPointImpulse/SellingPointImpulseShow';

export default (props) => {
  return (
    <ShowPreview
      data={props.sellingPointImpulse}
      title={"Punto di vendita Impulso"}
      basePath = {"/sellingPointImpulse"}
      resource = {"sellingPointImpulse"}
      redirect={`/sellingPoint/${props.sellingPointId}/show`}
      hasCreateModal={false}
      hasEditP={true}
      show={SellingPointImpulseShow}
    >
    </ShowPreview>
  )
}