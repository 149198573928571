import React from "react";
import { Typography, Box } from "@material-ui/core";

export function FormPhotosProviderData(props) {
  return (
    <>
      <Typography variant="caption">
          <Box>
            <span style={{ fontWeight: "bold" }}>{`Numero minimo di foto: `}</span>
            {props.data.minPhotos ? <span>{`${props.data.minPhotos}`}</span> : '-'}
          </Box>
        </Typography>
    </>
  );
}
