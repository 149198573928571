import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import BaseEdit from '../../inheritance/BaseEdit';
import { required } from 'react-admin';

export function SolubleTypeEdit(props) {
	return (
		<Edit {...props}>
			<SimpleForm>
				<TextInput fullWidth validate={[required()]} source="externalName" />
				<TextInput fullWidth validate={[required()]} source="internalName" />
				<BaseEdit />
			</SimpleForm>
		</Edit>
	);
}
