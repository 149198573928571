import React from 'react';
import { Create, SimpleForm, TextInput, AutocompleteInput, NumberInput } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { required } from 'react-admin';
import { PROMOTION_TYPE_VALUES } from './';

export function PromotionCreate(props) {
	return (
		<Create {...props}>
			<SimpleForm redirect="list">
				<TextInput source="name" validate={[required('campo obbligatorio')]} />
				<AutocompleteInput validate={[required('campo obbligatorio')]} source="type" choices={PROMOTION_TYPE_VALUES.map(enableValue => {
					return {
						id: enableValue,
						name: enableValue
					}
				})} />
				<NumberInput validate={[required('campo obbligatorio')]} source="value" />
				<TextInput source="label" />
				<BaseCreate />
			</SimpleForm>
		</Create>
	);
}