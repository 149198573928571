import React from 'react';
import { Datagrid, List, TextField, TextInput, ReferenceInput, AutocompleteInput, Filter, TopToolbar, useGetOne, Loading } from 'react-admin';
import { Permissions } from '../../../permissions/permissions';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
import { BulkDeleteButton } from 'react-admin';


function AccrWarehouseItemsFilters(props) {
    return (
        <Filter {...props}>
            <TextInput type="tel" label="item Id" source="item.id" alwaysOn />
            <TextInput label="item name" source="item.name" alwaysOn />
            <TextInput type="tel" label="quantità minima" source="quantity||gte" alwaysOn />
            <TextInput type="tel" label="quantità massima" source="quantity||lte" alwaysOn />
            <ReferenceInput
                alwaysOn
                filterToQuery={searchText => {
                    if (!searchText)
                    return {};

                    return {
                    internalName: searchText
                    }
                }}
                label="Categoria ecommerce"
                source="item.itemSaleable.clientCategoryId||eq"
                reference={`${Permissions.clientCategory.feature}`} >
                <AutocompleteInput
                    emptyText="Tutte"
                    optionText="internalName"
                    optionValue="id" />
            </ReferenceInput>
        </Filter>
    );
}

function AccrWarehouseItemsActions(props) {
    let history = useHistory();

    return (
        <TopToolbar {...props}>
            <Button variant="contained" color="primary" startIcon={<AddIcon/>} onClick={() => {
                history.push(`/accrWarehouseItems/create?parentId=${props.filterValues['warehouseLocationId||eq']}`);
            }}>CREATE</Button>
        </TopToolbar>
    )
}
function NonUndoableBulkActionButtons(props) {
    return (
        <React.Fragment>
        {props.buttons}
        <BulkDeleteButton {...props} undoable={false} />
        </React.Fragment>
    );
}

export function Accr_WarehouseItemsList(props) {
    const params = new URLSearchParams(props.location.search);
    const filter = params.get('filter');
    
    const { data, loading, error } = useGetOne(`warehouseLocation`, JSON.parse(filter)["warehouseLocationId||eq"]);

    if(loading || !data || error) {
        return (<Loading />);
    }

    return (
        <List
            {...props}
            title={`${data.name} > Accr. WarehouseItems`}
            actions={<AccrWarehouseItemsActions />}
            filters={<AccrWarehouseItemsFilters />}
            sort={{ field: 'item.itemSaleable.clientCategory.internalName', order: 'ASC'} }
            filterDefaultValues={{"quantity||gte": 1}}
            perPage={25}
            bulkActionButtons={<NonUndoableBulkActionButtons buttons={props.bulkActionButtons} />}
        >
            <Datagrid rowClick="edit">
                <TextField label="Item id" source="item.id"/>
                <TextField label="Item name" source="item.name"/>
                <TextField label="Categoria ecommerce" source="item.itemSaleable.clientCategory.internalName"/>
                <TextField label="Quantità" source="quantity" />
            </Datagrid>
        </List>
    )
}