import React from 'react';

import { Datagrid, List, useRefresh, usePermissions, changeListParams } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { AppBar as DefaultAppBar } from '../components/AppBar';
import { List as ListIcon } from '@material-ui/icons';
import { Permissions } from '../permissions/permissions';
import { useSelector, useDispatch } from 'react-redux';

const style = makeStyles((theme) => ({
    dialog: {
        width: '60vw',
    }
}));

const Empty = () => null;

const ListPreviewComponent = (props) => {
    const classes = style();
    const [open, setOpen] = React.useState(false);
    const refresh = useRefresh();
    const descriptionElementRef = React.useRef(null);
    const { permissions } = usePermissions();

    const dispatch = useDispatch();
    const filter = useSelector(state => state.admin.resources[props.resource] && state.admin.resources[props.resource].list.params.filter);
    const listParams = useSelector(state => state.admin.resources[props.resource] && state.admin.resources[props.resource].list.params);

    if(filter && Object.keys(filter).length > 0) {
        dispatch(changeListParams(props.resource, {
            ...listParams,
            filter: {}
        }));
    }

    React.useEffect(() => {
      if (open) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      }
    }, [open]);

    const CreateComponent = props.create;
    const ListComponent = props.list;

    if(!permissions)
        return null;

    if(permissions && !permissions.includes(`${props.resource}-${Permissions[props.resource].actions.ReadAll}`))
        return null;
    
    let rowClick;

    if(
        (props.rowClick === 'show' && permissions.includes(`${props.resource}-${Permissions[props.resource].actions.ReadOne}`))
        || (props.rowClick === 'edit' && permissions.includes(`${props.resource}-${Permissions[props.resource].actions.UpdateOne}`))
    )
        rowClick = props.rowClick;

    return (
        <Card>
            <AppBar
                setOpen={setOpen}
                parentProps={props}
                permissions={permissions}
            ></AppBar>

            {!props.list &&  <List
                title=" "
                {...{
                    permissions: [],
                    basePath: props.basePath,
                    resource: props.resource,
                    options: {}
                }}
                actions={null}
                {...props.reactAdminListProps}
                empty={<Empty></Empty>}
                filter={props.filterList}
                filters={props.filters}
            >
                <Datagrid rowClick={rowClick}>
                    {props.children}
                </Datagrid>
            </List>}

            {props.list && <ListComponent
                title=" "
                {...{
                    permissions: [],
                    basePath: props.basePath,
                    resource: props.resource,
                    options: {},
                    hasList: true,
                    hasEdit: true,
                    hasShow: true,
                    hasCreate: true
                }}
                actions={null}
                {...props.reactAdminListProps}
                empty={<Empty></Empty>}
                filter={props.filterList}
            >
            </ListComponent>}

            <Dialog   
                open={open}
                onClose={() => {setOpen(false)}}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth='lg'
            >
                <DialogTitle id="scroll-dialog-title">{props.modalSubTitle} {props.title}</DialogTitle>
                <DialogContent  className={classes.dialog} dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        {props.hasCreateModal && <CreateComponent 
                            basePath = {props.basePath}
                            resource = {props.resource}
                            {...props.createProps}
                            redirect={() => {
                                setOpen(false);
                                refresh();
                                return props.redirect
                            }}
                        />}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
      </Card>
    )
}

ListPreviewComponent.defaultProps = {
    hasCreateModal: false,
    hasListP: false,
    listIcon: <ListIcon />,
    linkText: 'Lista completa',
    actionTitle: 'Aggiungi',
    modalSubTitle: 'Nuovo',
    createProps: {},
    rowClick: 'edit',
    reactAdminListProps: {}
}

export const ListPreview = ListPreviewComponent;


function AppBar(props) {
    let action;

    if(props.parentProps.customAction) {
        action = props.parentProps.customAction;
    } else {
        action = props.permissions && props.permissions.includes(`${props.parentProps.resource}-${Permissions[props.parentProps.resource].actions.CreateOne}`) && props.parentProps.hasCreateModal ? () => {props.setOpen(true)} : null
    }

    return <DefaultAppBar
        title={props.parentProps.title}
        action={action}
        actionTitle={props.parentProps.actionTitle}
        link={props.permissions && props.permissions.includes(`${props.parentProps.resource}-${Permissions[props.parentProps.resource].actions.UpdateOne}`) && props.parentProps.hasListP ? `${props.parentProps.basePath}` : null}
        linkText={props.parentProps.linkText}
        linkIcon={props.parentProps.linkIcon}
    />
}