import React from 'react';
import { Datagrid, List, TextField, BooleanField, TextInput, NumberInput, BooleanInput } from 'react-admin';
import Filter from '../inheritance/BaseFilter';
import ListActions from '../../components/ListActions';

const TaxFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nome" source="name" alwaysOn />
        <NumberInput label="Valore in percentuale" source="value" />
        <BooleanInput label="é IVA" source="iva"></BooleanInput>
    </Filter>
);

export const TaxList = props => (
    <List {...props} actions={<ListActions />} filters={<TaxFilter />}>
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <TextField label="Nome" source="name" />
            <TextField label="Valore in percentuale" source="value" />
            <BooleanField label="é IVA" source="iva" />
        </Datagrid>
    </List>
);