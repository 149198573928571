import React from 'react';
import { Edit, SimpleForm, TextInput, AutocompleteInput, ReferenceInput } from 'react-admin';
import BaseEdit from '../../inheritance/BaseEdit';
import { required } from 'react-admin';
import { Permissions } from '../../../permissions/permissions';

export function CoffeeMachineEdit(props) {
	return (
		<Edit {...props}>
			<SimpleForm>
				<ReferenceInput
					fullWidth
					validate={[required()]}
					filterToQuery={searchText => {
						if(!searchText)
							return null;

						return {
							name: searchText
						}
					}}
					label="Luogo"
					source="placeId"
					reference={`${Permissions.place.feature}`}
				>
                    <AutocompleteInput
                        optionText={(record) => record ? `${record.id} - ${record.name}` : ''}
                        optionValue="id" />
                </ReferenceInput>
				<TextInput fullWidth validate={[required()]} source="name" />
				<TextInput fullWidth label="Numero di matricola" source='serialNumber' />
				<BaseEdit />
			</SimpleForm>
		</Edit>
	);
}
