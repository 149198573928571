import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField, NumberField } from 'react-admin';
import Aside from '../../inheritance/BaseAside';
import { Permissions } from '../../../permissions/permissions';

export const SupplierPriceListShow = (props) => {
return (
	<Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
		<SimpleShowLayout>
			<ReferenceField label="Fornitore" source="supplierId" reference={`${Permissions.supplier.feature}`} link="show">
				<TextField source="name" />
			</ReferenceField>
			<ReferenceField label="Item" source="itemPurchasable.item.id" reference={`${Permissions.item.feature}`} link="show">
				<TextField source="name" />
			</ReferenceField>
			<TextField label="Referenza Item fornitore" source="supplierReference" />
			<TextField label="Codice Item fornitore" source="supplierCode" />
			<NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Costo senza IVA" source="costNoTax" />
			<ReferenceField
				label="Unità di fabbisogno"
				source="orderUnitId"
				reference={Permissions.unitOfMeasurement.feature}
				>
				<TextField source="name" />
			</ReferenceField>

			<ReferenceField
				label="Unità di costo/ordine"
				source="costUnitId"
				reference={Permissions.unitOfMeasurement.feature}
				>
				<TextField source="name" />
			</ReferenceField>
			<NumberField label="Minimo di ordine (in unità di costo/ordine/fornitore)" source="minimumOrder" />
			<NumberField label="Arrotondamento (in unità di costo/ordine/fornitore)" source="rounding" />
			<TextField label="Note" source="note" />
		</SimpleShowLayout>
	</Show>
)};
