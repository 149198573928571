import React from "react";
import { Typography, Box } from "@material-ui/core";

export function FormTextDeliveryManProvidedData(props) {
  return (
    <>
      {props.data ? (
        <Typography variant="caption">
          <Box>
            <span style={{ fontWeight: "bold" }}>{`Testo inserito: `}</span>
            <span>{`${props.data.formText}`}</span>
          </Box>
        </Typography>
      ) : (
        "-"
      )}
    </>
  );
}
