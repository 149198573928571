import React from 'react';
import { Datagrid, List, TextField, TextInput } from 'react-admin';
import Filter from '../inheritance/BaseFilter';
import ListActions from '../../components/ListActions';

function BusinessUnitFilter(props) {
    return (
        <Filter {...props}>
            <TextInput source="name" alwaysOn />
            <TextInput source="techKey" alwaysOn />
        </Filter>
    );
}

export function BusinessUnitList(props) {
    return (
        <List {...props} actions={<ListActions />}  filters={<BusinessUnitFilter />}>
            <Datagrid rowClick="show">
                <TextField source="name" />
                <TextField source="techKey" />
            </Datagrid>
        </List>
    );
}