import React, { useEffect, useState } from 'react';
import { Edit, SimpleForm, DeleteButton, SaveButton, Toolbar, FormDataConsumer, TextInput, useDataProvider, Loading } from 'react-admin';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { required } from 'react-admin';
import { useForm } from 'react-final-form';
import { POST_OFF_RESOURCE } from '../../../providers/nestjs_crud';
import { Typography } from '@material-ui/core';
import { Permissions, CondionActions } from '../../../permissions/permissions';
import { Value } from './ConditionCreate';
import { useFormatOperatorLabel } from './uitls';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

function CustomToolbar(props) {
    let history = useHistory();

    return (
        <Toolbar {...props} classes={useStyles()}>
            <SaveButton />
            <DeleteButton mutationMode="undoable" redirect={() => {
                history.goBack();
                return null;
            }} />
        </Toolbar>
    );
}

export function ConditionEdit(props) {
	let history = useHistory();

	return (
		<Edit {...props}>
			<SimpleForm toolbar={<CustomToolbar />} redirect={() => {
				history.goBack();
				return '';
        	}}>
                <TextInput disabled source="promotionId"  validate={[required('campo obbligatorio')]} />
                <TextInput disabled label="FactType" source="factType" validate={[required('campo obbligatorio')]} />
                <TextInput disabled label="Operator" source="operator" format={useFormatOperatorLabel} validate={[required('campo obbligatorio')]} />
				<FormDataConsumer>
					{(props) => {
						return (
							<CustomForm
								{...props}
							/>
						);
					}}
        		</FormDataConsumer>
			</SimpleForm>
		</Edit>
	);
}

const STATUS_FETCH_DATA = "fetch_data";
const STATUS_FETCHING_DATA = "fetching_data";
const STATUS_DATA_FETCHED = "data_fetched";
const STATUS_FETCHING_DATA_ERROR = "fetching_data_error";

export function CustomForm(props) {
	const form = useForm();
	const [status, setStatus] = useState(STATUS_FETCH_DATA);
	const [choices, setDataChoices] = useState([]);
	const dataProvider = useDataProvider();

	useEffect(() => {
        if(status === STATUS_FETCH_DATA) {
			setStatus(STATUS_FETCHING_DATA);
			dataProvider(POST_OFF_RESOURCE, `${Permissions.condition.feature}/${CondionActions.fieldOptions}/factType`, {
				data: form.getState().values
			})
			.then(({data}) => {
				setDataChoices(data.choices);
				setStatus(STATUS_DATA_FETCHED);
			})
			.catch((e) => {
				setStatus(STATUS_FETCHING_DATA_ERROR)
			});
		}
	}, [dataProvider, form, status]);

	if(status === STATUS_FETCHING_DATA_ERROR) {
		return <Typography>Errore durante il caricamento</Typography>
	}

	if(status === STATUS_DATA_FETCHED) {
		return (
			<>
                
				{props.formData.factType && props.formData.operator && <Value
					formData={props.formData}
					data={choices}
				/>}
			</>
		);
	}

	return <Loading />;
}
