import React from 'react';
import { Edit, SimpleForm, TextInput, AutocompleteInput, ReferenceInput, NumberInput, required, BooleanInput } from 'react-admin';
import BaseEdit from '../../inheritance/BaseEdit';
import { Permissions } from '../../../permissions/permissions';

export function CoffeeSellingPointEdit(props) {
	return (
		<Edit {...props}>
			<SimpleForm redirect="show">
				<TextInput fullWidth validate={[required()]} source="publicName" />
				<ReferenceInput
					fullWidth
					filter={{ "typology||eq": 'Coffee' }}
					source="sellingPointId"
					reference={Permissions.sellingPoint.feature}
					filterToQuery={searchText => {
						if (!searchText)
							return null;
	
						return {
							'typology||eq': 'Coffee',
							name: searchText
						}
					}}
					validate={[required()]} >
					<AutocompleteInput
						optionText={(record) => record ? `${record.id} - ${record.name}` : ''}
						optionValue="id" />
				</ReferenceInput>
				<ReferenceInput
					fullWidth
					source="coffeeMachineId"
					reference={Permissions.coffeeMachine.feature}
					filterToQuery={searchText => {
						if (!searchText)
							return null;
	
						return {
							name: searchText
						}
					}}
					validate={[required()]} >
					<AutocompleteInput
						optionText={'name'}
						optionValue="id" />
				</ReferenceInput>
				<ReferenceInput
					fullWidth
					source="coffeeRecipeListId"
					reference={Permissions.coffeeRecipeList.feature}
					filterToQuery={searchText => {
						if (!searchText)
							return null;
	
						return {
							name: searchText
						}
					}}
					validate={[required()]} >
					<AutocompleteInput
						optionText={'name'}
						optionValue="id" />
				</ReferenceInput>
				<NumberInput fullWidth source="coffeePrice" validate={[required()]} />
				<NumberInput fullWidth source="milkPrice" validate={[required()]} />
				<NumberInput fullWidth source="coffeeMilkPrice" validate={[required()]} />
				<BooleanInput fullWidth source="open" /> 
				<TextInput fullWidth source="closePhrase" />
				<TextInput fullWidth source="note" />
				<BaseEdit />
			</SimpleForm>
		</Edit>
	);
}
