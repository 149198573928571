import React from 'react';
import { PhysicalItemTransferList } from "./PhysicalItemTransferList";
import { Permissions } from '../../../../permissions/permissions';
import { Redirect } from "react-router-dom";

const PhysicalItemTransferEdit = props => {
    return <Redirect
        to={{
            pathname: `/${Permissions.transfer.feature}_${Permissions.physicalItemTransfer.feature}/${props.id}`
        }}
    />
};

export default { list:PhysicalItemTransferList, edit: PhysicalItemTransferEdit };