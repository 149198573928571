import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField } from 'react-admin';
import Aside from '../../inheritance/BaseAside';
import { Permissions } from '../../../permissions/permissions';
import { TimeSlotCalendarField } from '../../timeSlotRule/TimeSlotCalendarField';
import { SelectRoutingAccount } from '../../../components/SelectRoutingAccount';

export function HubShow(props) {
	return (
		<Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
			<SimpleShowLayout>
				<TextField label="ID" source="id" />
				<TextField label="Nome" source="name" />
				<ReferenceField
					label="Luogo"
					source="placeId"
					reference={Permissions.place.feature}
					link="show"
					>
					<TextField source="name" />
				</ReferenceField>	
				<ReferenceField
					label="Carico"
					source="loadingId"
					reference={Permissions.warehouseLocation.feature}
					link="show"
					>
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField
					label="Scarico"
					source="unLoadingId"
					reference={Permissions.warehouseLocation.feature}
					link="show"
					>
					<TextField source="name" />
				</ReferenceField>
				<SelectRoutingAccount label="Routing account" source="routingAccountId" show/>
				<TimeSlotCalendarField />
			</SimpleShowLayout>
		</Show>
	)
};