import React from "react";
import { Title, usePermissions, useGetOne, Loading } from 'react-admin';
import { useHistory, useParams } from "react-router-dom";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { CustomListPreview as ItemSaleablePriceListListPreview } from '../priceList/itemSaleablePriceList/ItemSaleablePriceListList';
import { CustomListPreview as WarehouseLocationShowListPreview } from '../warehouse/location/WarehouseLocationShow';
import { Permissions } from '../../permissions/permissions';

const mappingResources = {
    'warehouseLocation-accrWarehouseItems': {
        component: WarehouseLocationShowListPreview,
        labelName: 'name',
        bulkActionButtons: false,
    },
    'priceList-itemSaleablePriceList':{
        component: ItemSaleablePriceListListPreview,
        labelName: 'name',
        bulkActionButtons: true,
    }
}

export function CustomListPreview(props) {
    let { resource, parent, id } = useParams();
    let { permissions } = usePermissions();
    let history = useHistory();

    const { data, loading, error } = useGetOne(`${parent}`, id);

    if(loading) {
        return (<Loading />);
    }

    if(!resource || !parent || !id || error) {
        return <Card>
        <Title title="Dati non sufficenti" />
            <CardContent>
                Dati non sufficenti
            </CardContent>
        </Card>
    }

    if(!mappingResources[`${parent}-${resource}`]) {
        return <Card>
        <Title title="Errore" />
            <CardContent>
                Risorsa non implementata
            </CardContent>
        </Card>
    }

    if(permissions && !permissions.includes(`${resource}-${Permissions[resource].actions.ReadAll}`)) {
        return <Card>
        <Title title="Permessi non sufficienti" />
            <CardContent>
                Permesso mancante: {`${resource}-${Permissions[resource].actions.ReadAll}`}
            </CardContent>
        </Card>
    }

    const Component = mappingResources[`${parent}-${resource}`].component;

    return (
        <div style={{padding: '16px'}}>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button variant="contained" color="primary" style={{margin: '0 0 8px'}} startIcon={<VisibilityIcon/>} onClick={() => {
                history.push(`/${parent}/${id}/show`);
            }}>TORNA ALLO SHOW</Button>
            </div>
            <div style={{padding: '16px', backgroundColor: 'white'}}>
                <Component bulkActionButtons={mappingResources[`${parent}-${resource}`].bulkActionButtons} title={data[mappingResources[`${parent}-${resource}`].labelName]} enableFilter={true} parentId={id} sortable={true} redirect={`/list-preview/${resource}/parent-resource/${parent}/${id}`} />
            </div>
        </div>
    )
}