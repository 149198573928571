import React from 'react';
import { Toolbar, SaveButton, DeleteButton, Edit, SimpleForm, TextInput, required, ReferenceInput, SelectInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import BaseEdit from '../../inheritance/BaseEdit';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const CustomToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
        {false && <DeleteButton redirect={props.redirect} />}
    </Toolbar>
);

const ItemPurchasableEditComponent = props => {
    return (
        <Edit {...props}>
            <SimpleForm redirect={() => (`/item/${props.itemId}/show`)} toolbar={<CustomToolbar data={props.data} redirect={() => (`/item/${props.itemId}/show`)}/>}>
                <ReferenceInput
                    fullWidth
                    label="Tassa di acquisto"
                    source="taxId"
                    reference="tax"
                    validate={[required('Indicare la tassazione è obbligatorio')]}>
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <TextInput
                    fullWidth
                    label="Produttore/Marchio"
                    source="brand" />

                <TextInput
                    fullWidth
                    label="Nota"
                    source="note" />
                <BaseEdit />
            </SimpleForm>
        </Edit>
    )
};

export const ItemPurchasableEdit = connect((state, props) => {
    return {
        itemId: state.admin.resources.itemPurchasable && state.admin.resources.itemPurchasable.data[props.id] ? state.admin.resources.itemPurchasable.data[props.id].item.id : null
    }
})(ItemPurchasableEditComponent)