import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, required } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';

export const DomainCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<TextInput label="Nome" source="name" validate={[required('campo obbligatorio')]} />
			<TextInput label="Dominio" source="regex" validate={[required('campo obbligatorio')]} />
			<SelectInput label="Tipo" source="type" choices={[
				{ id: 'google', name: 'Google' },
				{ id: 'microsoft', name: 'Microsoft' },
				{ id: 'email', name: 'Email' }
			]} validate={[required('campo obbligatorio')]} />
			<BaseCreate />
		</SimpleForm>
	</Create>
);