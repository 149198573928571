import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { useDataProvider } from 'react-admin';
import { PDFDownloadLink } from "@react-pdf/renderer";
import DDT from './Pdf/DDT';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import { usePermissions } from 'react-admin';
import { Permissions } from '../../../permissions/permissions';

export function DDTTransfer(props) {
    const [status, setStatus] = useState('fetch_data');
    const [data, setData] = useState(null);
    const dataProvider = useDataProvider();
    const { permissions } = usePermissions();

    useEffect(() => {
        if(status === 'fetch_data' && permissions && permissions.includes(`ddt-${Permissions.ddt.actions.ReadAll}`)) {
            setStatus('fetching_data')
            dataProvider.getList('ddt', {
                    filter: {
                        "transferId||eq": props.transferId,
                        "status": "new"
                    },
                    pagination: { page: 1 , perPage: 1 }
                })
                .then(({ data }) => {
                    if(data.length === 0) {
                        throw new Error();
                    }
                    if(!data[0].snapshot || (data[0].snapshot && Object.keys(data[0].snapshot).length === 0)) {
                        throw new Error();
                    } else {
                        setData(data[0]);
                        setStatus('data_fetched')
                    }
                })
                .catch(e =>  {
                    setStatus('fetching_error');
                })
        }
    }, [dataProvider, permissions, props.transferId, status])

    if(['fetching_data', 'fetch_data', 'fetching_error'].includes(status))
        return null;
    
    if (status === 'data_fetched' && data) {
        return  (
            <PDFDownloadLink
                document={<DDT data={data}/>}
                fileName={`ddt_${data.id}.pdf`}
            >
                {({ blob, url, loading, error }) =>
                loading ? "Caricamento DDT..." :
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<TransferWithinAStationIcon/>}>{props.title}</Button>
                }
                </PDFDownloadLink>
            );
    }
}