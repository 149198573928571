import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput, AutocompleteInput } from 'react-admin';
import BaseEdit from '../inheritance/BaseEdit';
import { required } from 'react-admin';
import { LINK_TYPE } from './enum';
import { url } from './CodeCreate';

export const CodeEdit = props => (
	<Edit {...props}>
		<SimpleForm>
			<BooleanInput label="Abilitato" source="enable"></BooleanInput>
			<TextInput label="Nome" source="name" validate={[required('campo obbligatorio')]} />
			<AutocompleteInput
				label="Tipo"
				source="type"
				choices={LINK_TYPE.map(type => {
					return {
						id: type,
						name: type
					}
			})} />
			<TextInput label="URL" source="url" validate={[url(), required('campo obbligatorio')]} />
			<BaseEdit />
		</SimpleForm>
	</Edit>
);