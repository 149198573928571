import React, { useState, cloneElement, Children } from 'react';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const style = makeStyles((theme) => ({
    dialog: {
		minWidth: '60vw',
    }
}));

export function ModalCreate({ children, ...props }) {
	const classes = style();
	const [open, setOpen] = useState(false);

    return Children.map(children, child => {
		return cloneElement(<span>
			<Button
				variant="contained"
				color="primary"
				onClick={() => setOpen(true)}
			>
			{child.props.title}
			</Button>
			<Dialog   
				open={open}
				onClose={() => {setOpen(false)}}
				scroll={'paper'}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
                maxWidth='lg'
			>
				<DialogTitle id="scroll-dialog-title">{child.props.title}</DialogTitle>
				<DialogContent className={classes.dialog} dividers={true}>
					<DialogContentText
						id="scroll-dialog-description"
						tabIndex={-1}
					>
						{cloneElement(child, {
							...props,
							redirect: (basePath, id, data) => {
								setOpen(false);
								if(child.props.onCreate) {
									return child.props.onCreate(basePath, id, data)
								}
								return null;
							}
						})}
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</span>)
	});
}