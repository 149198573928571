import React from 'react';
import {TextInput, AutocompleteInput, BooleanInput, NumberInput, DateInput } from 'react-admin';
import { required } from 'react-admin';
import { Typography } from '@material-ui/core';
import { useForm } from 'react-final-form';

export function Form(props) {
	const form = useForm();
	if(!form.getFieldState('accountCategory') || (form.getFieldState('accountCategory') && (form.getFieldState('accountCategory').value === null || form.getFieldState('accountCategory').value === undefined))) {
		return null;
	}
	const config = props.config[form.getFieldState('accountCategory').value];

	return (
		<div>
			<Typography>{JSON.stringify(config)}</Typography>
			<br></br>
			<BooleanInput label={`canBeNegative ${JSON.stringify(config.canBeNegative)}`} fullWidth validate={(value) => {
				if(config.canBeNegative === 'no' && (value === true) ) {
					return 'valore non valido';
				}
				else if(config.canBeNegative === 'yes' && (value === undefined || value === false)) {
					return 'valore non valido';
				}
				return null;				
			}} source="canBeNegative"></BooleanInput>
			<AutocompleteInput fullWidth validate={[required('campo obbligatorio')]} source="owner" helperText={`owner ${JSON.stringify(config.possibleOwners)}`} choices={config.possibleOwners.map(possibleOwner => {
				return {
					id: possibleOwner,
					name: possibleOwner
				}
			})} />
			<AutocompleteInput fullWidth validate={[required('campo obbligatorio')]} source="removeFromAccountingRevenuesAndTVA" helperText={`removeFromAccountingRevenuesAndTVA ${JSON.stringify(config.removeFromAccountingRevenuesAndTVA)}`} choices={config.removeFromAccountingRevenuesAndTVA.map(choice => {
				return {
					id: choice,
					name: choice
				}
			})} />
			<AutocompleteInput fullWidth validate={[required('campo obbligatorio')]} source="removeFromBIRevenues"  helperText={`removeFromBIRevenues ${JSON.stringify(config.removeFromBIRevenues)}`} choices={config.removeFromBIRevenues.map(choice => {
				return {
					id: choice,
					name: choice
				}
			})} />
			<TextInput fullWidth validate={(value) => {
				if(config.needExternalName && !value) {
					return 'valore obbligatorio'
				}
				return null;
			}} source="externalName" helperText={`needExternalName ${JSON.stringify(config.needExternalName)}`} />
			<DateInput
				fullWidth validate={(value) => {
	
				if(config.expirationDate === 'mandatory' && !value) {
					return 'campo obbligatorio';
				}
				else if(config.expirationDate === 'no' && value) {
					return 'il valore non deve essere indicato';
				}

				if(value) {
					const now = new Date();
					now.setHours(0, 0, 0, 0);
					const date = new Date(value);
					date.setHours(0, 0, 0, 0);
					if (date < now) {
						return 'expirationDate deve essere nel futuro';
					}
				}

				return null;
			}} helperText={`expirationDate ${JSON.stringify(config.expirationDate)}`} source="expirationDate" />
			<NumberInput
				fullWidth validate={(value) => {
				if(config.usagePriorityRange && !value) {
					return 'campo obbligatorio';
				}
				else if(!config.usagePriorityRange && value) {
					return 'il valore non deve essere indicato';
				}

				if(config.usagePriorityRange && value) {
					if(value < config.usagePriorityRange.min || value > config.usagePriorityRange.max) {
						return 'valore non corretto'
					}
				}

				return null;
			}} source="usagePriority" helperText={`usagePriorityRange ${JSON.stringify(config.usagePriorityRange)}`} />
		</div>
	);
}