import React from 'react';

import { TextField, ReferenceField, NumberField, DateField } from 'react-admin';
import { ListPreview } from '../../../layouts/ListPreview';
import { SupplierPriceListCreate } from './SupplierPriceListCreate';
import { Permissions } from '../../../permissions/permissions';

export default (props) => {
	return (
		<ListPreview
			reactAdminListProps={{
				sort: { field: 'created_at', order: 'DESC' }
			}}
			title={"Listino Supplier"}
			basePath = {`/${Permissions.supplierPriceList.feature}`}
			resource = {`${Permissions.supplierPriceList.feature}`}
			filterList={{ "supplierId||eq": props.supplierId }}
			redirect={`/supplier/${props.supplierId}/show`}
			hasCreateModal={true}
			createProps={{supplierId: props.supplierId}}
			create={SupplierPriceListCreate}
			rowClick="show"
		>
			<ReferenceField label="Item" source="itemPurchasableId" reference={`${Permissions.itemPurchasable.feature}`} link="show">
				<TextField source="item.name" />
			</ReferenceField>
			<TextField label="Referenza Item fornitore" source="supplierReference" />
			<TextField label="Codice Item fornitore" source="supplierCode" />
			<NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Costo senza IVA" source="costNoTax" />
			<DateField locales="it-IT" label="Data di creazione" source='created_at' />
		</ListPreview>  
	);
}