import React from 'react';
import { Create, SimpleForm, required } from 'react-admin';
import BaseCreate from '../../inheritance/BaseCreate';
import { Permissions, TransferActions } from '../../../permissions/permissions';
import NewInputSelectRest, { AutocompleteInputType, DateInputType, TextInputType } from './commonComponents/InputRest';
import TransferItemsFromGoogleSheet from './components/TransferItemsFromGoogleSheet';

export function TransferCreate(props) {
	return (
		<Create {...props}>
			<SimpleForm redirect="show">
				<NewInputSelectRest
					type={AutocompleteInputType}
					fieldOptions={`${Permissions.transfer.feature}/${TransferActions.fieldOptions}`}
					label="Tipo di Trasferimento"
					source="type"
					validate={[required('campo obbligatorio')]}
				/>
				<NewInputSelectRest
					type={AutocompleteInputType}
					fieldOptions={`${Permissions.transfer.feature}/${TransferActions.fieldOptions}`}
					allowEmpty={true}
					label="Parent Transaction"
					source="transactionParentId"
					dependOn="type"
				/>
				<NewInputSelectRest
					type={AutocompleteInputType}
					fieldOptions={`${Permissions.transfer.feature}/${TransferActions.fieldOptions}`}
					label="Assignee (backendUser)"
					source="assigneeId"
					dependOn="type"
				/>
				<NewInputSelectRest
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							}
						}
					}
					type={AutocompleteInputType}
					fieldOptions={`${Permissions.transfer.feature}/${TransferActions.fieldOptions}`}
					label="Trasferisci da"
					source="fromWarehouseLocationId"
					dependOn="type"
					validate={[required('campo obbligatorio')]}
				/>
				<NewInputSelectRest
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							}
						}
					}
					type={AutocompleteInputType}
					fieldOptions={`${Permissions.transfer.feature}/${TransferActions.fieldOptions}`}
					label="Trasferisci a"
					source="toWarehouseLocationId"
					dependOn="fromWarehouseLocationId"
					validate={[required('campo obbligatorio')]}
				/>
				<NewInputSelectRest
					type={DateInputType}
					fieldOptions={`${Permissions.transfer.feature}/${TransferActions.fieldOptions}`}
					label="Data prevista"
					source="expectedDate"
					dependOn="type"
				/>
				<NewInputSelectRest
					type={TextInputType}
					fieldOptions={`${Permissions.transfer.feature}/${TransferActions.fieldOptions}`}
					label="External document"
					source="externalDocument"
					dependOn="type"
				/>
				<TransferItemsFromGoogleSheet />
				<BaseCreate />
			</SimpleForm>
		</Create>
	);
}