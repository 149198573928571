import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import { ReferenceArrayInput, AutocompleteArrayInput, AutocompleteInput, FormDataConsumer, BooleanInput } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { required } from 'react-admin';
import AuthType, { AUTHTYPE_GOOGLE } from './AuthType';
import { Permissions } from '../../permissions/permissions';

export const UserCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<TextInput label="Nome" source="name" validate={[required('Il nome è obbligatorio')]} />
			<AutocompleteInput
				label={'Tipologia'}
				source={'authType'}
				validate={[required('Campo obbligatorio')]}
				choices={AuthType.map(authType => {
					return {
						id: authType,
						name: authType
					}
				})} />
			<FormDataConsumer>
			{({ formData, ...rest }) => {
				if(formData.authType === AUTHTYPE_GOOGLE)
					return (
						<TextInput
							label="Email"
							source="email"
							validate={[required('Email obbligatoria')]}/>
					);
				return null;
			}}
			</FormDataConsumer>
			<ReferenceArrayInput
				filterToQuery={searchText => {
					if(!searchText)
						return {};

					return { name: searchText }
				}} label="Ruoli" source="rolesIds" reference="role">
				<AutocompleteArrayInput optionText="name" />
			</ReferenceArrayInput>
			<ReferenceArrayInput
				filterToQuery={searchText => {
					if(!searchText)
						return {};

					return { name: searchText }
				}} label="Hub" source="hubsIds" reference={Permissions.hub.feature}>
				<AutocompleteArrayInput optionText="name" />
			</ReferenceArrayInput>
			<BooleanInput label="Accedi a tutti gli hub" source="accessAllHubs"/>
			<BaseCreate />
		</SimpleForm>
	</Create>
);