import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { EditButton, ShowButton } from 'react-admin';

import { Permissions } from '../../permissions/permissions';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end'
    },
}));

export default ({ basePath, data, resource }) => {
    const classes = useStyles();

    return (
    <div className={classes.toolbar}>
        <EditButton basePath={basePath} record={data} />
        <ShowButton label="ITEM" basePath={`/${Permissions.item.feature}`} record={data ? data.item : {}}></ShowButton>
    </div>
)};