import React from 'react';
import { SimpleShowLayout, TextField, BooleanField, DateField, ShowController, ShowView, ReferenceField } from 'react-admin';
import Aside from '../inheritance/BaseAside';
import { Permissions } from '../../permissions/permissions';
import { ListPreview } from '../../layouts/ListPreview';
import { WalletManualTransferCreate } from '../transaction/walletManualTransfer/WalletManualTransferCreate';
import MainUser from '../../components/MainUserField';
import { CustomArrayField } from '../../components/CustomArrayField';

export const CorporateCustomersShow = (props) => {
	return (
		<ShowController aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
			{controllerProps =>
				<ShowView {...props} {...controllerProps}>
					<SimpleShowLayout>
						<TextField label="Nome" source="name" />
						<TextField label="Nome esterno" source="externalName" />
                        <CustomArrayField label="Lista Domini" source="domains" subLabel="name" reference={Permissions.domains.feature} />
                        <CustomArrayField label="Lista Luoghi" source="places" subLabel="name" reference={Permissions.place.feature} />
						<BooleanField label="Attivo" source="active" />
						<DateField label="Creato il" source="created_at" />
						<DateField label="Aggiornato il" source="updated_at" />
                        <ReferenceField  label="Cliente B2B" source="b2bClientId" reference={`${Permissions.B2BClient.feature}`} link='show'>
                            <TextField source="name" />
                        </ReferenceField>
                        <MainUser title="Anagrafica Corporate Customer" />
						{controllerProps.record && <React.Fragment>
                        <ListPreview
                            actionTitle="Carica/Svuota"
                            modalSubTitle="Carica/Svuota"
                            hasCreateModal={true}
                            create={WalletManualTransferCreate}
                            createProps={{
                                ownerId: controllerProps.record.mainUserId
                            }}
                            title={"Conti Buono Aziendale"}
                            basePath = {`/${Permissions.walletAccAccount.feature}`}
                            resource = {`${Permissions.walletAccAccount.feature}`}
                            filterList={{ 
                                "ownerId||eq": controllerProps.record.mainUserId,
                                reserved: false
                            }}
                            reactAdminListProps={{
                                bulkActionButtons: null,
                                pagination: false,
                                sort: { field: 'created_at', order: 'DESC' }
                            }}
                            redirect={`/${Permissions.B2CUser.feature}/${controllerProps.record.id}/show`}
                            rowClick={null}>
                                <TextField sortable={false} source="id" />
                                <ReferenceField sortable={false} label="Nome" source="accAccountTypeId" reference={`${Permissions.walletAccAccountType.feature}`} link={null}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <ReferenceField sortable={false} label="Priorità" source="accAccountTypeId" reference={`${Permissions.walletAccAccountType.feature}`} link={null}>
                                    <TextField source="usagePriority" />
                                </ReferenceField>
                                <ReferenceField sortable={false} label="Scadenza" source="accAccountTypeId" reference={`${Permissions.walletAccAccountType.feature}`} link={null}>
                                    <DateField source="expirationDate" />
                                </ReferenceField>
                                <TextField sortable={false} source="balance" />
                        </ListPreview>
                        <br></br>
                    </React.Fragment>}

					</SimpleShowLayout>
				</ShowView>
			}
		</ShowController>
	)
};
