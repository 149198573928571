export const TRANSFER_ID = "TRANSFER_ID"

export function getKey(key) {
    let value = localStorage.getItem(key);

    if(value) {
        localStorage.removeItem(key);
        let json = JSON.parse(value);
        if(json) {
            return json.value;
        }
    }
    return null;
}

export function setKey(key, value, expire = 150000) {
    localStorage.setItem(key, JSON.stringify({
        value,
        expireIn: expire > 0 ? ((new Date()).getTime() + expire) : null
    }));

    if(expire > 0) {
        setTimeout(() => {
            let localStorageValue = localStorage.getItem(key);
            let date = new Date();
 
            if(localStorageValue) {
                let json = JSON.parse(localStorageValue);
                if(json.expireIn && json.expireIn <= (date.getTime() + expire + 100)) {
                    localStorage.removeItem(key);
                }
            }
        }, expire)
    }
}

export function checkKey(key) {
    let value = localStorage.getItem(key);
    if(value) {
        if(JSON.parse(value)) {
            return true;
        }
    }
    return false;
}