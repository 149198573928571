import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput, AutocompleteInput } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { required } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

export const ItemSaleableCreate = props => (
    <Create {...props}>
      <SimpleForm redirect={props.redirect}>
          <TextInput
              fullWidth
              label="Nome per Clienti Finali"
              source="name"
              validate={[required('Il nome è obbligatorio')]} />

        <TextInput
            label="Nome per Clienti Finali (Inglese)"
            source="englishName"
            validate={[required('Il nome è obbligatorio')]} />

          <TextInput
              fullWidth
              label="Photo Clienti"
              source="photoURL"
              validate={[required('La foto è obbligatoria')]} />
            
        <TextInput
            label="Photo Clienti scontornata"
            source="photoURL_NO_BACKGROUND"
            validate={[required('La foto scontornata è obbligatoria')]}
            />

          <TextInput
              fullWidth
              label="Short Description"
              source="shortDescription"
              validate={[required('La short description è obbligatoria')]} />

          <RichTextInput
              fullWidth
              label="Descrizione"
              source="description"
              validate={[required('La descrizione è obbligatoria')]} />

        <TextInput
            label="Short Description (Inglese)"
            source="englishShortDescription"
            validate={[required('La short description è obbligatoria')]} />

        <RichTextInput
            label="Descrizione (Inglese)"
            source="englishDescription"
            validate={[required('La descrizione è obbligatoria')]} />

          <NumberInput
              fullWidth
              label="Prezzo di default con IVA"
              source="price"
              validate={[required('Il prezzo è obbligatorio')]} />

          <ReferenceInput
              fullWidth
              label="Unità di misura"
              source="unitOfMeasurementId"
              reference="unitOfMeasurement"
              filter={{ "itemId||eq": props.itemId }}
              validate={[required('L\'unità di misura è obbligatoria')]}>
                  <SelectInput optionText="name" />
          </ReferenceInput>

          <ReferenceInput
              fullWidth
              label="Tasse"
              source="taxId"
              reference="tax"
              validate={[required('Indicare la tassazione è obbligatorio')]}>
              <SelectInput optionText="name" />
          </ReferenceInput>

          <ReferenceInput
              fullWidth
              filterToQuery={searchText => {
                if (!searchText)
                    return null;

                return {
                    internalName: searchText
                }
              }}
              label="Categoria Ecommerce"
              source="clientCategoryId"
              reference="clientCategory"
              validate={[required('Indicare la categoria ecommerce è obbligatorio')]}>
              <AutocompleteInput optionText="internalName" />
          </ReferenceInput>
            <TextInput
                label="Tag"
                source="tag"/>
            <NumberInput
                label="Tempo microonde in secondi"
                source="microwaveTime"
                helperText="0 se va consumato Freddo"
            />
          <div style={{display: 'none' }}>
            <NumberInput disabled source="itemId"  initialValue={props.itemId} />
          </div>
          <BaseCreate />
      </SimpleForm>
    </Create>
);