import React from 'react';
import { SimpleShowLayout, TextField, TopToolbar, ReferenceField, ShowController, ShowView, Loading, ReferenceArrayField, SingleFieldList, ChipField, DateField } from 'react-admin';
import Aside from '../../inheritance/BaseAsideWithUser';
import StateMachineReferenceField from '../../../../components/StateMachineReferenceField';
import { Permissions } from '../../../../permissions/permissions';
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from "react-router-dom";
import { NoApiCallReferenceField } from '../../../../components/NoApiCallReferenceField';
import  { StateMachineFieldWithActions } from '../../../../components/StateMachineFieldWithActions';
import { Grid, Container } from '@material-ui/core';
import  JobTaskList  from './task/JobTaskList';
import { makeStyles } from '@material-ui/core/styles';
import { Duration } from '../../../../components/Duration'
import { Actions as TransferAssignmentAction } from './transferAssignment/JobTransferAssignmentList';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1)
    }
}));

function ShowActions(props) {
    const history = useHistory();

	return (
		<TopToolbar>
			<Button color="primary" variant="text" startIcon={<EditIcon color="primary" />} onClick={() => {
                history.push(`/mission/job/${props.data.id}/edit`);
            }}>
                Edit
            </Button>
            <TransferAssignment {...props}/>
		</TopToolbar>
	);
}

export function JobShow(props) {
    const classes = useStyles();
    return (
        <ShowController {...props}>
            {controllerProps => !controllerProps.loaded ? <Loading /> :
                <Container maxWidth="xl" className={classes.root}>
                    <Grid container spacing={3}>
				        <Grid item xl={12} lg={12} md={12} xs={12}>
                    <ShowView {...props} {...controllerProps} aside={<Aside props={props} record={props.record}></Aside>} actions={<ShowActions {...props} />}>
                    <SimpleShowLayout>
                        <NoApiCallReferenceField
                            data={controllerProps.record}
                            label="Mission"
                            reference={Permissions.missionV2.feature}
                            referenceIdentifier="missionId"
                            source="missionId" />
                        <TextField label="Type" source="type" />
                        <TextField label="Transaction" source="transactionId" />
                        <br />
                        {controllerProps.record.sellingPointId !== null && <NoApiCallReferenceField
                            data={controllerProps.record}
                            label="Selling Point"
                            reference={Permissions.sellingPoint.feature}
                            referenceIdentifier="sellingPointId"
                            source="sellingPointLabel" />}
                        {controllerProps.record.warehouseLocationId !== null && <NoApiCallReferenceField
                            data={controllerProps.record}
                            label="Warehouse Location"
                            reference={Permissions.warehouseLocation.feature}
                            referenceIdentifier="warehouseLocationId"
                            source="warehouseLocationLabel" />}
                        {controllerProps.record.durationMinutes !== null && <TextField label="Duration (Minutes)" source="durationMinutes" />}
                        {controllerProps.record.businessUnitId !== null && <ReferenceField link='show' label={"Business Unit ID"} source="businessUnitId" reference={Permissions.businessUnit.feature}>
                            <TextField source="name" />
                        </ReferenceField>}
                        {controllerProps.record.deliveryManSkills !== null && Array.isArray(controllerProps.record.deliveryManSkills) && controllerProps.record.deliveryManSkills.length > 0 && <ReferenceArrayField label="Skills" reference={Permissions.deliveryManSkill.feature} source="deliveryManSkills">
                            <SingleFieldList linkType="show">
                                <ChipField source="name" />
                            </SingleFieldList>
                        </ReferenceArrayField>}
                        {controllerProps.record.maxAdvanceMinutes !== null && <TextField label="Max Advance (Minutes)" source="maxAdvanceMinutes" />}
                        {controllerProps.record.inventoryExecution !== null && <TextField label="Inventory Execution" source="inventoryExecution" />}
                        {controllerProps.record.inventoryLotCount !== null && <TextField label="Inventory Lot Count" source="inventoryLotCount" />}
                        {controllerProps.record.inventoryCycleCount !== null && <TextField label="Inventory Cycle Count" source="inventoryCycleCount" />}
                        {controllerProps.record.inventoryIds !== null && Array.isArray(controllerProps.record.inventoryIds) && controllerProps.record.inventoryIds.length > 0 && <ReferenceArrayField label="Inventory IDs" reference={Permissions.warehouseInventory.feature} source="inventoryIds">
                            <SingleFieldList linkType="show">
                                <ChipField source="id" />
                            </SingleFieldList>
                        </ReferenceArrayField>}
                        {controllerProps.record.loadTransferExecution !== null && <TextField label="Load Transfer Execution" source="loadTransferExecution" />}
                        {controllerProps.record.loadTransferId !== null && <ReferenceField link='show' label={"Load Transfer ID"} source="loadTransferId" reference={Permissions.transfer.feature}>
                            <TextField source="id" />
                        </ReferenceField>}
                        {controllerProps.record.unLoadTransferExecution !== null && <TextField label="UnLoad Transfer Execution" source="unLoadTransferExecution" />}
                        {controllerProps.record.unLoadTransferId !== null && <ReferenceField link='show' label={"Unload Transfer ID"} source="unLoadTransferId" reference={Permissions.transfer.feature}>
                            <TextField source="id" />
                        </ReferenceField>}
                        {controllerProps.record.executionComment !== null && <TextField label="Commento per esecuzione" source="executionComment" />}
                        {controllerProps.record.startDate !== null && <DateField showTime={true} locales="it-IT" label="Data avvio" source="startDate" />}
                        {controllerProps.record.lastStartDate !== null && <DateField showTime={true} locales="it-IT" label="Ultima data avvio" source="lastStartDate" />}
                        {controllerProps.record.totalDuration !== null && <Duration duration={controllerProps.record.totalDuration} label="Durata totale" />}
                        {controllerProps.record.actualDuration !== null && <Duration duration={controllerProps.record.actualDuration} label="Durata effettiva" />}
                        <StateMachineReferenceField label="Stato" source="stateMachine" reference={`${Permissions.missionJob.feature}_${Permissions.missionJob.subFeatures.stateMachine.name}`} />
                        <StateMachineFieldWithActions label="Actions" reference={`${Permissions.missionJob.feature}_${Permissions.missionJob.subFeatures.stateMachine.name}`} source='stateMachineId' />
                        {controllerProps.record.id !== null && <DeliveryManMissionJobShow id={controllerProps.record.id} />}
                    </SimpleShowLayout>
                </ShowView>

                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                        {controllerProps.record.id !== null && <JobTaskList missionJob={controllerProps.record} id={controllerProps.record.id} />}
                    </Grid>
			    </Grid>
                </ Container>
            } 
        </ShowController>
    );
}

function DeliveryManMissionJobShow(props) {
    const history = useHistory();

    if(!props.id) return null;
    
    return <Button	
        size="medium"
        onClick={() => {
            history.push(`/${Permissions.deliveryManMissionJob.feature}/${props.id}/show`)
        }}
        style={{marginTop: '12px'}}
        variant="contained"
        color="primary">
        Apri vista corriere
    </Button>
}

function TransferAssignment(props){
    if(
        (!props.data.loadTransferId && props.data.loadTransferExecution === 'mandatory') 
        || (!props.data.unLoadTransferId && props.data.unLoadTransferExecution === 'mandatory') 
    ){
        return (
            <TransferAssignmentAction 
                record={props.data}
                title="Aggiungi transfer"
            />
        )
    }
    return null;
}


