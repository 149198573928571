export const ALLERGEN_TYPE_PRESENTE = 'Presente';
export const ALLERGEN_TYPE_TRACCE = 'Tracce';
export const ALLERGEN_TYPE_ASSENTE = 'Assente';
export const ALLERGEN_TYPE = [ALLERGEN_TYPE_PRESENTE, ALLERGEN_TYPE_TRACCE, ALLERGEN_TYPE_ASSENTE];

export const DIET_TYPE_CONTIENE_DERIVATI_DELLA_CARNE_O_PESCE = 'Contiene derivati della carne o pesce';
export const DIET_TYPE_PESCETARIANO = 'Pescetariano';
export const DIET_TYPE_VEGETARIANO = 'Vegetariano';
export const DIET_TYPE_VEGANO = 'Vegano';
export const DIET_TYPE = [DIET_TYPE_CONTIENE_DERIVATI_DELLA_CARNE_O_PESCE, DIET_TYPE_PESCETARIANO, DIET_TYPE_VEGETARIANO, DIET_TYPE_VEGANO];

export const FRESH_RANGE_1 = '1° gamma';
export const FRESH_RANGE_2 = '2° gamma';
export const FRESH_RANGE_3 = '3° gamma';
export const FRESH_RANGE_4 = '4° gamma';
export const FRESH_RANGE_5 = '5° gamma';
export const FRESH_RANGE = [FRESH_RANGE_1, FRESH_RANGE_2, FRESH_RANGE_3, FRESH_RANGE_4, FRESH_RANGE_5];

export const NUTRITIONAL_VALUE_SOURCE_SCHEDA_TECNICA = 'Scheda tecnica';
export const NUTRITIONAL_VALUE_SOURCE_FORNITORI = 'Fornitori';
export const NUTRITIONAL_VALUE_SOURCE_NUTRIZIONISTA = 'Nutrizionista';
export const NUTRITIONAL_VALUE_SOURCE_FOORBAN = 'Foorban';
export const NUTRITIONAL_VALUE_SOURCE = [NUTRITIONAL_VALUE_SOURCE_SCHEDA_TECNICA, NUTRITIONAL_VALUE_SOURCE_FORNITORI, NUTRITIONAL_VALUE_SOURCE_NUTRIZIONISTA, NUTRITIONAL_VALUE_SOURCE_FOORBAN];