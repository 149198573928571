import React from 'react';
import { Filter, BooleanInput } from 'react-admin';

export default (props) => {
    return [
        <Filter {...props}>
            <BooleanInput label="Solo Attivi" source="active" defaultValue={true} />
            {props.children}
        </Filter>
    ];
}