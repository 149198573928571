import React from 'react';
import { Datagrid, List, TextField, TextInput, FunctionField, DateField } from 'react-admin';
import Filter from '../inheritance/BaseFilter';

const TimeSlotRuleFilter = (props) => (
    <Filter {...props}>
        <TextInput label="EntityId" disabled={true} source="entityId||eq" alwaysOn />
        <TextInput label="EntityType" disabled={true} source="entityType||eq" alwaysOn />
    </Filter>
);

export function TimeSlotRuleList(props) {
    return (
        <List {...props} actions={null}  filters={<TimeSlotRuleFilter />}>
            <Datagrid rowClick="show">
                <TextField sortable={false} label="ID" source="id" />
                <TextField sortable={false} label="Disponibilità" source="availability" />
                <DateField sortable={false} label="Da" source="startDate" />
                <DateField sortable={false} label="A" source="endDate" />
                <TextField sortable={false} label="Ora da" source="startTime" />
                <TextField sortable={false} label="Ora a" source="endTime" />
                <FunctionField sortable={false} label="Giorni" render={record => {
                    if(!record.weekdays || record.weekdays.length === 0) {
                        return null;
                    }
                    return record.weekdays.map(weekday => {
                            return {
                                '1': 'LUN',
                                '2': 'MAR',
                                '3': 'MER',
                                '4': 'GIO',
                                '5': 'VEN',
                                '6': 'SAB',
                                '0': 'DOM',
                            }[weekday]
                        }).join(', ');
                }} />
            </Datagrid>
        </List>
    );
}