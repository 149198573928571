import React from 'react';
import { Show, SimpleShowLayout, TextField, useShowController } from 'react-admin';
import { Typography } from '@material-ui/core';

export function BusinessUnitShow(props) {
	const {
		loaded,
		record,
	} = useShowController(props);
	return (
		<Show  {...props}>
			<SimpleShowLayout>
			<TextField source="id" />
			<TextField source="name" />
            <TextField source="techKey" />
			<div>
				<Typography>Description</Typography>
				{loaded && <div dangerouslySetInnerHTML={{ __html: record.description }} />}
			</div>
			</SimpleShowLayout>
		</Show>
	)
}
