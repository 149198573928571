import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, AutocompleteInput} from 'react-admin';
import BaseCreate from '../../inheritance/BaseCreate';
import { required } from 'react-admin';
import { Permissions } from '../../../permissions/permissions';

export const accr_WarehouseItemsCreate = props => (
    <Create {...props}>
        <SimpleForm style={{minHeight : '600px'}} redirect={props.redirect}>
            <div style={{display: 'none'}}>
                <TextInput initialValue={props.warehouseLocationId} source="warehouseLocationId"  validate={[required('Il nome è obbligatorio')]} />
            </div>
            <ReferenceInput

                filter={{ "saleable": true, "storable": true }}
                fullWidth
                filterToQuery={searchText => {
                    if(!searchText)
                        return {};

                    return {
                        "id": searchText,
                        "saleable": true,
                        "storable": true
                    }
                }}
                label="Item - ID"
                source="itemId"
                reference={Permissions.item.feature}
                validate={[required("E' obbligatorio agganciare un magazzino virtule")]} >
                    <AutocompleteInput
                        options={
                            {
                                suggestionsContainerProps: {
                                    disablePortal: true
                                },
                                type: "tel",
                                autoFocus: true
                            }
                        }
                        optionText={(record) => record ? `${record.id} -  ${record.name}` : ''}
                        optionValue="id" />
            </ReferenceInput>
            <ReferenceInput
                filter={{ "saleable": true, "storable": true }}
                fullWidth
                filterToQuery={searchText => {
                    if(!searchText)
                        return {};

                    return {
                        "name": searchText,
                        "saleable": true,
                        "storable": true
                    }
                }}
                label="Item - Name"
                source="itemId"
                reference={Permissions.item.feature}
                validate={[required("E' obbligatorio agganciare un magazzino virtule")]} >
                    <AutocompleteInput
                        options={
                            {
                                suggestionsContainerProps: {
                                    disablePortal: true
                                }
                            }
                        }
                        optionText={(record) => record ? `${record.id} -  ${record.name}` : ''}
                        optionValue="id" />
            </ReferenceInput>
            <TextInput fullWidth type="tel" label="Quantità" source="quantity" validate={[required('Il nome è obbligatorio')]} />
            <BaseCreate />
        </SimpleForm>
    </Create>
);