import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField, BooleanField } from 'react-admin';
import Aside from '../inheritance/BaseAside';
import { Permissions } from '../../permissions/permissions';
import { ListPreview } from '../../layouts/ListPreview';
import { Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1)
    }
  }));
export const B2BClientShow = (props) => {
    const classes = useStyles();
    
    return (
        <Container maxWidth="xl" className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} xs={12}>
            <Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
                <SimpleShowLayout>
                    <TextField lable="Name" source="name" />
                    <ReferenceField label={"Account Manager"} source="accountManagerId" reference={Permissions.mainUser.feature}>
                        <TextField source="label" />
                    </ReferenceField>
                    <ReferenceField label="Cluster" source="clusterId" reference={Permissions.clientCluster.feature}>
                        <TextField source="name" />
                    </ReferenceField>
                </SimpleShowLayout>
            </Show>
          </Grid>
            </Grid>
                <Grid container spacing={3}>
                    <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                        <PlaceList id={props.id} />
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                     <SellingPointList id={props.id} />
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                        <CorporateCustomersList id={props.id} />
                    </Grid>
                </Grid>
            </Container>
           
    )
};
function PlaceList (props) {
    return (
        <ListPreview
        title={"Luoghi"}
        basePath = {`/${Permissions.place.feature}`}
        resource = {`${Permissions.place.feature}`}
        filterList={{ "b2bClientId||eq": props.id}}
        hasCreateModal={false}
        rowClick={"show"}
        >
        <TextField source="name"/>
        <TextField label="Indirizzo" source="address" />
        <BooleanField label="Può acquistare" source="canPurchase" /> 
        <BooleanField label="Può vendere" source="canSell" /> 
    </ListPreview>
    )
}


function SellingPointList (props) {
  return (
      <ListPreview
      title={"Punti di Vendita"}
      basePath = {`/${Permissions.sellingPoint.feature}`}
      resource = {`${Permissions.sellingPoint.feature}`}
      filterList={{ "place.b2bClientId||eq": props.id}}
      hasCreateModal={false}
      rowClick={"show"}
      >
      <TextField source="name"/>
      <ReferenceField label="Luogo" source="placeId" reference="place" link="show">
          <TextField source="name" />
      </ReferenceField>
      <TextField label="Tipologia" source="typology" />
      <TextField label="Negozio" source="sellingPointImpulse.open" />
  </ListPreview>
  )
}


function CorporateCustomersList (props) {
    return (
        <ListPreview
        title={"CorporateCustomers"}
        basePath = {`/${Permissions.corporateCustomers.feature}`}
        resource = {`${Permissions.corporateCustomers.feature}`}
        filterList={{ "b2bClientId||eq": props.id}}
        hasCreateModal={false}
        rowClick={"show"}
        >
        <TextField source="name"/>
        <TextField label="Nome esterno" source="externalName" />
    </ListPreview>
    )
}
