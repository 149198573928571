import React from 'react';
import { SimpleShowLayout, TextField, ShowController, ShowView, NumberField, FunctionField } from 'react-admin';
import Aside from '../../inheritance/BaseAside';
import CustomToolbar from '../ItemChildActionShow';

export const ItemEdibleShow = (props) => {

    return  (
        <ShowController {...props} aside={<Aside props={props} record={props.record}></Aside>}>
            {controllerProps =>
                <ShowView {...props} {...controllerProps} actions={props.hideActions ? null : <CustomToolbar />}>
                    <SimpleShowLayout>
                    <TextField
                        label="Nome Etichetta"
                        source="label"
                    />
                    <TextField
                        label="Nome Etichetta (Inglese)"
                        source="englishLabel"
                    />
                    <TextField
                        label="Gamma fresco"
                        source="storageType"
                    />
                    <NumberField
                        label="Peso Lordo in grammi (di una unità di riferimento)"
                        source="grossWeight"
                    />
                    <NumberField
                        label="Peso Netto in grammi (di una unità di riferimento)"
                        source="netWeight"
                    />
                    <NumberField
                        label="Scarto (percentuale)"
                        source="discard"
                    />
                    <TextField
                        label="Ingredienti Completi (da normativa)"
                        source="ingredients"
                    />
                    <TextField
                        label="Ingredienti alla Foorban"
                        source="ingredientsFoorbanFormat"
                    />
                    <TextField
                        label="Ingredienti alla Foorban (Inglese)"
                        source="englishIngredientsFoorbanFormat"
                    />
                    <TextField
                        label={'Cereali contenenti glutine'}
                        source={'cerealsContainingGluten'}
                    />
                    <TextField
                        label={'Crostacei'}
                        source={'crustaceans'}
                    />
                    <TextField
                        label={'Uova'}
                        source={'eggs'}
                    />
                    <TextField
                        label={'Pesce'}
                        source={'fish'}
                    />
                    <TextField
                        label={'Arachidi'}
                        source={'peanuts'}
                    />
                    <TextField
                        label={'Soia'}
                        source={'soy'}
                    />
                    <TextField
                        label={'Latte e derivati'}
                        source={'milkAndDairyProducts'}
                    />
                    <TextField
                        label={'Frutta a guscio'}
                        source={'fruitsInShell'}
                    />
                    <TextField
                        label={'Sedano'}
                        source={'celery'}
                    />
                    <TextField
                        label={'Senape'}
                        source={'mustard'}
                    />
                    <TextField
                        label={'Semi di Sesamo'}
                        source={'sesame'}
                    />
                    <TextField
                        label={'Solfiti'}
                        source={'sulphur'}
                    />
                    <TextField
                        label={'Lupini'}
                        source={'lupine'}
                    />
                    <TextField
                        label={'Molluschi'}
                        source={'molluscs'}
                    />
                    <TextField
                        label={'Fonte valori nutrizionali'}
                        source={'nutritionalValueSource'}
                    />
                    <FunctionField
                        label="Kcal x 100gr (Kcal x porzione)"
                        render={record => `${record.kcal} (${(record.kcal * record.netWeight/100).toFixed(1)} x porzione)`} 
                    />
                    <FunctionField
                        label='Grassi x 100gr (Grassi x porzione)'
                        render={record => `${record.fats}gr (${(record.fats * record.netWeight /100).toFixed(1)}gr x porzione)`}
                    />
                    <FunctionField
                        label='Grassi Saturi x 100gr (Saturi x porzione)'
                        render={record => `${record.fatsSaturated}gr (${(record.fatsSaturated * record.netWeight /100).toFixed(1)}gr x porzione)`}
                    />
                    <FunctionField
                        label='Carboidrati x 100gr (Carboidrati x porzione)'
                        render={record => `${record.carbohydrates}gr (${(record.carbohydrates * record.netWeight /100).toFixed(1)}gr x porzione)`}
                    />
                    <FunctionField
                        label='Zuccheri x 100gr (Zuccheri x porzione)'
                        render={record => `${record.sugar}gr (${(record.sugar * record.netWeight /100).toFixed(1)}gr x porzione)`}
                    />
                    <FunctionField
                        label='Fibra x 100gr (Fibra x porzione)'
                        render={record => `${record.fibers}gr (${(record.fibers * record.netWeight /100).toFixed(1)}gr x porzione)`}
                    />
                    <FunctionField
                        label='Proteine x 100gr (Proteine x porzione)'
                        render={record => `${record.proteins}gr (${(record.proteins * record.netWeight /100).toFixed(1)}gr x porzione)`}
                    />
                    <FunctionField
                        label='Sale x 100gr (Sale x porzione)'
                        render={record => `${record.salt}gr (${(record.salt * record.netWeight /100).toFixed(1)}gr x porzione)`}
                    />
                    <TextField
                        label={'Dieta'}
                        source={'diet'}
                    />
                    <TextField
                        label="Link scheda tecnica"
                        source="dataSheet"
                    />
                    <TextField
                        label="Nota"
                        source="note"
                    />
                    </SimpleShowLayout>
                </ShowView>
            }
        </ShowController>
    );
}