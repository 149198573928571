import React, { useState, useEffect } from 'react';
import { Loading, useDataProvider, useRefresh, usePermissions } from 'react-admin';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import CloudIcon from '@material-ui/icons/Cloud';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import { POST_OFF_RESOURCE } from '../../../../providers/nestjs_crud';
import { Permissions } from '../../../../permissions/permissions';

const useStyles = makeStyles((theme) => ({
    completeButton: {
        margin: theme.spacing(1, 0, 2, 0),
    },
    specificButton: {
        margin: theme.spacing(0, 1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    errorText: {
        margin: theme.spacing(2, 0),
    }
}));

export default function BulkConvertRequest2Physical(props) {
    const [state, setState] = useState('INITIAL');
    const [errorData, setErrorData] = useState(null);
    // var today = new Date();
    // var dd = today.getDate();
    // var mm = today.getMonth()+1 ; //January is 0!
    // var yyyy = today.getFullYear();
  
    const [formData, setFormData] = useState({
        lotLogic: 'FEFO',
        bulkLogic: 'partially-reserve-quantity-available',
        minExpirationDate: null,//`${yyyy}-${mm < 10 ? '0' + mm : mm}-${dd < 10 ? '0' + dd : dd}`,
    });

    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const { permissions } = usePermissions();
    const classes = useStyles();

    useEffect(() => {
        if(state === 'RESERVE') {
            if(props.type === 'specific' && props.selectedIds && props.selectedIds.length > 0) {
                setState('RESERVING')
            } else if (props.type === 'complete') {
                setState('RESERVING');
            } else {
                return;
            }

            dataProvider(POST_OFF_RESOURCE, `transfer/${props.transferId}/reserve-many`, {
                data: {
                    type: props.type,
                    lotLogic: formData.lotLogic,
                    bulkLogic: formData.bulkLogic,
                    ids: props.type === 'specific' ? props.selectedIds : undefined,
                    minExpirationDate: formData.minExpirationDate ? new Date(formData.minExpirationDate) : formData.minExpirationDate
                }
            })
            .then(({ data }) => {
                setState('INITIAL');
                refresh();
            })
            .catch(error => {
                setErrorData(error);
                setState('ERROR_RESERVING');
            })
        }
    }, [dataProvider, formData.bulkLogic, formData.lotLogic, formData.minExpirationDate, props.selectedIds, props.transferId, props.type, refresh, state])

    return (
        <>  
            {props.enable && permissions && permissions.includes(`${Permissions.transfer.subFeatures.physicalItemTransfer.name}-${Permissions.transfer.subFeatures.physicalItemTransfer.actions.CreateMany}`) && <Button variant={props.type === 'specific' ? "text" : "contained"} className={props.type === 'specific' ? classes.specificButton : classes.completeButton} color="primary" onClick={() => {
                setState('AWAITING_LOT_LOGIC');
                switch (props.transfer.lotLogic) {
                    case 'FEFO':
                        setFormData({
                            ...formData,
                            lotLogic: 'FEFO',
                        });
                        break;
                    case 'LEFO':
                        setFormData({
                            ...formData,
                            lotLogic: 'LEFO',
                        });
                        break;
                    case 'FEFO-INCLUDING-EXPIRED':
                        setFormData({
                            ...formData,
                            lotLogic: 'FEFO',
                            minExpirationDate: '1970-01-01',
                        });
                        break;
                    default:
                        break;
                }
            }} startIcon={props.type === 'specific' ? <CloudIcon /> : <AllInclusiveIcon></AllInclusiveIcon>}>{props.type === 'specific' ? "RISERVA" : "RISERVA TUTTO"} </Button>}
            <Dialog
                fullScreen={false}
                open={state !== 'INITIAL'}
                onClose={() => setState('INITIAL')}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{state === 'ERROR_RESERVING'  ? "Errore durante l'invio, riprova" : "Logica Riservati"}</DialogTitle>
                <DialogContent>
                    {state === 'ERROR_RESERVING' && errorData && <Typography className={classes.errorText}>{errorData}</Typography>}
                    {state === 'RESERVING' && <Loading />}
                    {state !== 'RESERVING' && <>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label-lot">Logica Lotti</InputLabel>
                            <Select
                                labelId="demo-simple-select-label-lot"
                                id="demo-simple-select"
                                value={formData.lotLogic}
                                onChange={(event) => {
                                    setFormData({
                                        ...formData,
                                        lotLogic: event.target.value
                                    });
                                }}
                            >
                                <MenuItem value="LEFO">LEFO</MenuItem>
                                <MenuItem value="FEFO">FEFO</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label-bulk">Gestione Mancanze</InputLabel>
                            <Select
                                labelId="demo-simple-select-label-bulk"
                                id="demo-simple-select"
                                value={formData.bulkLogic}
                                onChange={(event) => {
                                    setFormData({
                                        ...formData,
                                        bulkLogic: event.target.value
                                    });
                                }}
                            >
                                <MenuItem value="partially-reserve-quantity-available">Continua</MenuItem>
                                <MenuItem value="do-all-or-fail">Genera Errore</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="date"
                                label="Scadenza Minima"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formData.minExpirationDate}
                                onChange={(event) => {
                                    setFormData({
                                        ...formData,
                                        minExpirationDate: event.target.value
                                    });
                                }}
                            />
                        </FormControl>
                    </>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setState('INITIAL')} color="primary">
                        Annulla
                    </Button>
                    <Button disabled={state === 'RESERVING'} onClick={() => setState('RESERVE')} variant="contained" size="large" color="primary">
                        {state === 'ERROR_RESERVING' ? 'RIPROVA': 'RISERVA'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}