import React from 'react';
import { Datagrid, List, TextField, EditButton, ShowButton, TextInput, BooleanField, ReferenceField, ReferenceInput, AutocompleteInput } from 'react-admin';
import Filter from '../../inheritance/BaseFilter';
import { BulkDeleteButton } from 'react-admin';
import { Permissions } from '../../../permissions/permissions';

function CoffeeSellingPointFilter(props) {
	return (
		<Filter {...props}>
			<TextInput source="publicName" alwaysOn />
			<ReferenceInput	
				filter={{ "typology||eq": 'Coffee' }}
				source="sellingPointId"
				reference={Permissions.sellingPoint.feature}
				filterToQuery={searchText => {
					if (!searchText)
						return null;

					return {
						'typology||eq': 'Coffee',
						name: searchText
					}
				}}>
				<AutocompleteInput
					optionText={(record) => {
						if(!record || !record.id) {
							return 'vuoto'
						}
						return `${record.id} - ${record.name}`;
					}}
					optionValue="id" />
			</ReferenceInput>
				<ReferenceInput
					source="coffeeMachineId"
					reference={Permissions.coffeeMachine.feature}
					filterToQuery={searchText => {
						if (!searchText)
							return null;
	
						return {
							name: searchText
						}
					}}>
					<AutocompleteInput
						optionText={(record) => {
							if(!record || !record.id) {
								return 'vuoto'
							}
							return record.name;
						}}
						optionValue="id" />
				</ReferenceInput>
				<ReferenceInput
					source="coffeeRecipeListId"
					reference={Permissions.coffeeRecipeList.feature}
					filterToQuery={searchText => {
						if (!searchText)
							return null;
	
						return {
							name: searchText
						}
					}}>
					<AutocompleteInput
						optionText={(record) => {
							if(!record || !record.id) {
								return 'vuoto'
							}
							return record.name;
						}}
						optionValue="id" />
				</ReferenceInput>
		</Filter>
	);
}

function NonUndoableBulkActionButtons(props) {
    return (
        <React.Fragment>
        {props.buttons}
        <BulkDeleteButton {...props} undoable={false} />
        </React.Fragment>
    );
}
export function CoffeeSellingPointList(props) {
	return (
		<List
			{...props}
			bulkActionButtons={<NonUndoableBulkActionButtons buttons={props.bulkActionButtons} />}
			sort={{ field: 'created_at', order: 'DESC' }} filters={<CoffeeSellingPointFilter />}>
			<Datagrid rowClick={null}>
				<TextField source="publicName" />
				<BooleanField source="open" />
				<ReferenceField
					source="sellingPointId"
					reference={Permissions.sellingPoint.feature}
					link="show">
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField
					source="coffeeMachineId"
					reference={Permissions.coffeeMachine.feature}
					link="show">
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField
					source="coffeeRecipeListId"
					reference={Permissions.coffeeRecipeList.feature}
					link="show">
					<TextField source="name" />
				</ReferenceField>
				<EditButton></EditButton>
				<ShowButton></ShowButton>
			</Datagrid>
		</List>
	);
}
