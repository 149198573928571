import React, { useEffect, useState } from 'react';
import { Create, SimpleForm, TextInput, AutocompleteInput, FormDataConsumer, useDataProvider, Loading, ReferenceInput, AutocompleteArrayInput, ArrayInput, DateTimeInput, ReferenceArrayInput } from 'react-admin';
import { required } from 'react-admin';
import { useHistory } from "react-router-dom";
import { Permissions, CorporateCreditCondionActions } from '../../../permissions/permissions';
import NewInputSelectRest, { AutocompleteInputType } from '../../../components/InputRest';
import { useForm } from 'react-final-form';
import { POST_OFF_RESOURCE } from '../../../providers/nestjs_crud';
import { Typography } from '@material-ui/core';
import { HOURS } from './';
import { DAYS } from '../../../utils/enum/Common';
const fieldOptions = `${Permissions.corporateCreditCondition.feature}/${CorporateCreditCondionActions.fieldOptions}`;

export function ConditionCreate(props) {
	let history = useHistory();
    const params = new URLSearchParams(props.location.search);
    const parentId = params.get('parentId');

	return (
		<Create {...props}>
			<SimpleForm redirect={() => {
				history.goBack();
				return '';
        	}}>
				<div style={{display: 'none'}}>
                	<TextInput initialValue={parentId} source="corporateCreditId"  validate={[required('campo obbligatorio')]} />
            	</div>
				<FormDataConsumer>
					{(props) => {
						return (
							<CustomForm
								{...props}
							/>
						);
					}}
        		</FormDataConsumer>
			</SimpleForm>
		</Create>
	);
}

const STATUS_FETCH_DATA = "fetch_data";
const STATUS_FETCHING_DATA = "fetching_data";
const STATUS_DATA_FETCHED = "data_fetched";
const STATUS_FETCHING_DATA_ERROR = "fetching_data_error";

export function CustomForm(props) {
	const form = useForm();
	const [status, setStatus] = useState(STATUS_FETCH_DATA);
	const [choices, setDataChoices] = useState([]);
	const dataProvider = useDataProvider();

	useEffect(() => {
        if(status === STATUS_FETCH_DATA) {
			setStatus(STATUS_FETCHING_DATA);
			dataProvider(POST_OFF_RESOURCE, `${Permissions.corporateCreditCondition.feature}/${CorporateCreditCondionActions.fieldOptions}/factType`, {
				data: form.getState().values
			})
			.then(({data}) => {
				setDataChoices(data.choices);
				setStatus(STATUS_DATA_FETCHED);
			})
			.catch((e) => {
				setStatus(STATUS_FETCHING_DATA_ERROR)
			});
		}
	}, [dataProvider, form, status]);

	if(status === STATUS_FETCHING_DATA_ERROR) {
		return <Typography>Errore durante il caricamento</Typography>
	}

	if(status === STATUS_DATA_FETCHED) {
		return (
			<>
				<AutocompleteInput	
					onChange={(value) => {
						form.change('operator', undefined);
						form.change('value', undefined);
					}}
					fullWidth
					emptyText="Non specificato"
					label="FactType"
					source="factType"
					validate={[required('campo obbligatorio')]}
					choices={choices}
				/>
				<NewInputSelectRest
					onChange={(value) => {
						form.change('value', undefined);
					}}
					type={AutocompleteInputType}
					fieldOptions={fieldOptions}
					label="Operator"
					source="operator"
					_dependsOn={["factType"]}
					validate={[required('campo obbligatorio')]} />
				{props.formData.factType && props.formData.operator && <Value
					formData={props.formData}
					data={choices}
				/>}
			</>
		);
	}

	return <Loading />;
}

export function Value(props) {
	const factTypeComplete = props.data.find(dataItem => {
		return dataItem.id === props.formData.factType;
	});

	if(!factTypeComplete) {
		return null;
	}

	if(
		[
			'b2cUserId',
			'sellingPointId',
			'corporateCustomerId'
		].includes(factTypeComplete.id)
	) {
		const config = {
			sellingPointId: {
				feature: Permissions.sellingPoint.feature,
				searchText: 'name',
			},
			b2cUserId: {
				feature: Permissions.B2CUser.feature,
				searchText: 'email',
			},
			corporateCustomerId: {
				feature: Permissions.corporateCustomers.feature,
				searchText: 'name',
			},
		}
		switch (props.formData.operator) {
			case 'in':
			case 'notIn':
				return (
					<ReferenceArrayInput
						fullWidth
						multiple
						allowNull
						filterToQuery={searchText => {
							if(!searchText)
								return {};
		
							return { [config[factTypeComplete.id].searchText]: searchText }
						}}
						label={factTypeComplete.name}
						source='value'
						reference={config[factTypeComplete.id].feature}
						validate={[required('campo obbligatorio')]}
					>
					<AutocompleteArrayInput
						optionText={(record)=> {
							if(!record) {
								return '';
							}
							return `${record.id} ${record[config[factTypeComplete.id].searchText]}`
						}}
						optionValue="id" />
				</ReferenceArrayInput>
			);
			default:
				return (
					<ReferenceInput
						fullWidth
						allowNull
						filterToQuery={searchText => {
							if(!searchText)
								return {};
		
							return { [config[factTypeComplete.id].searchText]: searchText }
						}}
						label={factTypeComplete.name}
						source='value'
						reference={config[factTypeComplete.id].feature}
						validate={[required('campo obbligatorio')]}
					>
						<AutocompleteInput
							optionText={(record)=> {
								if(!record) {
									return '';
								}
								return `${record.id} ${record[config[factTypeComplete.id].searchText]}`
							}}
							optionValue="id" />
				</ReferenceInput>
			);
			
		}
	}
	if (
		(props.formData.operator === 'in' || props.formData.operator === 'notIn') &&
		(factTypeComplete.id === 'hour' || factTypeComplete.id === 'dayOfWeek')
	) {
		return (
			<ArrayInput source="value">
				<AutocompleteArrayInput label={factTypeComplete.name} choices={factTypeComplete.id === 'hour' ? HOURS : DAYS} />
			</ArrayInput>
		);
	}

	if('timestampSinceEpochDelivery' === factTypeComplete.id) {
		return <DateTimeInput fullWidth label={factTypeComplete.name} source="value"/>	
	}

	return <TextInput fullWidth label={factTypeComplete.name} source="value"/>
}