import React from 'react';
import { usePermissions } from 'react-admin';
import { Button, Typography, Box } from '@material-ui/core';
import { Permissions } from '../permissions/permissions';
import { useHistory } from "react-router-dom";

export function NoApiCallReferenceField(props) {
    const { permissions } = usePermissions();
    const history = useHistory();
    if (!props.data) {
        return null;
    }
    const hasPermission = permissions && permissions.includes(`${props.reference}-${Permissions[props.reference].actions.ReadOne}`);
    return (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
            {props.label && <Typography variant="caption" >
                {props.label}
            </Typography>}
            {hasPermission && <Button
                size='small'
                color="primary"
                onClick={() => {
                    history.push(`/${props.reference}/${props.data[props.referenceIdentifier]}/show`);
                }}
                >{props.data[props.source]}</Button>}
        </Box>
    );
}

export function NoApiCallReferenceFieldSateMachine(props) {
    const history = useHistory();
    if (!props.data) {
        return null;
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
            {props.label && <Typography variant="caption" >
                {props.label}
            </Typography>}
            <Button
                size='small'
                color="primary"
                onClick={() => {
                    history.push(`/${props.reference}/${props.data[props.referenceIdentifier]}/show`);
                }}
                >{props.data[props.source]}</Button>
        </Box>
    );
}