import React from "react";
import { TextInput, ArrayInput, SimpleFormIterator } from "react-admin";

export function FormDropdown(props) {
  return (
    <ArrayInput label= "Item selezionabili (Minimo 2 opzioni)" source="providerData.dropdownItems">
      <SimpleFormIterator inline>
        <TextInput source="techKey" label="Tech key" helperText={false} />
        <TextInput source="label" label="Label" helperText={false} />
      </SimpleFormIterator>
    </ArrayInput>
  );
}
