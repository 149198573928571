import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceArrayInput, AutocompleteArrayInput, ReferenceInput, AutocompleteInput} from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { required } from 'react-admin';
import { Permissions } from '../../permissions/permissions';

export const CorporateCustomersCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<TextInput label="Nome" source="name" validate={[required('campo obbligatorio')]} />
			<TextInput label="Nome esterno" source="externalName" validate={[required('campo obbligatorio')]} />
			<ReferenceArrayInput
				validate={[required('campo obbligatorio')]}
				filterToQuery={searchText => {
					if(!searchText)
						return null;

					return {
						name: searchText
					}
				}} label="Lista Domini" source="domainsIds" reference={Permissions.domains.feature}>
				<AutocompleteArrayInput optionText="name" />
			</ReferenceArrayInput>
			<ReferenceArrayInput
				validate={[required('campo obbligatorio')]}
				filter={{"canSell||eq": true}}
				filterToQuery={searchText => {
					if(!searchText)
						return null;

					return {
						name: searchText,
						"canSell||eq": true
					}
				}} label="Lista Luoghi" source="placesIds" reference={Permissions.place.feature}>
				<AutocompleteArrayInput optionText="name" />
			</ReferenceArrayInput>
			<ReferenceInput
                    validate={[required('campo obbligatorio')]}
                    filterToQuery={searchText => {
                        if (!searchText)
                            return {};

                        return {
                            "name": searchText,
                        }
                    }}
                    label="Cliente B2B"
                    source="b2bClientId"
                    reference={Permissions.B2BClient.feature}
					>
                    <AutocompleteInput
                        optionText="name"
                        optionValue="id" />
            </ReferenceInput>
			<BaseCreate />
		</SimpleForm>
	</Create>
);