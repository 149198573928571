import React, { Fragment } from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, AutocompleteInput, DateInput, FormDataConsumer } from 'react-admin';
import BaseCreate from '../../inheritance/BaseCreate';
import { required } from 'react-admin';
import { Permissions } from '../../../permissions/permissions';
import { useSelector } from 'react-redux';
import { useForm } from 'react-final-form';

const PurchasePoint = ({ formData, ...rest }) => {
	const purchasePoints = useSelector(state => state.admin.resources.purchasePoint.data);
	const form = useForm();

	return (
		<Fragment>
			<ReferenceInput
				{...rest}
				filterToQuery={searchText => {
					if(!searchText)
						return {};

					return { "name": searchText }
				}}
				label="Punto di Acquisto"
				source="purchasePointId"
				reference={Permissions.purchasePoint.feature}
				validate={[required('Il punto di acquisto è obbligatorio')]}
				onChange={value => {
					form.change('placeId', purchasePoints[value].placeId);
				}}
			>
				<AutocompleteInput
					optionText="name"
					optionValue="id" />
			</ReferenceInput>
		</Fragment>
	);
}

const Supplier = ({ formData, ...rest }) => {
	const suppliers = useSelector(state => state.admin.resources.supplier.data);
	const form = useForm();

	return (
		<Fragment>
			<ReferenceInput
				{...rest}
				filterToQuery={searchText => {
					if(!searchText)
						return {};

					return { "name": searchText }
				}}
				label="Fornitore"
				source="supplierId"
				reference={Permissions.supplier.feature}
				validate={[required("E' obbligatorio agganciare un Fornitore")]}
				onChange={value => {
					form.change('requiredDeliveryDate', getDeliveryDate(suppliers[value].deliveryDays, suppliers[value].deliveryWeekDays));
					form.change('expectedDeliveryDate', getDeliveryDate(suppliers[value].deliveryDays, suppliers[value].deliveryWeekDays));
				}}
			>
				<AutocompleteInput
					optionText="name"
					optionValue="id" />
			</ReferenceInput>
		</Fragment>
	);
}

export const PurchaseOrderCreate = props => {
	return (
		<Create {...props}>
			<SimpleForm redirect="show">
				<TextInput label="Nome" source="name" validate={[required('Il nome è obbligatorio')]} />
				<FormDataConsumer>
					{formDataProps => (
						<PurchasePoint {...formDataProps} />
					)}
				</FormDataConsumer>
				<ReferenceInput
					filterToQuery={searchText => {
						if(!searchText)
							return {};

						return { "name": searchText }
					}}
					label="Luogo di Consegna"
					source="placeId"
					reference={Permissions.place.feature}
					validate={[required("E' obbligatorio agganciare un luogo")]} >
						<AutocompleteInput
							optionText="name"
							optionValue="id" />
				</ReferenceInput>
				<FormDataConsumer>
					{formDataProps => (
						<Supplier {...formDataProps} />
					)}
				</FormDataConsumer>
				<DateInput
					label="Data di Consegna richiesta"
					source="requiredDeliveryDate" />
				<DateInput
					label="Data di Consegna prevista"
					source="expectedDeliveryDate" />
				<TextInput
					label="Orario di consegna massimo"
					source="maxDeliveryHour" type="time" />
				<TextInput
					label="Nota fornitore"
					source="supplierNote" />
				<TextInput
					label="Nota interna"
					source="internalNote" />
				<BaseCreate />
			</SimpleForm>
		</Create>
	)
}

const getDeliveryDate = (deliveryDays = 0, deliveryWeekDays = []) => {
	let d = new Date();
	d.setDate(d.getDate() + deliveryDays)

	if(!deliveryWeekDays || deliveryWeekDays.length === 0) {
		return formatData(d);
	}

	let daLong = (new Intl.DateTimeFormat('en', { weekday: 'long' }).format(d)).toLowerCase();
	let i = 0;

	while(!deliveryWeekDays.includes(daLong) && i < 7) {
		i ++;
		d.setDate(d.getDate() + 1);
		daLong = (new Intl.DateTimeFormat('en', { weekday: 'long' }).format(d)).toLowerCase();
	}

	return formatData(d);
}
const formatData = (date) => {
	const ye = new Intl.DateTimeFormat('it', { year: 'numeric' }).format(date);
	const mo = new Intl.DateTimeFormat('it', { month: 'numeric' }).format(date);
	const da = new Intl.DateTimeFormat('it', { day: 'numeric' }).format(date);
	return `${ye}-${mo}-${da}`;
}