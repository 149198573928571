import React from 'react';

import { TextField,BooleanField } from 'react-admin';
import { ListPreview } from '../../../layouts/ListPreview';
import { UnitOfMeasurementCreate } from '../../unitOfMeasurement/UnitOfMeasurementCreate';

export default (props) => {
  return (
    <ListPreview
      title={"Unità di misura"}
      basePath = {"/unitOfMeasurement"}
      resource = {"unitOfMeasurement"}
      filterList={{ "itemId||eq": props.itemId }}
      redirect={`/item/${props.itemId}/show`}
      hasCreateModal={true}
      createProps={{itemId: props.itemId}}
      create={UnitOfMeasurementCreate}
    >
        <TextField source="name" label="Nome" />
        <BooleanField source="default" label="default"/>
        <TextField source="conversionValue" label="Conversione in unità di riferimento"/>
        <TextField source="rounding" label="Arrotondamento in questa unità"/>
    </ListPreview>
  )
}