import React from 'react';
import { Create, SimpleForm, ReferenceInput, AutocompleteInput, required, DateTimeInput, FormDataConsumer } from 'react-admin';
import BaseCreate from '../../inheritance/BaseCreate';
import { Permissions } from '../../../permissions/permissions';
import TransferItemsFromGoogleSheet from './components/TransferItemsFromGoogleSheet';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';

export function MissionCreate(props) {
    return (
        <Create {...props}>
            <SimpleForm redirect="show">
                <FormDataConsumer>
                        {(props) => {
                            return (
                                <MissionType
                                    {...props}
                                />
                            );
                        }}
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData }) => {
                        if(['smartfridge', 'coffee'].includes(formData.type)) {
                            return (
                                <AutocompleteInput
                                    fullWidth
                                    defaultValue="mandatory"
                                    label="Inventario"
                                    source="inventory"
                                    choices={[
                                        'mandatory',
                                        'optional',
                                    ].map(missioType => {
                                        return {
                                            id: missioType,
                                            name: missioType.charAt(0).toUpperCase() + missioType.slice(1)
                                        }
                                    })} />
                            );
                        } else {
                            return null;
                        }
                    }}
                </FormDataConsumer>
                <FormDataConsumer>
					{({ formData }) => {
                        if(['other', 'waste'].includes(formData.type)) {
                            return (
                                <ReferenceInput
                                    validate={[required("campo obbligatorio")]}
                                    fullWidth
                                    filterToQuery={searchText => {
                                        if(!searchText)
                                            return {};
                                        return {
                                            "name": searchText
                                        }
                                    }}
                                    label="Hub"
                                    source="hubId"
                                    reference={Permissions.hub.feature}>
                                        <AutocompleteInput
                                            optionText="name"
                                            optionValue="id" />
                                </ReferenceInput>
                            );
                        } else {
                            return null;
                        }
                    }}
                </FormDataConsumer>
                <FormDataConsumer>
					{({ formData }) => {
                        if([
                            'smartfridge',
                            'coffee',
                            'delivery',
                            'catering',
                            'store-delivery',
                        ].includes(formData.type)) {
                            const filterValue = {
                                'smartfridge': 'Impulso',
                                'coffee': 'Coffee',
                                'delivery': 'Delivery',
                                'catering': 'Catering',
                                'store-delivery': 'Corporate', 
                            };
                            return (
                                <ReferenceInput
                                    key={filterValue[formData.type]}
                                    fullWidth
                                    label="Punto di vendita"
                                    validate={[required("campo obbligatorio")]}
                                    filter={{ "typology||eq": filterValue[formData.type] }}
                                    source="sellingPointId"
                                    reference={Permissions.sellingPoint.feature}
                                    filterToQuery={searchText => {
                                        if (!searchText)
                                            return null;

                                        return {
                                            "typology||eq": filterValue[formData.type],
                                            name: searchText
                                        }
                                    }}>
                                    <AutocompleteInput
                                        optionText={(record) => {
                                            if(!record || !record.id) {
                                                return 'vuoto'
                                            }
                                            return `${record.id} - ${record.name}`;
                                        }}
                                    optionValue="id" />
                                </ReferenceInput>
                            );
                        } else {
                            return null;
                        }
					}}
				</FormDataConsumer>
                <FormDataConsumer>
					{({ formData }) => {
                        if(['waste'].includes(formData.type)) {
                            return (
                                <ReferenceInput
                                    filter={{"usage": 'Storage'}}
                                    fullWidth
                                    allowEmpty
                                    emptyText="Non specificato"
                                    filterToQuery={searchText => {
                                        if(!searchText)
                                            return {
                                                usage: 'Storage',
                                            };
                                        return {
                                            name: searchText,
                                            usage: 'Storage',
                                        }
                                    }}
                                    label="Magazzino di origine"
                                    source="originWarehouseLocationId"
                                    reference={Permissions.warehouseLocation.feature}>
                                        <AutocompleteInput
                                            optionText="name"
                                            optionValue="id" />
                                </ReferenceInput>
                            );
                        } else {
                            return null;
                        }
                    }}
                </FormDataConsumer>
                <FormDataConsumer>
					{({ formData }) => {
                        if(['other', 'waste'].includes(formData.type)) {
                            return (
                                <ReferenceInput
                                    filter={{"usage": formData.type === 'other' ? 'Storage' : 'Waste'}}
                                    fullWidth
                                    allowEmpty
                                    emptyText="Non specificato"
                                    filterToQuery={searchText => {
                                        if(!searchText)
                                            return {
                                                usage: formData.type === 'other' ? 'Storage' : 'Waste',
                                            };
                                        return {
                                            name: searchText,
                                            usage: formData.type === 'other' ? 'Storage' : 'Waste',
                                        }
                                    }}
                                    label="Magazzino di destinazione"
                                    source="destinationWarehouseLocationId"
                                    reference={Permissions.warehouseLocation.feature}>
                                        <AutocompleteInput
                                            optionText="name"
                                            optionValue="id" />
                                </ReferenceInput>
                            );
                        } else {
                            return null;
                        }
                    }}
                </FormDataConsumer>
                <FormDataConsumer>
					{({ formData }) => {
                        if(['other'].includes(formData.type)) {
                            return (
                                <ReferenceInput
                                    fullWidth
                                    filterToQuery={searchText => {
                                        if(!searchText)
                                            return {};
                                        return { "name": searchText }
                                    }}
                                    label="Luogo"
                                    source="placeId"
                                    reference={Permissions.place.feature}>
                                        <AutocompleteInput
                                            optionText="name"
                                            optionValue="id" />
                                </ReferenceInput>
                            );
                        } else {
                            return null;
                        }
					}}
				</FormDataConsumer>
                <DateTimeInput
                    fullWidth
                    source="date"
                    label="data e ora"
                    validate={[required("campo obbligatorio")]}
                />
                <ReferenceInput
                    fullWidth
                    allowEmpty
                    emptyText="Non specificato"
                    filter={{"authType||eq": "GOOGLE"}}
                    filterToQuery={searchText => {
                        if(!searchText)
                            return null;

                        return {
                            "email": searchText,
                            authType: "GOOGLE"
                        }
                    }}
                    source="assigneeId"
                    reference={`${Permissions.user.feature}`}
                >
                    <AutocompleteInput
                        optionText="email"
                        optionValue="id" />
                </ReferenceInput>
                <RichTextInput
                    label="Commento"
                    source="comment" />
                <TransferItemsFromGoogleSheet />
                <BaseCreate />
            </SimpleForm>
        </Create>
    );
}

export function MissionType() {
	const form = useForm();
    return (
        <AutocompleteInput fullWidth defaultValue="smartfridge" label="Tipo" source="type" onChange={(value) => {
            form.change('sellingPointId', undefined);
            form.change('placeId', undefined);
            form.change('destinationWarehouseLocationId', undefined);
            form.change('hubId', undefined);
        }} choices={[
            'smartfridge',
            'coffee',
            'delivery',
            'catering',
            'store-delivery',
            'waste',
            'other'
        ].map(missioType => {
            return {
                id: missioType,
                name: missioType.charAt(0).toUpperCase() + missioType.slice(1)
            }
        })} />
    )
}
