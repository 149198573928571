import React from 'react';

import { ShowPreview } from '../../../layouts/ShowPreview';
import { ItemSaleableCreate } from '../../itemSaleable/ItemSaleableCreate';
import { ItemSaleableShow } from '../../itemSaleable/ItemSaleableShow';

export default (props) => {
  return (
    <ShowPreview
      data={props.itemSaleable}
      title={"Item Vendibile"}
      basePath = {"/itemSaleable"}
      resource = {"itemSaleable"}
      redirect={`/item/${props.itemId}/show`}
      hasCreateModal={true}
      create={ItemSaleableCreate}
      createProps={{itemId: props.itemId}}
      hasEditP={true}
      show={ItemSaleableShow}
    >
    </ShowPreview>
  )
}