import React from 'react';
import { Datagrid, List, TextField, EditButton, ShowButton, ReferenceField, ReferenceInput, AutocompleteInput, TextInput } from 'react-admin';
import Filter from '../../inheritance/BaseFilter';
import { BulkDeleteButton } from 'react-admin';
import { Models } from './';
import { Permissions } from '../../../permissions/permissions';

function CoffeeMachineFilter(props) {
	return (
		<Filter {...props}>
			<ReferenceInput
				alwaysOn
				filterToQuery={searchText => {
					if (!searchText)
						return null;

					return {
						name: searchText
					}
				}}
				label="Luogo"
				source="placeId"
				reference={`${Permissions.place.feature}`}
			>
				<AutocompleteInput
					optionText={(record) => {
						if(!record || !record.id) {
							return 'vuoto'
						}
						return `${record.id} - ${record.name}`;
					}}
					optionValue="id" />
			</ReferenceInput>
			<TextInput source="name" />
			<AutocompleteInput source="model" choices={Models.map(Model => {
				return {
					id: Model,
					name: Model
				}
			})} />
			<TextInput label="Numero di matricola" source='serialNumber' />
		</Filter>
	);
}

function NonUndoableBulkActionButtons(props) {
	return (
		<React.Fragment>
			{props.buttons}
			<BulkDeleteButton {...props} undoable={false} />
		</React.Fragment>
	);
}

export function CoffeeMachineList(props) {
	return (
		<List
			{...props}
			bulkActionButtons={<NonUndoableBulkActionButtons buttons={props.bulkActionButtons} />}
			sort={{ field: 'created_at', order: 'DESC' }} filters={<CoffeeMachineFilter />}>
			<Datagrid rowClick={null}>
				<TextField source="model" />
				<TextField source="name" />
				<ReferenceField source="placeId" reference="place" link="show">
					<TextField source="name" />
				</ReferenceField>
				<TextField label="Numero di matricola" source='serialNumber' />
				<EditButton></EditButton>
				<ShowButton></ShowButton>
			</Datagrid>
		</List>
	);
}
