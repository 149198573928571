import React from 'react';
import { Show, SimpleShowLayout, TextField, NumberField } from 'react-admin';
import Aside from '../inheritance/BaseAside';

export const ClientClusterShow = (props) => {
    return (
        <Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
            <SimpleShowLayout>
                <TextField lable="Name" source="name" />
                <NumberField label="Valore di importanza" source="importanceValue" />
            </SimpleShowLayout>
        </Show>
    )
};

