import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { SimpleForm, ReferenceInput, AutocompleteInput, required, SelectInput, BooleanInput, FormDataConsumer, useGetOne, useDataProvider, Loading } from 'react-admin';
import { useForm } from 'react-final-form';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import { POST_OFF_RESOURCE } from '../../../../providers/nestjs_crud';
import { Permissions } from '../../../../permissions/permissions';
  
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1, 0),
    },
}));

export default function InventoryCompleteWarehouseAccAccount(props) {

    let { id } = useParams();
    const dataProvider = useDataProvider();
    const [state, setState] = useState(null);
    const [formData, setFormData] = useState({
        searchForId: true,
        itemId: undefined,
        locationId: Number.parseInt(id),
        lotId: undefined,
    });
    const [open, setOpen] = useState(false);
    const [inventoryId, setInventoryId ] = useState(null);
    const classes = useStyles();
    let history = useHistory();

    const { data, loading } = useGetOne(`warehouseLocation`, id);

    useEffect(() => {
        if (state === 'SEND_DATA') {
            setState('SENDING_DATA');
            
            dataProvider(POST_OFF_RESOURCE, 'warehouseInventory/init', {
                data: {requestItems: [{
                    locationId: formData.locationId,
                    lotId: formData.lotId,
                }]}
            })
            .then(({ data }) => {
                if(data.success) {
                    setState('DATA_SENDED');
                    history.replace(`/inventory/${data.data.warehouseInventoryId}`);
                } else if(data.data && data.data.code === '20141_pending_inventory') {
                    setState('ERROR_SENDING_DATA_INVETORY_PENDING');
                    setInventoryId(data.data.inventoryId);
                    setOpen(true);
                } else {
                    throw new Error();
                }
            })
            .catch(error => {
                if(error.code === '20141_pending_inventory') {
                    setState('ERROR_SENDING_DATA_INVETORY_PENDING');
                    setInventoryId(error.inventoryId);
                    setOpen(true);
                } else {
                    setState('ERROR_SENDING_DATA');
                }
            })
        }
    }, [dataProvider, formData.locationId, formData.lotId, history, state]);

    const handleClose = () => {
        history.goBack()
    };

    const handleChange = (e) => {
        const update = formData;
        switch (e.target.name) {
            case 'searchForId':
                update['searchForId'] = !update[e.target.name];
                break;
            case 'locationId':
                update['locationId'] = e.target.value;
                break;
            default:
                return null;
        }
        setFormData(update)
    };

    if(loading) {
        return <Loading />;
    }

    return (
        <>
        <Dialog fullScreen open={true} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar} >
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title} >
                        Item inventario{' > '}{data.name}
                    </Typography>
                </Toolbar>
            </AppBar>
            {!loading && <Container className={classes.container} maxWidth="sm" disableGutters>
                {state === 'ERROR_SENDING_DATA' && <SnackbarContent style={{ backgroundColor: '#f44336'}} message="Errore creazione, riprova" />}
                {state === 'ERROR_SENDING_DATA_INVETORY_PENDING' && <SnackbarContent style={{ backgroundColor: '#f44336'}} message="Errore creazione, inventario da completare" />}
                <SimpleForm record={{ locationId: id, searchForId: true, itemId: undefined, lotId: undefined }} style={{ width: '100%' }} save={() => { setState('SEND_DATA') }} saving={state === 'SENDING_DATA'} onChange={handleChange}>
                    <div style={{display: 'none'}}>
                    <ReferenceInput fullWidth disabled source="locationId" reference={Permissions.warehouseLocation.feature}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    </div>
                    <BooleanInput fullWidth label="Cerca per Id" source="searchForId" />
                    <ReferenceInput
                        validate={[required('campo obbligatorio')]}
                        onChange={(e) => {
                            if (!e.target) {
                                const update = formData;
                                update['itemId'] = e;
                                update['lotId'] = undefined;
                                setFormData(update);
                            }
                        }}
                        filter={{ "storable": true }}
                        fullWidth
                        filterToQuery={searchText => {
                            if (!searchText)
                                return {};

                            return {
                                [formData.searchForId ? "id" : "name"]: searchText,
                                "storable": true
                            }
                        }}
                        label={`Item`}
                        source="itemId"
                        reference={Permissions.item.feature}>
                        <AutocompleteInput
                            options={
                                {
                                    suggestionsContainerProps: {
                                        disablePortal: true,
                                        modifiers: {
                                            flip: {
                                                enabled: false,
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                boundariesElement: 'scrollParent',
                                            },
                                        }
                                    },
                                    autoFocus: true,
                                }
                            }
                            optionText={(record) => record ? `${record.id} -  ${record.name}` : ''}
                            optionValue="id" />
                    </ReferenceInput>
                    <FormDataConsumer>
                        {() => {
                            return formData.itemId && <ReferenceInput
                                validate={[required('campo obbligatorio')]}
                                record={undefined}
                                onChange={(e) => {
                                    if (!e.target) {
                                        const update = formData;
                                        update['lotId'] = e;
                                        setFormData(update);
                                    }
                                }}
                                sort={{ field: 'id', order: 'DESC' }}
                                filter={{ "itemId||eq": formData.itemId }}
                                fullWidth
                                filterToQuery={searchText => {
                                    if (!searchText)
                                        return {
                                            "itemId||eq": formData.itemId
                                        };

                                    return {
                                        "name": searchText,
                                        "itemId||eq": formData.itemId
                                    }
                                }}
                                label={`Lot`}
                                source="lotId"
                                reference={Permissions.lot.feature}>
                                <AutocompleteInput
                                    options={
                                        {
                                            suggestionsContainerProps: {
                                                disablePortal: true,
                                                modifiers: {
                                                    flip: {
                                                        enabled: false,
                                                    },
                                                    preventOverflow: {
                                                        enabled: true,
                                                        boundariesElement: 'scrollParent',
                                                    },
                                                }
                                            },
                                            autoFocus: true,
                                        }
                                    }
                                    optionText={(record) => {
                                        if(!record) {
                                          return '';
                                        }
                                        const expirationDate = record.expirationDate ? new Date(record.expirationDate) : null;
                                        let optionText = `${record.id}${expirationDate ? ` -${expirationDate.getDate()}/${expirationDate.getMonth() + 1}/${expirationDate.getFullYear()}` : ''}${record.supplierLot ? ` - ${record.supplierLot}` : ''}`;
                                        
                                        if (record.compliantStatus === 'not-compliant') {
                                            optionText += ' - non conforme';
                                        }

                                        return optionText
                                    }}
                                    optionValue="id" />
                            </ReferenceInput>
                        }
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {(form) => <UpdateForm
                            formData={formData}
                            form={form.formData}
                            />
                        }
                    </FormDataConsumer>
                </SimpleForm>
            </Container>}
        </Dialog>
        <Dialog
        fullScreen={false}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
        >
        <DialogTitle id="responsive-dialog-title">Per questa location è gia presente un inventario?</DialogTitle>
        <DialogActions>
            <Button autoFocus onClick={() => setOpen(false)} color="primary">
            Annulla
            </Button>
            <Button onClick={() => {
            inventoryId && history.replace(`/inventory/${inventoryId}`);
            }} color="primary" autoFocus>
            Continua Inventario
            </Button>
        </DialogActions>
        </Dialog>
        </>
    );
}

export function UpdateForm(props) {
	const form = useForm();
    if(props.form.lotId !== props.formData.lotId) {
        form.change('lotId', props.formData.lotId)
    }
    return null;
}