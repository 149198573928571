import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CachedIcon from '@material-ui/icons/Cached';
import { CardRequestShift } from './';
import { GET_OFF_RESOURCE, POST_OFF_RESOURCE } from '../../../../providers/nestjs_crud';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    backdrop: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'column',
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    title: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    list: {
        width: '100%',
    },
    text: {
        margin: theme.spacing(0, 0 , 1)
    },
    physicalContainerShift: {
        flex: 1.5,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        overflow: 'scroll',
        padding: theme.spacing(8, 0,)
    },
    container: {
        display: 'flex',
        height: '100%',
        width: '100%' 
    },
    exitButton: {
        margin: theme.spacing(1, 0 , 0)
    },
    appBarBottom: {
        top: 'auto',
        bottom: 0,
    },
    appBarBottomToolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    action: {
        width: '40%',
    }
}));

const loadingStatus = [
    'FETCHING_DATA',
    'SENDING_DATA'
];

const errorStatus = [
    'FETCHING_DATA_ERROR',
    'EMPTY_ACCOUNTS',
    'ERROR_SENDING_DATA',
];

export default function InventoryWarehouseAccAccount(props) {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const { id } = useParams();
    const params = new URLSearchParams(location.search);
    const requestedItemTransferId = params.get('requestedItemTransferId')
    const dataProvider = useDataProvider();
    const [state, setState] = useState('FETCH_DATA');

    const [warehouseAccAccounts, setWarehouseAccAccounts] = useState(null);
    const [requestedItemTransfer, setRequestedItemTransfer] = useState(null);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        if (state === 'FETCH_DATA' && requestedItemTransferId && id) {
            setState('FETCHING_DATA');
            dataProvider(GET_OFF_RESOURCE, `transfer/${id}/requestedItemTransfer/${requestedItemTransferId}/available-lots`)
                .then(({ data }) => {
                    if(data && Array.isArray(data.warehouseAccAccounts) && data.warehouseAccAccounts.length > 0) {
                        setRequestedItemTransfer(data.requestedItemTransfer);
                        setWarehouseAccAccounts(data.warehouseAccAccounts.map(warehouseAccAccount => {
                            return {
                                ...warehouseAccAccount,
                                quantity2Shift: 0
                            }
                        }));
                        setState('DATA_FETCHED');
                    } else if(data && Array.isArray(data.warehouseAccAccounts) && data.warehouseAccAccounts.length === 0) {
                        setState('EMPTY_ACCOUNTS');
                    } else {
                        setState('FETCHING_DATA_ERROR');
                    }
                })
                .catch(error => {
                    setState('FETCHING_DATA_ERROR');
                })
        }
    }, [dataProvider, state, id, requestedItemTransferId]);

    useEffect(() => {
        if (state === 'SEND_DATA' && requestedItemTransferId && id) {
            setState('SENDING_DATA');
            dataProvider(POST_OFF_RESOURCE, `transfer/${id}/requestedItemTransfer/${requestedItemTransferId}/bulk-create-physical`, {
                data: {
                    physicalItemTransferCreate: warehouseAccAccounts.filter((warehouseAccAccount) => {
                        return warehouseAccAccount.quantity2Shift > 0
                    }).map(warehouseAccAccount => {
                        return {
                            quantityReference: warehouseAccAccount.quantity2Shift,
                            lotId: warehouseAccAccount.lotId,
                        }
                    })
                }
            })
                .then(() => {
                    history.goBack();
                    setState('DATA_FETCHED');
                })
                .catch((e) => {
                    setErrors(e);
                    setState('ERROR_SENDING_DATA')
                });
        }
    }, [dataProvider, history, id, requestedItemTransferId, state, warehouseAccAccounts]);

    const handleClose = () => {
        history.goBack()
    };

    let reservedQuantity = 0;

    if(state === 'DATA_FETCHED' && requestedItemTransfer && requestedItemTransfer.physicalItemTransfers && Array.isArray(requestedItemTransfer.physicalItemTransfers) && requestedItemTransfer.physicalItemTransfers.length > 0) {
        reservedQuantity += requestedItemTransfer.physicalItemTransfers.map(physicalItemTransfer => {
            if(physicalItemTransfer.status === "Reserved") {
                return physicalItemTransfer.quantityReference
            }
            return 0;
        }).reduce((accumulator, single) => {
            if(single === 0) {
                return accumulator;
            }
            return Number.parseFloat((accumulator + single).toFixed(2));
        });
    }
    
    if(state === 'DATA_FETCHED' && warehouseAccAccounts && Array.isArray(warehouseAccAccounts)) {
        reservedQuantity += warehouseAccAccounts.map(warehouseAccAccounts => warehouseAccAccounts.quantity2Shift).reduce((accumulator, single) => {
            if(single === 0) {
                return accumulator;
            }
            return Number.parseFloat((accumulator + single).toFixed(2));
        })
    }

    return (
        <Dialog fullScreen open={true} onClose={handleClose} TransitionComponent={Transition}>
            <Container className={classes.container} maxWidth={'lg'} disableGutters>
                <Container className={classes.physicalContainerShift} disableGutters>
                    <AppBar color="default" position="fixed">
                        <Toolbar>
                            {state === 'DATA_FETCHED' && requestedItemTransfer && <Typography variant="h6" className={classes.title}>
                                Riservati {reservedQuantity} di {requestedItemTransfer.quantityReference}
                            </Typography>}
                        </Toolbar>
                    </AppBar>
                    {state === 'DATA_FETCHED' && warehouseAccAccounts &&
                    <List className={classes.list}>
                        {warehouseAccAccounts.map(warehouseAccAccount => {
                            return (
                                <ListItem key={warehouseAccAccount.id}>
                                    <CardRequestShift
                                        updateQuantity={(quantityToUpdate) => {
                                            setWarehouseAccAccounts(warehouseAccAccounts.map(_warehouseAccAccount => {
                                                if(_warehouseAccAccount.id === warehouseAccAccount.id) {
                                                    _warehouseAccAccount.quantity2Shift = quantityToUpdate;
                                                }
                                                return _warehouseAccAccount;
                                            }))
                                        }}
                                        warehouseAccAccount={warehouseAccAccount}
                                    />
                                </ListItem>
                            )
                        })}
                    </List>}
                </Container>
                <AppBar position="fixed" color="default" className={classes.appBarBottom}>
                    <Toolbar className={classes.appBarBottomToolbar}>
                        <Button className={classes.action} variant="contained" color="default" onClick={handleClose}>Annulla</Button>
                        <Button disabled={state !== 'DATA_FETCHED' || (state === 'DATA_FETCHED' && warehouseAccAccounts.map(warehouseAccAccounts => warehouseAccAccounts.quantity2Shift).reduce((accumulator, single) => {
                            return Number.parseFloat(accumulator + single).toFixed(6);
                        }) === 0)} className={classes.action} variant="contained" color="primary" onClick={() => {
                            setState('SEND_DATA');
                        }}>Done</Button>
                    </Toolbar>
                </AppBar>
                <Backdrop className={classes.backdrop} open={errorStatus.includes(state)} onClick={() => { }}>
                    <Typography className={classes.text} variant="h4">
                        {state === 'ERROR_SENDING_DATA' && 'Errore invio richiesta'}
                        {state === 'EMPTY_ACCOUNTS' && 'Non sono presenti account'}
                        {state === 'FETCHING_DATA_ERROR' && 'Errore durante il caricamento'}
                    </Typography>
                    {state === 'ERROR_SENDING_DATA' && errors && <Typography className={classes.text}>
                       {errors}
                    </Typography>}
                    {(state === 'FETCHING_DATA_ERROR' || state === 'EMPTY_ACCOUNTS') && <Button
                        style={{marginBottom: '8px'}}
                        size="large"
                        onClick={() => {
                            setState('FETCH_DATA');
                        }}
                        variant="contained"
                        color="primary"
                        startIcon={<CachedIcon />}>
                        RICARICA
                    </Button>}
                    {state === 'ERROR_SENDING_DATA' && <Button
                        style={{marginBottom: '8px'}}
                        size="large"
                        onClick={() => {
                            setErrors(null);
                            setState('SEND_DATA');
                        }}
                        variant="contained"
                        color="primary"
                        startIcon={<CachedIcon />}>
                        RIPROVA
                    </Button>}
                    {state === 'FETCHING_DATA_ERROR' && <Button
                        className={classes.exitButton}
                        size="small"
                        onClick={handleClose}
                        variant="contained"
                        color="default">
                        ESCI
                    </Button>}
                    {(state === 'EMPTY_ACCOUNTS' || state === 'ERROR_SENDING_DATA') && <Button
                        size="large"
                        onClick={handleClose}
                        variant="contained">
                        CHIUDI
                    </Button>}
                </Backdrop>
                <Backdrop className={classes.backdrop} open={loadingStatus.includes(state)} onClick={null}>
                    <Typography className={classes.text} variant="h4">
                        {state === 'FETCHING_DATA' && 'Caricamento dati in corso'}
                    </Typography>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        </Dialog>
    );
}