import React from 'react';
import { Datagrid, List, TextField, BooleanField, TextInput, BooleanInput } from 'react-admin';
import Filter from '../inheritance/BaseFilter';
import ListActions from '../../components/ListActions';

const RoleFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nome" source="name" alwaysOn />
        <BooleanInput label="ADMIN" source="admin"></BooleanInput>
    </Filter>
);

export const RoleList = props => (
    <List {...props} actions={<ListActions />}  filters={<RoleFilter />} >
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <TextField label="Nome" source="name" />
            <BooleanField label="ADMIN" source="admin" />        
        </Datagrid>
    </List>
);