import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@material-ui/core";

export function TransferItemsField(props) {

    if(!props.items){
        return null;
    }

	return (
		<Table style={{ marginTop: "20px" }}>
			<TableHead style={{ backgroundColor: "#e6e6e6" }}>
				<TableRow>
					<TableCell>Mission type</TableCell>
					<TableCell>Type</TableCell>
					<TableCell>Item</TableCell>
					<TableCell>Lot ID</TableCell>
					<TableCell>Quantity</TableCell>
					<TableCell>Value</TableCell>
					<TableCell>Comment</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{props.items.map((item) => (
					<TableRow>
						<TableCell>{item.missionType}</TableCell>
						<TableCell>{item.type}</TableCell>
						<TableCell>{item.itemId}</TableCell>
						<TableCell>{item.lotId}</TableCell>
						<TableCell>{item.quantity}</TableCell>
						<TableCell>{item.value}</TableCell>
						<TableCell>{item.comment}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
}
