import React from "react";
import { transactionToObjectLinkMap } from "./utils";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { usePermissions } from "react-admin";
import { CrudActions } from "../../../permissions/permissions";

/*
  Link to a list of transaction child objects, based on props.childType.
  
  props:
  - transactionId
  - childType
*/
export function LinkToTransactionChildObjectList(props) {
  const history = useHistory();
  const { permissions } = usePermissions();

  return (
    <>
      {props.transactionId && (
        <>
          {props.childType &&
            transactionToObjectLinkMap[props.childType] &&
            permissions &&
            permissions.includes(
              `${transactionToObjectLinkMap[props.childType].feature}-${
                CrudActions.ReadAll
              }`
            ) && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push(
                    `/${
                      transactionToObjectLinkMap[props.childType].feature
                    }?filter={"transactionParentId||eq":"${
                      props.transactionId
                    }"}`
                  );
                }}
              >
                {`Vai a ${
                  transactionToObjectLinkMap[props.childType].objectListLabel
                }`}
              </Button>
            )}
        </>
      )}
    </>
  );
}
