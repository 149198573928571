import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField, useShowController, usePermissions} from 'react-admin';
import Aside from '../inheritance/BaseAside';
import { makeStyles } from '@material-ui/core/styles';

import { Container, Grid } from '@material-ui/core';
import SellingPointImuplseShow from './sellingPointImpulse/SellingPointImpulseShow';

import { connect } from 'react-redux';
import { Permissions } from '../../permissions/permissions';

const useStyles = makeStyles((theme) => ({
  root: {
      flexGrow: 1,
      padding: theme.spacing(1)
    },
  title: {
    flexGrow: 1,
  },
  rootSaleable: {
    flexGrow: 1,
  }
}));

const SellingPointShowComponent = (props) => {
    const classes = useStyles();
    const { permissions } = usePermissions();

    return (
        <Container maxWidth="xl" className={classes.root}>
            <Grid container spacing={3}>
            <Grid item xl={12} lg={12} md={12} xs={12}>
                <Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
                    <SimpleShowLayout>
                        <TextField label="ID" source="id" />
                        <TextField label="Nome" source="name" />
                        <ReferenceField
                            link="show"
                            label="Luogo"
                            resource="place"
                            reference="place"
                            source="placeId"
                            basePath="/place"
                            record={useShowController(props)}>
                            <TextField
                                source="name"
                                component="span"
                                gutterBottom
                            />
                        </ReferenceField>
                        <ReferenceField link="show" label="Magazzino Storage" source="storageWarehouseLocationId" reference={Permissions.warehouseLocation.feature}>
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField link="show" label="Magazzino Cliente" source="clientWarehouseLocationId" reference={Permissions.warehouseLocation.feature}>
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField label="Tipologia" source="typology" />
                        <TextField label="Legacy IDs" source="legacyIDs" />
                        <TextField label="Warehouse Type" source="warehouseType" />
                        <TextField label="Lot Logic" source="lotLogic" />
                        <ReferenceField
                              label="Layout"
                              source="layoutId"
                              reference={Permissions.layout.feature}>
                            <TextField source='name' />
                        </ReferenceField>
                        {(permissions && permissions.includes(`${Permissions.hub.feature}-${Permissions[Permissions.hub.feature].actions.ReadOne}`)) && 
                            <ReferenceField sortable={false} label="Hub" source="hubId" reference={`${Permissions.hub.feature}`} link='show'>
                                <TextField source="name" />
                            </ReferenceField>
                        }
                    </SimpleShowLayout>
                </Show>
            </Grid>
            </Grid>
            {props.sellingPointImpulse && <Grid container spacing={3}>
            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                <SellingPointImuplseShow sellingPointImpulse={props.sellingPointImpulse} sellingPointId={props.id}></SellingPointImuplseShow>
            </Grid>
            </Grid>}
      </Container>
)};

/*
* find way to sanitizeRestProps
*/

export const SellingPointShow = connect((state, props) => {
  return {
    sellingPointImpulse: state.admin.resources.sellingPoint && state.admin.resources.sellingPoint.data[props.id] ? state.admin.resources.sellingPoint.data[props.id].sellingPointImpulse : null 
  }
})(SellingPointShowComponent)