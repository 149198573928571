import React from 'react';
import { Datagrid, List, TextField, SelectInput, TextInput } from 'react-admin';
import Filter from '../../../../../inheritance/BaseFilter';
import ListActions from '../../../../../../components/ListActions';

import { STAGES_MAP, EXECUTIONS, MANUAL_TASK_TYPES } from '../config';
import { TypeInput } from '../components/TypeInput';

const TaskConfigFilter = (props) => (
    <Filter {...props}>
        <TextInput label="label" source="label" alwaysOn />
        <TypeInput label="typeJob" source="typeJob"/>
        <SelectInput
            source="type"
            label="type"
            choices={MANUAL_TASK_TYPES.map((type) => ({id: type, name: type}))}
        />
        <SelectInput
            source="execution"
            label="execution"
            choices={EXECUTIONS.map((execution) => ({id: execution, name: execution}))}
        />
        <SelectInput
            source="jobStageName"
            label="jobStageName"
            choices={Object.keys(STAGES_MAP).map((stage) => ({id: stage, name: stage}))}
        />
    </Filter>
);

export const TaskConfigList = props => (
    <List {...props} actions={<ListActions />} filters={<TaskConfigFilter />}>
        <Datagrid rowClick="show">
            <TextField label="label" source="label"></TextField>
            <TextField label="typeJob" source="typeJob"></TextField>
            <TextField label="type" source="type"></TextField>
            <TextField label="execution" source="execution"></TextField>
            <TextField label="jobStageName" source="jobStageName"></TextField>
        </Datagrid>
    </List>
);