import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { required } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

export function BusinessUnitCreate(props) {
    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput source="name" validate={[required()]} />
                <TextInput source="techKey" validate={[required()]} />
                <RichTextInput source="description" />
                <BaseCreate />
            </SimpleForm>
        </Create>
    );
}
