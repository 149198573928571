import React from 'react';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';
import BaseEdit from '../../inheritance/BaseEdit';

export function CoffeeRecipeListEdit(props) {
	return (
		<Edit {...props}>
			<SimpleForm  redirect="show">
				<TextInput fullWidth validate={[required()]} source="name" />
				<BaseEdit />
			</SimpleForm>
		</Edit>
	);
}
