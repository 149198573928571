import React, { useState, useEffect } from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import BaseEdit from '../inheritance/BaseEdit';
import { Permissions } from '../../permissions/permissions';
import { useForm } from 'react-final-form';

export const B2CUserEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="Nome" source="name" />
            <TextInput label="Cognome" source="surname" />
            <TextInput label="Telefono" source="phoneNumber" />
            <BooleanInput label="Attivo" source="enable"></BooleanInput> 
            <ReferenceArrayInput
				filterToQuery={searchText => {
					if(!searchText)
						return null;

					return {
						name: searchText
					}
				}} label="corporateCustomers" source="corporateCustomersIds" reference={Permissions.corporateCustomers.feature}>
				<AutocompleteArrayInput optionText="name" />
			</ReferenceArrayInput>
            <Domains />
            <BaseEdit />
        </SimpleForm>
    </Edit>
);

export function Domains(props) {
	const form = useForm();
	const [status, setStatus] = useState('INIT');

	useEffect(() => {
        if(status === 'INIT') {
			setStatus('LOADED');
            form.change('corporateCustomersIds', props.record.corporateCustomers.map(corporateCustomer => corporateCustomer.id));
        }
	}, [status, form, props.record.corporateCustomers])

	return null;
}