import React from 'react';
import { Datagrid, List, TextField, ReferenceField, NumberField, ReferenceInput, TextInput, AutocompleteInput } from 'react-admin';
import Filter from '../../inheritance/BaseFilter';
import { Permissions } from '../../../permissions/permissions';
import ListActions from '../../../components/ListActions';

const SupplierPriceListListFilter = (props) => (
	<Filter {...props}>
		<ReferenceInput
            filterToQuery={searchText => {
                if(!searchText)
                    return {};

                return { name: searchText }
            }}
            label="Fornitore"
            source="supplierId||eq"
            reference={`${Permissions.supplier.feature}`}
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val && val.length >= 2 }
                optionText="name"
                optionValue="id" />
        </ReferenceInput>
		<ReferenceInput
			alwaysOn
			filterToQuery={searchText => {
				if(!searchText)
					return {};

				return { "itemPurchasable.item.name": searchText }
			}}
			label="Nome Item" source="itemPurchasableId" reference={Permissions.itemPurchasable.feature}>
			<AutocompleteInput optionText="item.name" />
		</ReferenceInput>
		<TextInput label="Referenza Item fornitore" source="supplierReference" />
		<TextInput label="Codice Item fornitore" source="supplierCode" />
	</Filter>
);

export const SupplierPriceListList = props => (
	<List {...props} actions={<ListActions />}  filters={<SupplierPriceListListFilter />}>
		<Datagrid rowClick="show">
			<ReferenceField label="Fornitore" source="supplierId" reference={Permissions.supplier.feature} link="show">
				<TextField source="name" />
			</ReferenceField>
			<ReferenceField label="Item" source="itemPurchasableId" reference={Permissions.itemPurchasable.feature} link="show">
				<TextField source="item.name" />
			</ReferenceField>
			<TextField label="Referenza Item fornitore" source="supplierReference" />
			<TextField label="Codice Item fornitore" source="supplierCode" />
			<ReferenceField label="Unità di fabbisogno" source="orderUnitId" reference={Permissions.unitOfMeasurement.feature} link="show">
				<TextField source="name" />
			</ReferenceField>
			<NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Costo senza IVA" source="costNoTax" />
			<ReferenceField label="Unità di costo/ordine" source="costUnitId" reference={Permissions.unitOfMeasurement.feature} link="show">
				<TextField source="name" />
			</ReferenceField>
			<NumberField label="Minimo di ordine (in unità di costo/ordine/fornitore)" source="minimumOrder" />
			<NumberField label="Arrotondamento (in unità di costo/ordine/fornitore)" source="rounding" />
		</Datagrid>
	</List>
);