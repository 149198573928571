import React from 'react';
import { Create, SimpleForm, FormDataConsumer, ReferenceInput, SelectInput, required, TextInput } from 'react-admin';
import { useForm } from 'react-final-form';
import BaseCreate from '../../../inheritance/BaseCreate';
import { Permissions, TransferActions } from '../../../../permissions/permissions';
import { ModalCreate } from '../../../../layouts/ModalCreate';
import { ModalLotCreate } from '../../lot/LotCreate';
import NewInputSelectRest, { AutocompleteInputType, NumberInputType } from '../commonComponents/InputRest';

const fieldOptions = `${Permissions.transfer.feature}/${Permissions.physicalItemTransfer.feature}/${TransferActions.fieldOptions}`;

export function ModalPhysicalItemTransferCreateCreate({title, requestedItemTransfer, redirect, ...rawProps }) {
	let props = {
		...rawProps,
		basePath: `/${Permissions.transfer.feature}_${Permissions.physicalItemTransfer.feature}`,
        resource: `${Permissions.transfer.feature}_${Permissions.physicalItemTransfer.feature}`,
		title: title ? title: "Aggiungi",
		requestedItemTransfer
    };

	return (
		<Create
			title=" "
            basePath={`/${Permissions.transfer.feature}_${Permissions.physicalItemTransfer.feature}`}
            resource={`${Permissions.transfer.feature}_${Permissions.physicalItemTransfer.feature}`}
		>
			<ModalCreate>
				<CreateForm {...props}/>
			</ModalCreate>
		</Create>
	);
}


function CreateForm(props) {
	return (
		<SimpleForm {...props}>
				<div style={{ display: 'none' }}>
					<TextInput initialValue={props.requestedItemTransfer.id} source={'requestedItemTransferId'} />
                </div>
				<ReferenceInput
					initialValue={props.requestedItemTransfer.itemId}
					fullWidth
					label="Item"
					source="itemId"
					reference={`${Permissions.item.feature}`}>
					<SelectInput options={{disabled: true}} optionText="name" />
				</ReferenceInput>
				<NewInputSelectRest
					type={AutocompleteInputType}
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							}
						}
					}
					fieldOptions={fieldOptions}
					label="Lotto"
					source="lotId"
					dependOn="itemId"
					validate={[required('campo obbligatorio')]} />
				<FormDataConsumer>
					{(form) => <LotCreate
						{...form}
						{...props}/>
					}
				</FormDataConsumer>
				<NewInputSelectRest
					type={AutocompleteInputType}
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							}
						}
					}
					fieldOptions={fieldOptions}
					reference={`${Permissions.warehouseLocation.feature}`}
					label="From"
					source="fromWarehouseLocationId"
					dependOn="requestedItemTransferId" />
				<NewInputSelectRest
					type={AutocompleteInputType}
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							}
						}
					}
					fieldOptions={fieldOptions}
					reference={`${Permissions.warehouseLocation.feature}`}
					label="To"
					source="toWarehouseLocationId"
					dependOn="fromWarehouseLocationId"
					validate={[required('campo obbligatorio')]} />
				<NewInputSelectRest
					type={NumberInputType}
					fieldOptions={fieldOptions}
					label="Quantità"
					source="quantityReference"
					dependOn="requestedItemTransferId" />
				<NewInputSelectRest
					type={NumberInputType}
					fieldOptions={fieldOptions}
					label="Valore"
					source="value"
					dependOn="quantityReference" />
			<BaseCreate />
		</SimpleForm>
	);
}

export function LotCreate(props) {
	const form = useForm();
	return (
		<ModalLotCreate
			requestedItemTransfer={props.requestedItemTransfer}
			onCreate={(basePath, id, data) => {
				form.change('lotId', id);
				return 'show';
			}}
		></ModalLotCreate>
	);
}