import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField } from 'react-admin';
import Aside from '../inheritance/BaseAside';
import { Permissions } from '../../permissions/permissions';

export const InternalCategoryShow = (props) => {
    return (
    <Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Nome" source="name" />
            <ReferenceField label="Categoria Padre" source="parentCategoryId" reference={`${Permissions.internalCategory.feature}`} link="show">
                <TextField source="name" />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
)};
