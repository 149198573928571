import React from 'react';
import BaseEdit from '../inheritance/BaseEdit';
import { Edit, SimpleForm, TextInput, required, ReferenceInput, AutocompleteInput} from 'react-admin';
import { Permissions } from '../../permissions/permissions';
export const B2BClientEdit = props => (
    <Edit {...props}>
        <SimpleForm>
        <TextInput lable="Name" source="name" validate={[required('campo obbligatorio')]}/>
                <ReferenceInput
                    validate={[required('campo obbligatorio')]}
                    enableGetChoices={val => {
                        return !!(val && val.label && val.label.length >= 2);
                    }}
                    filterToQuery={searchText => {
                        if (!searchText)
                            return {
                                "type": "RibesUser",
                                "accountManager||eq": true
                            }

                        return {
                            "label": searchText,
                            "type": "RibesUser",
                            "accountManager||eq": true
                        }
                    }}
                    label="Account Manager"
                    source="accountManagerId"
                    reference={Permissions.mainUser.feature}>
                    <AutocompleteInput
                        optionText={(record) => {
                            if (!record || !record.id) {
                                return 'cancel';
                            }
                            return `${record ? `${record.id} - ${record.label}` : ''}`
                        }}
                        optionValue="id" />
                </ReferenceInput>
                <ReferenceInput
                    validate={[required('campo obbligatorio')]}
                    filterToQuery={searchText => {
                        if (!searchText)
                            return {};

                        return {
                            "name": searchText,
                        }
                    }}
                    label="Cluster"
                    source="clusterId"
                    reference={Permissions.clientCluster.feature}
					>
                    <AutocompleteInput
                        optionText="name"
                        optionValue="id" />
                </ReferenceInput>
            <BaseEdit />
        </SimpleForm>
    </Edit>
);