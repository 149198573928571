import React, { useState } from 'react';
import TransferItemsFromGoogleSheet from './components/TransferItemsFromGoogleSheet';
import BidirectionalForm from '../../../../components/BidirectionalForm';

const config = {
    type: {
        dependsOn: [],
        type: 'select',
        defaultLabel: 'Tipo di Trasferimento',
        search: false,
    },
    fromWarehouseLocationId: {
        dependsOn: ['type'],
        type: 'select',
        defaultLabel: 'Trasferisci da',
        search: true,
    },
    toWarehouseLocationId: {
        dependsOn: ['type'],
        type: 'select',
        defaultLabel: 'Trasferisci a',
        search: true,
    },
    transactionParentId: {
        dependsOn: ['type'],
        type: 'select',
        defaultLabel: 'Parent Transaction',
        search: true,
    },
    assigneeId: {
        dependsOn: ['type'],
        type: 'select',
        defaultLabel: 'Assignee (backendUser)',
        search: true,
    },
    expectedDate: {
        dependsOn: ['type'],
        type: 'datetime-local',
        defaultLabel: 'Data prevista',
    },
    externalDocument: {
        dependsOn: ['type'],
        type: 'text',
        defaultLabel: 'External document',
    },
    comment: {
        dependsOn: ['type'],
        type: 'text',
        defaultLabel: 'Commento',
        disabled: false,
    },
    reasonToTransfer: {
        dependsOn: ['type'],
        type: 'select',
        defaultLabel: 'Causale',
        search: false,
    },
    deliveryMainUserId: {
        dependsOn: ['type'],
        type: 'select',
        defaultLabel: 'Corriere',
        search: true,
    },
    lotLogic: {
        dependsOn: ['type'],
        type: 'select',
        defaultLabel: 'Logica Magazzino',
    },
    priority: {
        dependsOn: ['type'],
        type: 'select',
        defaultLabel: 'Priorità',
    }
}

export default function TransferCreate() {
    const [ bulkRequestedItemTransfers, setBulkRequestedItemTransfers] = useState([]);

    return (
        <BidirectionalForm
            config={config}
            src='transfer'
            path='transfer/fieldOptions'
            buttonTitle='Crea'
            title='Crea Transfer'
            addData={(data) => {
                if (bulkRequestedItemTransfers.length > 0) {
                    data['bulkRequestedItemTransfers'] = bulkRequestedItemTransfers;
                }
                return data;
            }}>
            <TransferItemsFromGoogleSheet
                values={bulkRequestedItemTransfers}
                onChange={(values) => setBulkRequestedItemTransfers(values)}
            />
        </BidirectionalForm>
    );
}