import React, { useState } from "react";
import { Button, Typography, AppBar, Toolbar, Card, Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';  
import {  useDataProvider, showNotification } from 'react-admin';
import { useDispatch } from 'react-redux';
import { Permissions } from '../../../../../permissions/permissions';
import { GET_OFF_RESOURCE_PLAIN } from '../../../../../providers/nestjs_crud';

const useStyles = makeStyles((theme) => ({
    title: {
		flexGrow: 1
	}
}));

export const DropUnloadTask = (props) => {
    const { task, onDone } = props;
	const [state, setState] = useState('INITIAL')
	const classes = useStyles();
	const dispatch = useDispatch();
    const dataProvider = useDataProvider();

	if (state === 'FETCHING_DATA'){
		return ( 
		<Box textAlign='center'>
			<CircularProgress size={25} />
		</Box>  
		)
	}

    return (
        <Card>
			<AppBar color="default" position="static">
				<Toolbar>
					<Typography variant="h5" className={classes.title}>
						Scarico in cella rientro
					</Typography>
					{task.config.transfer && task.config.transfer.transferStateMachine &&
						<UnloadTransferFinal
							completeUnload={() => {
								setState('FETCHING_DATA')
								dataProvider(GET_OFF_RESOURCE_PLAIN, `${Permissions.deliveryManTransferTask.feature}/${task.id}/do-action/complete-unload`)
								.then((rawResponse) => {
									const response = JSON.parse(rawResponse);
									if(response.success) {
										onDone && onDone();
										dispatch(showNotification(`azione: drop-Unload completata`));
										setState('INITIAL')
									} else if(response.errors) {
										const message = response.errors.map(({constraints}) => {
											return Object.keys(constraints).map(key => constraints[key]).join(', ');
										}).join(', ');

										throw new Error(message)
									} else {
										throw new Error('unknown error')
									}
								})
								.catch(error => {
									setState('INITIAL')
									dispatch(showNotification(`Errore durante azione: drop-Unload ${error}`, 'error'));
								})
							}}
							status={task.config.transfer.transferStateMachine.status}/>
					}
				</Toolbar>
			</AppBar>
		</Card>
    )

}

function UnloadTransferFinal(props) {
	switch (props.status) {
		case 'delivering':
			return (
				<Button	
					size="large"
					onClick={props.completeUnload}
					variant="contained"
					color="primary">
					COMPLETA
				</Button>
			);
		case 'picked':
		case 'to_cancel':
		case 'completed':
			return (
				<Typography variant="subtitle1">
					Completato
				</Typography>
			);
		case 'to_finish':
		case 'todo':
		case 'wip':
			return (
				<Typography variant="subtitle1">
					non disponibile
				</Typography>
			);
		default:
			return (
				<Typography variant="subtitle1">
					Errore contattare Hub
				</Typography>
			);
	}
}