import React, { useEffect, useState } from 'react';
import { Datagrid, List, TextField, BooleanField, Filter, usePermissions, ReferenceInput, AutocompleteInput, useDataProvider, useRefresh, showNotification, AutocompleteArrayInput, useUnselectAll } from 'react-admin';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import { Permissions,  } from '../../../permissions/permissions';
import { POST_OFF_RESOURCE } from '../../../providers/nestjs_crud';
import { useDispatch } from 'react-redux';

function PhysicalItemTransferFilter(props) {
    return (
        <Filter {...props}>
            <ReferenceInput
                filterToQuery={searchText => {
                    if(!searchText)
                        return {};

                    return {
                        "name": searchText,
                    }
                }}
                label="Trasferimento Da (FK_92454687c5065c7a91bd78c9850)"
                source="fromWarehouseLocationId||eq"
                reference={Permissions.warehouseLocation.feature} >
                <AutocompleteInput
                    optionText="name"
                    optionValue="id" />
            </ReferenceInput>
            <ReferenceInput
                filterToQuery={searchText => {
                    if(!searchText)
                        return {};

                    return {
                        "name": searchText,
                    }
                }}
                label="Trasferimento a (FK_2a9deb0e15e6deb36a8bf6a1099)"
                source="toWarehouseLocationId||eq"
                reference={Permissions.warehouseLocation.feature}>
                <AutocompleteInput
                    optionText="name"
                    optionValue="id" />
            </ReferenceInput>
            <AutocompleteArrayInput
                label="Stato"
                source="status||in"
                choices={[    
                    {
                        id: 'Draft',
                        name: 'Draft'
                    },
                    {
                        id: 'Reserved',
                        name: 'Reserved'
                    },
                    {
                        id: 'Completed',
                        name: 'Completed'
                    },
                    {
                        id: 'Cancelled',
                        name: 'Cancelled'
                    }

                ]}
                translateChoice={false}/>
        </Filter>
    );
}

export default function BulkActionPhysicalItemTransferList(props) {
    return (
        <List
            {...props}
            basePath={`/${Permissions.physicalItemTransfer.feature}`}
            resource={Permissions.physicalItemTransfer.feature}
            hasCreate={false}
            hasEdit={false}
            hasList={true}
            hasShow={false}
            bulkActionButtons={<BulkActionButtons />}
            filters={<PhysicalItemTransferFilter />}>
            <Datagrid rowClick={null}>
                <BooleanField label="Done" source="done" sortable={false}></BooleanField> 
                <TextField label="Item"  source="item.name" sortable={false}/>
                <TextField label="Lotto" source="lot.name" sortable={false}/>
                <TextField label="Trasferisci da" source="fromWarehouseLocation.name" sortable={false}/>
                <TextField label="Trasferisci a" source="toWarehouseLocation.name" sortable={false}/>    
                <TextField label="Quantità in reference UoM" source="quantityReference" sortable={false}/>
                <TextField label="Valore" source="value" sortable={false}/>
                <TextField label="Status" source="status" sortable={false}/>
            </Datagrid>
        </List>
    );
}

function BulkActionButtons(props) {
    const { permissions } = usePermissions();
    const dataProvider = useDataProvider();
    const [state, setState] = useState('INITIAL');
    const refresh = useRefresh();
    const dispatch = useDispatch();
    const unselectAll = useUnselectAll();

    useEffect(() => {
        

        if(state === 'CANCEL_REQUEST' || state === 'COMMIT_REQUEST') {
            setState('SENDING_REQUEST');
            
            dataProvider(POST_OFF_RESOURCE, `physicalItemTransfer/${state === 'CANCEL_REQUEST' ? 'bulk-cancel' : 'bulk-commit'}`, {
                data: {
                    ids: props.selectedIds,
                }
            })
            .then(({ data }) => {
                setState('INITIAL');
                unselectAll(Permissions.physicalItemTransfer.feature);
                refresh();
            })
            .catch(error => {
                setState('INITIAL');
                dispatch(showNotification("Errore durante l'invio della richiesta", 'error'));
            })
        }
    }, [dataProvider, dispatch, props.selectedIds, refresh, state, unselectAll])

    return (
        <>
            {permissions && permissions.includes(`${Permissions.physicalItemTransfer.feature}-${Permissions.transfer.subFeatures.physicalItemTransfer.actions.BulkCancel}`) && <Button onClick={() => {
                setState('CANCEL_REQUEST')
            }} disabled={state === 'SENDING_REQUEST'} color="primary" startIcon={<CancelIcon/>}>CANCEL</Button>}
            {permissions && permissions.includes(`${Permissions.physicalItemTransfer.feature}-${Permissions.transfer.subFeatures.physicalItemTransfer.actions.BulkCommit}`) && <Button onClick={() => {
                setState('COMMIT_REQUEST')
            }} disabled={state === 'SENDING_REQUEST'} color="primary" startIcon={<DoneIcon />}>COMMIT</Button>}
        </>
    );
  }