import React from 'react';
import { Show, SimpleShowLayout, TextField, NumberField, DateField } from 'react-admin';
import Aside from '../../inheritance/BaseAside';
import StateMachineReferenceField from '../../../components/StateMachineReferenceField';
import { Permissions } from '../../../permissions/permissions';

export const PaymentShow = (props) => {
    return (
    <Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
        <SimpleShowLayout>
            <TextField label="uuid" source="uuid" />
            <DateField label="date" source="created_at" showTime={true} />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Amount" source="amount" />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="ConfirmedAmount" source="confirmedAmount" />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="CompletedAmount" source="completedAmount" />
            <TextField label="failureReasons" source="failureReasons" />
            <TextField label="paymentInstrumentId" source="paymentInstrumentId" />
            <TextField label="Partial amount paymentInstrumentId" source="partialAmountPIId" />
            <TextField label="mainUserId" source="mainUserId" />
            <TextField label="transactionParentId" source="transactionParentId" />
            <TextField label="transactionId" source="transactionId" />
            <StateMachineReferenceField
                label="Stato"
                source="paymentStateMachine"
                reference={`${Permissions.payment.feature}_${Permissions.payment.subFeatures.paymentStateMachine.name}`}>
            </StateMachineReferenceField>
        </SimpleShowLayout>
    </Show>
)};
