import React from 'react';
import { Datagrid, List, TextField, ReferenceField, TextInput, ReferenceInput, AutocompleteInput, DateInput, DateField } from 'react-admin';
import Filter from '../../inheritance/BaseFilter';
import ListActions from '../../../components/ListActions';
import { Permissions } from '../../../permissions/permissions';

const LotFilter = (props) => (
	<Filter {...props}>
		<TextInput label="Lotto" source="name" alwaysOn />
		<TextInput label="Lotto fornitore" source="supplierLot" alwaysOn />
		<ReferenceInput
			filterToQuery={searchText => {
				if(!searchText)
					return {};

				return {
					"item.name": searchText,
					
				}
			}}
			label="Item"
			source="itemStorableId||eq"
			reference={Permissions.itemStorable.feature}>
			<AutocompleteInput
				optionText="item.name"
				optionValue="id" />
		</ReferenceInput>
		<DateInput 
          source={`expirationDate||gte`}
          label={`data di scadenza Da`}
        />
		<DateInput 
          source={`expirationDate||lte`}
          label={`data di scadenza A`}
        />
	</Filter>
);

export const LotList = props => (
	<List {...props} actions={<ListActions />} filters={<LotFilter />} sort={{ field: 'id', order: 'DESC' }}>
		<Datagrid rowClick="show">
			<TextField label="Nome" source="name" />
			<ReferenceField label="Item" source="itemId" reference={`${Permissions.item.feature}`} link="show">
				<TextField source="name" />
			</ReferenceField>
			<DateField source="expirationDate" />
			<TextField source="supplierLot" />
			<TextField label="Conformità" source="compliantStatus" />
		</Datagrid>
	</List>
);