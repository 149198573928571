import React from 'react';
import { Show, SimpleShowLayout, TextField, BooleanField, Labeled } from 'react-admin';
import Aside from '../inheritance/BaseAside';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export const CodeShow = (props) => {
	return (
	<Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
		<SimpleShowLayout>
			<TextField label="ID" source="id" />
			<BooleanField label="Abilitato" source="enable" />
			<TextField label="Nome" source="name" />
			<TextField label="URL" source="url" />
			<TextField label="Tipo" source="type" />
			<TextField label="Slug" source="customSlug" />
			<LinkField label="NFC link" source="customSlug" utm_source="qrcode" utm_medium="nfc"></LinkField>
			<LinkField label="QR Code link" source="customSlug" utm_source="qrcode" utm_medium="external-reader"></LinkField>
			<HumanLinkField label="Human link" source="customSlug"></HumanLinkField>
		</SimpleShowLayout>
	</Show>
)};

const LinkField = ({ record, ...rest }) => {
	let link = `https://foorban.business/code-landing/${record[rest.source]}?utm_source=${rest.utm_source}&utm_medium=${rest.utm_medium}`;

	return (
		<Labeled label={rest.label}>
			<Typography>
				<Link href={link} target="_blank">
					{link}
				</Link>
			</Typography>
		</Labeled>
	);
}

const HumanLinkField = ({ record, ...rest }) => {
	let link = `foorban.link/${record[rest.source]}`;

	return (
		<Labeled label={rest.label}>
			<Typography>
				<Link href={`http://${link}`} target="_blank">
					{link}
				</Link>
			</Typography>
		</Labeled>
	);
}