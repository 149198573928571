import React from 'react';
import { Button} from '@material-ui/core';
import { Link } from 'react-router-dom';

export default function MainUser(props) {
	if(!props.record.mainUserId) {
		return null;
	}
	return (
		<Button
			variant="contained"
			color="primary"
			component={Link}
			to={{ pathname: `/mainUser/${props.record.mainUserId}/show` }}>
			{props.title}<strong style={{marginLeft: '8px'}}>{props.record.mainUserId}</strong>
		</Button>
	);
}