import React from 'react';
import { Edit, SimpleForm, TextInput,SelectInput, NumberInput, Toolbar, SaveButton,required } from 'react-admin';
import { DataProviderInputDispatcher } from './components/DataProviderInputDispatcher';
import { JobStageSubStage  } from './components/JobStageSubStage';
import { STAGES_MAP, EXECUTIONS } from './config';
import { useHistory } from "react-router-dom";

const UserEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export function JobTaskEdit(props) {
    let history = useHistory();
    return ( 
        <Edit {...props}>
            <SimpleForm toolbar={<UserEditToolbar />}
                redirect={() => {
                    history.goBack();
                    return '';
                }}>
                <TextInput label="Label" source="label" validate={[required()]} />
                <SelectInput
                    source="execution"
                    label="execution"
                    choices={EXECUTIONS.map((execution) => ({id: execution, name: execution}))}
                    validate={[required()]}
                />
                <SelectInput
                    source="jobStageName"
                    label="jobStageName"
                    choices={Object.keys(STAGES_MAP).map((stage) => ({id: stage, name: stage}))}
                    validate={[required()]}
                />
                <JobStageSubStage
                    stageSource="jobStageName"
                    source="jobStageSubStage"
                    label="jobStageSubStage"
                />
                <NumberInput label="Interstage Order" source="jobStageInterStageOrder" validate={[required()]} />
                <DataProviderInputDispatcher />
            </SimpleForm>
        </Edit>
    );
}
