import React from 'react';
import { ShowView, SimpleShowLayout, TextField, ReferenceField, DateField, ShowController, useDataProvider, showNotification, useRefresh, usePermissions, FunctionField } from 'react-admin';
import Aside from '../../inheritance/BaseAside';
import { Permissions } from '../../../permissions/permissions';
import StateMachineReferenceField from '../../../components/StateMachineReferenceField';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';
import RequestItemTransferListPreview from './requestedItemTransfer/RequestItemTransferListPreview';
import {TRANSFER_ID, setKey } from '../../../utils/cross-store';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import QueueIcon from '@material-ui/icons/Queue';
import GamesIcon from '@material-ui/icons/Games';
import { useDispatch } from 'react-redux';
import { GET_OFF_RESOURCE, POST_OFF_RESOURCE } from '../../../providers/nestjs_crud';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { TopToolbar } from 'react-admin';
import { Edit as EditIcon } from '@material-ui/icons';
import { DDTTransfer } from '../ddt/DDTTransfer';
import { TransactionInfo } from '../../transaction/components/TransactionInfo';
import { transferTypesEligibleForDelivery } from './';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(1)
	},
	dowload: {
		textDecoration: "none"
	}
}));

export const TransferShow = (props) => {
	const classes = useStyles();
	setKey(TRANSFER_ID, props.id);
	const history = useHistory();
	const dispatch = useDispatch();
	const refresh = useRefresh();
	const dataProvider = useDataProvider();
	const { permissions } = usePermissions();

	return (
	<Container maxWidth="xl" className={classes.root}>
		<Grid container spacing={3}>
			<Grid item xl={12} lg={12} md={12} xs={12}>
			<ShowController aside={<Aside props={props} record={props.record}></Aside>} {...props}>
				{controllerProps =>
				<ShowView actions={
					<TopToolbar>
						<Button
							color="primary"
							size="small"
							onClick={() => {
								history.push(`/v2/transfer/${props.id}/edit`);
							}}><EditIcon /> UPDATE</Button>

					</TopToolbar>}
				{...props} {...controllerProps}>
				<SimpleShowLayout>	
                    <TextField label="ID" source="id" />
                    <TextField label="Tipo di Trasferimento"  source="type" />
					{
            controllerProps && 
            controllerProps.record && 
            controllerProps.record.transactionId &&
              <TransactionInfo 
                transactionId={controllerProps.record.transactionId}
                transactionParentId={controllerProps.record.transactionParentId}
              />
          }
          <ReferenceField label="Assignee (backendUser)" source="assigneeId" reference={`${Permissions.user.feature}`} link="show">
              <TextField source="email" />
          </ReferenceField>
          <DateField showTime label="Data prevista" source="expectedDate" />
          <ReferenceField label="Trasferisci da" source="fromWarehouseLocationId" reference={`${Permissions.warehouseLocation.feature}`} link="show">
              <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="Trasferisci a" source="toWarehouseLocationId" reference={`${Permissions.warehouseLocation.feature}`} link="show">
              <TextField source="name" />
          </ReferenceField>
          <TextField label="External document" source="externalDocument" />
					<TextField label="Causale" source="reasonToTransfer" />
					<ReferenceField label="DeliveryMainUser" source="deliveryMainUserId" reference={`${Permissions.mainUser.feature}`} link="show">
						<FunctionField
							label=""
							render={record => {
								if(!record)
									return '';

								return `${record.id} - ${record.label}`;
							}}
						/>
                    </ReferenceField>
					<TextField source="priority" />
					<TextField source="lotLogic" />
					<TextField source="outboundSortingStatus" />
					<TextField source="currentOutboundLocationId" />
					<TextField source="currentOutboundLocationLabel" />
					<TextField source="comment" />
                    <StateMachineReferenceField
                        label="Stato"
                        source="transferStateMachine"
                        reference={`${Permissions.transfer.feature}_${Permissions.transfer.subFeatures.transferStateMachine.name}`}>
                    </StateMachineReferenceField>
					{controllerProps.record && controllerProps.record.type !== 'SupplierReception' && controllerProps.record.transferStateMachine && ['todo', 'to_finish'].includes(controllerProps.record.transferStateMachine.status) && <Button
						style={{marginTop: '8px'}}
						variant="outlined"
                        onClick={() => {							
							dataProvider(GET_OFF_RESOURCE, `transfer_transferStateMachine/${controllerProps.record.transferStateMachine.id}/transition/transition_transfer_wip`)
								.then(({ data }) => {
									dispatch(showNotification(`transazione: transition_transfer_wip completata`));
									refresh();
								})
								.catch(error => {
									dispatch(showNotification(typeof error === 'string' ? error : JSON.stringify(error), 'warning'));
								})
                        }}
                        color="primary"
                        startIcon={<GamesIcon />}>
                        INIZIA PICKING
                    </Button>}
					{controllerProps.record && transferTypesEligibleForDelivery.includes(controllerProps.record.type) && controllerProps.record.transferStateMachine && controllerProps.record.transferStateMachine.status === 'picked' && <Button
						style={{marginTop: '8px'}}
						variant="outlined"
                        onClick={() => {							
							dataProvider(GET_OFF_RESOURCE, `transfer_transferStateMachine/${controllerProps.record.transferStateMachine.id}/transition/transition_start_delivery`)
								.then(({ data }) => {
									dispatch(showNotification(`transazione: transition_start_delivery completata`));
									refresh();
								})
								.catch(error => {
									dispatch(showNotification(typeof error === 'string' ? error : JSON.stringify(error), 'warning'));
								})
                        }}
                        color="primary"
                        startIcon={<LocalShippingIcon />}>
                        INIZIA DELIVERY
                    </Button>}
					<>
					{controllerProps.record && controllerProps.record.type !== 'SupplierReception' && controllerProps.record.transferStateMachine && controllerProps.record.transferStateMachine.status === 'wip' && <Button
						style={{margin: '8px 8px'}}
						variant="outlined"
						onClick={() => {
							history.push(`/picking/${controllerProps.record.id}`);
						}}
						color="primary"
						startIcon={<QueueIcon />}>
						PICKING
					</Button>}
					{controllerProps.record && controllerProps.record.type !== 'SupplierReception' && controllerProps.record.transferStateMachine && controllerProps.record.transferStateMachine.status === 'wip' && permissions && permissions.includes('transfer-move2') && <Button style={{margin: '8px 8px'}} variant="outlined" color="primary" onClick={() => {
						dataProvider(POST_OFF_RESOURCE, `transfer/${controllerProps.record.id}/move-to/picked`, {
							data: {}
						})
						.then(({ data }) => {
							refresh();
						})
						.catch(error => {
							dispatch(showNotification(typeof error === 'string' ? error : JSON.stringify(error), 'warning'));
						})
					}}>Termina Picking</Button>}
					{controllerProps.record && controllerProps.record.type !== 'SupplierReception' && controllerProps.record.transferStateMachine && controllerProps.record.transferStateMachine.status === 'wip' && permissions && permissions.includes('transfer-move2') && <Button style={{margin: '8px 8px'}} variant="outlined" color="primary" onClick={() => {
						dataProvider(POST_OFF_RESOURCE, `transfer/${controllerProps.record.id}/move-to/todo`, {
							data: {}
						})
						.then(({ data }) => {
							refresh();
						})
						.catch(error => {
							dispatch(showNotification(typeof error === 'string' ? error : JSON.stringify(error), 'warning'));
						})
					}}>Metti in ToDo</Button>}
					</>
					<div style={{margin: '16px 0 8px'}}>
					{controllerProps.record && transferTypesEligibleForDelivery.includes(controllerProps.record.type) && controllerProps.record.transferStateMachine && ['completed', 'delivering'].includes(controllerProps.record.transferStateMachine.status) && <DDTTransfer title="DDT" transferId={controllerProps.record.id} />}
					</div>
					</SimpleShowLayout>
					</ShowView>
				}
			</ShowController>
			</Grid>
		</Grid>
		<Grid container spacing={3}>
			<Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
            <ShowController {...props} aside={<Aside props={props} record={props.record}></Aside>}>
            {controllerProps =>
				<RequestItemTransferListPreview transferId={props.id} transactionParentId={controllerProps.record ? controllerProps.record.transactionId : null} /*purchaseOrderId={props.id} *//>
            }
            </ShowController>
			</Grid>
		</Grid>
	</Container>
)};