import React from 'react';
import { Show, SimpleShowLayout, TextField,  BooleanField } from 'react-admin';
import { useStore } from 'react-redux';
import Aside from '../inheritance/BaseAside';
import { AUTHTYPE_GOOGLE, AUTHTYPE_API } from './AuthType';
import MainUser from '../../components/MainUserField';
import { CustomArrayField } from '../../components/CustomArrayField';

export const UserShow = (props) => {
    const store = useStore();
    const user = store.getState().admin.resources.user.data[props.id];

    return (
    <Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Nome" source="name" />
            <TextField label="Tipologia" source="authType" />
            {user && user.authType === AUTHTYPE_GOOGLE && <TextField label="Email" source="email" />}
            {user && user.authType === AUTHTYPE_API && <TextField label="UUID" source="uuid" />}
            <CustomArrayField  subLabel="name" label="Ruoli" reference="role" source="roles" />
            <CustomArrayField  subLabel="name" label="Hub" reference="id" source="hubs" />
            <BooleanField  label="Accedi a tutti gli hub" source="accessAllHubs"/>
            <MainUser title="Anagrafica Utente" />
        </SimpleShowLayout>
    </Show>
)};
