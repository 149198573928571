import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import Aside from '../../inheritance/BaseAside';
import { makeStyles } from '@material-ui/core/styles';

import { Container, Grid } from '@material-ui/core';

import { CustomListPreview }  from './recipeListItem/CoffeeRecipeListItem';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1)
      },
    title: {
      flexGrow: 1,
    },
    rootSaleable: {
      flexGrow: 1,
    }
  }));
export function CoffeeRecipeListShow(props) {
    const classes = useStyles();
    return (
        <Container maxWidth="xl" className={classes.root}>
        <Grid container spacing={3}>
        <Grid item xl={12} lg={12} md={12} xs={12}>
        <Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
                <SimpleShowLayout>
                  <TextField source="name" />
                </SimpleShowLayout>
            </Show>
        </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
              <CustomListPreview
                redirect={document.location.hash.replace('#', '')}
                parentId={props.id} />
          </Grid>
        </Grid>
        </Container>
)};
