import React from 'react';
import BaseEdit from '../inheritance/BaseEdit';
import { Edit, SimpleForm, TextInput, required, maxValue, minValue, NumberInput } from 'react-admin';

export const ClientClusterEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput lable="Name" source="name" validate={[required()]}/>
            <NumberInput 
                label="Valore di importanza" 
                source="importanceValue" 
                validate={[
                    required(), 
                    maxValue(10000, 'Maximum value is 10000'),
                    minValue(0, 'Minimum value is 0')
                ]} 
            />
            <BaseEdit />
        </SimpleForm>
    </Edit>
);