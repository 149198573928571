import React, { useState, useEffect } from "react";
import {
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
  DateTimeInput,
  ReferenceInput,
  required,
  AutocompleteInput,
  useDataProvider,
  showNotification,
} from "react-admin";
import BaseCreate from "../../inheritance/BaseCreate";
import { Permissions } from "../../../permissions/permissions";
import {
  Button,
  Toolbar,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useForm } from "react-final-form";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

function getDateString(dateInput) {
  const date = new Date(dateInput);

  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate()}`;;
}

function CustomToolbar(props) {
  let history = useHistory();

  const dataProvider = useDataProvider();
  const dispatch = useDispatch();

  const form = useForm();

  const [state, setState] = useState("INITIAL");
  const [existingMissions, setExistingMissions] = useState([]);

  useEffect(() => {
    if (state === "FETCH_DATA") {
      setState("FETCHING_DATA");

      const formState = form.getState();
      const dateString = getDateString(formState.values.maxExecutionDate);
      dataProvider
        .getList(Permissions.missionV2.feature, {
          filter: {
            "placeId||eq": formState.values.placeId,
            "maxExecutionDate||gte": `${dateString}T00:00:00`,
            "maxExecutionDate||lte": `${dateString}T23:59:59`,
            "stateMachine.status||notin": ["COMPLETED", "CANCELLED", "FAILED"],
          },
          pagination: { page: 1, perPage: 2 },
        })
        .then((response) => {
          if (response.data.length > 0) {
            setState("EXISTING_MISSIONS");
            setExistingMissions(response.data);
          } else {
            form.submit();
          }
        })
        .catch((e) => {
          setState("INITIAL");
          const error = typeof e === "string" ? e : JSON.stringify(e);
          dispatch(showNotification(`Errore: ${error}`, "error"));
        });
    }
  }, [dataProvider, dispatch, state, form, history]);

  return (
    <Toolbar {...props}>
      <Button
        disabled={["FETCH_DATA", "FETCHING_DATA", "EXISTING_MISSIONS"].includes(state)}
        variant="contained"
        color="primary"
        startIcon={["FETCH_DATA", "FETCHING_DATA", "EXISTING_MISSIONS"].includes(state) ? <CircularProgress size={25} color="inherit"></CircularProgress> : <SaveIcon />}
        onClick={() => {
          const formState = form.getState()
          if (Object.keys(formState.errors).length === 0) {
            setState("FETCH_DATA");
          } else {
            form.submit();
          }
        }}
      >
        Save
      </Button>

      <Dialog
        fullWidth
        open={state === "EXISTING_MISSIONS"}
        onClose={() => {
          setState("INITIAL");
        }}
      >
        <DialogContent>
          <DialogContentText>
            <b>{"Warning: "}</b>
            {`${existingMissions.length === 1
                ? "Esiste già una missione"
                : "Esistono già delle missioni"
              } nello stesso luogo per la data selezionata.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              form.submit();
            }}
            color="primary"
          >
            {"Crea comunque"}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (existingMissions.length === 1) {
                history.push(
                  `/${Permissions.missionV2.feature}/${existingMissions[0].id}/show`
                );
                return;
              }

              const formState = form.getState();

              const dateNoHours = `${formState.values.maxExecutionDate.getFullYear()}-${(formState.values.maxExecutionDate.getMonth() + 1).toString().padStart(2, "0")}-${formState.values.maxExecutionDate.getDate()}`;

              const filter = JSON.stringify({
                stateMachine: {
                  "status||notin": [
                    "COMPLETED",
                    "CANCELLED",
                    "FAILED",
                  ]
                },
                "placeId||eq": formState.values.placeId,
                "maxExecutionDate||gte": `${dateNoHours}T00:00:00.000Z`,
                "maxExecutionDate||lte": `${dateNoHours}T23:59:00.000Z`
              });

              history.push({
                pathname: `/${Permissions.missionV2.feature}`,
                search: new URLSearchParams({
                  filter
                }).toString()
              });
            }}
            color="primary"
          >
            {existingMissions.length === 1
              ? "Vai alla missione"
              : "Vai alla lista missioni"}
          </Button>
        </DialogActions>
      </Dialog>
    </Toolbar>
  );
}

export function MissionCreate(props) {
  return (
    <Create debounce={1000} {...props}>
      <SimpleForm redirect="show" toolbar={<CustomToolbar />}>
        <ReferenceInput
          enableGetChoices={val => {
            return !!(val && val.name && val.name.length >= 3);
          }}
          validate={[required("campo obbligatorio")]}
          label="Luogo"
          source="placeId"
          reference={Permissions.place.feature}
          filterToQuery={(searchText) => {
            if (!searchText)
              return {
                "hubId||notnull": null,
              };

            return {
              name: searchText,
              "hubId||notnull": null,
            };
          }}
        >
          <AutocompleteInput
            shouldRenderSuggestions={val => val && val.length >= 3 }
            optionValue="id"
            optionText={(record) => {
              if (!record || !record.id) {
                  return 'cancel';
              }
              return `${record ? `${record.id} - ${record.name}` : ''}`
          }}/>
        </ReferenceInput>
        <DateTimeInput
          locales="it-IT"
          validate={[
            required("campo obbligatorio"),
            (value) => {
              if (!value || !isNaN(new Date(value))) return;

              return 'Data non valida';
            }
          ]}
          label="Data massima di inizio"
          source="maxExecutionDate"
        />
        <DateTimeInput
          locales="it-IT"
          label="Data stimata di inizio"
          source="estimatedStartDate"
        />
        <NumberInput
					label="Stima tempi morti in minuti"
					source="downtimeDuration"
          defaultValue={0}
          validate={[required("campo obbligatorio")]}
				/>
        <NumberInput
          defaultValue={0}
          validate={[required("campo obbligatorio")]}
          label="Valore missione"
          source="value"
        />
        <TextInput label="Commento" source="comment" />
        <ReferenceInput
          enableGetChoices={val => {
            return !!(val && val['mainUser.label'] && val['mainUser.label'].length >= 3);
          }}
          filterToQuery={(searchText) => {
            if (!searchText) return {};

            return {
              "mainUser.label": searchText,
            };
          }}
          label="Corriere"
          source="deliveryManId"
          reference={Permissions.deliveryMan.feature}
          allowEmpty
        >
          <AutocompleteInput
            shouldRenderSuggestions={val => {
              return val && val.length >= 3;
            }}
            optionText={(record) => {
              if (!record || !record.id) {
                return "cancel";
              }
              return `${
                record ? `${record.mainUser.id} - ${record.mainUser.label}` : ""
              }`;
            }}
            optionValue="id"
          />
        </ReferenceInput>
        <ReferenceInput
          enableGetChoices={val => {
            return !!(val && val['mainUser.label'] && val['mainUser.label'].length >= 3);
          }}
          filterToQuery={(searchText) => {
            if (!searchText) return {};

            return {
              "mainUser.label": searchText,
            };
          }}
          label="Corriere suggerito"
          source="suggestedDeliveryManId"
          reference={Permissions.deliveryMan.feature}
          allowEmpty
        >
          <AutocompleteInput
            shouldRenderSuggestions={val => {
              return val && val.length >= 3;
            }}
            optionText={(record) => {
              if (!record || !record.id) {
                return "cancel";
              }
              return `${
                record ? `${record.mainUser.id} - ${record.mainUser.label}` : ""
              }`;
            }}
            optionValue="id"
          />
        </ReferenceInput>
        <BaseCreate />
      </SimpleForm>
    </Create>
  );
}
