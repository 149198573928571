import React, { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { TextField, MenuItem, Fab, Dialog, AppBar, Toolbar, Typography, IconButton, CircularProgress, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    fab: {
        marginRight: '95px',
        position: 'absolute',
        bottom: theme.spacing(3),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer
    },
    container: {
        marginTop: '60px',
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(0, 2),
        flexDirection: 'column',
    },
    loadingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'column',
        zIndex: theme.zIndex.drawer + 1
    },
    button: {
        margin: theme.spacing(1, 0, 0),
    }
}));

export function AddLot(props) {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [status, setStatus] = useState('INITIAL');
    const [statusDialog, setStatusDialog] = useState('CLOSE');
    const [ data, setData ] = useState();
    const [ dataReceived, setDataReceived ] = useState();
    const [ lotId, setLotId ] = useState();

    useEffect(() => {
        if (statusDialog === 'OPEN' && status === 'FETCH_DATA') {
            setStatus('FETCHING_DATA');

            dataProvider('GET_OFF_RESOURCE', `warehouseInventory/${props.warehouseInventoryId}/item/${props.itemId}/lots`)
                .then((response) => {
                    if(!response.data || response.data.success === false) {
                        setDataReceived(response.data.errors);
                        setStatus('ERROR_FETCHING_DATA');
                    } else {
                        if(response.data.data.length === 0) {
                            setStatus('NO_LOT'); 
                        } else {
                            setLotId(response.data.data[0].id)
                            setData(response.data.data);
                            setStatus('DATA_FETCHED'); 
                        }
                    }
                })
                .catch(() => {
                    setStatus('NO_MANAGED_ERROR_FETCHING_DATA'); 
                })
        }
        if (status === 'SEND_DATA') {
            setStatus('SENDING_DATA');
            dataProvider('POST_OFF_RESOURCE', `warehouseInventory/${props.warehouseInventoryId}/add-lot`, {
                data: {
                    lotId,
                }
             })
                .then(({ data }) => {
                    if(!data || data.success === false) {
                        setDataReceived(data);
                        setStatus('ERROR_SEND_DATA');
                    } else if(data.success === true) {
                        props.refreshData(data.data);
                        setStatusDialog('CLOSE');
                        setStatus('INITIAL');
                    } else {
                        setStatus('NO_MANAGED_ERROR_SEND_DATA');
                    }
                })
                .catch(() => {
                    setStatus('NO_MANAGED_ERROR_SEND_DATA'); 
                })
        }
    }, [dataProvider, props.itemId, props.warehouseInventoryId, status, statusDialog, lotId, props])

    return (
        <>
            <Fab className={classes.fab} onClick={() => {
                setStatusDialog('OPEN');
                setStatus('FETCH_DATA');
            }} variant="extended" color="primary" aria-label="add">
                <AddIcon />
                AGGIUNGI
            </Fab>
            <Dialog
                fullScreen={true}
                open={statusDialog === 'OPEN'}
                aria-labelledby="responsive-dialog-title"
            >
                <AppBar>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => {
                            setStatus('INITIAL');
                            setStatusDialog('CLOSE')
                        }} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6">
                            Aggiungi Lotto
                        </Typography>
                    </Toolbar>
                </AppBar>

                <div className={classes.container}>
                   {['ERROR_FETCHING_DATA', 'NO_MANAGED_ERROR_FETCHING_DATA', 'ERROR_SEND_DATA', 'NO_MANAGED_ERROR_SEND_DATA', 'NO_LOT'].includes(status) && <div>
                        <Typography gutterBottom variant="h6" align='center'>
                            {['ERROR_FETCHING_DATA', 'NO_MANAGED_ERROR_FETCHING_DATA'].includes(status) && 'Errore caricamento dati'}
                            {['ERROR_SEND_DATA', 'NO_MANAGED_ERROR_SEND_DATA'].includes(status) && 'Errore invio dati'}
                            {['NO_LOT'].includes(status) && 'Non sono presenti altri lotti'}
                        </Typography>
                        {['ERROR_SEND_DATA', 'ERROR_FETCHING_DATA'].includes(status) && <Typography gutterBottom>
                            {JSON.stringify(dataReceived.errors)}
                        </Typography>}
                        {['ERROR_SEND_DATA', 'NO_LOT'].includes(status) && <Button className={classes.button} onClick={() => {
                            setStatus('FETCH_DATA');
                        } } fullWidth variant="contained" color="primary" size="large">Ricarica lotti</Button>}
                        {['ERROR_SEND_DATA' ].includes(status) && <Button className={classes.button} onClick={() => {
                            setStatus('DATA_FETCHED');
                        } } fullWidth variant="contained" color="primary" size="large" >Scegli un'altro lotto</Button>}
                        <Button className={classes.button} onClick={() => {
                            setStatus('INITIAL');
                            setStatusDialog('CLOSE');
                        } } fullWidth variant="contained" size="large" >Annulla</Button>
                    </div>} 
                    { ['FETCHING_DATA', 'FETCH_DATA', 'SEND_DATA', 'SENDING_DATA'].includes(status) && <div className={classes.loadingContainer}>
                        <Typography gutterBottom variant="h6">
                        {['FETCHING_DATA', 'FETCH_DATA'].includes(status) && 'CARICAMENTO DATI IN CORSO...'}
                        {['SEND_DATA', 'SENDING_DATA'].includes(status) && 'INVIO DATI IN CORSO...'}
                        </Typography>
                        <CircularProgress size="2rem"/>
                    </div>}
                    { 'DATA_FETCHED' === status && <TextField
                        fullWidth
                        select
                        label="Lotto"
                        value={lotId}
                        onChange={(e) => {
                            setLotId(e.target.value)
                        }}
                        helperText="Seleziona un lotto"
                        >
                        {data.map((option) => {
                            let label;
                            if(option.expirationDate) {
                                const date = new Date(option.expirationDate);
                                label = `${(date.getDate()).toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
                            } else if(option.supplierLot) {
                                label = option.supplierLot;
                            }
                            return (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.id} - {label} {option.compliantStatus === 'not-compliant' ? '| not-compliant' : ''}
                                </MenuItem> 
                            );
                    })}
                        </TextField>
                    }
                    { 'DATA_FETCHED' === status && <Button onClick={() => {
                        setStatus('SEND_DATA')
                    } } fullWidth variant="contained" color="primary" size="large" >Aggiungi</Button>}
                </div>
            </Dialog>
        </>
    );
}