import React from 'react';

import { ReferenceField, TextField, NumberField } from 'react-admin';
import { ListPreview } from '../../../../layouts/ListPreview';
import { Permissions } from '../../../../permissions/permissions';
import { PurchaseOrderRowCreate } from './PurchaseOrderRowCreate';
import { useSelector } from 'react-redux';

export default (props) => {
  const orderStateMachine = useSelector(state => state.admin.resources.purchaseOrder.data[props.purchaseOrderId] ? state.admin.resources.purchaseOrder.data[props.purchaseOrderId].orderStateMachine : null);
  const disableAdd = orderStateMachine && (orderStateMachine.status !== 'draft' && orderStateMachine.status !== 'sent');

  return (
    <ListPreview
      title={"Righe ordine di acquisto"}
      basePath = {`/${Permissions.purchaseOrderRow.feature}`}
      resource = {`${Permissions.purchaseOrderRow.feature}`}
      filterList={{ "purchaseOrderId||eq": props.purchaseOrderId }}
      redirect={`/${Permissions.purchaseOrder.feature}/${props.purchaseOrderId}/show`}
      hasCreateModal={!disableAdd}
      createProps={{purchaseOrderId: props.purchaseOrderId}}
      create={PurchaseOrderRowCreate}
      rowClick={"show"}
      reactAdminListProps={disableAdd ? {bulkActionButtons: false} : {}}
    >
      <ReferenceField label="Listino fornitore" source="supplierPriceListId" reference={`${Permissions.supplierPriceList.feature}`} link="show">
          <TextField source="itemPurchasable.item.name" />
      </ReferenceField>
      <ReferenceField label="Unità" source="costUnitId" reference={`${Permissions.unitOfMeasurement.feature}`} link="show">
          <TextField source="name" />
      </ReferenceField>
      <NumberField label="Quantità" source="quantityConfirmedInPriceUnit" />
  
      <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Prezzo" source="unitPriceNoTax" />
      <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Aggiustamento" source="adjustmentNoTax" />
      <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Totale" source="totalNoTax" />
    </ListPreview>
  )
}