import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: { 
      width: '100%'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  action: {
    backgroundColor: 'white',
    width: '100%'
  },
  textField: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    width: '100%',
    minWidth: '64px',
  },
  quantityTopRight: {
    padding: theme.spacing(1),
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  cardContent: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default function CardRequestShift({
  warehouseAccAccount,
  updateQuantity,
}) {
  const classes = useStyles();
  let expirationDate = null;

  if(warehouseAccAccount && warehouseAccAccount.lot && warehouseAccAccount.lot.expirationDate) {
    const date = new Date(warehouseAccAccount.lot.expirationDate);
    expirationDate = `${(date.getDate()).toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  }

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent className={classes.cardContent}>
        <div>
        {expirationDate && <Typography  variant="h6" gutterBottom>
          <small>Scadenza: </small><strong>{expirationDate}</strong>
        </Typography>}
        {warehouseAccAccount && warehouseAccAccount.lot && warehouseAccAccount.lot.compliantStatus === "not-compliant"  && <Typography align="center" style={{backgroundColor: "red", fontWeight: "bold", padding: "8px 0 8px 0"}} gutterBottom>
            Lotto non conforme
        </Typography>}
        {warehouseAccAccount && warehouseAccAccount.lot && warehouseAccAccount.lot.supplierLot && <Typography  variant="h6" gutterBottom>
          <small>Lotto Fornitore: </small><strong>{warehouseAccAccount.lot.supplierLot}</strong>
        </Typography>}
        </div>
        <div>
          <Typography variant="h6" className={classes.quantityTopRight}>
            {warehouseAccAccount.stockBalance}
          </Typography>
        </div>
      </CardContent>
      <CardActions>
        <Button className={classes.action} variant="contained" color="default" onClick={() => {
          const quantity2Update = Number.parseFloat((Math.max(0, Number.parseFloat(warehouseAccAccount.quantity2Shift) - 5)).toFixed(6));
          if(quantity2Update <= warehouseAccAccount.stockBalance) {
            updateQuantity(quantity2Update);
          }
        }}>-5</Button>
        <Button className={classes.action} variant="contained" color="default" onClick={() => {
          const quantity2Update = Number.parseFloat((Math.max(0, Number.parseFloat(warehouseAccAccount.quantity2Shift) - 1)).toFixed(6));
          if(quantity2Update <= warehouseAccAccount.stockBalance) {
            updateQuantity(quantity2Update);
          }
        }}>-1</Button>
        <TextField type="number" inputProps={{min: 0, style: { textAlign: 'center', fontWeight: 900 }}} value={warehouseAccAccount.quantity2Shift} onChange={(e) => {
          const quantity2Update = Math.max(0, Number.parseFloat(e.target.value));
          if(quantity2Update <= warehouseAccAccount.stockBalance) {
            updateQuantity(quantity2Update);
          }
        }} className={classes.textField} size="small" label="" variant="outlined" />
        <Button className={classes.action} variant="contained" color="default" onClick={() => {
          const quantity2Update = Number.parseFloat((Math.max(0, Number.parseFloat(warehouseAccAccount.quantity2Shift) + 1)).toFixed(6));
          if(quantity2Update <= warehouseAccAccount.stockBalance) {
            updateQuantity(quantity2Update);
          }
        }}>+1</Button>
        <Button className={classes.action} variant="contained" color="default" onClick={() => {
          const quantity2Update = Number.parseFloat((Math.max(0, Number.parseFloat(warehouseAccAccount.quantity2Shift) + 5)).toFixed(6));
          if(quantity2Update <= warehouseAccAccount.stockBalance) {
            updateQuantity(quantity2Update);
          }
        }}>+5</Button>
      </CardActions>
    </Card>
  );
}
