import React from 'react';
import { Datagrid, List, TextField, TextInput, SelectInput } from 'react-admin';
import Filter from '../inheritance/BaseFilter';
import ListActions from '../../components/ListActions';

const DomainFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nome" source="name" />
		<TextInput label="Dominio" source="regex" />
        <SelectInput label="Tipo" source="type" choices={[
            { id: 'google', name: 'Google' },
            { id: 'microsoft', name: 'Microsoft' },
            { id: 'email', name: 'Email' }
		]} />
    </Filter>
);

export const DomainList = props => (
    <List {...props} actions={<ListActions />}  filters={<DomainFilter />}>
        <Datagrid rowClick="show">
            <TextField label="Nome" source="name" />
            <TextField label="Dominio" source="regex" />
            <TextField label="Tipo" source="type" />
        </Datagrid>
    </List>
);