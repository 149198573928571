import React from 'react';
import { Create, SimpleForm, TextInput,SelectInput, NumberInput, required } from 'react-admin';
import { DataProviderInputDispatcher } from './components/DataProviderInputDispatcher';
import { JobStageSubStage } from './components/JobStageSubStage';
import { STAGES_MAP, EXECUTIONS, MANUAL_TASK_TYPES } from './config';

export function JobTaskCreate(props) {
    return (
        <Create {...props}>
            <SimpleForm redirect={props.redirect}>
                <div style={{display: 'none' }}>
                    <NumberInput disabled source="missionJobId" initialValue={props.missionJobId} />
          	    </div>
                <TextInput label="Label" source="label" validate={[required()]} />
                <SelectInput
                    source="type"
                    label="type"
                    choices={MANUAL_TASK_TYPES.map((type) => ({id: type, name: type}))}
                    validate={[required()]}
                />
                <SelectInput
                    source="execution"
                    label="execution"
                    choices={EXECUTIONS.map((execution) => ({id: execution, name: execution}))}
                    validate={[required()]}
                />
                <SelectInput
                    source="jobStageName"
                    label="jobStageName"
                    choices={Object.keys(STAGES_MAP).map((stage) => ({id: stage, name: stage}))}
                    validate={[required()]}
                />
                <JobStageSubStage
                    stageSource="jobStageName"
                    source="jobStageSubStage"
                    label="jobStageSubStage"
                />
                <NumberInput label="Interstage Order" source="jobStageInterStageOrder" validate={[required()]} />
                <DataProviderInputDispatcher />
            </SimpleForm>
        </Create>
    );
}