import React from 'react';
import { Create, SimpleForm, TextInput, BooleanInput, NumberInput, FormDataConsumer, ReferenceInput, AutocompleteInput, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { required } from 'react-admin';
import { useForm } from 'react-final-form';
import { Permissions } from '../../permissions/permissions';

export const PlaceCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput fullWidth label="Nome" source="name" validate={[required('Il nome è obbligatorio')]} />
            <TextInput fullWidth label="Indirizzo" source="address" validate={[required('L\'indirizzo è obbligatorio')]} />
            <BooleanInput fullWidth label="Può acquistare " source="canPurchase"></BooleanInput>
            <CustomBooleanInput />
            <FormDataConsumer>
                {({ formData }) => {
                    if(formData.canSell) {
                        return (
                            <>
                                <NumberInput fullWidth label="Totale visitatori" source="totalAudience"  validate={[required('campo obbligatorio')]} />
                                <NumberInput fullWidth label="Numero medio di visitatori giornalieri" source="avgDailyAudience"  validate={[required('campo obbligatorio')]} />
                            </>
                        );
                    } else {
                        return null;
                    }
                }}
            </FormDataConsumer>
            <ReferenceInput
                    validate={[required('campo obbligatorio')]}
                    enableGetChoices={val => {
                        return !!(val && val.name && val.name.length >= 2);
                    }}
                    filterToQuery={searchText => {
                        if (!searchText)
                            return {};

                        return {
                            "name": searchText,
                        }
                    }}
                    label="Hub di riferimento"
                    source="hubId"
                    reference={Permissions.hub.feature}>
                    <AutocompleteInput
                        optionText="name"
                        optionValue="id" />
                </ReferenceInput>
                <ReferenceInput
                    filterToQuery={searchText => {
                        if (!searchText)
                            return {};

                        return {
                            "name": searchText,
                        }
                    }}
                    label="Cliente B2B"
                    source="b2bClientId"
                    reference={Permissions.B2BClient.feature}
					>
                    <AutocompleteInput
                        optionText="name"
                        optionValue="id" />
            </ReferenceInput>
            <TextInput fullWidth label="Note per corriere" source="deliveryManNote" />
            <ReferenceArrayInput filterToQuery={searchText => {
					if(!searchText)
						return null;
					return {
						name: searchText,  
					}
				}}  source="skillsIds" reference={Permissions.deliveryManSkill.feature}>
                <AutocompleteArrayInput  optionText="name"/> 
            </ReferenceArrayInput>
        <BaseCreate />
        </SimpleForm>
    </Create>
);

export function CustomBooleanInput() {
    const form = useForm()
    return <BooleanInput fullWidth onChange={(value) => {
        if(!value) {
            form.change("totalAudience", undefined);
            form.change("avgDailyAudience", undefined);
        }
    }} label="Può vendere" source="canSell"></BooleanInput>
}