import { green, yellow, blue, orange } from '@material-ui/core/colors'

export function getColorForStatus(action) {
    switch (action) {
        case 'PLANNED':
            return yellow;
        case 'DELIVERING':
        case 'RETURNING':
            return orange;
        case 'IN_PROGRESS':
            return blue;
        case 'COMPLETED':
            return green;
        default:
            return ''; 
    }
}