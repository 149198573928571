import React from 'react';
import { Fragment } from 'react';
import { NumberInput, useDataProvider, required } from 'react-admin';
import { useForm } from 'react-final-form';
import { ConvertUOM } from './HelperState';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    form: {
        display: 'flex',            
        flexGrow: 'colums',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
});

export default ({ formData, ...rest }) => {
    const form = useForm();
    const dataProvider = useDataProvider();
    const classes = useStyles();

    return (
        <Fragment>
            <div className={classes.form}>
            <NumberInput
                style={{width: "100%"}}
                {...rest}
                fullWidth 
                label="quantità in unità fabbisogno"
                source="quantity"
                validate={[required('Indicare una quantità è obbligatorio')]}
                onChange={() => {
                    ConvertUOM(form, dataProvider);
                }}
            />
            <NumberInput
                style={{width: "100%"}}
                {...rest}
                fullWidth 
                disabled
                label="quantità in unità prezzo"
                source="quantityPriceUnit"
                validate={[required('Indicare una quantità è obbligatorio')]} />
            </div>
        </Fragment>
    );
};