import React from 'react';
import { Datagrid, List, TextField, TextInput, ReferenceField, ReferenceInput, AutocompleteInput} from 'react-admin';
import ListActions from '../../components/ListActions';
import Filter from '../inheritance/BaseFilter';
import { Permissions } from '../../permissions/permissions';
const B2BClientFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name" alwaysOn />
        <ReferenceInput
                alwaysOn
                enableGetChoices={val => {
					return !!(val && val.label && val.label.length >= 2);
				}}
                filterToQuery={searchText => {
                    if (!searchText)
                        return {
                            "type": "RibesUser",
                            "accountManager||eq": true
                        }

                    return {
                        "label": searchText,
                        "type": "RibesUser",
                        "accountManager||eq": true
                    }
                }}
                label="Account Manager"
                source="accountManagerId"
                reference={Permissions.mainUser.feature}>
                <AutocompleteInput
                    optionText={(record) => {
                        if (!record || !record.id) {
                            return 'cancel';
                        }
                        return `${record ? `${record.id} - ${record.label}` : ''}`
                    }}
                    optionValue="id" />
            </ReferenceInput>
            <ReferenceInput
                allowEmpty
                emptyText="cancel"
                alwaysOn
                enableGetChoices={val => {
					return !!(val && val.name && val.name.length >= 2);
				}}
                filterToQuery={searchText => {
                    if (!searchText)
                        return {};

                    return {
                        "name": searchText,
                    }
                }}
                label="Cluster"
                source="clusterId"
                reference={Permissions.clientCluster.feature}>
                <AutocompleteInput
                    optionText="name"
                    optionValue="id" />
            </ReferenceInput>
    </Filter>
);

export function B2BClientList(props) {
    return (
        <List {...props} actions={<ListActions />} filters={<B2BClientFilter />}>
            <Datagrid rowClick="show">
                <TextField label="Name" source="name" />
                <ReferenceField label={"Account Manager"} source="accountManagerId" reference={Permissions.mainUser.feature}>
                    <TextField source="label" />
                </ReferenceField>
                <ReferenceField label="Cluster" source="clusterId" reference={Permissions.clientCluster.feature}>
                    <TextField source="name" />
                </ReferenceField>
            </Datagrid> 
        </List>
    );
}

