import { POST_OFF_RESOURCE } from '../../../../../providers/nestjs_crud';

export const ConvertUOM = (form, dataProvider) => {
    if(
        !form.getState().values.costUnitId ||
        !form.getState().values.orderUnitId ||
        form.getState().values.quantity === null ||
        form.getState().values.quantity === undefined
    ) {
        return null;
    }
    let inputQuantity = form.getState().values.quantity;
    let UoMInputId = form.getState().values.orderUnitId;
    let UoMOutputId = form.getState().values.costUnitId;

    dataProvider(POST_OFF_RESOURCE, 'unitOfMeasurement/calculate-quantity', {
        data: {
            inputQuantity: form.getState().values.quantity,
            UoMInputId: form.getState().values.orderUnitId,
            UoMOutputId: form.getState().values.costUnitId
        }
    }).then((r) => {
        if(
            inputQuantity === form.getState().values.quantity && 
            UoMInputId === form.getState().values.orderUnitId &&
            UoMOutputId === form.getState().values.costUnitId
        ) {
            form.change('quantityPriceUnit', r.data.outputQuantity)
        }
    }).catch(err => {
        console.log(err);
    })
}

export const FinalQuantity = (form, dataProvider) => {
    if(
        !form.getState().values.costUnitId ||
        form.getState().values.quantityPriceUnit === null ||
        form.getState().values.quantityPriceUnit === undefined
    ) {
        return null;
    }
    let supplierPriceListId = form.getState().values.supplierPriceListId;
    let inputQuantity = form.getState().values.quantityPriceUnit;
    let costUnitId =  form.getState().values.costUnitId;

    dataProvider(POST_OFF_RESOURCE, 'purchaseOrderRow/calculate-quantity', {
        data: {
            supplierPriceListId: form.getState().values.supplierPriceListId,
            inputQuantity: form.getState().values.quantityPriceUnit,
            costUnitId: form.getState().values.costUnitId
        }
    }).then(({data}) => {
        if(
            supplierPriceListId === form.getState().values.supplierPriceListId &&
            inputQuantity === form.getState().values.quantityPriceUnit &&
            costUnitId === form.getState().values.costUnitId
        ) {
            if(data.outputQuantity > 0) {
                form.change('quantityPriceUnitAfterMinOrderAndRounding', data.outputQuantity)
                form.change('quantityConfirmedInPriceUnit', data.outputQuantity)
            }
        }
    }).catch(err => {
        console.log(err);
    })
}