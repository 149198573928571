import React from "react";
import { NumberInput } from "react-admin";

export function FormPhotos(props) {
  return (
    <NumberInput
      source="providerData.minPhotos"
      label="Numero minimo di foto"
      helperText={false}
    />
  );
}
