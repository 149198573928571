import React from 'react';

import { ShowPreview } from '../../../layouts/ShowPreview';
import { ItemStorableCreate } from './ItemStorableCreate';
import { ItemStorableShow } from './ItemStorableShow';
import { Permissions } from '../../../permissions/permissions';

export default (props) => {
  return (
    <ShowPreview
      data={props.itemStorable}
      title={"Item Immagazzinabile"}
      basePath = {`/${Permissions.itemStorable.feature}`}
      resource = {`${Permissions.itemStorable.feature}`}
      redirect={`/item/${props.itemId}/show`}
      hasCreateModal={true}
      create={ItemStorableCreate}
      createProps={{itemId: props.itemId}}
      hasEditP={true}
      show={ItemStorableShow}
    >
    </ShowPreview>
  )
}