import React, { useState, useEffect } from 'react';
import { Datagrid, List, TextField, TextInput, AutocompleteInput, useDataProvider, Loading, DateField, NumberField } from 'react-admin';
import Filter from '../../../inheritance/BaseFilter';
import ListActions from '../../../../components/ListActions';
import { Permissions } from '../../../../permissions/permissions';
import { Typography } from '@material-ui/core';
import { GET_OFF_RESOURCE } from '../../../../providers/nestjs_crud';
const STATUS_FETCH_DATA = "FETCH_DATA";
const STATUS_FETCHING_DATA = "STATUS_FETCHING_DATA";
const STATUS_DATA_FETCHED = "STATUS_DATA_FETCHED";
const STATUS_ERROR_DATA_FETCHED = "STATUS_ERROR_DATA_FETCHED";

function WalletAccountTypeFilter(props) {
	const dataProvider = useDataProvider();
	const [config, setConfig] = useState(null);
	const [status, setStatus] = useState(STATUS_FETCH_DATA);
	
	useEffect(() => {
		if(status === STATUS_FETCH_DATA) {
			setStatus(STATUS_FETCHING_DATA)
			dataProvider(GET_OFF_RESOURCE,`${Permissions.walletAccAccountType.feature}_${Permissions.walletAccAccountType.subFeatures.config.name}` )
				.then(({data}) => {
					setStatus(STATUS_DATA_FETCHED);
					setConfig(data);
				})
				.catch(e => {
					setStatus(STATUS_ERROR_DATA_FETCHED)
				})
		}	
	}, [status, dataProvider, setConfig, setStatus]);
	
	if(status === STATUS_ERROR_DATA_FETCHED) { return <Typography variant="h6">Errore caricamento filtro</Typography>};
	
	if(status === STATUS_FETCHING_DATA) return <Loading />;
	
	if(status === STATUS_DATA_FETCHED && config !== null && config !== undefined) {
		return (
			<Filter {...props}>
				<TextInput source="id" />
				<TextInput source="name" />
				<AutocompleteInput source="accountCategory||eq" choices={Object.keys(config).map(key => {
					return {
						id: key,
						name: config[key].name
					}
				})} />
			</Filter>
		);
	}

	return null;
}

export const WalletAccountTypeList = props => (
	<List {...props} sort={{ field: 'created_at', order: 'DESC' }} actions={<ListActions />}  filters={<WalletAccountTypeFilter />}>
		<Datagrid rowClick="show">
			<TextField source="id" />
			<TextField source="name" />
			<TextField source="accountCategory" />
			<TextField source="externalName" />
			<TextField source="owner"/>
			<DateField source="expirationDate"/>
			<NumberField source="usagePriority"/>
		</Datagrid>
	</List>
);