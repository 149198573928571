import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { required } from 'react-admin';

export const PriceListCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Nome" source="name" validate={[required('Il nome è obbligatorio')]} />
            <BaseCreate />
        </SimpleForm>
    </Create>
);