import React, { useState } from 'react';
import { useLogin, Notification } from 'react-admin';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import GoogleButton from './GoogleButton';
import TextField from '@material-ui/core/TextField';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.foorban.com/">
        Foorban
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default ({location}) => {

  const classes = useStyles();
  const [email, setEmail] = useState();
  const login = useLogin();
  const token = location.search.replace('?','').split('=')[1];

  if(token) {
    login(token)
    .then((redirect) => {
      if(redirect) {
        window.location.replace(redirect);
      }
    })
    return null;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      {process.env.REACT_APP_WAREHOUSE_API === 'http://localhost' &&
        <div className={classes.paper}>
          <TextField label="Email" value={email} onChange={event => setEmail(event.target.value)} />
          <br></br>
          <GoogleButton type="light" label="Sign in local" onClick={() => {
              fetch(`http://localhost/auth/test/${email}`)
                .then(res => res.json())
                .then(
                  ({access_token}) => {
                    return login(access_token)
                      .then((redirect) => {
                        if(redirect) {
                          window.location.replace(redirect);
                        }
                      })
                  }
                )
          }}></GoogleButton>
        </div>
      }
      {process.env.REACT_APP_WAREHOUSE_API !== 'http://localhost' &&
        <div className={classes.paper}>
          <GoogleButton onClick={() => {window.location.assign(`${process.env.REACT_APP_WAREHOUSE_API}/auth/google`)}}></GoogleButton>
        </div>
      }
      <Box mt={8}>
        <Copyright />
      </Box>
      <Box mt={8}>
        <Notification />
      </Box>
    </Container>
  );
}