import React from 'react';
import { Create, SimpleForm, TextInput, AutocompleteInput } from 'react-admin';
import BaseCreate from '../../inheritance/BaseCreate';
import { required } from 'react-admin';
import { TypeList } from './';
import RichTextInput from 'ra-input-rich-text';

export function CoffeeTypeCreate(props) {
	return (
		<Create {...props}>
			<SimpleForm redirect="list">
				<AutocompleteInput fullWidth label="Tipo" validate={[required()]} source="type" choices={TypeList.map(type => {
					return {
						id: type,
						name: type
					}
				})} />
				<TextInput fullWidth validate={[required()]} source="name" />
				<TextInput fullWidth validate={[required()]} source="clientName" />
				<RichTextInput
					fullWidth
					source="description"
					/>
				<BaseCreate />
			</SimpleForm>
		</Create>
	);
}