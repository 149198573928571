import React from 'react';
import { useSelector } from 'react-redux';

import { Edit, SimpleForm, ReferenceInput, SelectInput, NumberInput, FormDataConsumer, AutocompleteInput, TextInput, required, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import BaseEdit from '../../../inheritance/BaseEdit';
import { Permissions } from '../../../../permissions/permissions';
import Quantity from './Form/Quantity';
import FinalQuantity from './Form/FinalQuantity';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    form: {
        display: 'flex',            
        flexGrow: 'colums',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
});

const CustomToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
        {false && <DeleteButton redirect={props.redirect} />}
    </Toolbar>
);

export const PurchaseOrderRowEdit = props => {
    const purchaseOrderId = useSelector(state => state.admin.resources.purchaseOrderRow.data[props.id] ? state.admin.resources.purchaseOrderRow.data[props.id].purchaseOrderId : null);
    return (
    <Edit {...props}>
        <SimpleForm redirect={() => (`/${Permissions.purchaseOrder.feature}/${purchaseOrderId}/show`)} toolbar={<CustomToolbar data={props.data} redirect={() => (`/${Permissions.purchaseOrder.feature}/${purchaseOrderId}/show`)}/>}>
            <div style={{display: 'none' }}>  
                <ReferenceInput disabled source="supplierPriceListId" reference={`${Permissions.supplierPriceList.feature}`}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </div>
            <ReferenceInput disabled label="Label" source="itemId" reference={`${Permissions.item.feature}`}>
                  <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                disabled
                label="unità di misura fabbisogno"
                source="orderUnitId"
                reference={`${Permissions.unitOfMeasurement.feature}`}>
                  <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                disabled
                label="unità di misura prezzo"
                source="costUnitId"
                reference={`${Permissions.unitOfMeasurement.feature}`}>
                  <SelectInput optionText="name" />
            </ReferenceInput>
            <FormDataConsumer>
                {formDataProps => (
                    <Quantity {...formDataProps} />
                )}
            </FormDataConsumer>
            <NumberInput
                fullWidth
                label="prezzo senza IVA"
                source="unitPriceNoTax"
                validate={[required('Indicare una prezzo è obbligatorio')]}
            />

            <ReferenceInput
                fullWidth
                label="Tassa"
                source="taxId"
                reference={`${Permissions.tax.feature}`}
                filterToQuery={searchText => {
                    if(!searchText)
                        return {};
                
                    return { "name": searchText }
                }}
                >
                <AutocompleteInput
                    optionText="name"
                    optionValue="id"
                    options={
                        {
                            suggestionsContainerProps: {
                                disablePortal: true
                            }
                        }
                    }
                />
            </ReferenceInput>
            <NumberInput
                fullWidth
                disabled
                label="minimo d’ordine in unità prezzo"
                source="minimumOrder"
                validate={[required('Indicare una prezzo è obbligatorio')]}
            />
            <NumberInput
                fullWidth
                disabled
                label="arrotondamento d’ordine in unità prezzo"
                source="rounding"
                validate={[required('Indicare l\'arrotondamento è obbligatorio')]}
            />
            <FormDataConsumer>
                {formDataProps => (
                    <FinalQuantity {...formDataProps} />
                )}
            </FormDataConsumer>
            <NumberInput
                fullWidth
                label="quantità unità prezzo dopo minimo d’ordine e arrotondamento"
                source="quantityPriceUnitAfterMinOrderAndRounding"
                validate={[required('Campo obbligatorio')]}
            />
            <NumberInput
                fullWidth
                label="quantità confermata dal fornitore"
                source="quantityConfirmedInPriceUnit"
                validate={[required('Campo obbligatorio')]}
            />
            <TextInput fullWidth label="nome aggiustamento" source="adjustmentName" />
            <NumberInput fullWidth label="sconti/aggiustamenti senza tasse" source="adjustmentNoTax" />
            <BaseEdit />
        </SimpleForm>
    </Edit>
)};