import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import Aside from '../inheritance/BaseAside';
import { Grid, Container, makeStyles } from '@material-ui/core';
import LayoutPhotoList from "./layoutPhoto/LayoutPhotoList";
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(1)
    },
    button: {
        marginTop: theme.spacing(1)
    }
  }));

export const LayoutShow = (props) => {
    const classes = useStyles();
   
    return (
        <Container maxWidth="xl" className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xl={12} lg={12} md={12} xs={12}>
                    <Show aside={<Aside props={props} record={props.record}></Aside>} {...props}>
                        <SimpleShowLayout>
                            <TextField lable="Name" source="name" />
                            <TextField label="Commento" source="comment" />
                            <OpenLayout />
                        </SimpleShowLayout>
                    </Show>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                    <LayoutPhotoList id={props.id} />
                </Grid>
            </Grid>
        </Container>
    )
};

function OpenLayout (props) {
    const history = useHistory();
    const classes = useStyles();
    return (
        <Button
            className={classes.button}
            size="small" 
            variant='contained' 
            color='primary' 
            onClick={() => history.push(`/mission_rider/layout-list/${props.record.id}/show`)}
        >
            Preview corriere
        </Button>
    )
}
