import React from 'react';
import { Create, SimpleForm, TextInput, AutocompleteInput, NumberInput, ReferenceInput } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { required } from 'react-admin';
import { TIME_INTERVAL_TYPE_VALUES } from './';
import { Permissions } from '../../permissions/permissions';

export function CorporateCreditCreate(props) {
	return (
		<Create {...props}>
			<SimpleForm redirect="list">
				<TextInput source="name" validate={[required('campo obbligatorio')]} />
				<ReferenceInput
					validate={[required('campo obbligatorio')]}
                    filter={{"accountCategory||eq": 'CorporateSalePayment'}}
                    filterToQuery={searchText => {
                        if(!searchText)
                            return {
								"accountCategory||eq": 'CorporateSalePayment'
							};

                        return {
                            name: searchText,
							"accountCategory||eq": 'CorporateSalePayment'
                        }
                    }}
                    label="Pagato dal tipo di Conto"
                    source="walletAccountTypePayingId"
                    reference={`${Permissions.walletAccAccountType.feature}`}
                >
                    <AutocompleteInput
                        optionText={(record) => record ? `${record.name} - ${record.owner}` : ''}
                        optionValue="id" />
                </ReferenceInput>
				<ReferenceInput
					filter={{
						"mainUserId||notnull": null
					}}
					validate={[required('campo obbligatorio')]}
					filterToQuery={searchText => {
						if(!searchText)
							return {
								"mainUserId||notnull": null
							};

						return {
							name: searchText,
							"mainUserId||notnull": null
						}
					}}
					label="Pagato dal CorporateCustomer"
					source="corporateCustomerId"
					reference={Permissions.corporateCustomers.feature}>
					<AutocompleteInput
                        optionText={(record) => record ? `${record.name}` : ''}
                        optionValue="id" />
                </ReferenceInput>
				<AutocompleteInput label="Periodo" validate={[required('campo obbligatorio')]} source="timeIntervalType" choices={TIME_INTERVAL_TYPE_VALUES.map(timeIntervalType => {
					return {
						id: timeIntervalType,
						name: timeIntervalType
					}
				})} />
				<NumberInput validate={[required('campo obbligatorio')]} source="credit" />
				<BaseCreate />
			</SimpleForm>
		</Create>
	);
}