import React from 'react';
import { Datagrid, List, TextField, ReferenceInput, AutocompleteInput, DateInput  } from 'react-admin';
import ListActions from '../../components/ListActions';
import Filter from '../inheritance/BaseFilter';
import { Permissions } from '../../permissions/permissions';

function DeliveryMainFilter(props) {
    return (
        <Filter {...props}>
            <ReferenceInput
                alwaysOn
                enableGetChoices={val => {
					return !!(val && val.label && val.label.length >= 2);
				}}
                filterToQuery={searchText => {
                    if (!searchText)
                        return {
                            "type": "RibesUser",
                            "planner||eq": true
                        }

                    return {
                        "label": searchText,
                        "type": "RibesUser",
                        "planner||eq": true
                    }
                }}
                label="Planner"
                source="plannerMainUserId"
                reference={Permissions.mainUser.feature}>
                <AutocompleteInput
                    optionText={(record) => {
                        if (!record || !record.id) {
                            return 'cancel';
                        }
                        return `${record ? `${record.id} - ${record.label}` : ''}`
                    }}
                    optionValue="id" />
            </ReferenceInput>
            <ReferenceInput
                alwaysOn
                enableGetChoices={val => {
					return !!(val && val.label && val.label.length >= 2);
				}}
                filterToQuery={searchText => {
                    if (!searchText)
                        return {
                            "type": "RibesUser",
                            "deliveryMan||eq": true
                        }

                    return {
                        "label": searchText,
                        "type": "RibesUser",
                        "deliveryMan||eq": true
                    }
                }}
                label="Corriere"
                source="mainUserId"
                reference={Permissions.mainUser.feature}>
                <AutocompleteInput
                    optionText={(record) => {
                        if (!record || !record.id) {
                            return 'cancel';
                        }
                        return `${record ? `${record.id} - ${record.label}` : ''}`
                    }}
                    optionValue="id" />
            </ReferenceInput>
            <ReferenceInput
                enableGetChoices={val => {
					return !!(val && val.name && val.name.length >= 2);
				}}
                filterToQuery={searchText => {
                    if (!searchText)
                        return {};

                    return {
                        "name": searchText,
                    }
                }}
                label="Hub di riferimento"
                source="hubId"
                reference={Permissions.hub.feature}>
                <AutocompleteInput
                    optionText="name"
                    optionValue="id" />
            </ReferenceInput>
            <DateInput
                alwaysOn
                label="Data assunzione"
                source="assumptionDate" />
            <DateInput
                alwaysOn
                label="Data scadenza contratto"
                source="contractExpiryDate" />
            
            <ReferenceInput
                alwaysOn
                allowEmpty
                label="Skills"
                source="skills.id"
                reference={Permissions.deliveryManSkill.feature}
                filterToQuery={searchText => {
                    if (!searchText)
                        return {};

                    return {
                        "name": searchText,
                    }
                }}
                >
                <AutocompleteInput optionText="name" emptyText="cancel" />
            </ReferenceInput>
            
        </Filter>
        
    );
}

export function DeliveryManList(props) {
    return (
        <List {...props} actions={<ListActions />} filters={<DeliveryMainFilter />}>
            <Datagrid rowClick="show">
                <TextField label="Corriere" source="mainUser.label" />
                <TextField label="Planner" source="plannerMainUser.label" />
                <TextField label="Hub" source="hub.name" />
            </Datagrid> 
        </List>
    );
}

