import React from 'react';
import { Show, SimpleShowLayout, TextField, useShowController, BooleanField } from 'react-admin';
import { Typography } from '@material-ui/core';

export function MainUserShow(props) {
	const {
		loaded,
		record,
	} = useShowController(props);
	return (
		<Show  {...props}>
			<SimpleShowLayout>
				<TextField source="id" />
				<TextField source="type" />
				<TextField source="label" />
				<TextField source="sdi" />
				<TextField source="registryType" />
				<TextField source="contactPerson" />
				<TextField source="vatNumber" />
				<TextField source="taxCode" />
				<TextField source="country" />
				<TextField source="address" />
				<TextField source="city" />
				<TextField source="cap" />
				<TextField source="province" />
				<TextField source="addressNote" />
				<TextField source="emails" />
				<TextField source="pec" />
				<TextField source="phone" />
				<div>
					<Typography>extraNote</Typography>
					{loaded && <div dangerouslySetInnerHTML={{ __html: record.extraNote }} />}
				</div>
				{loaded && record.type === 'RibesUser' && <BooleanField source="planner"/>}
				{loaded && record.type === 'RibesUser' && <BooleanField source="deliveryMan"/>}
				{loaded && record.type === 'RibesUser' && <BooleanField source="accountManager"/>}
			</SimpleShowLayout>
		</Show>
	)
}
