import React, { useState, useEffect } from 'react';
import { SimpleShowLayout, TextField, BooleanField, TopToolbar, EditButton, showNotification, useDataProvider, ShowView, ShowController, ReferenceField, DateField, usePermissions, ShowButton } from 'react-admin';
import Aside from '../inheritance/BaseAside';
import { GET_OFF_RESOURCE_PLAIN } from '../../providers/nestjs_crud';
import { useDispatch } from 'react-redux';
import BlockIcon from '@material-ui/icons/Block';
import Button from '@material-ui/core/Button';
import { Permissions, B2CUserWalletActions } from '../../permissions/permissions';
import { ListPreview } from '../../layouts/ListPreview';
import { WalletManualTransferCreate } from '../transaction/walletManualTransfer/WalletManualTransferCreate';
import { Typography } from '@material-ui/core';
import StateMachineReferenceField from '../../components/StateMachineReferenceField';
import MainUser from '../../components/MainUserField';
import { CustomArrayField } from '../../components/CustomArrayField';

const ShowActions = (props) => {
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();

	return (
		<TopToolbar>
			<EditButton basePath={props.basePath} record={props.data} />
			{props.data && props.data.firebaseUUID && <Button
                onClick={() => {
                    dataProvider(GET_OFF_RESOURCE_PLAIN, `b2c/auth/invalid/${props.data.firebaseUUID}`)
                    .then(message => {
                        dispatch(showNotification(message));
                    })
                    .catch(() => {
                        dispatch(showNotification("Errore durante revoca", "error"));
                    });
                }}
                color="primary"><BlockIcon></BlockIcon> Disabilita Firebase</Button>}
		</TopToolbar>
	);
}

export const B2CUserShow = (props) => {
    return (
    <ShowController aside={<Aside props={props} record={props.record}></Aside>} actions={<ShowActions />} {...props}>
        {controllerProps =>
            <ShowView {...props} {...controllerProps}>
                <SimpleShowLayout>
                    <TextField label="ID" source="id" />
                    <TextField label="Email" source="email" />
                    <TextField label="firebaseUUID" source="firebaseUUID"  />
                    <TextField label="Nome" source="name" />
                    <TextField label="Cognome" source="surname" />
                    <TextField label="Numero di telefono" source="phoneNumber" />
                    <BooleanField label="Abilitato" source="enable"></BooleanField> 
                    <CustomArrayField  subLabel="name" label="corporateCustomers" reference={Permissions.corporateCustomers.feature} source="corporateCustomers" />
                    <MainUser title="Anagrafica Utente" />
                    {controllerProps.record && <Balance mainUserId={controllerProps.record.mainUserId} />}
                    {controllerProps.record && <React.Fragment>
                        <ListPreview
                            actionTitle="Carica/Svuota"
                            modalSubTitle="Carica/Svuota"
                            hasCreateModal={true}
                            create={WalletManualTransferCreate}
                            createProps={{
                                ownerId: controllerProps.record.mainUserId
                            }}
                            title={"Conti Cliente"}
                            basePath = {`/${Permissions.walletAccAccount.feature}`}
                            resource = {`${Permissions.walletAccAccount.feature}`}
                            filterList={{ 
                                "ownerId||eq": controllerProps.record.mainUserId,
                                reserved: false
                            }}
                            reactAdminListProps={{
                                bulkActionButtons: null,
                                pagination: false,
                                sort: { field: 'created_at', order: 'DESC' }
                            }}
                            redirect={`/${Permissions.B2CUser.feature}/${controllerProps.record.id}/show`}
                            rowClick={null}>
                                <TextField sortable={false} source="id" />
                                <ReferenceField sortable={false} label="Nome" source="accAccountTypeId" reference={`${Permissions.walletAccAccountType.feature}`} link={null}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <ReferenceField sortable={false} label="Priorità" source="accAccountTypeId" reference={`${Permissions.walletAccAccountType.feature}`} link={null}>
                                    <TextField source="usagePriority" />
                                </ReferenceField>
                                <ReferenceField sortable={false} label="Scadenza" source="accAccountTypeId" reference={`${Permissions.walletAccAccountType.feature}`} link={null}>
                                    <DateField source="expirationDate" />
                                </ReferenceField>
                                <TextField sortable={false} source="balance" />
                        </ListPreview>
                        <br></br>
                    </React.Fragment>}
                    {controllerProps.record && <React.Fragment>
                        <ListPreview
                            hasCreateModal={false}
                            title={"Ricariche Automatiche"}
                            basePath = {`/${Permissions.walletRecharge.feature}`}
                            resource = {`${Permissions.walletRecharge.feature}`}
                            filterList={{
                                "mainUserId||eq": controllerProps.record.mainUserId,
                            }}
                            reactAdminListProps={{
                                bulkActionButtons: null,
                                pagination: false,
                                sort: { field: 'created_at', order: 'DESC' }
                            }}
                            rowClick={null}>
                                <TextField sortable={false} source="id" />
                                <TextField sortable={false} source="uuid" />
                                <TextField sortable={false} source="amount" />
                                <TextField sortable={false} source="completedAmount" />
                                <TextField sortable={false} source="paymentAttemptUUID" />
                                <StateMachineReferenceField
                                    sortable={false}
                                    label="Stato"
                                    source="walletRechargeStateMachine"
                                    reference={`${Permissions.walletRecharge.feature}_${Permissions.walletRecharge.subFeatures.walletRechargeStateMachine.name}`}>
                                </StateMachineReferenceField>
                                <ShowButton />
                        </ListPreview>
                    </React.Fragment>}
                </SimpleShowLayout>
            </ShowView>
        }
    </ShowController>
)};

const STATUS_FETCH_DATA = "fetch_data";
const STATUS_FETCHING_DATA = "fetching_data";
const STATUS_DATA_FETCHED = "data_fetched";
const STATUS_FETCHING_DATA_ERROR = "fetching_data_error";


function Balance(props) {
    const dataProvider = useDataProvider();
    const { permissions } = usePermissions();
    const [status, setStatus] = useState(STATUS_FETCH_DATA);
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        if(status === STATUS_FETCH_DATA && permissions && permissions.includes(`${Permissions.B2CUser.feature}_${Permissions.B2CUser.subFeatures.wallet.name}-${B2CUserWalletActions.balance}`)) {
            setStatus(STATUS_FETCHING_DATA);
            dataProvider(GET_OFF_RESOURCE_PLAIN, `${Permissions.B2CUser.feature}/${B2CUserWalletActions.balance}/${props.mainUserId}`)
            .then(dataRaw => {
                setStatus(STATUS_DATA_FETCHED);
                const data = JSON.parse(dataRaw);
                setBalance(data.balance);
            })
            .catch(() => {
                setStatus(STATUS_FETCHING_DATA_ERROR)
            });
        }
        return () => {}
    }, [dataProvider, props.mainUserId, status, setStatus, permissions])
    
    return status === STATUS_DATA_FETCHED && <Typography variant="h6"> <small>Balance: </small><strong>{balance} €</strong></Typography>;
}