import React from 'react';
import { SimpleForm, AutocompleteInput, ReferenceInput, TextInput, FormDataConsumer, Edit } from 'react-admin';
import BaseCreate from '../../../inheritance/BaseCreate';
import { required } from 'react-admin';
import {integrationTypes, customProviderTypes, categoryTypes} from './interfaces';
import { Permissions } from '../../../../permissions/permissions';
import { Gateway } from './WalletRechargeConfigCreate';

export function WalletRechargeConfigEdit(props) {
    return (
        <Edit {...props}>
            <SimpleForm redirect="list">
                <AutocompleteInput fullWidth validate={[required('campo obbligatorio')]} source="provider" choices={customProviderTypes} />
                <AutocompleteInput fullWidth validate={[required('campo obbligatorio')]} source="integration" choices={integrationTypes} />
                <AutocompleteInput fullWidth validate={[required('campo obbligatorio')]} source="category" choices={categoryTypes} />
                <FormDataConsumer>
					{(form) => <Gateway
						{...form}
						{...props}/>
					}
				</FormDataConsumer>
                <TextInput disabled={true} fullWidth label="GatewayMainUserId" source="gatewayMainUserId" />
                <ReferenceInput
                    filter={{"owner||eq": 'gateway'}}
                    fullWidth
                    filterToQuery={searchText => {
                        if(!searchText)
                            return {};

                        return {
                            name: searchText,
                            "owner||eq": 'gateway'
                        }
                    }}
                    label="Walllet Account Type Gateway"
                    source="accountTypeGatewayId"
                    reference={`${Permissions.walletAccAccountType.feature}`}
                >
                    <AutocompleteInput
                        optionText={(record) => record ? `${record.name} - ${record.owner}` : ''}
                        optionValue="id" />
                </ReferenceInput>
                <ReferenceInput
                    filter={{"owner||eq": '3rd-party'}}
                    fullWidth
                    filterToQuery={searchText => {
                        if(!searchText)
                            return {};

                        return {
                            name: searchText,
                            "owner||eq": '3rd-party'
                        }
                    }}
                    label="Walllet Account Type User"
                    source="accountTypeUserId"
                    reference={`${Permissions.walletAccAccountType.feature}`}
                >
                    <AutocompleteInput
                        optionText={(record) => record ? `${record.name} - ${record.owner}` : ''}
                        optionValue="id" />
                </ReferenceInput>
                <BaseCreate />
            </SimpleForm>
        </Edit>
    )
};