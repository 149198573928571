import React from 'react';
import { Permissions } from '../../../../permissions/permissions';
import { ListPreview } from '../../../../layouts/ListPreview';
import {  TextField, ReferenceField, DateField } from 'react-admin';

export const WarehouseInventoryItemsList = (props) => {
    return (
        <ListPreview
            title={"Items di Inventario"}
            basePath = {`/${Permissions.warehouseInventoryItem.feature}`}
            resource = {`${Permissions.warehouseInventoryItem.feature}`}
            filterList={{ "warehouseInventoryId||eq": props.id}}
            hasCreateModal={false}
            rowClick={"show"}
            reactAdminListProps={{
                bulkActionButtons: false,
            }}
        >
        <TextField sortable={false} label='Item Id' source="itemId" />
        <ReferenceField sortable={false} label='Item' source="itemId" reference={Permissions.item.feature}>
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField sortable={false} label='Lot' source="lotId" reference={Permissions.lot.feature}>
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField sortable={false} label='Conformita' source="lotId" reference={Permissions.lot.feature}>
            <TextField source="compliantStatus" />
        </ReferenceField>
        <ReferenceField sortable={false} label='Editor' source="last_update_user" reference={Permissions.mainUser.feature}>
            <TextField source="label" />
        </ReferenceField>
        <TextField sortable={false} label='Quantità iniziale non riservata' source="quantityInitialNotReserved"/>
        <TextField sortable={false} label='Quantità iniziale riservata' source="quantityInitialReserved"/>
        <TextField sortable={false} label='Quantità inventariata (riservata + non riservata)' source="quantityInventory"/>
        <DateField sortable={false} showTime locales="it-IT" label='Data completamento' source="completeDate"/>
        <TextField sortable={false} label='Conta prodotti(partial) o lotti(full)' source="lotCountingMode"/>
        <TextField sortable={false} label='STATUS' source="status"/>
    </ListPreview>
    )
}

