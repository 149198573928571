import React from 'react';
import { SimpleShowLayout, TextField, BooleanField,NumberField, ShowController, ShowView} from 'react-admin';
import Aside from '../../inheritance/BaseAside';
import CustomToolbar from '../ItemChildActionShow';

export const ItemStorableShow = (props) => {
    return  (
        <ShowController {...props} aside={<Aside props={props} record={props.record}></Aside>}>
            {controllerProps =>
                <ShowView {...props} {...controllerProps} actions={props.hideActions ? null : <CustomToolbar />}>
                    <SimpleShowLayout>
                        <BooleanField
                            label="Has Shelf Life"
                            source="hasShelfLife" />

                        <NumberField
                            label="Shelf Life minima"
                            source="minimumShelfLife" />

                        <BooleanField
                            label="Lottizzabile"
                            source="isPlottable" />

                        <NumberField
                        label="Volume di una unità di riferimento in dm^3"
                        source="volume" />

                        <NumberField
                        label="Peso in KG di una unità di riferimento"
                        source="weight" />

                        <TextField
                        label="Conservazione predefinita"
                        source="storageType" />

                        <BooleanField
                        label="Utilizzabile"
                        source="usable" />

                        <TextField
                        label="Nota"
                        source="note" />

                        <TextField
                        label="Ordine Inventario"
                        source="inventoryOrder" />
                        <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Valore" source="value" />
                        <NumberField
                            source="warehouseCycleCountingDays"
                        ></NumberField>
                        <NumberField
                            source="missionCycleCountingDays"
                        ></NumberField>
                        <NumberField
                            source="widthInmm"
                        ></NumberField>
                        <NumberField
                            source="depthtInmm"
                        ></NumberField>
                        <NumberField
                            source="heightInmm"
                        ></NumberField>
                        <NumberField
                            source="maximumNumberStackableItems"
                        ></NumberField>
                    </SimpleShowLayout>
                </ShowView>
            }
        </ShowController>
    );
}