import React from 'react';
import { Datagrid, List, TextField, ReferenceField, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import Filter from '../inheritance/BaseFilter';
import ListActions from '../../components/ListActions';
import { Permissions } from '../../permissions/permissions';

const InternalCategoryFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nome" source="name" alwaysOn />
        <ReferenceInput
            filterToQuery={searchText => {
                if(!searchText)
                    return {};

                return { name: searchText }
            }}
            label="Categoria Padre"
            source="parentCategoryId||eq"
            reference={`${Permissions.internalCategory.feature}`}
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val && val.length >= 2 }
                optionText="name"
                optionValue="id" />
        </ReferenceInput>
    </Filter>
);

export const InternalCategoryList = props => (
    <List {...props} actions={<ListActions />}  filters={<InternalCategoryFilter />} >
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <TextField label="Nome" source="name" />
            <ReferenceField label="Categoria Padre" source="parentCategoryId" reference={`${Permissions.internalCategory.feature}`} link="show">
                <TextField source="name" />
            </ReferenceField>
        </Datagrid>
    </List>
);