export default {
    login: (token) =>  {
        return fetch(`${process.env.REACT_APP_WAREHOUSE_API}/auth/user/permissions`, {
            headers: new Headers({
                'Authorization': `Bearer ${token}` 
            })
        })
        .then(res => res.json())
        .then(
            (data) => {
                localStorage.setItem('token', token);
                if(data.actions) {
                    if(data.user) {
                        localStorage.setItem('user', JSON.stringify(data.user));
                    }
                    localStorage.setItem('actions', data.actions);
                } else {
                    localStorage.setItem('actions', data);
                }
                const redirect = localStorage.getItem('redirect');
                if(redirect) {
                    localStorage.removeItem('redirect')
                }
                return Promise.resolve(redirect);
            },
            (error) => {
                return Promise.reject(error);
            }
        )
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('actions');
        localStorage.removeItem('user');
        return Promise.resolve();
    },
    checkError: error => {
        if(error && error.statusCode && error.statusCode === 403) {
            if(!window.location.href.includes('login')) {
                localStorage.setItem('redirect', window.location.href);
            }
            window.location.replace(`/#/unauthorized?permission=${error.message}`);
            localStorage.removeItem('token');
            localStorage.removeItem('actions');
        } else if(error && error.statusCode && error.statusCode === 401) {
            if(!window.location.href.includes('login')) {
                localStorage.setItem('redirect', window.location.href);
            }
            localStorage.removeItem('token');
            localStorage.removeItem('actions');
            return Promise.reject();
        }
        return Promise.resolve(error);
    },
    checkAuth: () => {
        const token = localStorage.getItem('token')
        if(!token) {
            if(!window.location.href.includes('login')) {
                localStorage.setItem('redirect', window.location.href);
            }
            return Promise.reject();
        } else {
            return Promise.resolve();
        }
    },
    getPermissions: () => {
        const actions = localStorage.getItem('actions');
        return actions ? Promise.resolve(actions) : Promise.reject();
    }
};