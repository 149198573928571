import React from 'react';
import { Create, SimpleForm, NumberInput, ReferenceInput, TextInput, AutocompleteInput } from 'react-admin';
import BaseCreate from '../../../inheritance/BaseCreate';
import { required } from 'react-admin';
import { Permissions } from '../../../../permissions/permissions';

export function CoffeeRecipeListItemCreate(props)  {
    return (
        <Create {...props}>
            <SimpleForm redirect={props.redirect}>
                <div style={{display: 'none' }}>
                    <TextInput source="coffeeRecipeListId"  initialValue={props.coffeeRecipeListId}  />
                </div>
                <NumberInput fullWidth label="Ordine" helperText="numero da 1 a x" source="order" validate={[required("L'ordine è obbligatorio")]} />
                <ReferenceInput
                    fullWidth
                    validate={[required("L'ordine è obbligatorio")]}
					label="CoffeeRecipe"
					filterToQuery={searchText => {
						if (!searchText)
							return null;

						return {
							'item.name': searchText
						}
					}} source="coffeeRecipeId" reference={Permissions.coffeeRecipe.feature}>
					<AutocompleteInput
                        options={{suggestionsContainerProps: {disablePortal: true}}}
                        optionText={(record) => record ? `${record.item.id} - ${record.item.name}` : ''}
                    />
				</ReferenceInput>
                <BaseCreate />
            </SimpleForm>
        </Create>
    );
}