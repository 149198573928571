import React, { useEffect, useState } from 'react';
import { Create, SimpleForm, TextInput, SelectInput, NumberInput, minValue, required, useDataProvider, showNotification, usePermissions } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';

import { ASSET_TYPE, TEMPERATURE_TRAKING_STATE } from "./index"
import { GET_OFF_RESOURCE } from '../../providers/nestjs_crud';
import { useDispatch } from 'react-redux';
import { Permissions } from '../../permissions/permissions';
export const LockCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <TextInput label="Nome" source="name" validate={[required('Il nome è obbligatorio')]} />
            <ChipModelInput {...props}/>
            <SelectInput
                allowEmpty
                emptyText='cancel'
                source="assetType"
                label="Asset Type"
                choices={ASSET_TYPE}
            />
            <TextInput label="Asset Identifier" source="assetIdentifier"  />
            <SelectInput
                defaultValue={'disabled'}
                source="temperatureTrackingState"
                label="Temperature Tracking State"
                choices={TEMPERATURE_TRAKING_STATE}
            />
            <TextInput
                source="temperatureTrackingMacAddress"
                label="Temperature Tracking Mac Address"
            />
            <NumberInput source="temperatureTarget" label="Temperature Target" />
            <NumberInput source="temperatureOffset" label="Temperature Offset" />
            <NumberInput validate={[required('Il nome è obbligatorio'), minValue(1)]} defaultValue={1} source="version" label="Version" />
            <BaseCreate />
        </SimpleForm>
    </Create>
);

export function ChipModelInput(props) {
	const [state, setState] = useState("INITIAL");
	const [models, setModels] = useState([]);
	const dataProvider = useDataProvider();
	const dispatch = useDispatch();
	const { permissions } = usePermissions();

	useEffect(() => {
		if (state === "INITIAL") {
			setState("FETCHING_DATA");
			dataProvider(
				GET_OFF_RESOURCE,
				Permissions.lock.feature + "/chipmodels"
			)
				.then((message) => {
					setState("FETCHED");
					setModels(message.data);
				})
				.catch((error) => {
					setState("ERROR_FETCHING_DATA");
					dispatch(
						showNotification(
							"Errore durante la fetch dei modelli",
							"error"
						)
					);
				});
		}
	}, [dataProvider, dispatch, state]);

	if (
		!permissions ||
		!permissions.includes(
			`${Permissions.lock.feature}-${Permissions.lock.actions.ReadAll}`
		)
	) {
		return null;
	}

	if (state !== "FETCHED") {
		return null;
	}

	return (
		<SelectInput
            validate={[required('Il chip model è obbligatorio')]}
			{...props}
			choices={models}
			source={props.source || "chipModel"}
			label={props.label || "Chip Model"}
		/>
	);
}