import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "red"
    
  },
  quantity: {
    padding: theme.spacing(1),
    width: '100%',
  },
}));

export default function CardMissingQuantity({label}) {
  const classes = useStyles();
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent >
        <Typography className={classes.quantity} align="center" variant="h4">
          {label}
        </Typography>
      </CardContent>
    </Card>
  );
}
