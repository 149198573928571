import React from 'react';

import { ReferenceField, TextField, NumberField } from 'react-admin';
import { ListPreview } from '../../../layouts/ListPreview';
import { SellingOrderRowCreate } from '../../sellingOrderRow/SellingOrderRowCreate';
import { Permissions } from '../../../permissions/permissions';

export default (props) => {
  return (
    <ListPreview
      title={"Righe ordine di vendita"}
      basePath = {`/${Permissions.sellingOrderRow.feature}`}
      resource = {`${Permissions.sellingOrderRow.feature}`}
      filterList={{ "sellingOrderId||eq": props.sellingOrderId }}
      redirect={`/${Permissions.sellingOrder.feature}/${props.sellingOrderId}/show`}
      hasCreateModal={!props.disableAdd}
      createProps={{sellingOrderId: props.sellingOrderId}}
      create={SellingOrderRowCreate}
      rowClick={props.disableClickRow ? null : "show"}
      reactAdminListProps={props.reactAdminListProps ? props.reactAdminListProps : {}}
    >
      <ReferenceField label="Item" source="itemId" reference={`${Permissions.item.feature}`} link="show">
          <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Unità" source="unitOfMeasurementId" reference={`${Permissions.unitOfMeasurement.feature}`} link="show">
          <TextField source="name" />
      </ReferenceField>
      <NumberField label="Quantità" source="quantity" />
      <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Prezzo" source="unitPriceWithTax" />
      <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Aggiustamento con tasse" source="adjustmentWithTax" />
      <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Totale con tasse" source="totalWithTax" />
      <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Tasse sul totale" source="totalTax" />
    </ListPreview>
  )
}