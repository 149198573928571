import React, { useEffect, useState } from "react";
import { useDataProvider, showNotification } from "react-admin";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  AppBar,
  Toolbar,
  Card,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Box,
  IconButton,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

import { Permissions } from "../../../../../permissions/permissions";
import { POST_OFF_RESOURCE } from "../../../../../providers/nestjs_crud";

const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1,
  },
}));

export function FormTextTask(props) {
  const { task, config } = props;
  const [formText, setFormText] = useState(config && config.formText ? config.formText : "");
  const [state, setState] = useState("INITIAL");
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();

  useEffect(() => {
    if (state === "SEND_DATA") {
      setState("SENDING_DATA");
      dataProvider(
        POST_OFF_RESOURCE,
        `${Permissions.deliveryManFormTextTask.feature}/${task.id}`,
        {
          data: { formText: formText },
        }
      )
        .then((rawResponse) => {
          const response = rawResponse.data;
          if (response.success) {
            props.onDone && props.onDone();
            dispatch(
              showNotification(`Azione: ${task.type} completata`)
            );
          } else {
            let message = "Errore non riconosciuto durante azione: send-form-text. ";

            if (response.errors) {
              message = response.errors
                .map(({ constraints }) => {
                  return Object.keys(constraints)
                    .map((key) => constraints[key])
                    .join(", ");
                })
                .join(", ");
            }
            dispatch(showNotification(message, "error"));
          }
          setState("INITIAL");

        })
        .catch((error) => {
          setState("INITIAL");

          dispatch(
            showNotification(
              `Errore durante azione: send-form-text. ${error}`,
              "error"
            )
          );
        });
    }
  }, [dataProvider, dispatch, formText, props, props.onDone, state, task.id, task.type]);

  let previousValue = config && config.formText;

  return (
    <>
      <Card>
        <AppBar color="default" position="static">
          <Toolbar>
            {!previousValue && <Typography variant="h5" className={classes.title}>
                {task.label}
              </Typography>}
            {previousValue && <div className={classes.title}> 
                <small>{task.label}</small><br/><Typography variant="h5" className={classes.title}>{previousValue}</Typography>
            </div>}
            <Button
              color="primary"
              variant="contained"
              disabled={state !== "INITIAL"}
              onClick={() => {
                setState("AWAITING_DATA");
              }}
            >
              {previousValue ? "Modifica" : "Invia"}
            </Button>
          </Toolbar>
        </AppBar>
      </Card>
      <Dialog
        open={state === "AWAITING_DATA"}
        onClose={() => {
          setState("INITIAL");
        }}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">
          <Box display="flex" alignItems="center">
            <Typography style={{ width: '100%' }} align="center" variant="h6">{task.label}</Typography>
            <Box>
              <IconButton onClick={() => setState("INITIAL")}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <TextField
              fullWidth
              value={formText}
              autoFocus={true}
              onChange={(e) => {
                setFormText(e.target.value);
              }}
            />
          </DialogContentText>
          <DialogActions>
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={() => {
                setState("SEND_DATA");
              }}
            >
              {previousValue ? "Modifica" : "Invia"}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
