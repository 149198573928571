import React from "react";
import { Typography, Box } from "@material-ui/core";
import { FormPhotosDeliveryManProvidedData } from "./formPhotos/formPhotosDeliveryManProvidedData";
import { FormDropdownDeliveryManProvidedData } from "./formDropdown/FormDropdownDeliveryManProvidedData";
import { FormTextDeliveryManProvidedData } from "./formText/FormTextDeliveryManProvidedData";
import { InventoryDeliveryManProvidedData } from "./inventory/InventoryDeliveryManProvidedData";

export function DeliveryManProvidedData(props) {

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="caption" style={{ color: "rgba(0, 0, 0, 0.54)" }}>
        {"Dati inviati dal corriere"}
      </Typography>
      <Data {...props} />
    </Box>
  );
}

function Data(props) {
  if (!props.record) {
    return null;
  }

  switch (props.record.type) {
    case "formPhotos":
      return (
        <FormPhotosDeliveryManProvidedData data={props.record.deliveryManProvidedData} />
      );
    case "formDropdown":
      return (
        <FormDropdownDeliveryManProvidedData data={props.record.deliveryManProvidedData} />
      );
    case "formText":
      return (
        <FormTextDeliveryManProvidedData data={props.record.deliveryManProvidedData} />
      );
    case "inventory":
      return (
        <InventoryDeliveryManProvidedData data={props.record.deliveryManProvidedData} />
      );
    default:
      return (
        <Typography variant="subtitle2">
          {JSON.stringify(props.record.deliveryManProvidedData)}
        </Typography>
      );
  }
}
