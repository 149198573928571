import React from "react";
import { Typography, Box } from "@material-ui/core";

export function FormDropdownDeliveryManProvidedData(props) {
  return (
    <>
      <Typography variant="caption">
        <Box fontWeight={"bold"}>{`Item selezionato (Tech key - Label)`}</Box>
      </Typography>
      {props.data ? (
        <Typography variant="body2">{`${props.data.selectedItem.techKey} - ${props.data.selectedItem.label}`}</Typography>
      ) : (
        "-"
      )}
    </>
  );
}
