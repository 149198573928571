import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  media: {
    height: 100,
  },
  fullImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  closeIcon: {
    width: "1.5em",
    height: "1.5em",
    marginRight: "0.5em",
    marginTop: "0.5em",
    position: "absolute",
    right: "0",
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-focusVisible": { backgroundColor: theme.palette.grey[200] },
  },
}));

function MediaCard(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Card className={classes.rootCard} onClick={handleOpenDialog}>
        <CardActionArea>
          <CardMedia className={classes.media} image={props.image} />
        </CardActionArea>
      </Card>

      <Dialog open={open} onClose={handleCloseDialog} maxWidth="xl">
        <IconButton
          className={classes.closeIcon}
          edge="start"
          color="default"
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </IconButton>
        <img src={props.image} alt="Full Size" className={classes.fullImage} />
      </Dialog>
    </>
  );
}

export function FormPhotosDeliveryManProvidedData(props) {
  const { data } = props;
  const classes = useStyles();

  return (
    <>
      <Typography variant="caption">
        <Box fontWeight={"bold"}>{`Foto caricate`}</Box>
      </Typography>
      {data ? (
        <Container maxWidth="xl" fullWidth={"lg"} className={classes.root}>
          <Grid container className={classes.container} spacing={3}>
            {data.photoUrls &&
              data.photoUrls.map((photo) => {
                return (
                  <Grid key={photo} item xs={6} lg={2}>
                    <MediaCard image={photo} />
                  </Grid>
                );
              })}
          </Grid>
        </Container>
      ) : (
        "-"
      )}
    </>
  );
}
