import { TransferEdit } from "./TransferEdit";
import { TransferList } from "./TransferList";
import { TransferCreate } from "./TransferCreate";
import { TransferShow } from "./TransferShow";


export default {edit:TransferEdit, list:TransferList, create:TransferCreate, show: TransferShow};

export const transferTypesEligibleForDelivery = [
    'Transfer',
    'CustomerDelivery',
    'Waste',
    'SupplierReturn',
    'ClientReturn',
];

export const transferTypesEligibleForConvertRequest2Physical = [
    'InternalMoving',
    'Waste',
    'CustomerDelivery',
    'Transfer',
    'SupplierReturn',
    'ClientReturn',
];

export const mapTypeToShortName = {
    'InternalMoving': 'INT-MOV',
    'SupplierReception': 'SUP-REC',
    'SupplierReturn': 'SUP-RETURN',
    'ClientReturn': 'CLIENT-RETURN',
};


const mapTransferReason2DDTReason =  {
    Donation: 'Donazione',
    Disposal: 'Smaltimento',
    AttemptedSale: 'Tentata vendita',
    ConsignmentSales: 'Conto vendita',
    InternalTransfer: 'Trasferimento interno',
    Sale: 'Vendita',
    Complimentary: 'Omaggio',
    SaleWithDeferredInvoicing: 'Vendita con fatturazione differita',
    ConsumptionOfSpareParts: 'Consumo Materiale di ricambio',
    Repair: 'Riparazione',
    Return: 'Reso',
};

const fixedDDTReasonBasedOnTrasferType = {
    SupplierReturn: mapTransferReason2DDTReason.Return,
    ClientReturn: mapTransferReason2DDTReason.Return,
};

export function transferReason2DDTReason(transfer) {
    if(fixedDDTReasonBasedOnTrasferType[transfer.type]) {
        return fixedDDTReasonBasedOnTrasferType[transfer.type];
    } else if(mapTransferReason2DDTReason[transfer.reasonToTransfer]) {
        return mapTransferReason2DDTReason[transfer.reasonToTransfer];
    } else {
        return '';
    }
}
