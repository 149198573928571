import React from 'react';
import { Route } from 'react-router-dom';
import Unauthorized from './sign-in/Unauthorized';
import { CustomListPreview } from './resources/listPreview/CustomListPreview';
import InventoryWarehouseAccAccount from './resources/warehouse/accounting/account/Inventory';
import InventoryCompleteWarehouseAccAccount from './resources/warehouse/accounting/account/InventoryComplete';
import AddWarehouseAccAccount from './resources/warehouse/accounting/account/AddWarehouseAccAccount';
import BulkActionPhysicalItemTransferList from './resources/warehouse/bulkActionPhysicalItemTransfer/List'
import Picking from './resources/warehouse/transfer/Picking';
import PickingRequestShift from './resources/warehouse/transfer/PickingRequestShift';
import { MissionShow } from './resources/transaction/mission/rider/MissionShow';
import { TransferCreate, TransferEdit } from './resources/warehouse/transfer/v2';
import InventoryOptions from './resources/warehouse/accounting/account/InventoryOptions';
import LayoutList from './resources/transaction/mission/components/LayoutList';
import MissionJobCreate from './resources/ops/mission/job/JobCreate';
import MissionJobEdit from './resources/ops/mission/job/JobEdit';
import { DeliveryManTaskForStage } from './resources/ops/deliveryMan/missonJob/task/DeliveryManTaskForStage';
import ImportDataFromFoorban from './components/ImportDataFromFoorban';
import RecurringJobCreate from './resources/ops/mission/recurring/mission/job/RecurringJobCreate';
import RecurringJobEdit from './resources/ops/mission/recurring/mission/job/RecurringJobEdit';

export default [
    <Route exact path="/unauthorized" component={Unauthorized} noLayout />,
    <Route exact path="/list-preview/:resource/parent-resource/:parent/:id" component={CustomListPreview} />,
    <Route exact path="/inventory-complete/:id" component={InventoryCompleteWarehouseAccAccount} />,
    <Route exact path="/inventory/:id" component={InventoryWarehouseAccAccount} />,
    <Route exact path="/inventory-options/:id" component={InventoryOptions} />,
    <Route exact path="/create-warehouse-acc-account/:id" component={AddWarehouseAccAccount} />,
    <Route exact path="/bulk-action-physical-item-transfer" component={BulkActionPhysicalItemTransferList} />,
    <Route exact path="/picking/:id" component={Picking} />,
    <Route exact path="/picking/:id/request-shift" component={PickingRequestShift} />,
    <Route exact path="/mission_rider/:id/show" component={MissionShow} />,
    <Route exact path="/v2/transfer/create" component={TransferCreate} />,
    <Route exact path="/v2/transfer/:id/edit" component={TransferEdit} />,
    <Route exact path="/mission_rider/layout-list/:id/show" component={LayoutList} />,
    <Route exact path="/mission/job/create" component={MissionJobCreate} />,
    <Route exact path="/mission/job/:id/edit" component={MissionJobEdit} />,
    <Route exact path="/deliveryManMissionJob/tasks" component={DeliveryManTaskForStage} />,
    <Route exact path="/import-data-from-foorban" component={ImportDataFromFoorban} />,
    <Route exact path="/mission-recurring/job/create" component={RecurringJobCreate} />,
    <Route exact path="/mission-recurring/job/:id/edit" component={RecurringJobEdit} />,
];