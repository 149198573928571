import React from "react"
import { TextField } from 'react-admin';
import { ListPreview } from '../../../../layouts/ListPreview';
import { Permissions } from '../../../../permissions/permissions';
import { getColorForJobStatus } from './utils'
import { Typography } from '@material-ui/core';

export function  DeliveryManMissionJobList (props) {
    return (
        <ListPreview
            title={"Servizi Missione"}
            basePath = {`/${Permissions.deliveryManMissionJob.feature}`}
            resource = {`${Permissions.deliveryManMissionJob.feature}`}
            filterList={{ "missionId||eq": props.missionId}}
            hasCreateModal={false}
            rowClick={'show'}
            reactAdminListProps={{
                actions: false,
                pagination: false,
                bulkActionButtons: false,
            }}
            actionTitle={"Aggiungi"}
        >
            <TextField sortable={false} label='Tipologia' source="type" />
            <TextField sortable={false} label='Destinazione' source="destination.label" />
            <TextField sortable={false} label='Tempo stimato' source="durationMinutes" />
            <TextField sortable={false} label='commento' source="executionComment" />
            <Status label='Stato' source="status"></Status>
        </ListPreview>
    )
}
function Status (props) {
    if(props.record && props.record.status) {
        const backgroundColor = getColorForJobStatus(props.record.status);
        return  (
            <Typography
                style={{ backgroundColor: backgroundColor[300], textAlign: 'center'}}>
                {props.record.status}
            </Typography>
        )
    }
    return null
}