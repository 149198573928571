import React, { useState } from "react";
import { Button, Typography, AppBar, Toolbar, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';  
import VisibilityIcon from '@material-ui/icons/Visibility';
import { DDTTransfer } from '../../../../warehouse/ddt/DDTTransfer';

const useStyles = makeStyles((theme) => ({
    title: {
		flexGrow: 1
	},
	unLoadDDT: {
		marginLeft: '8px'
	}
}));

const ALLOWED_STATUS = ['delivering','completed']

export const DDTTask = (props) => {
    const { config } = props;
	const classes = useStyles();
	const [documentStatus, setDocumentStatus] = useState('HIDE')
    return (
        <Card>
			<AppBar color="default" position="static">
				<Toolbar>
					<Typography variant="h5" className={classes.title}>
						DDT
					</Typography>
					{documentStatus === 'HIDE'  && config.transfer && (
						(config.transfer.load && ALLOWED_STATUS.includes(config.transfer.load.transferStateMachine.status))
							||
						(config.transfer.unLoad && ALLOWED_STATUS.includes(config.transfer.unLoad.transferStateMachine.status))
					) && <Button
						size="small"
						onClick={() => {
							setDocumentStatus('SHOW')
						}}
						variant="contained"
						color="primary"
						startIcon={<VisibilityIcon />}>
						VISUALIZZA DDT
					</Button>}
					{documentStatus === 'SHOW'  && config.transfer.load && ALLOWED_STATUS.includes(config.transfer.load.transferStateMachine.status) && <DDTTransfer title="DDT CARICO" transferId={config.transfer.load.id} />}
					{documentStatus === 'SHOW'  && config.transfer.unLoad && ALLOWED_STATUS.includes(config.transfer.unLoad.transferStateMachine.status) && <div className={classes.unLoadDDT}><DDTTransfer title="DDT SCARICO" transferId={config.transfer.unLoad.id} /></div>}
				</Toolbar>
			</AppBar>
		</Card>
    )
}