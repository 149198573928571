import React, { useState, useEffect } from 'react';
import { Datagrid, List, TextField, TextInput, ReferenceField, AutocompleteArrayInput, useDataProvider, ReferenceInput, AutocompleteInput, DateField, NumberInput } from 'react-admin';
import Filter from '../../inheritance/BaseFilter';
import ListActions from '../../../components/ListActions';
import { Permissions } from '../../../permissions/permissions';
import { GET_OFF_RESOURCE } from '../../../providers/nestjs_crud';
import DateTimeFilter from '../../../components/DateTimeFilter';
const PurchaseOrderFilter = (props) => {
    const dataProvider = useDataProvider();
    const [dataStatus, setDataStatus] = useState([]);

    useEffect(() => {
        dataProvider(GET_OFF_RESOURCE,`${Permissions.purchaseOrder.feature}_${Permissions.purchaseOrder.subFeatures.orderStateMachine.name}` )
            .then(({data}) => {
                setDataStatus(Object.keys(data).map(statusKey => {
                    return {
                        id: data[statusKey],
                        name: data[statusKey].toUpperCase()
                    }
                }))
            });
    }, [dataProvider, setDataStatus]);

    return (
    <Filter {...props}>
        <NumberInput label="ID" source="id||eq" alwaysOn/>
        <TextInput label="Nome" source="name" alwaysOn />
        <DateTimeFilter 
          source={`created_at||gte`}
          label={`data di creazione Da`}
        />
        <DateTimeFilter 
          source={`created_at||lte`}
          label={`data di creazione A`}
        />
        <AutocompleteArrayInput
            label="Stato"
            source="orderStateMachine.status||in"
            choices={dataStatus}
            translateChoice={false}
            allowEmpty={true} />
        <ReferenceInput
            filterToQuery={searchText => {
                if(!searchText)
                    return {};
                return { "name": searchText }
            }}
            label="Punto di Acquisto"
            source="purchasePointId||eq"
            reference={Permissions.purchasePoint.feature}>
            <AutocompleteInput
                optionText="name"
                optionValue="id" />
        </ReferenceInput>
        <ReferenceInput
            filterToQuery={searchText => {
                if(!searchText)
                    return {};
                return { "name": searchText }
            }}
            label="Luogo di Consegna"
            source="placeId||eq"
            reference={Permissions.place.feature}>
            <AutocompleteInput
                optionText="name"
                optionValue="id" />
        </ReferenceInput>
        <ReferenceInput
            filterToQuery={searchText => {
                if(!searchText)
                    return {};
                return { "name": searchText }
            }}
            label="Fornitore"
            source="supplierId||eq"
            reference={Permissions.supplier.feature}>
            <AutocompleteInput
                optionText="name"
                optionValue="id" />
        </ReferenceInput>
        <DateTimeFilter 
          source={`expectedDeliveryDate||gte`}
          label={`data di consega Da`}
        />
        <DateTimeFilter 
          source={`expectedDeliveryDate||lte`}
          label={`data di consega A`}
        />
        <NumberInput label="Transaction ID" source="transactionId||eq" />
        <NumberInput label="transactionParentId" source="transactionParentId||eq"/>
    </Filter>
    );
}

export const PurchaseOrderList = props => (
    <List {...props} actions={<ListActions />}  sort={{ field: 'created_at', order: 'DESC' }} filters={<PurchaseOrderFilter />}>
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <TextField label="Nome" source="name" />
            <ReferenceField
				label="Punto di Acquisto"
				source="purchasePointId"
                reference={Permissions.purchasePoint.feature}
                link={"show"}>
				<TextField source="name" />
			</ReferenceField>
            <ReferenceField
				label="Luogo di Consegna"
				source="placeId"
                reference={Permissions.place.feature}
                link={"show"}>
				<TextField source="name" />
			</ReferenceField>
            <ReferenceField
				label="Fornitore"
				source="supplierId"
                reference={Permissions.supplier.feature}
                link={"show"}>
				<TextField source="name" />
			</ReferenceField>
            <TextField label="Stato Ordine" source="orderStateMachine.status" />
            <DateField
                label="Data di consegna prevista"
                source="expectedDeliveryDate"
            />
        </Datagrid>
    </List>
);
