import React from 'react';
import { InputLabel,Typography, Box } from '@material-ui/core';

export function Duration(props) {
	const seconds = props.duration
	const minutes = Math.floor(seconds / 60) % 60;
	const hours = Math.floor(seconds / 3600);
	const remainingSeconds = seconds % 60;

	return (
		<Box style={{ marginBottom: '0.5em' }}>
			<InputLabel >
				<Typography variant='caption'>{props.label}</Typography>
			</InputLabel>
			<Typography variant='subtitle2' >{`${hours > 0 ? hours + 'h' : ''} ${minutes > 0 ? minutes + 'm' : ''} ${remainingSeconds}s`}</Typography>
		</Box> 
	);
}