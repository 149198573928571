import React, { useState } from 'react';
import { Fragment } from 'react';
import {ReferenceInput, useDataProvider, SelectInput } from 'react-admin';
import { useSelector } from 'react-redux';
import { Permissions } from '../../../../../permissions/permissions';
import { useForm } from 'react-final-form';

import { ConvertUOM } from './HelperState';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    form: {
        display: 'flex',            
        flexGrow: 'colums',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
});

export default ({ formData, ...rest }) => {
    const form = useForm();
    const dataProvider = useDataProvider();
    const classes = useStyles();

    const supplierPriceLists = useSelector(state => state.admin.resources.supplierPriceList.data);
    const [supplierPriceListId, setSupplierPriceListId] = useState(0);

    if(!formData.supplierPriceListId)
        return null;

    if(supplierPriceListId === 0) {
        setSupplierPriceListId(formData.supplierPriceListId);
    }
    else if(supplierPriceListId !== 0 && formData.supplierPriceListId !== supplierPriceListId) {
        formData.costUnitId = undefined;
        formData.orderUnitId = undefined;
        setSupplierPriceListId(formData.supplierPriceListId);

        return null;
    }

    if(!supplierPriceLists[supplierPriceListId])
        return null;

    return (
        <Fragment>
            <div className={classes.form}>
            <ReferenceInput
                {...rest}
                style={{width: "100%"}}
                initialValue={supplierPriceLists[supplierPriceListId].orderUnitId}
                label="unità di misura fabbisogno"
                source="orderUnitId"
                filter={{"itemId||eq": supplierPriceLists[supplierPriceListId].itemPurchasable.item.id}}
                reference={`${Permissions.unitOfMeasurement.feature}`}
                onChange={() => {
                    ConvertUOM(form, dataProvider);
                }}
                >
                <SelectInput optionText={(record)=> record ? `${record.name} ${record.default ? ' - (default)' : ''}` : ''} />
            </ReferenceInput>
            <ReferenceInput
                {...rest}
                style={{width: "100%"}}
                fullWidth
                initialValue={supplierPriceLists[supplierPriceListId].costUnitId}
                label="unità di misura prezzo"
                source="costUnitId"
                filter={{"itemId||eq": supplierPriceLists[supplierPriceListId].itemPurchasable.item.id}}
                reference={`${Permissions.unitOfMeasurement.feature}`}
                onChange={() => {
                    ConvertUOM(form, dataProvider);
                }}
                >
                    <SelectInput optionText={(record)=> record ? `${record.name} ${record.default ? ' - (default)' : ''}` : ''} />
            </ReferenceInput>
            </div>
        </Fragment>
    );
};