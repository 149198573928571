import React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';

export function ForecastCategoryCreate(props) {
    return (
        <Create {...props} >
            <SimpleForm redirect="list">
                <TextInput lable="Name" source="name" validate={[required()]}/>
                <BaseCreate />
            </SimpleForm>
        </Create>
    )
};