import React from 'react';
import { Create, SimpleForm, TextInput,  NumberInput, BooleanInput, FormDataConsumer, AutocompleteInput } from 'react-admin';
import BaseCreate from '../../inheritance/BaseCreate';
import { required } from 'react-admin';
import { STORAGE_TYPE } from './enum';
export const ItemStorableCreate = props => {
    return (
	<Create {...props}>
		<SimpleForm redirect={props.redirect}>
			<BooleanInput fullWidth defaultValue={false} label="Has Shelf Life" source="hasShelfLife" helperText="Non modificabile dopo la creazione"/>
			<FormDataConsumer>
				{({ formData, ...rest }) => {
					if(!formData.hasShelfLife) {
						return;
                    }
                
                    return (
						<NumberInput
                        fullWidth
						label="Shelf Life minima"
						source="minimumShelfLife"
						helperText="Shelf Life minima prevista alla consegna in giorni (only if has shelf life)"
						validate={[required('Indicare una Shelf Life minima è obbligatorio')]} />
					);
				}}
			</FormDataConsumer>
			<BooleanInput
				label="Lottizzabile"
				source="isPlottable" />
			<NumberInput
                fullWidth
				label="Volume di una unità di riferimento in dm^3"
				source="volume"
				validate={[required('Indicare un volume è obbligatorio')]} />
			<NumberInput
                fullWidth
				label="Peso in KG di una unità di riferimento"
				source="weight"
				validate={[required('Indicare un peso è obbligatorio')]} />
			<AutocompleteInput label="Conservazione predefinita" source="storageType" options={{suggestionsContainerProps: {disablePortal: true}}} validate={[required('Indicare la conservazione è obbligatorio')]} choices={STORAGE_TYPE.map(storageType => {
                return {
					id: storageType,
					name: storageType
				}
			})} />
			<BooleanInput label="Utilizzabile" source="usable"  initialValue={true} />
			<TextInput fullWidth label="Nota" source="note" />

			<div style={{display: 'none' }}>
				<NumberInput disabled source="itemId" initialValue={props.itemId} />
			</div>
			<NumberInput
				label="Ordine Inventario"
				source="inventoryOrder"
				validate={[required('campo obbligatorio')]}
			/>
			<NumberInput
				label="Valore"
				source="value"
				validate={[required('campo obbligatorio')]} />
			<NumberInput
				fullWidth
				initialValue={0}
				source="warehouseCycleCountingDays"
			></NumberInput>
			<NumberInput
				fullWidth
				initialValue={0}
				source="missionCycleCountingDays"
			></NumberInput>
			<NumberInput
				fullWidth
				initialValue={100}
				source="widthInmm"
			></NumberInput>
			<NumberInput
                fullWidth
				initialValue={100}
				source="depthtInmm"
			></NumberInput>
			<NumberInput
                fullWidth
				initialValue={100}
				source="heightInmm"
			></NumberInput>
			<NumberInput
                fullWidth
				initialValue={1}
				source="maximumNumberStackableItems"
			></NumberInput>
			<BaseCreate />
		</SimpleForm>
	</Create>
);}