import React from 'react';
import { Show, SimpleShowLayout, ReferenceField, TextField, NumberField } from 'react-admin';
import { Permissions } from '../../../permissions/permissions';
import StateMachineReferenceField from '../../../components/StateMachineReferenceField';

export const CoffeeSellingOrderShow = (props) => {
	return (
	<Show {...props}>
		<SimpleShowLayout>
			<StateMachineReferenceField
				label="Stato"
				source="orderStateMachine"
				reference={`${Permissions.coffeeSellingOrder.feature}_${Permissions.coffeeSellingOrder.subFeatures.orderStateMachine.name}`}>
			</StateMachineReferenceField>
			<ReferenceField label="Utente" source="b2cUserId" reference={`${Permissions.B2CUser.feature}`} link="show">
				<TextField source="email" />
			</ReferenceField>
			<TextField label="CorporateCredit" source="bulkCorporateCreditId" />
			<TextField label="WalletTransfer" source="bulkWalletTransferId" />
			<NumberField options={{ style: 'currency', currency: 'EUR' }} label="Dal Buono Aziendale" source="totalToPayFromCorporateCredit" />
			<NumberField options={{ style: 'currency', currency: 'EUR' }} label="Dal Wallet" source="totalToPayFromWallet" />
		</SimpleShowLayout>
	</Show>
)};
