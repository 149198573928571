import React from 'react';
import { SimpleShowLayout, ShowController, Loading, ShowView } from 'react-admin';
import Aside from '../../inheritance/BaseAsideWithUser';
import { Permissions } from '../../../permissions/permissions';
import { Grid } from '@material-ui/core';
import { WarehouseInventoryItemsList } from './warehouseInventoryItem/WarehouseInventoryItemList';
import { NoApiCallReferenceField, NoApiCallReferenceFieldSateMachine } from '../../../components/NoApiCallReferenceField';

export function WarehouseInventoryShow(props) {
    return (
      <ShowController {...props}>
        {controllerProps => !controllerProps.loaded ? <Loading /> :
          <Grid container spacing={3}>
            <Grid item xl={12} lg={12} md={12} xs={12}>
              <ShowView {...props} {...controllerProps} aside={<Aside props={props}></Aside>} >
                <SimpleShowLayout>
                  <NoApiCallReferenceField
                    data={controllerProps.record.location}
                    label="Location di Magazzino"
                    reference={Permissions.warehouseLocation.feature}
                    referenceIdentifier="id"
                    source="name" />
                  <NoApiCallReferenceFieldSateMachine
                    data={controllerProps.record.warehouseInventoryStateMachine}
                    label="Stato"
                    reference={`${Permissions.warehouseInventory.feature}_${Permissions.warehouseInventory.subFeatures.warehouseInventoryStateMachine.name}`}
                    referenceIdentifier="id"
                    source="status" />
                </SimpleShowLayout>
              </ShowView>
            </Grid>
            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
              <WarehouseInventoryItemsList id={props.id} />
            </Grid>            
          </Grid>
        }
      </ShowController>
    );
};

