import React from 'react';
import { Show, SimpleShowLayout, TextField, BooleanField, NumberField, ReferenceField } from 'react-admin';
import Aside from '../inheritance/BaseAside';
import { TimeSlotCalendarField } from '../timeSlotRule/TimeSlotCalendarField';
import { Permissions } from '../../permissions/permissions';
import { CustomArrayField } from '../../components/CustomArrayField';

export const PlaceShow = (props) => {
    return (
    <Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Nome" source="name" />
            <TextField label="Indirizzo" source="address" />
            <BooleanField label="Può acquistare" source="canPurchase" /> 
            <BooleanField label="Può vendere" source="canSell" />
            <NumberField label="Totale visitatori" source="totalAudience" />
            <NumberField label="Numero medio di visitatori giornalieri" source="avgDailyAudience" /> 
            <ReferenceField sortable={false} label="Hub" source="hubId" reference={`${Permissions.hub.feature}`} link='show'>
				<TextField source="name" />
			</ReferenceField>
            <ReferenceField  label="Cliente B2B" source="b2bClientId" reference={`${Permissions.B2BClient.feature}`} link='show'>
				<TextField source="name" />
			</ReferenceField>
            <TextField label="Note per corriere" source="deliveryManNote" />
            <CustomArrayField label="Skills" source="skills" subLabel="name" reference={Permissions.deliveryManSkill.feature} />
            <TimeSlotCalendarField />
        </SimpleShowLayout>
    </Show>
)};
