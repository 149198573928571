import React from 'react';
import { Datagrid, List, TextField, NumberField, TextInput } from 'react-admin';
import Filter from '../inheritance/BaseFilter';
import ListActions from '../../components/ListActions';

const ClientCategoryFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nome Interno" source="internalName" alwaysOn />
        <TextInput label="Nome Cliente" source="clientName" alwaysOn />
    </Filter>
);

export const ClientCategoryList = props => (
    <List {...props} actions={<ListActions />}  filters={<ClientCategoryFilter />} >
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <TextField label="Nome Interno" source="internalName" />
            <TextField label="Nome Cliente" source="clientName" />
            <NumberField label="Ordine" source="order" />
        </Datagrid>
    </List>
);