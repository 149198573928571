import React from 'react';
import { Page, Document, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import Row from './Row';
import logo from './logo.png';
import { transferReason2DDTReason } from '../../transfer'
const styles = StyleSheet.create({
  body: {
    padding: 10
  },
  title: {
    fontSize: 10,
    fontWeight: 500,
    textAlign: 'right',
    paddingTop: 5
  },
  text: {
    fontSize: 10
  },
  textTitle: {
    fontSize: 11,
    fontWeight: 'bold',
    marginBottom: 3
  },
  textRow: {
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 15
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    paddingBottom: 10
  },
  textRowContent: {
    flex: 1
  },
  logo: {
    width: 100,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  textTitle2: {
    fontSize: 11,
    fontWeight: 'bold',
    marginTop: 10
  },
  textSign: {
    fontSize: 10,
    marginTop: 10
  },
  textTitle3: {
    fontSize: 11,
    fontWeight: 'bold',
    marginTop: 20
  }
});

export default (props) => {
  let reasonToTransfer = transferReason2DDTReason(props.data.transfer);

  const expectedDate = new Intl.DateTimeFormat('it', { year: 'numeric',  month: 'numeric', day: 'numeric'}).format(new Date(props.data.created_at));
  return (
  <Document>
    <Page style={styles.body}>
      <View style={styles.row}>
        <View style={styles.textRowContent}>
          <Image style={styles.logo} src={logo} />
        </View>
        <View style={styles.textRowContent}>
          <Text style={styles.title}>DOCUMENTO DI TRASPORTO</Text>
        </View>
      </View>
      <View style={styles.textRow}>
          <View style={styles.textRowContent}>
              <Text style={styles.text}>
              Foorban Srl
              </Text>
              <Text style={styles.text}>
              P.zza Castello 26
              </Text>
              <Text style={styles.text}>
              20121 Milano (MI)
              </Text>
          </View>
          <View style={styles.textRowContent}>
            <Text style={styles.text}>
            Numero Documento di Trasporto: {props.data.sequenceNumber}
            </Text>
            <Text style={styles.text}>
            Data di inizio trasporto: {expectedDate}
            </Text>
            <Text style={styles.text}>
            Causale di trasporto: {reasonToTransfer}
            </Text>
          </View>
      </View>
      <View style={styles.textRow}>
          <View style={styles.textRowContent}>
              <Text style={styles.textTitle}>
                Cedente: {props.data.snapshot.transferorData.label}
              </Text>
              <Text style={styles.text}>
                P.IVA: {props.data.snapshot.transferorData.vatNumber}
              </Text>
              <Text style={styles.textTitle2}>
                Luogo di origine:
              </Text>
              <Text style={styles.text}>
                {props.data.snapshot.placeFromData.address}
              </Text>
          </View>
          <View style={styles.textRowContent}>
            <Text style={styles.textTitle}>
                Cessionario: {props.data.snapshot.transfereeData.label}
              </Text>
              <Text style={styles.text}>
                P.IVA: {props.data.snapshot.transfereeData.vatNumber}
              </Text>
              <Text style={styles.textTitle2}>
                Luogo di destinazione:
              </Text>
              <Text style={styles.text}>
                {props.data.snapshot.placeToData.address}
              </Text>
          </View>
      </View>
      <View style={styles.textRow}>
        <Text style={styles.textTitle}>
            Aspetto merce: Sfusa
          </Text>
      </View>
      <Row data={props.data.snapshot.rows} totalWeight={props.data.snapshot.totalWeight} />
      <View style={styles.textRow}>
          <View style={styles.textRowContent}>
              <Text style={styles.textTitle}>
                Vettore: {props.data.snapshot.deliveryData.label}
              </Text>
              <Text style={styles.text}>
                P.IVA: {props.data.snapshot.deliveryData.vatNumber}
              </Text>
              <Text style={styles.textTitle3}>
                Firma conducente
              </Text>
              <Text style={styles.textSign}>
                ___________________________
              </Text>
          </View>
          <View style={styles.textRowContent}>
              <Text style={styles.textTitle}>
                Firma
              </Text>
              <Text style={styles.textSign}>
              ___________________________
              </Text>
              <Text style={styles.textTitle2}>
                Firma destinatario
              </Text>
              <Text style={styles.textSign}>
              ___________________________
              </Text>
          </View>
      </View>
    </Page>
  </Document>
);}

