import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput } from 'react-admin';
import BaseEdit from '../inheritance/BaseEdit';
import { required } from 'react-admin';

export const DomainEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="Nome" source="name" validate={[required('campo obbligatorio')]} />
			<TextInput label="Dominio" source="regex" validate={[required('campo obbligatorio')]} />
			<SelectInput label="Tipo" source="type" choices={[
				{ id: 'google', name: 'Google' },
				{ id: 'microsoft', name: 'Microsoft' },
				{ id: 'email', name: 'Email' }
			]} validate={[required('campo obbligatorio')]} />
            <BaseEdit />
        </SimpleForm>
    </Edit>
);