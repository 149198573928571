import React from 'react';
import { Datagrid, List, TextField, EditButton, ShowButton, DateField, TextInput, SelectInput } from 'react-admin';
import Filter from '../inheritance/BaseFilter';
import ListActions from './components/ListActions';
import Button from '@material-ui/core/Button';
import SettingsIcon from '@material-ui/icons/Settings';
import { BulkDeleteButton, useDataProvider, usePermissions, useNotify, useRefresh } from 'react-admin';
import { useHistory } from "react-router-dom";
import { POST_OFF_RESOURCE } from "../../providers/nestjs_crud";
import { Permissions } from "../../permissions/permissions";
import DangerousIcon from "@material-ui/icons/Warning";

function PromotionFilter(props) {
	return (
		<Filter {...props}>
			<TextInput label="Nome" source="name" alwaysOn />
			<TextInput label="Label" source="label" alwaysOn />
			<SelectInput emptyText="X remove" label="Enable" source="enable" 
			choices={[
				{ id: 'ENABLED', name: 'ENABLED' },
				{ id: 'DISABLED', name: 'DISABLED' },							
			]}  alwaysOn />
			<SelectInput emptyText="X remove" label="Type" source="type" 
			choices={[
				{ id: 'Percentage', name: 'Percentage' },
				{ id: 'New Price', name: 'New Price' },							
			]}  alwaysOn />
		</Filter>
	);
}

function NonUndoableBulkActionButtons(props) {
    return (
        <React.Fragment>
        {props.buttons}
        <BulkDeleteButton {...props} undoable={false} />
        </React.Fragment>
    );
}
export function PromotionList(props) {
	return (
		<List
			{...props}
			bulkActionButtons={<NonUndoableBulkActionButtons buttons={props.bulkActionButtons} />}
			sort={{ field: 'created_at', order: 'DESC' }} actions={<ListActions />}  filters={<PromotionFilter />}>
			<Datagrid rowClick={null}>
				<TextField source="enable" />
				<TextField source="status" />
				<TextField source="name" />
				<TextField source="type" />
				<TextField source="value" />
				<TextField source="label" />
				<DateField source="created_at" />
				<ButtonList />
				<EditButton></EditButton>
				<ShowButton></ShowButton>
        <EnableDangerous />
			</Datagrid>
		</List>
	);
}

function ButtonList({ record = {} }) {
    let history = useHistory();

    return (
        <Button variant="contained" color="primary" endIcon={<SettingsIcon/>} onClick={() => {
            history.push(`/condition?filter={"promotionId||eq":"${record.id}"}`);
        }}>Condizioni</Button>
    );
}

function EnableDangerous({ record = {} }) {

  const dataProvider = useDataProvider();
  const notify = useNotify();
  let { permissions } = usePermissions();
  const refresh = useRefresh();

  if(record.enable === 'DISABLED' && permissions &&
    permissions.includes(
      `${Permissions.promotion.feature}-${Permissions.promotion.actions.enableDangerous}`
    )) {
      return (
      <Button
        variant="text"
        color="primary"
        endIcon={<DangerousIcon />}
        onClick={() => {
          var confirm = window.confirm("Sei sicuro di voler abilitare questa promozione?");
          if (confirm) {
            dataProvider(
              POST_OFF_RESOURCE,
              `${Permissions.promotion.feature}/${Permissions.promotion.actions.enableDangerous}/${record.id}`,
              {
                data: {},
              }
            )
              .then(({ data }) => {
                notify(`Promozione abilitata`);
                refresh()
              })
              .catch((error) => {
                alert(error);
              });
          }
        }}
      >
        Abilita
      </Button>
    );
  }
  return null;
}