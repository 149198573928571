import React from 'react';
import { Datagrid, List, TextField, EditButton, ShowButton, TextInput } from 'react-admin';
import Filter from '../../inheritance/BaseFilter';
import { BulkDeleteButton } from 'react-admin';

function CoffeeTypeFilter(props) {
	return (
		<Filter {...props}>
			<TextInput source="externalName" alwaysOn />
			<TextInput source="internalName" alwaysOn />
		</Filter>
	);
}

function NonUndoableBulkActionButtons(props) {
    return (
        <React.Fragment>
        	{props.buttons}
        	<BulkDeleteButton {...props} undoable={false} />
        </React.Fragment>
    );
}
export function SolubleTypeList(props) {
	return (
		<List
			{...props}
			bulkActionButtons={<NonUndoableBulkActionButtons buttons={props.bulkActionButtons} />}
			sort={{ field: 'created_at', order: 'DESC' }} filters={<CoffeeTypeFilter />}>
			<Datagrid rowClick={null}>
				<TextField source="externalName" />
				<TextField source="internalName" />
				<EditButton></EditButton>
				<ShowButton></ShowButton>
			</Datagrid>
		</List>
	);
}
