import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField  } from 'react-admin';
import Aside from '../inheritance/BaseAside';
import { Permissions } from '../../permissions/permissions';
import { Button, Typography, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { TimeSlotCalendarField } from '../timeSlotRule/TimeSlotCalendarField';
import { CustomArrayField } from '../../components/CustomArrayField';
export const DeliveryManShow = (props) => {
    return (
        <Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
            <SimpleShowLayout>
                <TextField lable="hub" source="hub.name" />
                <DateField label="Data assunzione" source="assumptionDate" />
                <DateField label="Data scadenza contratto" source="contractExpiryDate" />
                <CustomArrayField label="Skills" source="skills" subLabel="name" reference={Permissions.deliveryManSkill.feature} />
                <MainUser />
                <Planner />
                <TimeSlotCalendarField />
            </SimpleShowLayout>
        </Show>
    )
};

function MainUser(props) {
    if (!props.record.mainUserId || !props.record.mainUser) {
        return null;
    }

    return (
        <Box marginTop={1}>
            <Typography style={{width: '100%'}} variant="subtitle2" gutterBottom>Corriere</Typography>
            <Button
                variant="contained"
                color="primary"
                component={Link}
                to={{ pathname: `/${Permissions.mainUser.feature}/${props.record.mainUserId}/show` }}>{props.record.mainUser.label}</Button>
        </Box>
    );
}

function Planner(props) {
    if (!props.record.plannerMainUserId || !props.record.plannerMainUser) {
        return null;
    }
    return (
        <Box marginTop={1}>
            <Typography style={{width: '100%'}} variant="subtitle2" gutterBottom>Planner</Typography>
            <Button
                variant="contained"
                color="primary"
                component={Link}
                to={{ pathname: `/${Permissions.mainUser.feature}/${props.record.plannerMainUserId}/show` }}>{props.record.plannerMainUser.label}</Button>
        </Box>
    );
}