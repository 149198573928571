import React from 'react';
import PropTypes from 'prop-types';
import MALink from '@material-ui/core/Link';
import InputLabel from '@material-ui/core/InputLabel';
import { Link } from 'react-router-dom';

const StateMachineReferenceField = ({ source, record = {}, reference, label}) => {
    return (
        <div>
            <InputLabel>
                {label}
            </InputLabel>
            <MALink
                component={Link}
                variant="body2"
                to={`/${reference}/${record[source] ? record[source].id : ''}/show`}
            >
            {record[source] ? record[source].status : '-'}
            </MALink>
        </div>
      );
};

StateMachineReferenceField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired
};

export default StateMachineReferenceField;