import React from 'react';
import { Create, SimpleForm, TextInput,AutocompleteInput } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { required } from 'react-admin';
import { FILES_TYPE } from './';

export const FileCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<AutocompleteInput validate={[required('campo obbligatorio')]} label="Tipologia" source="type" choices={FILES_TYPE.map(type => {
					return {
						id: type,
						name: type
					}
			})} />
			<TextInput label="Titolo" source="title" validate={[required('campo obbligatorio')]} />
			<TextInput label="Alt" source="alt" validate={[required('campo obbligatorio')]} />
			<BaseCreate />
		</SimpleForm>
	</Create>
);