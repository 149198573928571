import { FileEdit } from "./FileEdit";
import { FileList } from "./FileList";
import { FileCreate } from "./FileCreate";
import { FileShow } from "./FileShow";
export const FILES_TYPE = ['image', 'pdf'];

export const FILES_TYPE_TO_ACCEPT = {
    image : '.jpg,.jpeg,.png',
    pdf : '.pdf'
};

export const FILE_TYPE_TO_EXTENSION = {
    "image/png": '.png',
    "image/jpg": '.jpg',
    "image/jpeg": '.jpeg',
    "application/pdf": '.pdf'
}
export default {edit:FileEdit, list:FileList, create:FileCreate, show: FileShow};