import React from 'react';
import { StyleSheet, View, Text } from '@react-pdf/renderer';
    
const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 40
const COLN_WIDTH = (100 - COL1_WIDTH) / 3;

const styles = StyleSheet.create({
  table: { 
    display: "table", 
    width: "auto", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableRowHead: {
    margin: "auto", 
    flexDirection: "row",
    backgroundColor: 'rgb(242, 242, 242)',
    fontStyle: 'bold',
  }, 
  tableRowFooter: { 
    margin: "auto", 
    flexDirection: "row",
    backgroundColor: 'rgb(242, 242, 242)',
  }, 
  tableCol1Header: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },     
  tableColHeader: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },   
  tableCol1: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },   
  tableCol: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCol5: { 
    width: ((COLN_WIDTH * 4) + COL1_WIDTH) + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCellHeader: {
    margin: 5, 
    fontSize: 9,
    fontWeight: 500
  },  
  tableCell: { 
    margin: 5, 
    fontSize: 8 
  },
  tableCellFooter: {
    margin: 5, 
    fontSize: 9,
    fontWeight: 500
  },  
  right: {
    textAlign: 'right'
  }
});

export default (props) => (
      <View style={styles.table}> 
        <View style={styles.tableRowHead}> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Codice fornitore</Text> 
          </View> 
          <View style={styles.tableCol1Header}> 
            <Text style={styles.tableCellHeader}>Referenza fornitore	</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Unità di misura	</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Quantità</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Prezzo unitario	</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Sconto/Aggiustamento</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Totale</Text> 
          </View> 
        </View>
        
        {props.purchaseOrder.purchaseOrderRows
        .filter(purchaseOrderRow => {
          return purchaseOrderRow.active;
        })
        .map((purchaseOrderRow) => <View key={purchaseOrderRow.id} style={styles.tableRow}>
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>{purchaseOrderRow.supplierCode}</Text> 
          </View> 
          <View style={styles.tableCol1}> 
            <Text style={styles.tableCell}>{purchaseOrderRow.supplierReference}</Text> 
          </View> 
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{purchaseOrderRow.costUnitName}</Text> 
          </View>
          <View style={[styles.tableCol, styles.right]}> 
            <Text style={styles.tableCell}>{purchaseOrderRow.quantityConfirmedInPriceUnit}</Text> 
          </View> 
          <View style={[styles.tableCol, styles.right]}> 
            <Text style={styles.tableCell}>{purchaseOrderRow.unitPriceNoTax}</Text> 
          </View> 
          <View style={[styles.tableCol, styles.right]}>
            <Text style={styles.tableCell}>{purchaseOrderRow.adjustmentNoTax}</Text> 
          </View>
          <View style={[styles.tableCol, styles.right]}> 
            <Text style={styles.tableCell}>{purchaseOrderRow.totalNoTax}</Text> 
          </View> 
        </View>)}
  
        <View style={styles.tableRowFooter}> 
          <View style={styles.tableCol5}> 
          </View> 
          <View style={styles.tableCol}>
            <Text style={styles.tableCellFooter}>Sub-Totale</Text> 
          </View>
          <View style={[styles.tableCol, styles.right]}> 
            <Text style={styles.tableCellFooter}>{props.purchaseOrder.totalNoTax}</Text> 
          </View> 
        </View>
        <View style={styles.tableRowFooter}> 
          <View style={styles.tableCol5}> 
          </View> 
          <View style={styles.tableCol}>
            <Text style={styles.tableCellFooter}>Tasse</Text> 
          </View>
          <View style={[styles.tableCol, styles.right]}> 
            <Text style={styles.tableCellFooter}>{props.purchaseOrder.totalTax}</Text> 
          </View> 
        </View>
        <View style={styles.tableRowFooter}> 
          <View style={styles.tableCol5}> 
          </View> 
          <View style={styles.tableCol}>
            <Text style={styles.tableCellFooter}>Totale</Text> 
          </View>
          <View style={[styles.tableCol, styles.right]} > 
            <Text style={styles.tableCellFooter}>{props.purchaseOrder.totalWithTax}</Text> 
          </View> 
        </View>        
      </View>
);

