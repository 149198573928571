import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import BaseCreate from '../../../inheritance/BaseCreate';
import { required } from 'react-admin';
import Input, { ReferenceInputType, TextInputType, ReferenceInputChoicesType} from '../components/Input';
import { Permissions, TransferActions } from '../../../../permissions/permissions';
import { TransferItemTypeItem, TransferItemTypeLot } from './type';
import NewInputSelectRest, { AutocompleteInputType, TextInputType as CommonTextInputType } from '../commonComponents/InputRest';

const fieldOptions = `${Permissions.transfer.feature}/${Permissions.requestedItemTransfer.feature}/${TransferActions.fieldOptions}`;

export const RequestedItemTransferCreate = props => {
	return (
		<Create
			basePath={`/${Permissions.transfer.feature}_${Permissions.requestedItemTransfer.feature}`}
			resource={`${Permissions.transfer.feature}_${Permissions.requestedItemTransfer.feature}`}
			>
			<SimpleForm redirect={props.redirect}>
				<div style={{ display: 'none' }}>
					<TextInput initialValue={props.transactionParentId} source={'transactionParentId'} />
				</div>
				<NewInputSelectRest
					type={AutocompleteInputType}
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							}
						}
					}
					fieldOptions={fieldOptions}
					label="Tipo di Trasferimento"
					source="transferType"
					dependOn="transactionParentId"
					validate={[required('campo obbligatorio')]} />
				<NewInputSelectRest
					type={AutocompleteInputType}
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							}
						}
					}
					fieldOptions={fieldOptions}
					label="Tipo di Oggetto da trasferire"
					source="transferItemType"
					dependOn="transferType"
					validate={[required('campo obbligatorio')]} />
				<Input
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							}
						}
					}
					dependOn="transferItemType"
					dependOnValues={[TransferItemTypeItem, TransferItemTypeLot]}
					type={ReferenceInputType}
					validate={[required('campo obbligatorio')]}
					filterToQuery={searchText => {
						if(!searchText)
							return null;

						return {
							"name": searchText,
							"storable": true
						}
					}}
					label="Item"
					source="itemId"
					reference={`${Permissions.item.feature}`}
					shouldRenderSuggestions={val => val && val.length >= 2 }
					optionText={(record)=> `${record ? record.id : ''} ${record ? record.name : ''}`}
					optionValue="id"
				/>
				<Input
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							}
						}
					}
					dependsOnValues={{
						transferItemType: [TransferItemTypeLot],
						itemId: []
					}}
					type={ReferenceInputType}
					validate={[required('campo obbligatorio')]}
					filterToQuery={(searchText, props) => {
						if(!searchText)
							return null;

						return {
							"name": searchText,
							"itemId||eq": props.formData.itemId
						}
					}}
					label="Lotto"
					source="lotId"
					reference={`${Permissions.lot.feature}`}
					shouldRenderSuggestions={val => val && val.length >= 2 }
					optionText={(record) => {
                        if (!record || !record.id) {
							return '';
						}

						let optionText = `${record.name} - ${record.id}`;
						
						if (record.compliantStatus === 'not-compliant') {
							optionText += ' - non conforme';
						}
						
						return optionText;
                    }}
				/>
				<Input
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							}
						}
					}
					dependsOnValues={{
						itemId: []
					}}
					type={ReferenceInputChoicesType}
					validate={[required('campo obbligatorio')]}
					filterToQuery={(props) => {
						return {
							"itemId||eq": props.formData.itemId
						}
					}}
					label="UoM Input"
					source="uomInputId"
					reference={`${Permissions.unitOfMeasurement.feature}`}
					shouldRenderSuggestions={val => val && val.length >= 2 }
					optionText="name"
					optionValue="id"
				/>
				<Input
					dependsOnValues={{
						uomInputId: []
					}}
					type={TextInputType}
					validate={[required('campo obbligatorio')]}
					label="Quantità Input"
					source="quantityInput"
				/>
				<NewInputSelectRest
					type={CommonTextInputType}
					fieldOptions={fieldOptions}
					label="Nome transazione" source="transactionName" dependOn="transferType" />
				<NewInputSelectRest
					type={CommonTextInputType}
					fieldOptions={fieldOptions}
					label="Quantità in unità documento collegato" source="quantityDocumentLinked" dependOn="transferType" />
				<NewInputSelectRest
					type={CommonTextInputType}
					fieldOptions={fieldOptions}
					label="Unità di misura documento collegato" source="uomDocumentLinked" dependOn="transferType" />
				<NewInputSelectRest
					type={CommonTextInputType}
					fieldOptions={fieldOptions}
					label="Valore" source="value" dependOn="transferType" />
				<BaseCreate />
			</SimpleForm>
		</Create>
	);
};