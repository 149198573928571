import React, { useState, useEffect } from 'react';
import { TextInput, Edit, SimpleForm, AutocompleteInput, useDataProvider, Loading, FormDataConsumer } from 'react-admin';
import BaseEdit from '../../../inheritance/BaseEdit';
import { required } from 'react-admin';
import { Permissions } from '../../../../permissions/permissions';
import { Typography } from '@material-ui/core';
import { GET_OFF_RESOURCE } from '../../../../providers/nestjs_crud';
import { Form } from './components/Form';

const STATUS_FETCH_DATA = "FETCH_DATA";
const STATUS_FETCHING_DATA = "STATUS_FETCHING_DATA";
const STATUS_DATA_FETCHED = "STATUS_DATA_FETCHED";
const STATUS_ERROR_DATA_FETCHED = "STATUS_ERROR_DATA_FETCHED";

export function WalletAccountTypeEdit(props) {
	const dataProvider = useDataProvider();
	const [config, setConfig] = useState(null);
	const [status, setStatus] = useState(STATUS_FETCH_DATA);

	useEffect(() => {
		if(status === STATUS_FETCH_DATA) {
			setStatus(STATUS_FETCHING_DATA)
			dataProvider(GET_OFF_RESOURCE,`${Permissions.walletAccAccountType.feature}_${Permissions.walletAccAccountType.subFeatures.config.name}` )
				.then(({data}) => {
					setStatus(STATUS_DATA_FETCHED);
					setConfig(data);
				})
				.catch(e => {
					setStatus(STATUS_ERROR_DATA_FETCHED)
				})
		}	
	}, [status, dataProvider, setConfig, setStatus]);

	if(status === STATUS_ERROR_DATA_FETCHED) { return <Typography variant="h6">Errore</Typography>};

	if(status === STATUS_FETCHING_DATA) return <Loading />;

	if(status === STATUS_DATA_FETCHED && config !== null && config !== undefined) {
		return (
			<Edit {...props}>
				<SimpleForm redirect="list">
					<TextInput fullWidth source="name" validate={[required('campo obbligatorio')]} />
					<AutocompleteInput options={{ disabled: true }} validate={[required('campo obbligatorio')]} source="accountCategory" choices={Object.keys(config).map(key => {
						return {
							id: key,
							name: config[key].name
						}
					})} />
					<FormDataConsumer>
						{(form) => <Form
							config={config}
							{...form}
							{...props}/>
						}
					</FormDataConsumer>
					<BaseEdit />
				</SimpleForm>
			</Edit>
		);
	}

	return null;
}
