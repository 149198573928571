const CustomIntegrationTypes = [
    'braintree_cc',
    'pellegrini_ticket',
    'paypal_account',
    'edenred_ticket',
    'vuoto',
    'sodexo_ticket',
    'satispay_standard',
];

export const integrationTypes = CustomIntegrationTypes.map((integrationType) => {
    return {
        id: integrationType,
        name: integrationType,
    }
});

const CustomProviderTypes = [
    'visa',
    'amex',
    'mastercard',
    'pellegrini',
    'edenred',
    'sodexo',
    'vuoto',
    'paypal',
    'satispay',
];

export const customProviderTypes = CustomProviderTypes.map((providerTypes) => {
    return {
        id: providerTypes,
        name: providerTypes,
    }
});

const CustomCategoryTypes = ['cc', 'ticket', 'vuoto', 'account'];

export const categoryTypes = CustomCategoryTypes.map((categoryType) => {
    return {
        id: categoryType,
        name: categoryType,
    }
});