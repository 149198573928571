import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField, NumberField } from 'react-admin';
import Aside from '../../../inheritance/BaseAside';
import { Permissions } from '../../../../permissions/permissions';
import { useSelector } from 'react-redux';

export const PurchaseOrderRowShow = (props) => {
    const purchaseOrderRow = useSelector(state => state.admin.resources.purchaseOrderRow.data[props.id] ? state.admin.resources.purchaseOrderRow.data[props.id] : null);
    const orderStateMachine = useSelector(state => purchaseOrderRow ? (state.admin.resources.purchaseOrder.data[purchaseOrderRow.purchaseOrderId] ? state.admin.resources.purchaseOrder.data[purchaseOrderRow.purchaseOrderId].orderStateMachine : null) : null);
    const disableAdd = orderStateMachine && (orderStateMachine.status !== 'draft' && orderStateMachine.status !== 'sent');

    return (
    <Show aside={<Aside props={props} record={props.record}></Aside>}  {
        ...{
            ...props,
            hasEdit: !disableAdd
        }
    }>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <ReferenceField label="Ordine di acquisto" source="purchaseOrderId" reference={`${Permissions.purchaseOrder.feature}`} link="show">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Item" source="itemId" reference={`${Permissions.item.feature}`} link="show">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Listino fornitore" source="supplierPriceListId" reference={`${Permissions.supplierPriceList.feature}`} link="show">
                <TextField source="itemPurchasable.item.name" />
            </ReferenceField>
            <TextField label="referenza fornitore alla conferma" source="supplierReference" />
            <TextField label="codice fornitore alla conferma" source="supplierCode" />
            <NumberField label="quantità in unità fabbisogno" source="quantity" />
            <ReferenceField label="unità di misura fabbisogno" source="orderUnitId" reference={`${Permissions.unitOfMeasurement.feature}`} link="show">
                <TextField source="name" />
            </ReferenceField>        
            <NumberField label="quantità in unità di riferimento per l’item" source="quantityReference" />
            <ReferenceField label="unità di misura di riferimento" source="refereceUnitId" reference={`${Permissions.unitOfMeasurement.feature}`} link="show">
                <TextField source="name" />
            </ReferenceField>
            <NumberField label="quantità in unità prezzo" source="quantityPriceUnit" />
            <ReferenceField label="unità di misura prezzo" source="costUnitId" reference={`${Permissions.unitOfMeasurement.feature}`} link="show">
                <TextField source="name" />
            </ReferenceField>
            <TextField label="unità di misura alla conferma" source="costUnitName" />
            <TextField label="minimo d’ordine in unità prezzo" source="minimumOrder" />
            <TextField label="arrotondamento d’ordine in unità prezzo" source="rounding" />

            <TextField label="quantità unità prezzo dopo minimo d’ordine e arrotondamento" source="quantityPriceUnitAfterMinOrderAndRounding"></TextField>
            <TextField label="quantità confermata dal fornitore" source="quantityConfirmedInPriceUnit"></TextField>
    
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Prezzo unitario" source="unitPriceNoTax" />

            <TextField label="Nome Aggiustamento"source="adjustmentName" />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Aggiustamento (no-tax)" source="adjustmentNoTax" />
            <ReferenceField label="Tassa" source="taxId" reference={`${Permissions.tax.feature}`} link="show">
                <TextField source="name" />
            </ReferenceField>            
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Aggiustamento" source="adjustmentWithTax" />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Sub Totale prima Aggiustamento" source="subTotalBeforeAdjustmentWithTax" />    
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Sub Totale prima Aggiustamento (no-tax)" source="subTotalBeforeAdjustmentNoTax" />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Tasse su Sub Totale finale" source="subTotalBeforeAdjustmentTax" />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Totale finale" source="totalWithTax" />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Totale finale (no-tax)" source="totalNoTax" />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Tasse su totale finale" source="totalTax" />
        </SimpleShowLayout>
    </Show>
)};
