import React from 'react';
import { Fragment } from 'react';
import { required, ReferenceInput, AutocompleteInput } from 'react-admin';
import { useForm } from 'react-final-form';
import { Permissions } from '../../../../../permissions/permissions';
import { useSelector } from 'react-redux';
function OptionText(props) {
    if(!props) {
        return '';
    }
    return `${props.itemPurchasable.item.id} ${props.itemPurchasable.item.name}`
}
export default ({ purchaseOrderId, formData, ...rest }) => {
    const form = useForm();
    const purchaseOrder = useSelector(state => state.admin.resources.purchaseOrder.data[purchaseOrderId]);
    const supplierPriceLists = useSelector(state => state.admin.resources.supplierPriceList.data);
    rest['className'] = '';
    return (
        <Fragment>
            <ReferenceInput
                fullWidth
                {...rest}
                filterToQuery={searchText => {
                    if(!searchText)
                        return {};

                    return {
                        [form.getState().values.searchForId ? "itemPurchasable.item.id" : "itemPurchasable.item.name"]: searchText,
                    }
                }}
                label="Item"
                source="supplierPriceListId"
                reference={`${Permissions.supplierPriceList.feature}`}
                filter={{"supplierId||eq": purchaseOrder.supplierId}}
                validate={[required('Indicare un Item è obbligatorio')]}
                onChange={value => {
                    form.change('unitPriceNoTax', supplierPriceLists[value].costNoTax);
                    form.change('taxId', supplierPriceLists[value].itemPurchasable.taxId);
                    form.change('minimumOrder', supplierPriceLists[value].minimumOrder);
                    form.change('rounding', supplierPriceLists[value].rounding);
                }}
                >
                    <AutocompleteInput
                        optionText={OptionText}
                        optionValue="id"
                        options={
                            {
                                suggestionsContainerProps: {
                                    disablePortal: true
                                }
                            }
                        }
                    />
            </ReferenceInput>
        </Fragment>
    )

}