import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CachedIcon from '@material-ui/icons/Cached';
import { Card, Item, CardLotCountingModePartial } from './components';
import { GET_OFF_RESOURCE, POST_OFF_RESOURCE, GET_OFF_RESOURCE_PLAIN } from '../../../../providers/nestjs_crud';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import KitchenIcon from '@material-ui/icons/Kitchen';
import Switch from '@material-ui/core/Switch';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    backdrop: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'column',
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    list: {
        width: '100%',
    },
    listPadding: {
        padding: theme.spacing(0, 0, 10)
    },
    text: {
        margin: theme.spacing(0, 0 , 1)
    },
    itemContainer: {
        flex: 1
    },
    lotContainer: {
        flex: 1.5,
        overflow: 'scroll',
        backgroundColor: 'rgba(0, 0, 0, 0.1)'
    },
    container: {
        display: 'flex',
        height: '100%',
        width: '100%' 
    },
    exitButton: {
        margin: theme.spacing(1, 0 , 0)
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(3),
        right: theme.spacing(2),
    },
    toolBar: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));

const loadingStatus = [
    'FETCHING_DATA',
    'SENDING_REQUEST_TERMINATE',
    'SENDING_REFRESH',
    'SUBMITTING',
];

const errorStatus = [
    'FETCHING_DATA_ERROR',
    'ERROR_SEND_REQUEST_TERMINATE',
    'ERROR_SEND_SUBMIT',
    'ERROR_REFRESH'
];

export default function InventoryWarehouseAccAccount(props) {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const { id } = useParams();
    const params = new URLSearchParams(location.search);
    const item = (params.get('item') === null || params.get('item') === undefined) ? 1 : (params.get('item') > 0 ? params.get('item') : 1);
    const dataProvider = useDataProvider();
    const [state, setState] = useState('FETCH_DATA');
    const [warehouseInventory, setWarehouseInventory] = useState(null);
    const [inventoryItems, setInventoryItems] = useState(null);
    const [doorState, setDoorState] = useState('INITIAL');
    const [statusSwitch, setStatusSwitch] = useState(false);

    const updateWarehouseInventory = (warehouseInventoryData, override = {}) => {
        const _inventoryObjectItems = {};

        warehouseInventoryData.warehouseInventoryItems.forEach(warehouseInventoryItem => {
            if(!_inventoryObjectItems[warehouseInventoryItem.orderItem]) {
                _inventoryObjectItems[warehouseInventoryItem.orderItem] = [warehouseInventoryItem];
            } else {
                _inventoryObjectItems[warehouseInventoryItem.orderItem].push(warehouseInventoryItem);
            }
        });

        const _inventoryItems = Object.keys(_inventoryObjectItems).map(key => {
            return _inventoryObjectItems[key]
            .map(warehouseInventoryItem => {
                if(override.id === warehouseInventoryItem.id) {
                    return {
                        ...warehouseInventoryItem,
                        quantity: Number.parseFloat(
                            (warehouseInventoryItem.quantityInitialReserved + warehouseInventoryItem.quantityInitialNotReserved).toFixed(6),
                        ),
                        ...override
                    }
                }
                return {
                    ...warehouseInventoryItem,
                    quantity: Number.parseFloat(
                        (warehouseInventoryItem.quantityInitialReserved + warehouseInventoryItem.quantityInitialNotReserved).toFixed(6),
                    ),
                }
            })
            .sort((warehouseInventoryItemA, warehouseInventoryItemB) => {
                return warehouseInventoryItemA.orderLot - warehouseInventoryItemB.orderLot
            });
        }).sort((warehouseInventoryItemA, warehouseInventoryItemB) => {
            return warehouseInventoryItemA.orderItem - warehouseInventoryItemB.orderItem
        });

        setInventoryItems(_inventoryItems);

        setWarehouseInventory(warehouseInventoryData);
    };

    useEffect(() => {
        if (state === 'FETCH_DATA' && id) {
            setState('FETCHING_DATA');
            dataProvider(GET_OFF_RESOURCE, `warehouseInventory/v2/${id}`)
            .then(({ data }) => {
                if(data.success) {
                    updateWarehouseInventory(data.data);
                    setState('DATA_FETCHED');
                } else {
                    setState('FETCHING_DATA_ERROR');
                }
            })
            .catch(() => {
                setState('FETCHING_DATA_ERROR');
            })
        }
    }, [dataProvider, state, id]);

    useEffect(() => {
        if (state === 'TERMINATE') {
            setState('SENDING_REQUEST_TERMINATE');
            dataProvider(GET_OFF_RESOURCE, `warehouseInventory/${id}/terminate`)
                .then(() => {
                    history.goBack();
                })
                .catch(error => {
                    setState('ERROR_SEND_REQUEST_TERMINATE');
                })
        }
    }, [dataProvider, state, id, history]);

    useEffect(() => {
        if (state === 'DATA_FETCHED' && id && inventoryItems && (params.get('item') === null || params.get('item') === undefined)) {
            let itemIndex = inventoryItems.findIndex(inventoryItem => {
                return inventoryItem.filter(inventoryItem => inventoryItem.status === 'ToDo').length > 0
            });

            if(itemIndex === null || itemIndex === undefined || itemIndex === -1) {
                itemIndex = 0;
            }
            history.replace(`/inventory/${id}?item=${itemIndex + 1}`)
        }
    }, [history, id, params, state, inventoryItems]);

    useEffect(() => {
        if (doorState === 'REQUEST_OPEN' && state === 'DATA_FETCHED' && warehouseInventory && warehouseInventory.location && warehouseInventory.location.locker && warehouseInventory.location.locker.lockId) {
            setDoorState('OPENING');
            dataProvider(GET_OFF_RESOURCE_PLAIN, `lock/${warehouseInventory.location.locker.lockId}/open`)
                    .then(() => {
                        setDoorState('INITIAL');
                    })
                    .catch(() => {
                        alert('Errore apertura')
                        setDoorState('INITIAL');
                    });
        }
    }, [dataProvider, doorState, state, warehouseInventory]);

    const handleClose = () => {
        history.goBack()
    };

    return (
        <>
        <Dialog fullScreen open={true} onClose={handleClose} TransitionComponent={Transition}>
            <Container className={classes.container} maxWidth={false} disableGutters>
                <Container className={classes.itemContainer} disableGutters>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Inventario {state === 'DATA_FETCHED' && warehouseInventory.location.name}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {state === 'DATA_FETCHED' && warehouseInventory.warehouseInventoryStateMachine.status === 'in_progress' && <Item {...{
                        showAddButton: inventoryItems[item > inventoryItems.length ? inventoryItems.length - 1 : item - 1][0].lotCountingMode === 'full' || statusSwitch,
                        itemLenght: inventoryItems.length,
                        lotLength: inventoryItems[item > inventoryItems.length ? inventoryItems.length - 1 : item - 1].length,
                        terminate: () => {
                            setState('REQUEST_TERMINATE');
                        },
                        refreshData: (inventoryItem2Refresh) => {
                            setInventoryItems(inventoryItems.map(inventoryItemForItem => {
                                if(inventoryItemForItem[0].orderItem === inventoryItem2Refresh.orderItem) {
                                    inventoryItem2Refresh.quantity =  Number.parseFloat(
                                        (inventoryItem2Refresh.quantityInitialReserved + inventoryItem2Refresh.quantityInitialNotReserved).toFixed(6),
                                    );
                                    return inventoryItemForItem
                                            .concat([inventoryItem2Refresh])
                                            .sort((warehouseInventoryItemA, warehouseInventoryItemB) => {
                                                return warehouseInventoryItemA.orderLot - warehouseInventoryItemB.orderLot
                                            });
                                } else {
                                    return inventoryItemForItem;
                                }
                            }));
                        },
                        item: {
                            id: inventoryItems[item > inventoryItems.length ? inventoryItems.length - 1 : item - 1][0].item.id,
                            title: inventoryItems[item > inventoryItems.length ? inventoryItems.length - 1 : item - 1][0].item.name,
                            uom: inventoryItems[item > inventoryItems.length ? inventoryItems.length - 1 : item - 1][0].item.unitOfMeasurements.find(unitOfMeasurement => unitOfMeasurement.default) ? inventoryItems[item > inventoryItems.length ? inventoryItems.length - 1 : item - 1][0].item.unitOfMeasurements.find(unitOfMeasurement => unitOfMeasurement.default).name : false || 'PZ',
                            image: inventoryItems[item > inventoryItems.length ? inventoryItems.length - 1 : item - 1][0].item.photoURL || 'https://via.placeholder.com/1000x1500?text=Immagine+non-trovata'
                        }
                    }} />}
                </Container>
                <Container className={classes.lotContainer} disableGutters>
                    {state === 'DATA_FETCHED' && inventoryItems[item > inventoryItems.length ? inventoryItems.length - 1 : item - 1][0].lotCountingMode === 'partial' &&
                            <>
                            <AppBar color="default" className={classes.appBar}>
                                <Toolbar className={classes.toolBar}>
                                    <Typography align="center" variant="h6">
                                        Conta i lotti
                                    </Typography>
                                    <Switch checked={statusSwitch} onChange={(event) => {
                                        setStatusSwitch(event.target.checked)
                                    }}/>
                                </Toolbar>
                            </AppBar>
                                {!statusSwitch && <List className={`${classes.list} ${state === 'DATA_FETCHED' && warehouseInventory && warehouseInventory.location && warehouseInventory.location.locker && warehouseInventory.location.locker.lockId ? classes.listPadding : ''}`}>
                                    <Typography align="center" variant="h6">
                                        Conta i prodotti
                                    </Typography>
                                    <ListItem key={'warehouseInventoryItem-count-items'}>
                                        <CardLotCountingModePartial {
                                            ...{
                                                quantity: inventoryItems[item > inventoryItems.length ? inventoryItems.length - 1 : item - 1].reduce(
                                                    (accumulator, currentValue) => accumulator + currentValue.quantity,
                                                    0
                                                ),
                                                inventoryItems: inventoryItems[item > inventoryItems.length ? inventoryItems.length - 1 : item - 1],
                                                submit: (quantity) => {
                                                    setState('SUBMITTING');
                                                    dataProvider(POST_OFF_RESOURCE, `warehouseInventory/${warehouseInventory.id}/item/${inventoryItems[item > inventoryItems.length ? inventoryItems.length - 1 : item - 1][0].item.id}/submit-partial-lot-count`, {
                                                        data: {
                                                            quantity
                                                        }
                                                    })
                                                    .then(({ data }) => {
                                                        if(data.success) {
                                                            updateWarehouseInventory(data.data);
                                                            setState('DATA_FETCHED');
                                                            if(Number.parseInt(item) < inventoryItems.length) {
                                                                history.replace(`/inventory/${id}?item=${Number.parseInt(item) + 1}`)
                                                            }
                                                        } else {
                                                            setState('ERROR_SEND_SUBMIT');
                                                        }
                                                    })
                                                    .catch(() => {
                                                        setState('ERROR_SEND_SUBMIT');
                                                    })
                                                },
                                                refresh: () => {
                                                    setState('FETCH_DATA');
                                                },
                                                edit: () => {
                                                    setState('SENDING_REFRESH');
                                                    dataProvider(POST_OFF_RESOURCE, `warehouseInventory/${warehouseInventory.id}/refresh`, {
                                                        data: {
                                                            itemId: inventoryItems[item > inventoryItems.length ? inventoryItems.length - 1 : item - 1][0].item.id
                                                        }
                                                    })
                                                    .then(({ data }) => {
                                                        if(data.success) {
                                                            setInventoryItems(inventoryItems.map(inventoryItem => {
                                                                return inventoryItem.map(inventoryItemSingle => {
                                                                    const inventoryItem2Update = data.data.find(dataItem => {
                                                                        return dataItem.id === inventoryItemSingle.id;
                                                                    })
                                                                    if(inventoryItem2Update) {
                                                                        return {
                                                                            ...inventoryItem2Update,
                                                                            quantity: Number.parseFloat(
                                                                                (inventoryItem2Update.quantityInitialReserved + inventoryItem2Update.quantityInitialNotReserved).toFixed(6),
                                                                            ),
                                                                            status: 'ToDo',
                                                                        }
                                                                    } else {
                                                                        return inventoryItemSingle;
                                                                    }
                                                                })
                                                            }))
                                                            setState('DATA_FETCHED');
                                                        } else {
                                                            throw new Error();
                                                        }
                                                    })
                                                    .catch(() => {
                                                        setState('ERROR_REFRESH');
                                                    })
                                                },
                                            }
                                        }/>
                                    </ListItem>
                                </List>}
                            </>
                    }
                    {state === 'DATA_FETCHED' && warehouseInventory.warehouseInventoryStateMachine.status === 'in_progress' && (
                        inventoryItems[item > inventoryItems.length ? inventoryItems.length - 1 : item - 1][0].lotCountingMode === 'full' || statusSwitch
                    ) && <List className={`${classes.list} ${state === 'DATA_FETCHED' && warehouseInventory && warehouseInventory.location && warehouseInventory.location.locker && warehouseInventory.location.locker.lockId ? classes.listPadding : ''}`}>
                    <Typography align="center" variant="h6">
                        Conta i lotti
                    </Typography>
                    {   
                        inventoryItems[item > inventoryItems.length ? inventoryItems.length - 1 : item - 1].map((warehouseInventoryItem, warehouseInventoryItemKey) => {
                            let expireDate = null;
                            if (warehouseInventoryItem.lot.expirationDate) {
                                const date = new Date(warehouseInventoryItem.lot.expirationDate);
                                expireDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
                            }

                            return (
                            <ListItem key={warehouseInventoryItem.id}>
                                <Card {
                                    ...{
                                        status: warehouseInventoryItem.status,
                                        supplierLot: warehouseInventoryItem.lot.supplierLot,
                                        quantityInitialReserved: warehouseInventoryItem.quantityInitialReserved,
                                        quantityInitialNotReserved: warehouseInventoryItem.quantityInitialNotReserved,
                                        expireDate,
                                        quantityInventory: warehouseInventoryItem.quantityInventory,
                                        quantity: warehouseInventoryItem.quantity,
                                        updateQuantity: (quantityToUpdate) => {
                                            setInventoryItems(inventoryItems.map((inventoryItem) => {
                                                return inventoryItem.map(inventoryItemSingle => {
                                                    if(inventoryItemSingle.id === warehouseInventoryItem.id) {
                                                        return {
                                                            ...warehouseInventoryItem,
                                                            quantity: quantityToUpdate,
                                                        };
                                                    }
                                                    return inventoryItemSingle;
                                                });
                                            }));
                                        },
                                        submit: () => {
                                            setState('SUBMITTING');

                                            dataProvider(POST_OFF_RESOURCE, `warehouseInventory/${warehouseInventory.id}/warehouseInventoryItem/${warehouseInventoryItem.id}/submit`, {
                                                data: {quantity: inventoryItems[item - 1].find(inventoryItemSingle => inventoryItemSingle.id === warehouseInventoryItem.id).quantity}
                                            })
                                            .then(({ data }) => {
                                                if(!data.success) {
                                                    throw new Error();
                                                }
                                                let totalPendingCount = 0;
                                                const is_last = inventoryItems[item - 1].filter(inventoryItem => inventoryItem.status === 'ToDo').length === 1;

                                                inventoryItems.forEach(inventoryItemContainer => {
                                                    totalPendingCount += inventoryItemContainer.filter(inventoryItem => {
                                                        return inventoryItem.status === 'ToDo';
                                                    }).length;
                                                });

                                                setInventoryItems(inventoryItems.map((inventoryItem) => {
                                                    return inventoryItem.map(inventoryItemSingle => {
                                                        if(inventoryItemSingle.id === warehouseInventoryItem.id) {
                                                            return {
                                                                ...data.data,
                                                                quantity: Number.parseFloat(
                                                                    (data.data.quantityInitialReserved + data.data.quantityInitialNotReserved).toFixed(6),
                                                                ),
                                                            };
                                                        }
                                                        return inventoryItemSingle;
                                                    });
                                                }));

                                                setState('DATA_FETCHED');

                                                if(totalPendingCount === 1) {
                                                    setState('REQUEST_TERMINATE');
                                                }
                                                else {
                                                    setState('DATA_FETCHED');    
                                                }

                                                if(is_last && Number.parseInt(item) < inventoryItems.length) {
                                                    history.replace(`/inventory/${id}?item=${Number.parseInt(item) + 1}`)
                                                }
                                            })
                                            .catch(() => {
                                                setState('ERROR_SEND_SUBMIT');
                                            })
                                        },
                                        refresh: () => {
                                            setState('SENDING_REFRESH');
                                            dataProvider(POST_OFF_RESOURCE, `warehouseInventory/${warehouseInventory.id}/warehouseInventoryItem/${warehouseInventoryItem.id}/refresh`, {
                                                data: {}
                                            })
                                            .then(({ data }) => {
                                                if(data.success) {
                                                    setInventoryItems(inventoryItems.map((inventoryItem) => {
                                                        return inventoryItem.map(inventoryItemSingle => {
                                                            if(inventoryItemSingle.id === warehouseInventoryItem.id) {
                                                                return {
                                                                    ...data.data,
                                                                    status: 'ToDo',
                                                                    quantity: Number.parseFloat(
                                                                        (data.data.quantityInitialReserved + data.data.quantityInitialNotReserved).toFixed(6),
                                                                    ),
                                                                };
                                                            }
                                                            return inventoryItemSingle;
                                                        });
                                                    }));
                                                    setState('DATA_FETCHED');
                                                } else {
                                                    throw new Error();
                                                }
                                            })
                                            .catch(() => {
                                                setState('ERROR_REFRESH');
                                            })
                                        },
                                        compliantStatus: warehouseInventoryItem.lot.compliantStatus,
                                    }
                                }/>
                            </ListItem>)
                        })
                    }
                    </List>}
                    {state === 'DATA_FETCHED' && warehouseInventory && warehouseInventory.location && warehouseInventory.location.locker && warehouseInventory.location.locker.lockId && <Fab variant="extended"  disabled={doorState === 'OPENING'} onClick={() => {
                        setDoorState('REQUEST_OPEN');
                    }} className={classes.fab} color="primary" aria-label="add">
                        {doorState === 'OPENING' && <CircularProgress size={24} color="inherit" />}
                        {doorState !== 'OPENING' && <><KitchenIcon /> APRI</>}
                    </Fab>}
                </Container>
                <Backdrop className={classes.backdrop} open={state === 'DATA_FETCHED' && ['done', 'expired'].includes(warehouseInventory.warehouseInventoryStateMachine.status)} onClick={() => { }}>
                    <Typography className={classes.text} variant="h4">
                        Inventario Terminato
                    </Typography>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        color="default"
                        startIcon={<ArrowBackIcon />}>
                        INDIETRO
                    </Button>
                </Backdrop>
                <Backdrop className={classes.backdrop} open={errorStatus.includes(state)} onClick={() => { }}>
                    <Typography className={classes.text} variant="h4">
                        {('ERROR_SEND_REQUEST_TERMINATE' === state || 'ERROR_REFRESH' === state) && 'Errore durante invio dati'}
                        {state === 'ERROR_SEND_SUBMIT' && 'Errore invio dati, aggiorna le quantità'}
                        {state === 'FETCHING_DATA_ERROR' && 'Errore durante il caricamento'}
                    </Typography>
                    {state === 'ERROR_SEND_REQUEST_TERMINATE' && <Button
                        size="large"
                        onClick={() => {
                            setState('TERMINATE');
                        }}
                        variant="contained"
                        color="primary"
                        startIcon={<CachedIcon />}>
                        RIPROVA
                    </Button>}
                    {state === 'FETCHING_DATA_ERROR' && <Button
                        size="large"
                        onClick={() => {
                            setState('FETCH_DATA');
                        }}
                        variant="contained"
                        color="primary"
                        startIcon={<CachedIcon />}>
                        RICARICA
                    </Button>}
                    {state === 'FETCHING_DATA_ERROR' && <Button
                        className={classes.exitButton}
                        size="small"
                        onClick={handleClose}
                        variant="contained"
                        color="default">
                        ESCI
                    </Button>}
                    {state === 'ERROR_SEND_SUBMIT' && <Button
                        size="large"
                        onClick={() => {
                            setState('DATA_FETCHED');
                        }}
                        variant="contained"
                        color="primary"
                        startIcon={<CachedIcon />}>
                        CHIUDI
                    </Button>}
                </Backdrop>
                <Backdrop className={classes.backdrop} open={loadingStatus.includes(state)} onClick={null}>
                    <Typography className={classes.text} variant="h4">
                        {(state === 'SENDING_REFRESH' || state === 'SUBMITTING') && 'Invio dati in corso'}
                        {state === 'FETCHING_DATA' && 'Caricamento dati in corso'}
                    </Typography>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        </Dialog>
        <Dialog
            fullScreen={false}
            open={state === 'REQUEST_TERMINATE'}
            onClose={() => setState('DATA_FETCHED')}
            aria-labelledby="responsive-dialog-title"
            >
            <DialogTitle id="responsive-dialog-title">Termina inventario?</DialogTitle>
            <DialogActions>
                <Button onClick={() => setState('DATA_FETCHED')} color="primary">
                    Annulla
                </Button>
                <Button onClick={() => {
                    setState('TERMINATE')
                }} color="primary">
                    Termina
                </Button>
            </DialogActions>
        </Dialog>
        </>
    );
}