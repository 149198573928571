import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cardDone: {
    backgroundColor: '#bef67a'
  },
  cardToDo: {
    backgroundColor: '#ff7961'
  },
  root: { 
      width: '100%'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  action: {
    backgroundColor: 'white',
    width: '100%'
  },
  cardContent: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  textField: {
    backgroundColor: 'white',
    width: '100%',
    minWidth: '64px',
  },
  quantityTopRight: {
    padding: theme.spacing(1),
    backgroundColor: 'white',
  }
}));

export default function LotCard(props) {
  const classes = useStyles();

  const quantity = Number.parseFloat(
    (props.quantityInitialReserved + props.quantityInitialNotReserved).toFixed(2),
  );
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <Card className={`${classes.root} ${props.status === 'Done' ? classes.cardDone : classes.cardToDo}`} variant="outlined">
      <CardContent className={classes.cardContent}>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center'}}>
            <Typography style={{ width: '100%' }} variant="h6" gutterBottom>
              {props.expireDate && <>Scadenza: <strong>{props.expireDate}</strong></>}
            </Typography>
            {props.compliantStatus === "not-compliant" && 
            <Typography style={{backgroundColor: "red", fontWeight: "bold", padding: "8px 0 8px 0"}} 
              align="center" gutterBottom
            > 
              Lotto non conforme
            </Typography>
            }
          </div>
          {props.supplierLot && <Typography variant="h6" gutterBottom>
            Lotto Fornitore: <strong>{props.supplierLot}</strong>
          </Typography>}
        </div>
        {quantity > 0 && props.status !== 'Done' && <div>
          <Typography variant="h6" className={classes.quantityTopRight}>
            {quantity} {props.quantityInitialReserved > 0 && <><small>(R </small>{props.quantityInitialReserved}<small> NR </small>{props.quantityInitialNotReserved}<small>)</small></>}
          </Typography>
        </div>}
        {props.status === 'Done' && <div>
        <Typography variant="h6" className={classes.quantityTopRight}>
          {props.quantityInventory}
        </Typography>
        </div>}
      </CardContent>
      {props.status !== 'Done' && props.quantityInitialReserved > 0 && <Typography align="center" variant="h6">
        <span style={{backgroundColor: 'white', padding: '8px'}}>
        <small>NR</small> {Number.parseFloat((Math.max(0, Number.parseFloat(props.quantity) - props.quantityInitialReserved)).toFixed(6))}
        </span>
      </Typography>}
      {props.status !== 'Done' && <CardActions>
        <Button className={classes.action} variant="contained" color="default" onClick={() => {
          let value = Number.parseFloat((props.quantity - 5).toFixed(6));
          if(value < props.quantityInitialReserved) {
            props.updateQuantity(props.quantityInitialReserved)
          } else {
            props.updateQuantity(Math.max(0, value));
          }
        }}>-5</Button>
        <Button className={classes.action} variant="contained" color="default" onClick={() => {
          let value = Number.parseFloat((props.quantity - 1).toFixed(6));
          if(value < props.quantityInitialReserved) {
            props.updateQuantity(props.quantityInitialReserved)
          } else {
            props.updateQuantity(Math.max(0, value));
          }
        }}>-1</Button>
        <TextField type="number" inputProps={{min: 0, style: { textAlign: 'center', fontWeight: 900 }}} value={props.quantity} onChange={(e) => {
          let value = Number.parseFloat(e.target.value);
          if(value < props.quantityInitialReserved) {
            props.updateQuantity(props.quantityInitialReserved)
          } else {
            props.updateQuantity(Math.max(0, value));
          }
        }}className={classes.textField} size="small" label="" variant="outlined" />
        <Button className={classes.action} variant="contained" color="default" onClick={() => {
          let value = Number.parseFloat((props.quantity + 1).toFixed(6));
          if(value < props.quantityInitialReserved) {
            props.updateQuantity(props.quantityInitialReserved)
          } else {
            props.updateQuantity(Math.max(0, value));
          }
        }}>+1</Button>
        <Button className={classes.action} variant="contained" color="default" onClick={() => {
          let value = Number.parseFloat((props.quantity + 5).toFixed(6));
          if(value < props.quantityInitialReserved) {
            props.updateQuantity(props.quantityInitialReserved)
          } else {
            props.updateQuantity(Math.max(0, value));
          }
        }}>+5</Button>
      </CardActions>}
      <CardActions>
        <Button className={classes.action} variant="contained" color="default" onClick={props.refresh}>{props.status === 'Done' ? 'Modifica' : 'Aggiorna'}</Button>
        {props.status !== 'Done' && <Button className={classes.action} variant="contained" color="default" onClick={() => {
          if(props.quantityInitialReserved > 0) {
            setOpen(true);
          } else {
            props.submit();
          }
        }}>Invia</Button>}
      </CardActions>
    </Card>
    <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Ci sono {props.quantityInitialReserved} item riservati, controlla che siano realmente presenti prima di continuare ?</DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancella
          </Button>
          <Button onClick={() => {
            props.submit();
            handleClose();
          }} color="primary" autoFocus>
            Continua
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
