import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput, NumberInput} from 'react-admin';
import BaseEdit from '../inheritance/BaseEdit';
import { required } from 'react-admin';

export const TaxEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="Nome" source="name" validate={[required('Il nome è obbligatorio')]} />
            <NumberInput label="Valore in percentuale" source="value" validate={[required('Il valore in percentuale è obbligatorio')]} />
            <BooleanInput label="é IVA" source="iva"></BooleanInput>
            <BaseEdit />
        </SimpleForm>
    </Edit>
);