import React from 'react';
import { Toolbar, SaveButton, DeleteButton, Edit, SimpleForm, TextInput, required, NumberInput, ReferenceInput, SelectInput, FormDataConsumer } from 'react-admin';
import { connect } from 'react-redux';
import BaseEdit from '../inheritance/BaseEdit';
import { makeStyles } from '@material-ui/core/styles';
import { Permissions } from '../../permissions/permissions';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    form: {
        display: 'flex',            
        flexGrow: 'colums',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
});

const CustomToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
        {false && <DeleteButton redirect={props.redirect} />}
    </Toolbar>
);

const SellingOrderRowEditComponent = props => {
    const classes = useStyles();
    return (
    <Edit {...props}>
        <SimpleForm redirect={() => (`/${Permissions.sellingOrder.feature}/${props.sellingOrderId}/show`)} toolbar={<CustomToolbar data={props.data} redirect={() => (`/${Permissions.sellingOrder.feature}/${props.sellingOrderId}/show`)}/>}>
            <ReferenceInput key={`tax`}
                label="tassa" source="taxId"
                reference={`${Permissions.tax.feature}`}>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <FormDataConsumer>
            {({ formData, ...rest }) => {
                if(!formData.itemSaleableId)
                    return null;

                const itemSaleable = props.itemSaleableList.data[formData.itemSaleableId];

                if(!itemSaleable)
                    return null;

                return <div className={classes.form}>
                    <ReferenceInput key={`uom`} {...rest}
                        label="unità di misura di vendita"
                        source="unitOfMeasurementId"
                        reference={`${Permissions.unitOfMeasurement.feature}`}
                        filter={{"itemId||eq": itemSaleable.itemId}} >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    </div>
                }
            }
            </FormDataConsumer>
            <NumberInput key={`price`}
                label="prezzo unitario con tasse"
                source="unitPriceWithTax" />
            <NumberInput label="quantità in unità di vendità" source="quantity" validate={[required('Indicare una quantità è obbligatorio')]} />
            <TextInput label="nome aggiustamento" source="adjustmentName" />
            <NumberInput label="sconti/aggiustamenti con tasse" source="adjustmentWithTax" />
            <BaseEdit />
        </SimpleForm>
    </Edit>
)};

export const SellingOrderRowEdit = connect((state, props) => {
    return {
        itemSaleableList: state.admin.resources.itemSaleable,
        sellingOrderId: state.admin.resources.sellingOrderRow && state.admin.resources.sellingOrderRow.data[props.id] ? state.admin.resources.sellingOrderRow.data[props.id].sellingOrderId : null
    }
})(SellingOrderRowEditComponent)