import React, { useEffect, useState } from 'react';
import { Edit, SimpleForm, TextInput, ReferenceField, TextField, AutocompleteInput, DateInput, useDataProvider, showNotification } from 'react-admin';
import BaseEdit from '../../inheritance/BaseEdit';
import Typography from '@material-ui/core/Typography';
import { Permissions } from '../../../permissions/permissions';
import { useDispatch } from 'react-redux';

export const LotEdit = props => {
	return (
	<Edit {...props}>
		<SimpleForm redirect="list">
			<SupplierLot></SupplierLot>
			<ReferenceField style={{display: "none"}} label={false} source="itemId" reference={`${Permissions.item.feature}`} link="show">
				<TextField source="name" />
			</ReferenceField>
			<AutocompleteInput
				source="compliantStatus"
				choices={['compliant', 'not-compliant'].map(choice => {
					return {
						id: choice,
						name: choice
					}
			})} />
			<DateInput source="notCompliantDate" />
			<TextInput
				source="notCompliantComment" />
			<BaseEdit />
		</SimpleForm>
	</Edit>
)};

function SupplierLot(props) {
	const [isPlottable, setIsPlottable] = useState(false)
	const [status, setStatus] = useState("FETCH_DATA")
	const dataProvider = useDataProvider();
	const dispatch = useDispatch();
	useEffect(() => {
		if(status === "FETCH_DATA") {
			setStatus("FETCHING_DATA")
			dataProvider.getOne(Permissions.itemStorable.feature, {id: props.record.itemStorableId})
			.then((response) => {
				setIsPlottable(response.data.isPlottable)
				setStatus("DATA_FETCHED")
			})
			.catch(() => {
				setStatus("ERROR_DATA_FETCHING")
				dispatch(showNotification("Errore caricamento dati", "error"));
			})
		}

	}, [dataProvider, dispatch, props.record.itemStorableId, status])

	if(status === "FETCHING_DATA")
		return <Typography>Caricamento del Lotto...</Typography>

	if(status === "ERROR_DATA_FETCHING")
		return <Typography>Errore caricamento ItemStorable!</Typography>

	if(isPlottable && status === "DATA_FETCHED")			
		return (<TextInput label="Lotto fornitore" source="supplierLot" />);

	return null;	
}