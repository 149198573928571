import { GET_OFF_RESOURCE } from "../../../../../../providers/nestjs_crud";
import { Permissions } from "../../../../../../permissions/permissions";
import Compress from 'compressorjs';

export function uploadFormPhotos(heavyFile, contentType, dataProvider, onUrlsReady, onError) {
  compressImage(heavyFile).then((file) => {
  dataProvider(
    GET_OFF_RESOURCE,
    `${Permissions.deliveryManFormPhotosTask.feature}/presignedUrl/${contentType.replace("image/", "")}`
  )
    .then(({ data }) => {
      var formData = new FormData();

      Object.keys(data.presignedPost.fields).forEach((key) => {
        formData.append(key, data.presignedPost.fields[key]);
      });

      data.headers.forEach((header) => {
        Object.keys(header).forEach((key) => {
          formData.append(key, header[key]);
        });
      });

      formData.append("file", file, data.fileName);

      fetch(data.presignedPost.url, {
        method: "POST",
        body: formData,
      }).then(
        (res) => {
          onUrlsReady(`${process.env.REACT_APP_FILES_CDN}/${data.fileName}`);
        },
        (error) => {
          onError(error);
        }
      );
    })
    .catch((error) => {
      onError(error);
    });
  }).catch((error) => {
    onError(error);
  });
}

export async function compressImage(file) {
  return new Promise((resolve, reject) => {
    new Compress(file, {
      size: 1,
      quality: 0.75,
      maxWidth: 1920,
      maxHeight: 1920,
      resize: true,
      success(result) {
        resolve(result);
      },
      error(err) {
        reject(err.message);
      },
    })
  });
}