import React from 'react';
import { Create, SimpleForm, TextInput, NumberInput, BooleanInput } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { required } from 'react-admin';

export const TaxCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Nome" source="name" validate={[required('Il nome è obbligatorio')]} />
            <NumberInput label="Valore in percentuale" source="value" validate={[required('Il valore in percentuale')]} />
            <BooleanInput label="é IVA" source="iva"></BooleanInput>
            <BaseCreate />
        </SimpleForm>
    </Create>
);