import React from 'react';
import { SimpleShowLayout, TextField, ReferenceField, ShowView, ShowController, Filter, TextInput, ReferenceInput, AutocompleteInput, FunctionField } from 'react-admin';
import Aside from '../../inheritance/BaseAside';
import { WarehouseLocationType_Warehouse, WarehouseLocationType_WarehouseLocation, WarehouseLocationUsageStorage, WarehouseLocationUsageTransit, WarehouseLocationUsageClient, WarehouseLocationUsageWaste, WarehouseLocationUsageInventory, WarehouseLocationUsageSupplier } from './enum';
import { Permissions } from '../../../permissions/permissions';
import { ListPreview } from '../../../layouts/ListPreview';
import { accr_WarehouseItemsCreate } from '../accr_warehouseItems/accr_WarehouseItemsCreate';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import ExploreIcon from '@material-ui/icons/Explore';
import { ButtonInventory } from './WarehouseLocationList';

export const WarehouseLocationShow = (props) => {
    let history = useHistory();

    return (
    <ShowController aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
        {controllerProps =>
            <ShowView {...props} {...controllerProps}>
                <SimpleShowLayout>
                    <TextField label="ID" source="id" />
                    <TextField label="Nome" source="name" />
                    <TextField label="Nome corto" source="shortName" />
                    <TextField label="Utilizzo" source="usage" />
                    <TextField label="Tipo" source="type" />
                    {controllerProps.record && controllerProps.record.type === WarehouseLocationType_Warehouse && <ReferenceField label="Luogo" source="placeId" reference={`${Permissions.place.feature}`} link="show">
                        <TextField source="name" />
                    </ReferenceField>}
                    {controllerProps.record && controllerProps.record.type === WarehouseLocationType_WarehouseLocation && <ReferenceField label="Parent" source="parentId" reference={`${Permissions.warehouseLocation.feature}`} link="show">
                        <TextField source="name" />
                    </ReferenceField>}
                    {controllerProps.record && (controllerProps.record.usage === WarehouseLocationUsageStorage || controllerProps.record.usage === WarehouseLocationUsageTransit) &&
                        <ReferenceField label="Waste" source="wasteId" reference={`${Permissions.warehouseLocation.feature}`} link="show">
                            <TextField source="name" />
                        </ReferenceField>
                    }
                    {controllerProps.record && (controllerProps.record.usage === WarehouseLocationUsageStorage || controllerProps.record.usage === WarehouseLocationUsageTransit) &&
                        <ReferenceField label="Inventory" source="inventoryId"  reference={`${Permissions.warehouseLocation.feature}`} link="show">
                            <TextField source="name" />
                        </ReferenceField>
                    }
                    {controllerProps.record && controllerProps.record.usage === WarehouseLocationUsageStorage &&
                        <ReferenceField label="Locker" source="lockerId"  reference={`${Permissions.locker.feature}`} link="show">
                            <TextField source="name" />
                        </ReferenceField>
                    }
                    {controllerProps.record && [WarehouseLocationUsageStorage, WarehouseLocationUsageClient, WarehouseLocationUsageWaste].includes(controllerProps.record.usage) && <TextField label="ddtEmail" source="ddtEmail"  />}
                    {controllerProps.record && [WarehouseLocationUsageSupplier, WarehouseLocationUsageStorage, WarehouseLocationUsageClient, WarehouseLocationUsageInventory, WarehouseLocationUsageTransit].includes(controllerProps.record.usage) && <ReferenceField
                        source="ownerId"
                        reference={`${Permissions.mainUser.feature}`}>
                        <FunctionField
							label=""
							render={record => {
								if(!record)
									return '';

								return `${record.id} - ${record.label}`;
							}}
						/>
                    </ReferenceField>}
                    <ReferenceField
                        source="businessUnitId"
                        reference={`${Permissions.businessUnit.feature}`}>
                           <TextField source="name" />
                    </ReferenceField>
                    <TextField label="Note per corriere" source="deliveryManNote" />
                    {controllerProps.record && controllerProps.record.type === WarehouseLocationType_WarehouseLocation && <TextField label="Tree" source="customPath" />}
                    <div dangerouslySetInnerHTML={{ __html: controllerProps.record ? controllerProps.record.note : "" }} />
                    <ButtonInventory title="Magazzino" record={controllerProps.record} />
                    {controllerProps.record && controllerProps.record.usage === WarehouseLocationUsageStorage && <React.Fragment>
                        <Button variant="contained" color="primary" style={{margin: '16px 0'}} endIcon={<ExploreIcon/>} onClick={() => {
                            history.push(`/accrWarehouseItems?filter={"warehouseLocationId||eq":${controllerProps.record.id},"quantity||gte":1}`);
                        }}>LISTA COMPLETA</Button>
                        <CustomListPreview enableFilter={false} redirect={`/${Permissions.warehouseLocation.feature}/${controllerProps.record.id}/show`} sortable={false} parentId={controllerProps.record.id} />
                    </React.Fragment>}
                </SimpleShowLayout>
            </ShowView>
        }
    </ShowController>
)};

export function FilterListPreview(props) {
    return (
        <div style={{padding: '16px'}}>
            <Filter {...props}>
                <TextInput type="tel" label="item Id" source="item.id" alwaysOn />
                <TextInput label="item name" source="item.name" alwaysOn />
                <TextInput type="tel" label="quantità minima" source="quantity||gte" alwaysOn />
                <TextInput type="tel" label="quantità massima" source="quantity||lte" alwaysOn />
                <ReferenceInput
                    alwaysOn
                    filterToQuery={searchText => {
                        if (!searchText)
                        return {};

                        return {
                        internalName: searchText
                        }
                    }}
                    label="Categoria ecommerce"
                    source="item.itemSaleable.clientCategoryId||eq"
                    reference={`${Permissions.clientCategory.feature}`} >
                    <AutocompleteInput
                        emptyText="Tutte"
                        optionText="internalName"
                        optionValue="id" />
                    </ReferenceInput>
            </Filter>
        </div>
    );
}

export function CustomListPreview(props) {
    return (
        <ListPreview
            filters={props.enableFilter ? <FilterListPreview /> : null}
            title={`${props.title ? props.title + ' > ' : ''}Accr. WarehouseItems`}
            basePath = {`/${Permissions.accrWarehouseItems.feature}`}
            resource = {`${Permissions.accrWarehouseItems.feature}`}
            filterList={{
                "warehouseLocationId||eq": props.parentId,
                //"quantity||gte": 1
            }}
            hasCreateModal={true}
            create={accr_WarehouseItemsCreate}
            createProps={{warehouseLocationId: props.parentId}}
            createModalProps={{disableBackdropClick: true}}
            reactAdminListProps={{
                sort: { field: 'item.itemSaleable.clientCategory.internalName', order: 'ASC' },
                perPage: 50,
                bulkActionButtons: props.bulkActionButtons === true ? undefined : false,
                filterDefaultValues: { "quantity||gte": 1 }
            }}
            redirect={props.redirect}>
                <TextField sortable={props.sortable} label="Item id" source="item.id"/>
                <TextField sortable={props.sortable} label="Item name" source="item.name"/>
                <TextField sortable={props.sortable} label="Categoria ecommerce" source="item.itemSaleable.clientCategory.internalName"/>
                <TextField sortable={props.sortable} label="Quantità" source="quantity" />
        </ListPreview>
    );
}