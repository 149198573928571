import React from 'react';
import { Edit, SimpleForm, TextInput, AutocompleteInput, NumberInput } from 'react-admin';
import BaseEdit from '../inheritance/BaseEdit';
import { required } from 'react-admin';
import { TIME_INTERVAL_TYPE_VALUES, ENABLE_VALUES } from './';

export function CorporateCreditEdit(props) {
	return (
		<Edit {...props}>
			<SimpleForm>
				<TextInput source="name" validate={[required('campo obbligatorio')]} />
				<AutocompleteInput label="Periodo" validate={[required('campo obbligatorio')]} source="timeIntervalType" choices={TIME_INTERVAL_TYPE_VALUES.map(timeIntervalType => {
					return {
						id: timeIntervalType,
						name: timeIntervalType
					}
				})} />
				<NumberInput validate={[required('campo obbligatorio')]} source="credit" />
				<AutocompleteInput validate={[required('campo obbligatorio')]} source="enable" choices={ENABLE_VALUES.map(enableValue => {
					return {
						id: enableValue,
						name: enableValue
					}
				})} />
				<BaseEdit />
			</SimpleForm>
		</Edit>
	);
}
