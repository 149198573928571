import { CoffeeRecipeEdit } from "./CoffeeRecipeEdit";
import { CoffeeRecipeList } from "./CoffeeRecipeList";
import { CoffeeRecipeCreate } from "./CoffeeRecipeCreate";
import { CoffeeRecipeShow } from "./CoffeeRecipeShow";

export default { edit:CoffeeRecipeEdit, list:CoffeeRecipeList, create:CoffeeRecipeCreate, show: CoffeeRecipeShow};


export const RecipeTypeList = [
    'milk',
    'coffee',
    'coffee-milk',
    'soluble',
    'milk-soluble',
    'coffee-soluble',
    'coffee-milk-soluble',
];
