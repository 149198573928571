import React from 'react';
import { Create, SimpleForm, TextInput, showNotification, AutocompleteInput, AutocompleteArrayInput, Toolbar, DateInput } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { required } from 'react-admin';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';

export function TimeSlotRuleCreate(props) {
    const history = useHistory();
    const params = new URLSearchParams(props.location.search);
    const dispatch = useDispatch();

    if(!params.get('entityId') || !params.get('entityType')) {
        dispatch(showNotification('Errore: parametri mancanti'));
        history.goBack();
        return null;
    }

    return (
        <Create title={`Create Time Slot Rule > ${params.get('entityType')}`} {...props}>
            <SimpleForm redirect={history.goBack} toolbar={<Toolbar alwaysEnableSaveButton />}>
                <TextInput fullWidth disabled={true} initialValue={params.get('entityType')} label="entityType" source="entityType" validate={[required('campo obbligatorio')]} />
                <TextInput fullWidth disabled={true} initialValue={params.get('entityId')} label="entityId" source="entityId" validate={[required('campo obbligatorio')]} />
                <AutocompleteInput
                    validate={[required('campo obbligatorio')]}
                    initialValue='Available'
                    fullWidth
                    label="Disponibilità"
                    source="availability"
                    choices={['Available', 'NotAvailable'].map(type => {
                        return {
                            id: type,
                            name: type
                        }
                    })} />
                <AutocompleteArrayInput
                    validate={[required('campo obbligatorio')]}
                    fullWidth
                    initialValue={['1', '2', '3', '4', '5', '6', '0']}
                    label="Giorni della settimana"
                    source="weekdays"
                    choices={['1', '2', '3', '4', '5', '6', '0'].map(weekday => {
                        return {
                            id: weekday,
                            name: {
                                '1': 'LUN',
                                '2': 'MAR',
                                '3': 'MER',
                                '4': 'GIO',
                                '5': 'VEN',
                                '6': 'SAB',
                                '0': 'DOM',
                            }[weekday],
                        }
                    })} />
                    <TextInput
                        validate={[required('campo obbligatorio')]}
                        fullWidth
                        initialValue={"09:00"}
                        helperText="Inizio Formato: HH:MM"
                        label=""
                        source="startTime" type="time"/>
                    <TextInput
                        validate={[required('campo obbligatorio')]}
                        fullWidth
                        initialValue={"18:00"}
                        helperText="Fine Formato: HH:MM"
                        label=""
                        source="endTime"  type="time"/>
                    <DateInput
                        fullWidth
                        label="Data inizio"
                        source="startDate"
                        helperText="non obbligatoria"
                    />
                    <DateInput
                        fullWidth
                        label="Data fine"
                        source="endDate"
                        helperText="non obbligatoria"
                    />
                <BaseCreate />
            </SimpleForm>
        </Create>
    );
}
