import React from 'react';

import { TextField, ReferenceField } from 'react-admin';
import { ListPreview } from '../../../../layouts/ListPreview';
import { CoffeeRecipeListItemCreate } from './CoffeeRecipeListItemCreate';
import { Permissions } from '../../../../permissions/permissions';

export function CustomListPreview(props) {
  return (
    <ListPreview
      title={`${props.title ? props.title + ' > ' : ''} Coffee List`}
      basePath={"/coffeeRecipeListItem"}
      resource={"coffeeRecipeListItem"}
      filterList={{ "coffeeRecipeListId||eq": props.parentId }}
      redirect={props.redirect}
      hasCreateModal={true}
      createProps={{ coffeeRecipeListId: props.parentId }}
      create={CoffeeRecipeListItemCreate}
      reactAdminListProps={{
        sort: { field: 'order', order: 'ASC' },
        perPage: 50,
        bulkActionButtons: props.bulkActionButtons === true ? undefined : false,
      }}
    >
      <TextField source="order" />
      <ReferenceField label="CoffeeRecipe" source="coffeeRecipeId" reference={Permissions.coffeeRecipe.feature}>
        <TextField source="item.name" />
      </ReferenceField>
    </ListPreview>
  )
}