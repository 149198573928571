export const DAYS = [
    {
        id: 'MON',
        name: 'L',
    },
    {
        id: 'TUE',
        name: 'MA',
    },
    {
        id: 'WED',
        name: 'ME',
    },
    {
        id: 'THU',
        name: 'G',
    },
    {
        id: 'FRI',
        name: 'V',
    },
    {
        id: 'SAT',
        name: 'S',
    },
    {
        id: 'SUN',
        name: 'D',
    },
  ]