import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import Filter from '../../../inheritance/BaseFilter';
import ListActions from '../../../../components/ListActions';
import { Redirect } from "react-router-dom";
import { Permissions } from '../../../../permissions/permissions';

const RequestedItemTransferFilter = (props) => (
    <Filter {...props}>
    </Filter>
);

const RequestedItemTransferList = props => {
  return (
    <List {...props} actions={<ListActions />}  filters={<RequestedItemTransferFilter />}>
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
        </Datagrid>
    </List>
)};

const RequestedItemTransferEdit = props => {
    return <Redirect
        to={{
            pathname: `/${Permissions.transfer.feature}_${Permissions.requestedItemTransfer.feature}/${props.id}`
        }}
        />
};
export default { list: RequestedItemTransferList, edit: RequestedItemTransferEdit };