import React from "react";
import { DateTimeInput } from "react-admin";

export function DateTimeFilter(props) {
	return (
		<DateTimeInput
			parse={(value) => value}
            {...props}
		/>
	);
}

export default DateTimeFilter;
