import React from 'react';

import { ShowPreview } from '../../../layouts/ShowPreview';
import { ItemEdibleCreate } from './ItemEdibleCreate';
import { ItemEdibleShow } from './ItemEdibleShow';
import { Permissions } from '../../../permissions/permissions';

export default (props) => {
  return (
    <ShowPreview
      data={props.itemEdible}
      title={"Item Edibile"}
      basePath = {`/${Permissions.itemEdible.feature}`}
      resource = {`${Permissions.itemEdible.feature}`}
      redirect={`/item/${props.itemId}/show`}
      hasCreateModal={true}
      create={ItemEdibleCreate}
      createProps={{itemId: props.itemId}}
      createModalProps={{disableBackdropClick: true}}
      hasEditP={true}
      show={ItemEdibleShow}
    >
    </ShowPreview>
  )
}