import React, { cloneElement, useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'ra-core';
import { TopToolbar, CreateButton, Button, sanitizeListRestProps, usePermissions } from 'react-admin';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DvrIcon from '@material-ui/icons/Dvr';
import { rawDataWithHeader2SpreadsheetFormat } from '../components/ImportDataFromFoorban';
import { CrudActions } from '../permissions/permissions';
function CopyRawWithHeadersButton(props) {
    const [status, setStatus] = useState('INITIAL');
    const dataProvider = useDataProvider();
    const notify = useNotify();

    useEffect(() => {
        if (status === 'FETCH_DATA') {
            setStatus('FETCHING_DATA');
            dataProvider('GET_LIST_RAW', props.resource,
                {
                    sort: props.currentSort,
                    filter: { ...props.filterValues, ...props.permanentFilter },
                    pagination: { page: 1, perPage: 5000 },
                    type: 'raw-with-headers'
                })
                .then(({data}) => {
                    const rawText = rawDataWithHeader2SpreadsheetFormat(data);

                    navigator.clipboard.writeText(rawText).then(() => {
                        notify("Dati copiati");
                        setStatus('INITIAL');
                    });
                })
                .catch(() => {
                    setStatus('INITIAL');
                    notify('Errore durante la generazione dei dati');
                });
        }
    }, [dataProvider, notify, props.currentSort, props.filterValues, props.permanentFilter, props.resource, status]);

    return <Button
        disabled={['FETCH_DATA', 'FETCHING_DATA'].includes(status)}
        onClick={() => {
            setStatus('FETCH_DATA');
        }}
        label={'Spreadsheet'}
    >
        <DvrIcon />
    </Button>
}

const ListActions = ({
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter,
    filterValues,
    permanentFilter,
    hasCreate,
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    ...rest
}) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const { permissions } = usePermissions();

    let textArea = null;
    return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <div style={{
            position: "absolute",
            top: "-9999px",
            left: "-9999px"
        }}>
        <textarea
        type="text"
        ref={(textarea) => { textArea = textarea; }} />
        </div>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {!rest.customCreateButton && hasCreate && <CreateButton basePath={basePath} /> }
        {rest.customCreateButton && hasCreate && rest.customCreateButton }
        <Button
            onClick={() => {                
                const { url } = dataProvider('PLAIN_URL', resource, {
                    sort: currentSort,
                    filter: { ...filterValues, ...permanentFilter },
                    pagination: { page: 1, perPage: 1000 },
                });

                textArea.value = url;
                textArea.select();

                try {
                    const success = document.execCommand("copy")
                    if(success) {
                        notify(`${url}`);
                    }
                } catch (err) {
                }            
            }}
            label="Copy"
        >
            <FileCopyIcon />
        </Button>
        {permissions && permissions.includes(`${resource}-${CrudActions.Export}`) && <CopyRawWithHeadersButton 
            resource={resource}
            currentSort={currentSort}
            filterValues={filterValues}
            permanentFilter={permanentFilter}
        />}
    </TopToolbar>
)};

ListActions.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
};

export default ListActions;