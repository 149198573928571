import React from 'react';
import { Show, SimpleShowLayout, TextField, BooleanField, ReferenceField} from 'react-admin';
import Aside from '../inheritance/BaseAside';
import { Permissions } from '../../permissions/permissions';


export const LockerShow = (props) => {
    return (
    <Show aside={<Aside props={props} record={props.record}></Aside>} {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Nome" source="name" />
            <TextField label="Nome per cliente" source="publicName" />
            <BooleanField label="Abilita preordini" source="preOrderPickUp"></BooleanField>
            <ReferenceField label="Lock" source="lockId" reference={`${Permissions.lock.feature}`} link="show">
                <TextField source="name" />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
)};
