import React, {useEffect} from 'react';
import { Datagrid, List, TextField, TopToolbar, useGetOne, Loading as LoadingRA, BooleanField, useRefresh, useDelete } from 'react-admin';
import { Permissions } from '../../../../permissions/permissions';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MUIButton from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { UOM } from '../requestedItemTransfer/RequestItemTransferListPreview';
import { ModalPhysicalItemTransferCreateCreate } from './PhysicalItemTransferCreate';

function Actions({filterValues}) {
    const { data, loading, error } = useGetOne(`${Permissions.transfer.feature}_${Permissions.requestedItemTransfer.feature}`, filterValues["requestedItemTransferId||eq"]);
    const refresh = useRefresh();
    if (loading) { return <LoadingRA />; }
    if (error) { return <TopToolbar><Typography>Errore caricamento</Typography></TopToolbar> }   

    return <TopToolbar>
        {data.expected && <ModalPhysicalItemTransferCreateCreate
            onCreate={() => {
                refresh();
                return `/${Permissions.physicalItemTransfer.feature}?filter=${JSON.stringify({ "requestedItemTransferId||eq": filterValues["requestedItemTransferId||eq"] })}`
            }}
            requestedItemTransfer={data}
            title="Aggiungi Item Fisico"
        />}
    </TopToolbar>
}

export function CustomEditButton({ record }) {
    if(record.status === 'Draft') {
        return  (
            <MUIButton
                color="primary"
                component={Link}
                to={{
                    pathname: `/${Permissions.transfer.feature}_${Permissions.physicalItemTransfer.feature}/${record.id}`
                }}>
                Edit
            </MUIButton>);
    }

    return null;
}

export function CustomCancelButton({ record }) {
    const [deleteOne, { loading, error, loaded }] = useDelete(`${Permissions.transfer.feature}_${Permissions.physicalItemTransfer.feature}`, record.id);
    const refresh = useRefresh();

    useEffect(() => {
        if(loaded) {
            refresh();
        }
    }, [refresh, loaded])

    if (error) { return <TopToolbar><Typography>Errore caricamento</Typography></TopToolbar> }   

    if(record.status === 'Reserved' || record.status === 'Draft') {
        return  (
            <MUIButton
                color="primary"
                variant="contained"
                disabled={loading}
                onClick={deleteOne}>
                Cancel
            </MUIButton>);
    }

    return null;
}

export function PhysicalItemTransferList(props) {
    if(!decodeURIComponent(props.location.search).includes(`?filter={"requestedItemTransferId||eq":`)) {
        return (
            <Card>
                <CardContent>
                <Typography>Flow non corretto</Typography>
                <MUIButton
                    color="primary"
                    component={Link}
                    to={{
                        pathname: '/transfer'
                    }}
                >
                    Torna alla Lista trasferimenti
                </MUIButton>
                </CardContent>
            </Card>
        );
    }
    return (
    <List {...props} actions={<Actions />} filters={null} bulkActionButtons={false}>
        <Datagrid rowClick={null}>
            <BooleanField label="Done" source="done" sortable={false}></BooleanField> 
            <TextField label="Item"  source="item.name" sortable={false}/>
            <TextField label="Lotto" source="lot.name" sortable={false}/>
            <TextField label="Trasferisci da" source="fromWarehouseLocation.name" sortable={false}/>
            <TextField label="Trasferisci a" source="toWarehouseLocation.name" sortable={false}/>    
            <TextField label="Quantità in reference UoM"source="quantityReference" sortable={false}/>
            <UOM label="UoM"></UOM>
            <TextField label="Valore" source="value" sortable={false}/>
            <TextField label="Status" source="status" sortable={false}/>
            <CustomCancelButton></CustomCancelButton>
            <CustomEditButton></CustomEditButton>
        </Datagrid>
    </List>
);}