import React from 'react';
import { Show, SimpleShowLayout, TextField, BooleanField, DateField } from 'react-admin';

export function PromotionShow(props) {
	return (
		<Show  {...props}>
			<SimpleShowLayout>
				<TextField label="ID" source="id" />
				<BooleanField label="Attivo" source="active" />
				<DateField label="Creato il" source="created_at" />
				<DateField label="Aggiornato il" source="updated_at" />
			</SimpleShowLayout>
		</Show>
	)
}
