import React from 'react';

import { Show, SimpleShowLayout, useRefresh, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Button } from '@material-ui/core';
import { AppBar } from '../components/AppBar';
import { Edit as EditIcon } from '@material-ui/icons';
import Aside from '../resources/inheritance/BaseAside';
import { Permissions } from '../permissions/permissions';

const style = makeStyles((theme) => ({
    dialog: {
        width: '60vw',
    }
}));

const ShowPreviewComponent = (props) => {
    const classes = style();
    const [open, setOpen] = React.useState(false);
    const refresh = useRefresh();
    const descriptionElementRef = React.useRef(null);
    const { permissions } = usePermissions();

    React.useEffect(() => {
      if (open) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      }
    }, [open]);

    const CreateComponent = props.create;
    const ShowComponent = props.show;

    if(!permissions)
        return null;

    if(permissions && !permissions.includes(`${props.resource}-${Permissions[props.resource].actions.ReadOne}`))
        return null;

    return (
        <Card>
            <AppBar
                title={props.title}
                action={permissions && permissions.includes(`${props.resource}-${Permissions[props.resource].actions.CreateOne}`) && props.hasCreateModal && !props.data ? () => {setOpen(true)} : null}
                actionTitle={props.actionTitle}
                link={permissions && permissions.includes(`${props.resource}-${Permissions[props.resource].actions.UpdateOne}`) && props.hasEditP && props.data && `${props.basePath}/${props.data.id}`}
                linkTitle={props.linkText}
                linkIcon={props.listIcon}
            ></AppBar>

            {props.data && props.show && <ShowComponent
                title=" "
                {...{
                    permissions: [],
                    basePath: props.basePath,
                    resource: props.resource,
                    id: props.data.id,
                    options: {},
                    hasList: true,
                    hasEdit: false,
                    hasShow: true,
                    hasCreate: true
                }}
                hideActions={true}
                aside={<Aside props={props} record={props.record}></Aside>}>
            </ShowComponent>}

            {props.data && !props.show && <Show
                title=" "
                {...{
                    permissions: [],
                    basePath: props.basePath,
                    resource: props.resource,
                    id: props.data.id,
                    options: {},
                    hasList: true,
                    hasEdit: false,
                    hasShow: true,
                    hasCreate: true
                }}
                aside={<Aside props={props} record={props.record}></Aside>}>
                <SimpleShowLayout>
                    {props.children}
                </SimpleShowLayout>
            </Show>}

            <Dialog   
                open={open}
                onClose={() => {setOpen(false)}}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth='lg'
                {...props.createModalProps}
            >
                <DialogTitle id="scroll-dialog-title">{props.modalSubTitle} {props.title}</DialogTitle>
                <DialogContent  className={classes.dialog} dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        {props.hasCreateModal && <CreateComponent
                            title=" "
                            basePath = {props.basePath}
                            resource = {props.resource}
                            {...props.createProps}
                            redirect={() => {
                                setOpen(false);
                                refresh();
                                return props.redirect
                            }}
                        />}
                    </DialogContentText>
                </DialogContent>
                {props.createModalProps.disableBackdropClick && 
                <DialogActions>
                    <Button onClick={() => {setOpen(false)}} color="primary">
                        CHIUDI
                    </Button>
                </DialogActions>}
            </Dialog>
      </Card>
    )
}

ShowPreviewComponent.defaultProps = {
    hasCreateModal: false,
    hasEditP: false,
    listIcon: <EditIcon />,
    linkText: 'Modifica',
    actionTitle: 'Aggiungi',
    modalSubTitle: 'Nuovo',
    createProps: {},
    createModalProps: {}
}

export const ShowPreview = ShowPreviewComponent;