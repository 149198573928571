import React from 'react';
import { Create, SimpleForm, TextInput, required, NumberInput } from 'react-admin';
import BaseCreate from '../../inheritance/BaseCreate';
import { validateStringLength } from '../validate';

export function LayoutPhotoCreate(props) {
    return (
        <Create {...props}>
            <SimpleForm redirect={props.redirect}>
                <TextInput label="Commento" source="comment" validate={[validateStringLength]} />
                <TextInput label="Url" source="url" validate={[required()]}/>
                <NumberInput  label="Ordine" source="order" validate={[required()]}/>
                <TextInput style={{display: "none"}} disabled label="Layout" source="layoutId" defaultValue={props.layoutId}/>
                <BaseCreate />
            </SimpleForm>
        </Create>
    )
};