import { CoffeeMachineEdit } from "./CoffeeMachineEdit";
import { CoffeeMachineList } from "./CoffeeMachineList";
import { CoffeeMachineCreate } from "./CoffeeMachineCreate";
import { CoffeeMachineShow } from "./CoffeeMachineShow";

export default { edit:CoffeeMachineEdit, list:CoffeeMachineList, create:CoffeeMachineCreate, show: CoffeeMachineShow};

export const Models = [
    'Cimbali-SSeries',
    'Carimali',
    'Saeco',
    'Ottolina-Nessie',
    'Aroma-Polti-Coffea',
    'Aroma-Polti-Aroma',
    'Cimbali SLite',
    'Altro',
];

