import React from 'react';
import { Datagrid, List, TextField, TextInput, BooleanField, BooleanInput, NumberInput } from 'react-admin';
import Filter from '../inheritance/BaseFilter';
import ListActions from '../../components/ListActions';

const LockerFilter = (props) => (
    <Filter {...props}>
        <NumberInput label="ID" source="id||eq" alwaysOn/>
        <TextInput label="Nome" source="name" alwaysOn />
        <TextInput label="Nome per cliente" source="publicName" />
        <BooleanInput label="Preordine abilitato" source="preOrderPickUp" defaultValue={true}></BooleanInput>
    </Filter>
);

export const LockerList = props => (
    <List {...props} actions={<ListActions />} filters={<LockerFilter />}>
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <TextField label="Nome" source="name" />
            <TextField label="Nome per cliente" source="publicName" />
            <BooleanField label="Abilita preordini" source="preOrderPickUp"></BooleanField>
        </Datagrid>
    </List>
);