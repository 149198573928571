import React from 'react';
import { Datagrid, List, TextField, EditButton, ShowButton, ReferenceField, ReferenceInput, AutocompleteInput } from 'react-admin';
import Filter from '../../inheritance/BaseFilter';
import { BulkDeleteButton } from 'react-admin';
import { Permissions } from '../../../permissions/permissions';
import { RecipeTypeList } from '.';

function CoffeeRecipeFilter(props) {
	return (
		<Filter {...props}>
			<ReferenceInput
				alwaysOn
				filterToQuery={searchText => {
					if (!searchText)
						return null;

					return {
						name: searchText
					}
				}}
				label="Item"
				source="itemId"
				reference={`${Permissions.item.feature}`}
			>
				<AutocompleteInput
					optionText={(record) => record ? `${record.id} - ${record.name}` : ''}
					optionValue="id" />
			</ReferenceInput>
			<AutocompleteInput alwaysOn label="Tipo" source="type" choices={RecipeTypeList.map(RecipeType => {
				return {
					id: RecipeType,
					name: RecipeType
				}
			})} />
			<ReferenceInput
				filterToQuery={searchText => {
					if (!searchText)
						return null;

					return {
						name: searchText
					}
				}}
				label="Coffee Type"
				source="coffeeTypeId"
				reference={`${Permissions.coffeeType.feature}`}
			>
				<AutocompleteInput
					optionText={(record) => record ? `${record.id} - ${record.name}` : ''}
					optionValue="id" />
			</ReferenceInput>
			<ReferenceInput
				filterToQuery={searchText => {
					if (!searchText)
						return null;

					return {
						name: searchText
					}
				}}
				label="Solubile 1"
				source="solubleType1Id"
				reference={`${Permissions.solubleType.feature}`}
			>
				<AutocompleteInput
					optionText="internalName"
					optionValue="id" />
			</ReferenceInput>
			<ReferenceInput
				filterToQuery={searchText => {
					if (!searchText)
						return null;

					return {
						name: searchText
					}
				}}
				label="Solubile 2"
				source="solubleType2Id"
				reference={`${Permissions.solubleType.feature}`}
			>
				<AutocompleteInput
					optionText="internalName"
					optionValue="id" />
			</ReferenceInput>
		</Filter>
	);
}

function NonUndoableBulkActionButtons(props) {
	return (
		<React.Fragment>
			{props.buttons}
			<BulkDeleteButton {...props} undoable={false} />
		</React.Fragment>
	);
}
export function CoffeeRecipeList(props) {
	return (
		<List
			{...props}
			bulkActionButtons={<NonUndoableBulkActionButtons buttons={props.bulkActionButtons} />}
			sort={{ field: 'created_at', order: 'DESC' }} filters={<CoffeeRecipeFilter />}>
			<Datagrid rowClick={null}>
				<TextField label="Tipo" source="type" />
				<ReferenceField source="itemId" reference={Permissions.item.feature} link="show">
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField source="coffeeTypeId" reference={Permissions.coffeeType.feature} link="show">
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField label="Solubile 1" source="solubleType1Id" reference={`${Permissions.solubleType.feature}`} link="show">
					<TextField source="internalName" />
				</ReferenceField>
				<ReferenceField label="Solubile 2" source="solubleType2Id" reference={`${Permissions.solubleType.feature}`} link="show">
					<TextField source="internalName" />
				</ReferenceField>
				<EditButton></EditButton>
				<ShowButton></ShowButton>
			</Datagrid>
		</List>
	);
}
