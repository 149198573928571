import React, { useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(8, 2, 0),
    },
    formControl: {
        width: '100%',
        margin: theme.spacing(0, 0, 2),
    },
    button: {
        margin: theme.spacing(2, 0, 0),
    }
}));

export default function InventoryOptions(props) {
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams();

    const [ formData, setFormData ] = useState({
        itemCountMode: {
            value: 'full',
            error: null
        },
        lotCountMode:  {
            value: 'full',
            error: null
        },
        cycleCountType:  {
            value: null,
            error: null
        },
        cycleCountDelayDays:  {
            value: null,
            error: null
        },
    });

    return (
        <Dialog fullScreen open={true} onClose={history.goBack} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={history.goBack} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title} >
                        Configurazione inventario
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container className={classes.container} maxWidth="md" disableGutters>
                <FormControl className={classes.formControl} error={formData.itemCountMode.error}>
                    <InputLabel id="select-label-itemCountMode">ItemCountMode</InputLabel>
                    <Select
                        labelId="select-label-itemCountMode"
                        value={formData.itemCountMode.value}
                        onChange={(event) => {
                            setFormData({
                                ...formData,
                                itemCountMode:{
                                    value: event.target.value,
                                    error: null,
                                }
                            });
                        }}
                    >
                        <MenuItem value="full">Full</MenuItem>
                        <MenuItem value="cycleCount">CycleCount</MenuItem>
                    </Select>
                    {formData.itemCountMode.error && <FormHelperText>{formData.itemCountMode.error}</FormHelperText>}
                </FormControl>
                <FormControl className={classes.formControl} error={formData.cycleCountType.error}>
                    <InputLabel id="select-label-cycleCountType">CycleCountType</InputLabel>
                    <Select
                        labelId="select-label-cycleCountType"
                        value={formData.cycleCountType.value}
                        onChange={(event) => {
                            setFormData({
                                ...formData,
                                cycleCountType:{
                                    value: event.target.value,
                                    error: null,
                                }
                            });
                        }}
                    >
                        <MenuItem value={undefined}><small>elimina valore</small></MenuItem>
                        <MenuItem value="fixed">Fixed</MenuItem>
                        <MenuItem value="warehouse">Warehouse</MenuItem>
                        <MenuItem value="mission">Mission</MenuItem>
                    </Select>
                    {formData.cycleCountType.error && <FormHelperText>{formData.cycleCountType.error}</FormHelperText>}
                </FormControl>
                <FormControl className={classes.formControl} error={formData.cycleCountDelayDays.error}>
                    <TextField
                        label="CycleCountDelayDays"
                        value={formData.cycleCountDelayDays.value}
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(event) => {
                            setFormData({
                                ...formData,
                                cycleCountDelayDays: {
                                    value: event.target.value,
                                    error: null,
                                }
                            });
                        }} />
                    {formData.cycleCountDelayDays.error && <FormHelperText>{formData.cycleCountDelayDays.error}</FormHelperText>}
                </FormControl>
                <FormControl className={classes.formControl} error={formData.lotCountMode.error}>
                    <InputLabel id="select-label-lotCountMode">LotCountMode</InputLabel>
                    <Select
                        labelId="select-label-lotCountMode"
                        value={formData.lotCountMode.value}
                        onChange={(event) => {
                            setFormData({
                                ...formData,
                                lotCountMode:{
                                    value: event.target.value,
                                    error: null,
                                }
                            });
                        }}
                    >
                        <MenuItem value="full">Full</MenuItem>
                        <MenuItem value="partial">Partial</MenuItem>
                    </Select>
                    {formData.lotCountMode.error && <FormHelperText>{formData.lotCountMode.error}</FormHelperText>}
                </FormControl>
                <Button className={classes.button} fullWidth variant="contained" onClick={() => {
                    let errorOccurred = false;
                    if(formData.itemCountMode.value === 'cycleCount' && !['fixed', 'warehouse', 'mission'].includes(formData.cycleCountType.value)) {
                        errorOccurred = true;
                        setFormData({
                            ...formData,
                            cycleCountType:{
                                ...formData.cycleCountType,
                                error: 'Valore non valido',
                            }
                        });
                    }
                    if(formData.cycleCountType.value === 'fixed' && !formData.cycleCountDelayDays.value) {
                        errorOccurred = true;
                        setFormData({
                            ...formData,
                            cycleCountDelayDays:{
                                ...formData.cycleCountDelayDays,
                                error: 'Valore non valido',
                            }
                        });
                    }
                    if(errorOccurred) {
                        return;
                    }
                    const data = {
                        itemCountMode: formData.itemCountMode.value,
                        lotCountMode: formData.lotCountMode.value,
                        cycleCountType: formData.cycleCountType.value,
                    };
                    if(formData.cycleCountDelayDays.value) {
                        data['cycleCountDelayDays'] = parseInt(formData.cycleCountDelayDays.value);
                    }
                    const jsonData = encodeURIComponent(JSON.stringify(data));
                    history.replace(`/inventory-complete/${id}?json_data=${jsonData}`)
                }} color="primary">
                    CONTINUA
                </Button>
            </Container>
        </Dialog>
    );
}

