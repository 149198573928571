import React, { useEffect, useState } from 'react';
import { Edit, SimpleForm, TextInput, ReferenceArrayInput, AutocompleteArrayInput, ReferenceInput, AutocompleteInput} from 'react-admin';
import BaseEdit from '../inheritance/BaseEdit';
import { required } from 'react-admin';
import { Permissions } from '../../permissions/permissions';
import { useForm } from 'react-final-form';

export const CorporateCustomersEdit = props => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput label="Nome" source="name" validate={[required('campo obbligatorio')]} />
			<TextInput label="Nome esterno" source="externalName" validate={[required('campo obbligatorio')]} />
			<ReferenceArrayInput
			validate={[required('campo obbligatorio')]}
				filterToQuery={searchText => {
					if(!searchText)
						return null;

					return {
						name: searchText
					}
				}} label="Lista Domini" source="domainsIds" reference={Permissions.domains.feature}>
				<AutocompleteArrayInput optionText="name" />
			</ReferenceArrayInput>
			<ReferenceArrayInput
				validate={[required('campo obbligatorio')]}
				filterToQuery={searchText => {
					if(!searchText)
						return null;

					return {
						name: searchText
					}
				}} label="Lista Luoghi" source="placesIds" reference={Permissions.place.feature}>
				<AutocompleteArrayInput optionText="name" />
			</ReferenceArrayInput>
			<ReferenceInput
                    validate={[required('campo obbligatorio')]}
                    filterToQuery={searchText => {
                        if (!searchText)
                            return {};

                        return {
                            "name": searchText,
                        }
                    }}
                    label="Cliente B2B"
                    source="b2bClientId"
                    reference={Permissions.B2BClient.feature}
					>
                    <AutocompleteInput
                        optionText="name"
                        optionValue="id" />
            </ReferenceInput>
			<Domains />
			<Places />
			<BaseEdit />
		</SimpleForm>
	</Edit>
);


export function Domains(props) {
	const form = useForm();
	const [status, setStatus] = useState('INIT');

	useEffect(() => {
        if(status === 'INIT') {
			setStatus('LOADED');
            form.change('domainsIds', props.record.domains.map(domain => domain.id));
        }
	}, [form, props.record.domains, status])

	return null;
}

export function Places(props) {
	const form = useForm();
	const [status, setStatus] = useState('INIT');

	useEffect(() => {
        if(status === 'INIT') {
			setStatus('LOADED');
            form.change('placesIds', props.record.places.map(place => place.id));
        }
	}, [form, props.record.places, status])

	return null;
}