import React,  { useState } from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput, AutocompleteInput, FormDataConsumer, required } from 'react-admin';
import BaseCreate from '../../inheritance/BaseCreate';
import { useStore } from 'react-redux';
import { Permissions } from '../../../permissions/permissions';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    form: {
        display: 'flex',            
        flexGrow: 'colums',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
});

export const SupplierPriceListCreate = props => {
	const store = useStore();
	const classes = useStyles();
	const [itemPurchasableId, setItemPurchasableId] = useState(0);

    return (
	<Create {...props}>
		<SimpleForm redirect={props.redirect}>
			<ReferenceInput
				fullWidth
				filterToQuery={searchText => {
				if(!searchText)
					return {};

				return { "item.name": searchText }
				}}
				label="Item Aquistabile" source="itemPurchasableId" reference={`${Permissions.itemPurchasable.feature}`} validate={[required("Indicare l'item è obbligatorio")]}>
			<AutocompleteInput
				shouldRenderSuggestions={val => val && val.length >= 2 }
				options={{suggestionsContainerProps: {disablePortal: true}}}
				optionText="item.name"
				optionValue="id" />
			</ReferenceInput>

			<TextInput fullWidth label="Referenza Item fornitore" source="supplierReference"  validate={[required("Referenza Item fornitore obbligatoria")]}></TextInput>
			<TextInput fullWidth label="Codice Item fornitore" source="supplierCode"></TextInput>

			<NumberInput fullWidth label="Costo senza IVA" helperText="Costo senza IVA per una Unità di costo/ordine/ordine/fornitore" source="costNoTax" validate={[required("Costo senza IVA obbligatoria")]}></NumberInput>
			<FormDataConsumer>
			{({ formData, ...rest }) => {

				if(!formData.itemPurchasableId)
					return null;

				if(itemPurchasableId === 0) {
					setItemPurchasableId(formData.itemPurchasableId);
				}
				else if(itemPurchasableId !== 0 && formData.itemPurchasableId !== itemPurchasableId) {
					formData.costUnitId = undefined;
					formData.orderUnitId = undefined;

					setItemPurchasableId(formData.itemPurchasableId);

					return null;
				}

				const itemPurchasable = store.getState().admin.resources.itemPurchasable.data[formData.itemPurchasableId]

				if(!itemPurchasable)
					return null;

				return <div className={classes.form}>
						<ReferenceInput
							{...rest}
							filter={{ "itemId||eq": itemPurchasable.item.id }}
							label="Unità di fabbisogno"
							source="orderUnitId"
							reference={Permissions.unitOfMeasurement.feature}
							validate={[required("Unità di fabbisogno obbligatoria")]}
							>
							<SelectInput optionText={(record) => record ? `${record.name} ${record.default ? ' - (default)' : ''}` : ''} />
						</ReferenceInput>

						<ReferenceInput
							{...rest}
							filter={{ "itemId||eq": itemPurchasable.item.id }}
							label="Unità di costo/ordine"
							source="costUnitId"
							reference={Permissions.unitOfMeasurement.feature}
							validate={[required("Unità di costo/ordine obbligatoria")]}
							>
							<SelectInput optionText={(record) => record ? `${record.name} ${record.default ? ' - (default)' : ''}` : ''} />
						</ReferenceInput>
					</div>
				}}
			</FormDataConsumer>
	
			<NumberInput fullWidth label="Minimo di ordine" helperText="in unità di costo/ordine/fornitore" source="minimumOrder" validate={[required("Minimo di ordine obbligatoria")]}></NumberInput>
			<NumberInput fullWidth label="Arrotondamento" helperText="in unità di costo/ordine/fornitore" source="rounding" validate={[required("Arrotondamento obbligatoria")]}></NumberInput>

			<TextInput fullWidth label="Nota" source="note"></TextInput>

			<div style={{display: 'none' }}>
				<ReferenceInput disabled source="supplierId" reference={Permissions.supplier.feature} initialValue={props.supplierId} >
					<SelectInput optionText="name" />
				</ReferenceInput>
			</div>
			<BaseCreate />
		</SimpleForm>
	</Create>
);}