import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import BaseCreate from '../../inheritance/BaseCreate';
import { required } from 'react-admin';

export function SolubleTypeCreate(props) {
	return (
		<Create {...props}>
			<SimpleForm redirect="list">
				<TextInput fullWidth validate={[required()]} source="externalName" />
				<TextInput fullWidth validate={[required()]} source="internalName" />
				<BaseCreate />
			</SimpleForm>
		</Create>
	);
}