import React from 'react';
import { Datagrid, List, TextField, TextInput } from 'react-admin';
import Filter from '../inheritance/BaseFilter';
import ListActions from '../../components/ListActions';
import { BulkDeleteButton } from 'react-admin';

const CorporateCustomersFilter = (props) => (
	<Filter {...props}>
		<TextInput label="Nome" source="name" />
		<TextInput label="Nome esterno" source="externalName" />
	</Filter>
);

function NonUndoableBulkActionButtons(props) {
    return (
        <React.Fragment>
        {props.buttons}
        <BulkDeleteButton {...props} undoable={false} />
        </React.Fragment>
    );
}

export const CorporateCustomersList = props => (
	<List {...props}
		bulkActionButtons={<NonUndoableBulkActionButtons buttons={props.bulkActionButtons} />}
		actions={<ListActions />}
		filters={<CorporateCustomersFilter />}>
		<Datagrid rowClick="show">
			<TextField label="ID" source="id" />
			<TextField label="Nome" source="name" />
			<TextField label="Nome esterno" source="externalName" />
		</Datagrid>
	</List>
);