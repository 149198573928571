import React from 'react';
import { Datagrid, List, TextField, TextInput, NumberField } from 'react-admin';
import ListActions from '../../components/ListActions';
import Filter from '../inheritance/BaseFilter';

const ClientClusterFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name" alwaysOn />
    </Filter>
);

export function ClientClusterList(props) {
    return (
        <List {...props} actions={<ListActions />} filters={<ClientClusterFilter />}>
            <Datagrid rowClick="show">
                <TextField label="Name" source="name" />
                <NumberField label="Valore di importanza" source="importanceValue" />
            </Datagrid> 
        </List>
    );
}

