import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField, DateField } from 'react-admin';
import Aside from '../../inheritance/BaseAside';
import { Permissions } from '../../../permissions/permissions';

export const LotShow = (props) => {
	return (
		<Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
			<SimpleShowLayout>
				<TextField label="ID" source="id" />
				<TextField label="Nome" source="name" />
				<ReferenceField label="Item" source="itemId" reference={`${Permissions.item.feature}`} link="show">
					<TextField source="name" />
				</ReferenceField>
				<DateField source="expirationDate" />
				<TextField source="supplierLot" />
				<TextField source="compliantStatus" />
				<DateField source="notCompliantDate" />
				<DateField source="notCompliantComment" />
			</SimpleShowLayout>
		</Show>
	)
};