import React, { useState, useEffect } from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import { ReferenceArrayInput, AutocompleteArrayInput, FormDataConsumer, BooleanInput } from 'react-admin';
import BaseEdit from '../inheritance/BaseEdit';
import { required } from 'react-admin';
import { AUTHTYPE_GOOGLE } from './AuthType';
import { useForm } from 'react-final-form';
import { Permissions } from '../../permissions/permissions';

export const UserEdit = props => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput label="Nome" source="name" validate={[required('Il nome è obbligatorio')]} />
			<div style={{display: "none"}}>
				<TextInput disabled label="Tipologia" source="authType" validate={[required('Il nome è obbligatorio')]} />
			</div>
			<FormDataConsumer>
			{({ formData, ...rest }) => {

				if(formData.authType === AUTHTYPE_GOOGLE)
					return (
						<TextInput
							label="Email"
							source="email"
							validate={[required('Email obbligatoria')]}/>
					);
				
				return null;
			}}
			</FormDataConsumer>
			<ReferenceArrayInput
				filterToQuery={searchText => {
					if(!searchText)
						return {};

					return { name: searchText }
				}} label="Ruoli" source="rolesIds" reference="role">
				<AutocompleteArrayInput optionText="name" />
			</ReferenceArrayInput>
			<ReferenceArrayInput
				enableGetChoices={val => {
					return !!(val && val.name && val.name.length >= 2);
				}}
				filterToQuery={searchText => {
					if(!searchText)
						return {};

					return { name: searchText }
				}} label="Hub" source="hubsIds" reference={Permissions.hub.feature}>
				<AutocompleteArrayInput optionText="name" />
			</ReferenceArrayInput>
			<BooleanInput label="Accedi a tutti gli hub" source="accessAllHubs"/>
			<Roles />
			<RolesHub />
			<BaseEdit />
		</SimpleForm>
	</Edit>
);

export function Roles(props) {
	const form = useForm();
	const [status, setStatus] = useState('INIT');

	useEffect(() => {
        if(status === 'INIT') {
			setStatus('LOADED');
            form.change('rolesIds', props.record.roles.map(role => role.id));
        }
	}, [setStatus, form, props.record.roles, status])

	return null;
}

export function RolesHub(props) {
	const form = useForm();
	const [status, setStatus] = useState('INIT');

	useEffect(() => {
        if(status === 'INIT') {
			setStatus('LOADED');
            form.change('hubsIds', props.record.hubs.map(hub => hub.id));
        }
	}, [setStatus, form, props.record.hubs, status])

	return null;
}