import React from 'react';
import { Datagrid, List, TextField, BooleanField, TextInput, AutocompleteInput, BooleanInput } from 'react-admin';
import Filter from '../inheritance/BaseFilter';
import ListActions from '../../components/ListActions';
import { LINK_TYPE } from './enum';

const CodeFilter = (props) => (
	<Filter {...props}>
		<TextInput label="Nome" source="name" alwaysOn />
		<TextInput label="Slug" source="customSlug" />
		<TextInput label="URL" source="url" />
		<AutocompleteInput
				label="Tipo"
				source="type"
				choices={LINK_TYPE.map(type => {
					return {
						id: type,
						name: type
					}
			})} />
		<BooleanInput label="Abilitato" source="enable" defaultValue={true}></BooleanInput>
	</Filter>
);

export const CodeList = props => (
	<List {...props} actions={<ListActions />}  filters={<CodeFilter />}>
		<Datagrid rowClick="show">
			<BooleanField label="Abilitato" source="enable" />
			<TextField label="Nome" source="name" />
			<TextField label="URL" source="url" />
			<TextField label="Tipo" source="type" />
			<TextField label="Slug" source="customSlug" />
		</Datagrid>
	</List>
);