import React, { useState, useEffect } from 'react';
import { GET_LIST, useDataProvider, usePermissions } from 'react-admin';

import { Permissions } from '../../../permissions/permissions';
import { ShowPreview } from '../../../layouts/ShowPreview';
import { LockerSellingOrderShow } from '../../transaction/lockerSellingOrder/LockerSellingOrderShow';

export default (props) => {
	const dataProvider = useDataProvider();
	const [lockerSellingOrderData, setLockerSellingOrderData] = useState(null);
	const { permissions } = usePermissions();

	useEffect(() => {
		if(permissions) {
				permissions.includes(`${Permissions.lockerSellingOrder.feature}-${Permissions.lockerSellingOrder.actions.ReadOne}`) && props.sellingOrderId &&
				dataProvider(GET_LIST,`${Permissions.lockerSellingOrder.feature}`, {
					filter: { "sellingOrderId||eq": props.sellingOrderId },
					pagination: {
						page: 1,
						perPage: 2
					}
				})
				.then(({data}) => {
					if(data.length > 0) {
						setLockerSellingOrderData(data[0]);
					}
				});
		}
	}, [dataProvider, setLockerSellingOrderData, props.sellingOrderId, permissions]);

	return (
		<ShowPreview
			data={lockerSellingOrderData}
			title={"Dati Vendita Impuso da App"}
			basePath = {`/${Permissions.lockerSellingOrder.feature}`}
			resource = {`${Permissions.lockerSellingOrder.feature}`}
			show={LockerSellingOrderShow}
		>
		</ShowPreview>
	);
};