import React from 'react';
import { Show, SimpleShowLayout, TextField} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';
import Aside from '../../inheritance/BaseAside';
import StateMachineHistoryListPreview  from '../stateMachineHistory/stateMachineHistoryListPreview';
import StateMachineField from '../../../components/StateMachineField';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1)
  }
}));

export const OpsStateMachineShow = (props) => {
    const classes = useStyles();
    return (
        <Container maxWidth="xl" className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} xs={12}>
            <Show
              {...props}
              aside={<Aside props={props} record={props.record}></Aside>}
            >
                <SimpleShowLayout>
                    <TextField label="Status" source="status" />
                    <TextField label="Versione Configurazione" source="configVersion" />
                    <TextField label="Tipo" source="type" />
                    <TextField label="Owner" source="ownerType" />
                    <TextField label="OwnerId" source="ownerId" />
                    <StateMachineField
                      label="Transizioni Manuali"
                      source="orderStateMachine">
                    </StateMachineField>
                </SimpleShowLayout>
            </Show>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
            <StateMachineHistoryListPreview
              stateMachineId={props.id}
            ></StateMachineHistoryListPreview>
          </Grid>
        </Grid>
      </Container>
)};