import React, { useState, useEffect } from 'react';
import { useDataProvider, Loading } from 'react-admin';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import { POST_OFF_RESOURCE } from '../../providers/nestjs_crud';
import Typography from '@material-ui/core/Typography';
import MUITextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AsyncSelect from 'react-select/async';
import FormHelperText from '@material-ui/core/FormHelperText';
import { debounce } from '../../utils/debounce';

async function update(rawValues, dataProvider, config, path) {
    const values = JSON.parse(JSON.stringify(rawValues));

    const valueToShow = values.filter(value => {
        return (values.filter(valueDependency => {
            return config[value.key].dependsOn.includes(valueDependency.key) && !valueDependency.selected
        })).length === 0
    })

    const valuesToFetch = valueToShow.filter(value => {
        return value.awaitingDependency
    });

    if (valuesToFetch.length === 0) {
        return values;
    }

    for (const valueToFetch of valuesToFetch) {
        const index = values.findIndex((element) => element.key === valueToFetch.key);
        let result;
        try {
            result = await dataProvider(POST_OFF_RESOURCE, `${path}/${valueToFetch.key}`, {
                data: values.reduce(function (acc, cur) {
                    acc[cur.key] = cur.value;
                    return acc;
                }, {})
            })
        } catch (error) {
            values[index].error = 'ERROR_FETCHING_FIELD';
            values[index].awaitingDependency = false;
            return update(values, dataProvider, config, path);
        }

        if (result.data.label) {
            values[index].label = result.data.label;
        }

        if (config[valueToFetch.key].type === 'select') {
            const selected = result.data.find(formDataItem => {
                return formDataItem.selected;
            });
            if (selected) {
                values[index].value = selected.id;
                values[index].selected = true;
            }
            values[index].formData = [{
                value: '',
                label: 'NULL'
            }].concat(result.data.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            }));
            values[index].formData.push()
        } else if(['date', 'datetime-local'].includes(config[valueToFetch.key].type) && values[index].value) {
            function formatDateTime(date) {
                var d = new Date(date);
                return d.toISOString().split('.')[0];
            }
            
            function formatDate(date) {
                var d = new Date(date);
                return d.toISOString().split('T')[0];
            }
    
            values[index].value = 'date' === config[valueToFetch.key].type ? formatDate(values[index].value) : formatDateTime(values[index].value)

            values[index].formData = result.data;
        } else {
            values[index].formData = result.data;
        }
        values[index].awaitingDependency = false;
    }
    return update(values, dataProvider, config, path);
}

function resetMyDependencies(key, rawValues, config) {
    let values = JSON.parse(JSON.stringify(rawValues));

    const valuesFiltered = values.filter(value => {
        return value.key !== key && config[value.key].dependsOn.includes(key) && (value.error !== null || value.selected === true || value.value !== null || value.awaitingDependency === false)
    })

    if (valuesFiltered.length === 0) {
        return values;
    }

    valuesFiltered.forEach(value => {
        const index = values.findIndex((element) => element.key === value.key);
        values[index].error = null;
        values[index].selected = false;
        values[index].value = null;
        values[index].awaitingDependency = true;
        values = resetMyDependencies(value.key, values, config);
    })

    return values;
}


export default function Form(props) {
    const [status, setStatus] = useState('FETCH_DATA');
    const dataProvider = useDataProvider();
    const history = useHistory();
    const id  = props.id;
    const [values, setValues] = useState();
    const config = props.config;
    const [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
        if(status === 'FETCH_DATA' && !id) {
            setValues(Object.keys(config).map((configItemKey) => {
                return {
                    key: configItemKey,
                    selected: false,
                    value: null,
                    formData: null,
                    error: null,
                    awaitingDependency: true,
                    label: config[configItemKey].defaultLabel
                }
            }))
            setStatus('INITIAL');
        }

        if(status === 'FETCH_DATA' && id) {
            setStatus('FETCHING_DATA')
            dataProvider
            .getOne(props.src, { id })
            .then(({ data }) => {
                setValues(Object.keys(config).map((configItemKey) => {
                    return {
                        key: configItemKey,
                        selected: false,
                        value: data[configItemKey],
                        formData: null,
                        error: null,
                        awaitingDependency: true,
                        label: config[configItemKey].defaultLabel
                    }
                }))
                setStatus('INITIAL');
            })
            .catch(error => {
                if(error && error.statusCode === 401) {
                    setStatus('ERROR_401')
                } else {
                    setStatus('ERROR_FETCHING_DATA');
                }
            });
        }
        if (status === 'INITIAL') {
            setStatus('INITIALIZING')
            update(values, dataProvider, config, props.path)
                .then(newValues => {
                    setValues(newValues);
                    setStatus('VALUE_READY')
                })
                .catch(() => {
                    setStatus('ERROR_INITIALIZING');
                });
        }

        if (status === 'SEND_DATA') {
            setStatus('SENDING_DATA');
            let data = values.reduce(function (acc, cur) {
                acc[cur.key] = cur.value || null;
                return acc;
            }, {});

            if(props.addData) {
                data = props.addData(data);
            }

            if(id) {
                data.id = id;
                dataProvider
                    .update(props.src, {
                        id,
                        data,
                    })
                    .then(({ data }) => {
                        history.goBack();
                    })
                    .catch(error => {
                        if(error && error.statusCode === 401) {
                            setStatus('ERROR_401')
                        } else {
                            setStatus('ERROR_SENDING_DATA');
                            setErrorMessage(typeof error === 'string' ? error : JSON.stringify(error));
                        }
                    });
            } else {
                dataProvider
                    .create('transfer', {
                        data
                    })
                    .then(({ data }) => {
                        history.goBack();
                    })
                    .catch(error => {
                        if(error && error.statusCode === 401) {
                            setStatus('ERROR_401')
                        } else {
                            setStatus('ERROR_SENDING_DATA');
                            setErrorMessage(typeof error === 'string' ? error : JSON.stringify(error));
                        }
                    });
            }
        }
    }, [dataProvider, history, status, values, id, config, props])

    const onChange = (value, key, config, path) => {
        setStatus('START_UPDATE');

        const newValues = JSON.parse(JSON.stringify(values));
        const index = values.findIndex((element) => element.key === key);

        newValues[index].value = value;
        newValues[index].selected = true;

        const resettedValues = resetMyDependencies(key, newValues, config);

        update(resettedValues, dataProvider, config, path)
            .then(updatedValue => {
                setValues(updatedValue);
                setStatus('VALUE_READY')
            })
            .catch((error) => {
                if(error && error.statusCode === 401) {
                    setStatus('ERROR_401')
                } else {
                    setStatus('ERROR')
                }
            });
    }

    return <div style={{padding: '16px'}}>
        <Typography variant="h5" gutterBottom component="div">
           {props.title}
        </Typography>
        {[
            'ERROR_401',
        ].includes(status) && <>
            <Typography variant="h4" gutterBottom component="div">
                Errore di autorizzazione
            </Typography>
            <Button fullWidth variant="contained" color="primary" size="large"  onClick={() => {
                localStorage.removeItem('token');
                history.push(`/login`);
            }}>VAI AL LOGIN</Button>
        </>}
        {[
            'ERROR_FETCHING_DATA',
            'ERROR_INITIALIZING',
            'ERROR_SENDING_DATA',
            'ERROR',
        ].includes(status) && <>
            <Typography variant="h5" gutterBottom component="div">
                {status === 'ERROR_FETCHING_DATA' && 'Errore caricamento dati'}
                {status === 'ERROR_INITIALIZING' && 'Errore processamento'}
                {status === 'ERROR_SENDING_DATA' && 'Errore invio dati'}
                {status === 'ERROR' && 'Errore non riconosciuto'}
            </Typography>
            {errorMessage && <Typography>
                {errorMessage}
            </Typography>}
            <Button fullWidth variant="contained" color="primary" size="large"  onClick={() => {
                if(status === 'ERROR_FETCHING_DATA') {
                    setStatus('FETCH_DATA')
                } else {
                    setStatus('INITIAL')
                }
                setErrorMessage('');
            }}>RIPROVA</Button>
        </>}
        {status === 'VALUE_READY' && values.map((value) => {
            return <FormControl style={{marginBottom: '16px' }} fullWidth key={value.key}>   
                <Typography gutterBottom={config[value.key].type === 'select' || (config[value.key].type !== 'select' && (!value.formData && value.awaitingDependency))}>{value.label}</Typography>
                {value.formData && !value.awaitingDependency && config[value.key].type === 'select' && <AsyncSelect
                isDisabled={config[value.key].disabled}
                isSearchable={config[value.key].search === true}
                loadOptions={debounce((inputValue, callback) => {
                    if(config[value.key].search === true && inputValue.length > 2){
                        let searchParams = new URLSearchParams({
                            search: inputValue,
                        });
                        dataProvider(POST_OFF_RESOURCE, `${props.path}/${value.key}?${searchParams.toString()}`, {
                            data: values.reduce(function (acc, cur) {
                                acc[cur.key] = cur.value;
                                return acc;
                            }, {})
                        }).then(({data}) => {
                            const result = data.map(item => {
                                return {
                                    value: item.id,
                                    label: item.name
                                }
                            })
                            const copyValues = JSON.parse(JSON.stringify(values));

                            const index = copyValues.findIndex((valueItem) => {
                                return value.key === valueItem.key;
                            });
                            copyValues[index].formData = result;
                            const selected = result.find(formDataItem => {
                                return formDataItem.selected;
                            });
                            if (selected) {
                                copyValues[index].value = selected.id;
                            }
                            setValues(copyValues);
                            callback(result);
                        })
                        .catch(error => {
                            if(error && error.statusCode === 401) {
                                setStatus('ERROR_401');
                            } else {
                                setStatus('ERROR')
                            }
                        })
                    } else {
                        callback(value.formData);
                    }
                }, 1000)}
                defaultOptions={value.formData}
                value={value.value ? value.formData.find(formDataItem => {
                    return formDataItem.value === value.value
                }) : null}
                onChange={e => {
                    onChange(e.value, value.key, config, props.path);
                }}
                />}
                {value.formData && !value.awaitingDependency && config[value.key].type === 'text' && <TextField disabled={config[value.key].disabled} value={value.value} valueKey={value.key} onChange={(value, key) => onChange(value, key, config, props.path)} />}
                {value.formData && !value.awaitingDependency && config[value.key].type === 'date' && <TextField disabled={config[value.key].disabled} value={value.value} type="date" valueKey={value.key} onChange={(value, key) => onChange(value, key, config, props.path)} />}
                {value.formData && !value.awaitingDependency && config[value.key].type === 'datetime-local' && <TextField disabled={config[value.key].disabled} value={value.value} type="datetime-local" valueKey={value.key} onChange={(value, key) => onChange(value, key, config, props.path)} />}
                {value.formData && !value.awaitingDependency && config[value.key].type === 'number' && <TextField disabled={config[value.key].disabled} value={value.value} type="number" valueKey={value.key} onChange={(value, key) => {onChange(parseFloat(value), key, config, props.path)}} />}
                {value.error && <FormHelperText error={true}>{value.error}</FormHelperText>}
            </FormControl>
        })}
        {status === 'VALUE_READY' && props.children}
        {status === 'VALUE_READY' && <Button fullWidth variant="contained" color="primary" size="large"  onClick={() => {
            setStatus('SEND_DATA')
        }}>{props.buttonTitle}</Button>}
        {[
            'FETCH_DATA',
            'INITIAL',
            'FETCHING_DATA',
            'INITIALIZING',
            'SEND_DATA',
            'SENDING_DATA',
            'START_UPDATE'
        ].includes(status) && <Loading />}
    </div>
}

function TextField(props) {
    const [value, setValue] = useState(props.value);
    const [status, setStatus] = useState('INITAL');

    useEffect(() => {
        if (status === 'SEND_VALUE') {
            if (value || (props.value !== value)) {
                props.onChange(value, props.valueKey);
            } else {
                setStatus('INITIAL')
            }
        }
    }, [status, props, value]);


    return (
        <MUITextField
            value={value}
            type={props.type || 'text'}
            openPopup={['date', 'datetime-local'].includes(props.type)}
            onFocus={() => { setStatus('FOCUS') }}
            onBlur={() => setStatus('SEND_VALUE')}
            onChange={(e) => setValue(e.target.value)}
            placeholder={['date', 'datetime-local'].includes(props.type) ? '' : 'Digita il testo'}
            InputProps={{
                [['date', 'datetime-local'].includes(props.type) ? 'startAdornment' : 'endAdornment']: status === 'FOCUS' ? <InputAdornment position={['date', 'datetime-local'].includes(props.type) ? 'start' : 'end'}>
                    <Button onClick={() => { setStatus('SEND_VALUE') }} variant="contained" color="primary" size="small" endIcon={<CheckCircleOutlineIcon />}>
                        DONE
                    </Button>
                </InputAdornment> : null
            }}
        />
    );
}
