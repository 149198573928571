import React from 'react';
import { Datagrid, List, TextField, EditButton, ShowButton, TextInput, AutocompleteInput } from 'react-admin';
import Filter from '../../inheritance/BaseFilter';
import { BulkDeleteButton } from 'react-admin';
import { TypeList } from './';

function CoffeeTypeFilter(props) {
	return (
		<Filter {...props}>
			<TextInput source="name" alwaysOn />
			<AutocompleteInput fullWidth label="Tipo" source="type" choices={TypeList.map(type => {
				return {
					id: type,
					name: type
				}
			})} />
		</Filter>
	);
}

function NonUndoableBulkActionButtons(props) {
    return (
        <React.Fragment>
        {props.buttons}
        <BulkDeleteButton {...props} undoable={false} />
        </React.Fragment>
    );
}
export function CoffeeTypeList(props) {
	return (
		<List
			{...props}
			bulkActionButtons={<NonUndoableBulkActionButtons buttons={props.bulkActionButtons} />}
			sort={{ field: 'created_at', order: 'DESC' }} filters={<CoffeeTypeFilter />}>
			<Datagrid rowClick={null}>
				<TextField source="type" />
				<TextField source="name" />
				<EditButton></EditButton>
				<ShowButton></ShowButton>
			</Datagrid>
		</List>
	);
}
