const CrudActions = {
	ReadAll: "Read-All",
	ReadOne: "Read-One",
	CreateOne: "Create-One",
	CreateMany: "Create-Many",
	UpdateOne: "Update-One",
	ReplaceOne: "Replace-One",
	DeleteOne: "Delete-One",
	Export: "Export",
	ActivateOne: 'Activate-One',
};
const StateMachineActions = {
	get: "get",
	getTransitions: "getTransitions",
	doTransition: "doTransition",
};

export const Permissions = {
	missionV2: {
		feature: "missionV2",
		subFeatures: {
			stateMachine: {
				name: "stateMachine",
				actions: { ...StateMachineActions },
			},
		},
		actions: {
			...CrudActions,
			ReturnJobs: "Return-Jobs",
			RoutingToolSync: "RoutingToolSync",
			RoutingToolCreate: "RoutingToolCreate",
			ForceBackToBePlanned: "ForceBackToBePlanned",
		},
	},
	opsStateMachineHistory: {
		feature: "opsStateMachineHistory",
		actions: {
			ReadAll: CrudActions.ReadAll,
			ReadOne: CrudActions.ReadOne,
			Export: CrudActions.Export,
		},
	},
	deliveryManMission: {
		feature: "deliveryManMission",
		actions: {
			ReadAll: CrudActions.ReadAll,
			ReadOne: CrudActions.ReadOne,
			doAction: "OpsDeliveryMan-Mission-doAction",
			Export: CrudActions.Export,
		},
	},
	deliveryManMissionJob: {
		feature: "deliveryManMissionJob",
		actions: {
			ReadAll: CrudActions.ReadAll,
			ReadOne: CrudActions.ReadOne,
			ReadOneTask: "OpsDeliveryMan-MissionJob-Read-One-Task",
			ReadAllTask: "OpsDeliveryMan-MissionJob-Read-All-Task",
			doAction: "OpsDeliveryMan-MissionJob-doAction",
			doOpenLocker: "OpsDeliveryMan-MissionJob-doOpenLocker",
			Export: CrudActions.Export,
		},
	},
	deliveryManTransferTask: {
		feature: "deliveryManTransferTask",
		actions: {
			doAction: "OpsDeliveryMan-TransferTask-doAction",
		},
	},
	deliveryManInventoryTask: {
		feature: "deliveryManInventoryTask",
		actions: {
			doAction: "OpsDeliveryMan-InventoryTask-doAction",
		},
	},
	deliveryManFormTextTask: {
		feature: "deliveryManFormTextTask",
		actions: {
			doAction: "OpsDeliveryMan-FormTextTask-doAction",
		},
	},
	deliveryManFormDropdownTask: {
		feature: "deliveryManFormDropdownTask",
		actions: {
			doAction: "OpsDeliveryMan-FormDropdownTask-doAction",
		},
	},
	deliveryManFormPhotosTask: {
		feature: "deliveryManFormPhotosTask",
		actions: {
			doAction: "OpsDeliveryMan-FormPhotosTask-doAction",
			getPresignedUrl: "OpsDeliveryMan-FormPhotosTask-getPresignedUrl",
		},
	},
	missionJob: {
		feature: "missionJob",
		actions: {
			FieldOptions: "FieldOptions",
			ReadAll: CrudActions.ReadAll,
			ReadOne: CrudActions.ReadOne,
			Export: CrudActions.Export,
		},
		subFeatures: {
			stateMachine: {
				name: "stateMachine",
				actions: { ...StateMachineActions },
			},
		},
	},
	missionJobTransferAssignment: {
		feature: "missionJobTransferAssignment",
		actions: {
			ReadAll: CrudActions.ReadAll,
			Assignment: "Assignment",
		},
	},
	jobTask: {
		feature: "jobTask",
		actions: {
			ReadAll: CrudActions.ReadAll,
			ReadOne: CrudActions.ReadOne,
			CreateOne: CrudActions.CreateOne,
			UpdateOne: CrudActions.UpdateOne,
			Export: CrudActions.Export,
		},
		subFeatures: {
			stateMachine: {
				name: "stateMachine",
				actions: { ...StateMachineActions },
			},
		},
	},
	taskConfig: {
		feature: "taskConfig",
		actions: {
			ReadAll: CrudActions.ReadAll,
			ReadOne: CrudActions.ReadOne,
			CreateOne: CrudActions.CreateOne,
			UpdateOne: CrudActions.UpdateOne,
			DeleteOne: CrudActions.DeleteOne,
			Export: CrudActions.Export,
		},
	},
	recurringMission: {
		feature: "recurringMission",
		actions: {
			...CrudActions,
		},
		subFeatures: {
			stateMachine: {
				name: "stateMachine",
				actions: { ...StateMachineActions },
			},
		},
	},
	recurringMissionJob: {
		feature: "recurringMissionJob",
		actions: {
			FieldOptions: "FieldOptions",
			ReadAll: CrudActions.ReadAll,
			ReadOne: CrudActions.ReadOne,
			CreateOneJob: "Create-One-Job",
			UpdateOneJob: "Update-One-Job",
			DeleteOneJob: "Delete-One-Job",
		},
	},
	recurringMissionExecution: {
		feature: "recurringMissionExecution",
		actions: {
			ReadAll: CrudActions.ReadAll,
			ReadOne: CrudActions.ReadOne,
			Execute: "execute",
			ExecuteForce: "execute-force",
			ExecuteCreateAgain: "execute-create-again",
		},
	},
	routing: {
		feature: "routing",
		actions: {
			routingAccounts: "routingAccounts",
		},
	},
};
