import React, { useState } from "react";
import { Button, Typography, AppBar, Toolbar, Card, Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';  
import {  useDataProvider, showNotification } from 'react-admin';
import { useDispatch } from 'react-redux';
import { Permissions } from '../../../../../permissions/permissions';
import { GET_OFF_RESOURCE_PLAIN } from '../../../../../providers/nestjs_crud';

const useStyles = makeStyles((theme) => ({
    title: {
		flexGrow: 1
	}
}));

export const LoadTask = (props) => {
    const { task } = props;
	const [state, setState] = useState('INITIAL')
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
	const classes = useStyles();

	const doAction = (action) => {
		setState('FETCHING_DATA')
		dataProvider(GET_OFF_RESOURCE_PLAIN, `${Permissions.deliveryManTransferTask.feature}/${task.id}/do-action/${action}`)
		.then((rawResponse) => {
			const response = JSON.parse(rawResponse)
			if(response.success) {
				props.onDone && props.onDone();
				dispatch(showNotification(`azione: ${action} completata`));
				setState('INITIAL')
			} else if(response.errors) {
				const message = response.errors.map(({constraints}) => {
					return Object.keys(constraints).map(key => constraints[key]).join(', ');
				}).join(', ');

				throw new Error(message)
			} else {
				throw new Error('unknown error')
			}
		})
		.catch(error => {
			setState('INITIAL')
			dispatch(showNotification(`Errore durante azione: ${action} ${error}`, 'error'));
		})
		}

	if (state === 'FETCHING_DATA'){
		return ( 
		<Box textAlign='center'>
			<CircularProgress size={25} />
		</Box>  
		)
	}

    return (
        <Card>
			<AppBar color="default" position="static">
				<Toolbar>
					<Typography variant="h5" className={classes.title}>
						Carico
					</Typography>
					{task.config.transfer && task.config.transfer.transferStateMachine &&
						<LoadTransfer
							completeLoad={doAction}
							status={task.config.transfer.transferStateMachine.status}
							taskStatus={task.status}
							/>}
				</Toolbar>
			</AppBar>
		</Card>
    )
}

function LoadTransfer(props) {
	switch (props.status) {
		case 'delivering':
			const actionButton = []

			if(props.taskStatus === 'WIP') {
				actionButton.push(<Button
					size="large"
					onClick={() => props.completeLoad('complete-load')}
					variant="contained"
					color="primary">
						COMPLETA
					</Button>)
			} 

			if(props.taskStatus === 'TO_DO') {
				actionButton.push(
					<Button
					size="large"
					onClick={() => props.completeLoad('start-load')}
					variant="contained"
					color="primary">
						INIZIA
					</Button>
				);

			}

			return actionButton;
			
		case 'completed':
			return (
				<Typography variant="subtitle1">
					Completato
				</Typography>
			);
		default:
			return (
				<Typography variant="subtitle1">
					Errore contattare Hub
				</Typography>
			);
	}
}
