import React from 'react';
import { Create, SimpleForm, TextInput, AutocompleteInput } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { required } from 'react-admin';
import { LINK_TYPE, LINK_INTERNAL } from './enum';

const maxLength = (max, message = 'Too long') =>
	value => value && value.length > max ? message : undefined;

export const url = (message = 'Not Valid') => {
	return (input, dto) => {
		if(!input || !dto.type)
			return undefined;

		if(dto.type === LINK_INTERNAL)
			return undefined;

		var expression = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
		var regex = new RegExp(expression);

		return input.match(regex) ? undefined : message;
	}
}
export const slug = (message = 'Inserisci caratteri alfanumerici minuscoli') => {
	return (input) => {
		if(!input)
			return undefined;

		var expression = "^[a-z0-9]+$";
		var regex = new RegExp(expression);

		return input.match(regex) ? undefined : message;
	}
}

export const CodeCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<TextInput label="Nome" source="name" validate={[required('campo obbligatorio')]} />
			<AutocompleteInput
				label="Tipo"
				source="type"
				choices={LINK_TYPE.map(type => {
					return {
						id: type,
						name: type
					}
			})} />
			<TextInput label="URL" source="url" validate={[url(), required('campo obbligatorio')]} />
			<TextInput label="Slug" source="customSlug" validate={[maxLength(20), slug()]} helperText={"tenere corto, meno di 10 caratteri (solo alfanumerici minuscoli) possibilimente"} />
			<BaseCreate />
		</SimpleForm>
	</Create>
);