import React from 'react';
import { Show, SimpleShowLayout, TextField, BooleanField } from 'react-admin';
import { Typography, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Aside from '../inheritance/BaseAside';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
}));
  
const PermissionsField = ({ record }) => {
    const classes = useStyles();
    return (
        <div>
            <Typography variant="h6">
                Permessi
            </Typography>
            <div className={classes.root}>
                {record.permissions.map(permission => (
                    <Chip key={permission} label={permission} />
                ))}
            </div>
        </div>
    )
};

export const RoleShow = (props) => {
    return (
    <Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField label="Nome" source="name" />
            <BooleanField label="ADMIN" source="admin"></BooleanField>
            <PermissionsField></PermissionsField>
        </SimpleShowLayout>
    </Show>
)};
