import React from 'react';
import { Edit, SimpleForm, TextInput, FormDataConsumer, ReferenceInput, AutocompleteInput } from 'react-admin';
import BaseEdit from '../../inheritance/BaseEdit';
import { required } from 'react-admin';
import { useForm } from 'react-final-form';
import { WarehouseLocationType_Warehouse } from './enum';
import RichTextInput from 'ra-input-rich-text';
import { Permissions } from '../../../permissions/permissions';
import { WasteInventory, Owner, DDTEmail } from './WarehouseLocationCreate';
import { Locker } from './WarehouseLocationCreate';

const Relation = () => {
    const form = useForm();

    if(!form.getFieldState('type') || (form.getFieldState('type') && !form.getFieldState('type').value)) {
		return null;
    }

    if(form.getFieldState('type').value === WarehouseLocationType_Warehouse) {
        return  (
            <ReferenceInput
                validate={[required('campo obbligatorio')]}
                filterToQuery={searchText => {
                    if(!searchText)
                        return null;

                    return { "name": searchText }
                }}
                label="Luogo"
                source="placeId"
                reference={`${Permissions.place.feature}`}
            >
                <AutocompleteInput
                    allowEmpty
                    shouldRenderSuggestions={val => val && val.length >= 2 }
                    optionText={(record)=> `${record ? record.id : ''} ${record ? record.name : ''}`}
                    optionValue="id"
                />
            </ReferenceInput>
        );
    }

    return null;
}

export const WarehouseLocationEdit = props => (
    <Edit {...props}>
        <SimpleForm redirect="show">
            <TextInput label="Nome" source="name" validate={[required('campo obbligatorio')]} />
            <TextInput label="Nome Corto" source="shortName" validate={[required('campo obbligatorio')]} />
            <TextInput label="Tipo" disabled source="type" />
            <TextInput label="Utilizzo" disabled source="usage" />
            <FormDataConsumer>
                {formDataProps => (
                    <Relation {...formDataProps} />
                )}
            </FormDataConsumer>
            <RichTextInput
                label="Nota"
                source="note" />
            <FormDataConsumer>
                {formDataProps => (
                    <WasteInventory {...formDataProps} />
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {formDataProps => (
                    <Locker {...formDataProps} />
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {formDataProps => (
                    <Owner {...formDataProps} />
                )}
            </FormDataConsumer>
            <FormDataConsumer>
                {formDataProps => (
                    <DDTEmail {...formDataProps} />
                )}
            </FormDataConsumer>
            <ReferenceInput
                filterToQuery={searchText => {
                    if(!searchText)
                    return null;

                    return {
                        name: searchText,
                    }
                }}
                source="businessUnitId"
                reference={`${Permissions.businessUnit.feature}`}>
                    <AutocompleteInput
                        optionText="name"
                        optionValue="id" />
            </ReferenceInput>
            <TextInput label="Note per corriere" source="deliveryManNote" fullWidth />
            <BaseEdit />
        </SimpleForm>
    </Edit>
);