import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { required } from 'react-admin';
import Typologies from '../../utils/enum/Typology';
import { Permissions } from '../../permissions/permissions';

export const SellingPointCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Nome" source="name" validate={[required('Il nome è obbligatorio')]} />
            <ReferenceInput
                validate={[required("Il luogo è obbligatorio")]}
                filter={{"canSell||eq": true}}
                filterToQuery={searchText => {
                    if(!searchText)
                        return {};

                    return {
                        "name": searchText,
                        "canSell||eq": true
                    }
                }}
                label="Luogo"
                source="placeId"
                reference={Permissions.place.feature}
                allowEmpty>
                    <AutocompleteInput
                        optionText="name"
                        optionValue="id" />
            </ReferenceInput>
            <AutocompleteInput label="Tipologia" source="typology" choices={Typologies.map(typology => {
                return {
                    id: typology,
                    name: typology
                }
            })} />
            <ReferenceInput
                filter={{"usage||eq": "Storage"}}
                filterToQuery={searchText => {
                    if(!searchText)
                        return {};

                    return {
                        "name": searchText,
                        "usage||eq": "Storage"
                    }
                }}
                label="Magazzino Storage"
                source="storageWarehouseLocationId"
                reference={Permissions.warehouseLocation.feature}
                allowEmpty>
                    <AutocompleteInput
                        optionText="name"
                        optionValue="id" />
            </ReferenceInput>
            <ReferenceInput
                filter={{"usage||eq": "Client"}}
                filterToQuery={searchText => {
                    if(!searchText)
                        return {};

                    return {
                        "name": searchText,
                        "usage||eq": "Client"
                    }
                }}
                label="Magazzino Cliente"
                source="clientWarehouseLocationId"
                reference={Permissions.warehouseLocation.feature}
                allowEmpty>
                    <AutocompleteInput
                        optionText="name"
                        optionValue="id" />
            </ReferenceInput>
            <TextInput label="Legacy IDs" helperText="(comma separated)" source="legacyIDs" />
            <AutocompleteInput
				label="WarehouseType"
				source="warehouseType"
				choices={['not-use', 'official', 'accr'].map(warehouseType => {
					return {
						id: warehouseType,
						name: warehouseType
					}
			})} />
            <AutocompleteInput
				label="LotLogic"
				source="lotLogic"
				choices={['LEFO', 'FEFO'].map(lotLogic => {
					return {
						id: lotLogic,
						name: lotLogic
					}
			})} />
            <ReferenceInput
                allowEmpty
                filterToQuery={searchText => {
                    if(!searchText)
                        return {};

                    return {
                        "name": searchText,
                    }
                }}
                label="Hub di riferimento"
                source="hubId"
                reference={Permissions.hub.feature}>
                    <AutocompleteInput
                        optionText="name"
                        optionValue="id" />
            </ReferenceInput>
            <ReferenceInput
                emptyText="cancel"
                allowEmpty
                filterToQuery={searchText => {
                    if(!searchText)
                        return {};

                    return {
                        "name": searchText,
                    }
                }}
                label="Layout"
                source="layoutId"
                reference={Permissions.layout.feature}>
                    <AutocompleteInput
                        optionText="name"
                        optionValue="id" />
            </ReferenceInput>
            <BaseCreate />
        </SimpleForm>
    </Create>
);