import React from 'react';
import { Edit, SimpleForm, TextInput, ReferenceInput, BooleanInput, AutocompleteInput } from 'react-admin';
import BaseEdit from '../inheritance/BaseEdit';
import { required } from 'react-admin';
import { Permissions } from '../../permissions/permissions';

export const ItemEdit = props => (
    <Edit {...props}>
        <SimpleForm redirect="show">
            <TextInput label="Nome Interno" source="name" validate={[required("Il nome è obbligatorio")]} />
            <BooleanInput label="Acquistabile" source="purchasable"></BooleanInput>
            <BooleanInput label="Vendibile" source="saleable"></BooleanInput>
            <BooleanInput label="Immagazzinabile" source="storable"></BooleanInput>
            <BooleanInput label="Edibile" source="edible"></BooleanInput>
            <ReferenceInput
				filterToQuery={searchText => {
					if(!searchText)
						return {};

					return { name: searchText }
				}}
				label="Categoria Interna" source="categoryId" reference={`${Permissions.internalCategory.feature}`} validate={[required("La categoria è obbligatoria")]}>
				<AutocompleteInput
					shouldRenderSuggestions={val => val && val.length >= 2 }
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							}
						}
					}
					optionText="name"
					optionValue="id" />
			</ReferenceInput>
			<ReferenceInput
                    filterToQuery={searchText => {
                        if (!searchText)
                            return {};

                        return {
                            "name": searchText,
                        }
                    }}
                    label="Categoria di previsione"
                    source="forecastCategoryId"
                    reference={Permissions.forecastCategory.feature}
                    allowEmpty
                    emptyText="Non specificato"
                    >
                    <AutocompleteInput
                        optionText="name"
                        optionValue="id" />
            </ReferenceInput>
            <TextInput label="Photo URL" source="photoURL" />
            <TextInput label="Legacy IDs" helperText="(comma separated)" source="legacyIDs" />
            <TextInput label="Barcode" source="barcode" />
            <TextInput label="Note" source="note" multiline /> 
            <BaseEdit />
        </SimpleForm>
    </Edit>
);