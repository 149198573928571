import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import BaseEdit from '../inheritance/BaseEdit';
import { required } from 'react-admin';

export const GatewayEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="Nome" source="name" validate={[required('Il nome è obbligatorio')]} />
            <BaseEdit />
        </SimpleForm>
    </Edit>
);