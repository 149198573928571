import React from 'react';
import { Create, SimpleForm, TextInput, required, NumberInput, maxValue, minValue } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';

export function ClientClusterCreate(props) {
    return (
        <Create {...props} >
            <SimpleForm redirect="list">
                <TextInput lable="Name" source="name" validate={[required()]}/>
                <NumberInput 
                label="Valore di importanza" 
                source="importanceValue" 
                validate={[
                    required(), 
                    maxValue(10000, 'Maximum value is 10000'),
                    minValue(0, 'Minimum value is 0')
                ]} />
                <BaseCreate />
            </SimpleForm>
        </Create>
    )
};