import React from 'react';
import { Show, SimpleShowLayout, TextField, NumberField, DateField } from 'react-admin';
import Aside from '../../../inheritance/BaseAside';
import StateMachineReferenceField from '../../../../components/StateMachineReferenceField';
import { Permissions } from '../../../../permissions/permissions';

export const PaymentAttemptShow = (props) => {
    return (
    <Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
        <SimpleShowLayout>
            <TextField label="uuid" source="uuid" />
            <DateField label="date" source="created_at" showTime={true} />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Amount" source="amount" />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="RequestedAmount" source="requestedAmount" />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="CompletedAmount" source="completedAmount" />
            <TextField label="failureReasons" source="failureReasons" />
            <TextField label="paymentInstrumentId" source="paymentInstrumentId" />
            <TextField label="mainUserId" source="mainUserId" />
            <TextField label="providerPaymentAttemptId" source="providerPaymentAttemptId" />
            <TextField label="reconcilationPaymentAttemptId" source="reconcilationPaymentAttemptId" />
            <TextField label="providerData" source="providerData" />
            <TextField label="type" source="type" />
            <StateMachineReferenceField
                label="Stato"
                source="paymentAttemptStateMachine"
                reference={`${Permissions.paymentAttempt.feature}_${Permissions.paymentAttempt.subFeatures.paymentAttemptStateMachine.name}`}>
            </StateMachineReferenceField>
        </SimpleShowLayout>
    </Show>
)};