import React from 'react';
import BaseEdit from '../../inheritance/BaseEdit';
import { Edit, SimpleForm, TextInput, required, NumberInput, ImageField, Toolbar, SaveButton,DeleteButton } from 'react-admin';
import { validateStringLength } from '../validate';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

function CustomToolbar(props) {
    let history = useHistory();
    return (
        <Toolbar {...props} classes={useStyles()} >
            <SaveButton />
            <DeleteButton redirect={() => {
                history.goBack();
                return '';
            }} />
        </Toolbar>
    );
}

export const LayoutPhotoEdit = (props) => {
    return(
        <Edit {...props}>
            <SimpleForm toolbar={<CustomToolbar />} redirect={props.history.goBack}>
                <TextInput label="Commento" source="comment" validate={[validateStringLength]} />
                <TextInput label="Url" source="url" validate={[required()]}/>
                <ImageField label="Current Image" source="url"  />
                <NumberInput  label="Ordine" source="order" validate={[required()]}/>
                <BaseEdit />
            </SimpleForm>
        </Edit>
    )
};