import React from "react";
import { LinkToTransactionObject } from "./LinkToTransactionObject";
import { Typography, Box } from "@material-ui/core";

/*

Shows the following:

- Transaction ID
- Transaction Parent ID
- Link to transaction parent object
*/
export function TransactionInfo(props) {
  return (
    <Box style={{ marginTop: "5px" }}>
      {props.transactionId && (
        <>
          <Typography
            variant="caption"
            style={{ color: "rgba(0, 0, 0, 0.54)" }}
          >
            {"Transaction ID"}
          </Typography>
          <Typography style={{ marginBottom: "5px" }} variant="subtitle2">
            {props.transactionId}
          </Typography>
        </>
      )}

      {props.transactionParentId && (
        <>
          <Typography
            variant="caption"
            style={{ color: "rgba(0, 0, 0, 0.54)" }}
          >
            {"Transaction parent ID"}
          </Typography>
          <Typography style={{ marginBottom: "5px" }} variant="subtitle2">
            {props.transactionParentId}
          </Typography>
          {/*Link to transaction parent object*/}
          <LinkToTransactionObject transactionId={props.transactionParentId} />
        </>
      )}
    </Box>
  );
}
