import React from 'react';
import { Datagrid, List, TextField, DateField, usePermissions, showNotification } from 'react-admin';
import ListActions from '../../../../components/ListActions';
import { Permissions } from '../../../../permissions/permissions';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';

export function MissionList() {
	const history = useHistory();
	const { permissions } = usePermissions();
	const dispatch = useDispatch();

	const todayEarliest = new Date();
	const todayLatest = new Date();
	todayEarliest.setDate(todayEarliest.getDate() - 1);
	todayEarliest.setHours(23, 59, 59);
	todayLatest.setHours(23, 59, 59);
	let userParsed = null;

	if(localStorage.getItem('user')) {
		userParsed = JSON.parse(localStorage.getItem('user'));
	}

	return (
		<List
			basePath = {`/${Permissions.mission.feature}`}
			resource = {`${Permissions.mission.feature}`}
			actions={<ListActions />}
			sort={{ field: 'date', order: 'ASC' }}
			filterDefaultValues={{
				'assigneeId||eq': userParsed.id,
				'date||gte': todayEarliest.toISOString(),
				'date||lte': todayLatest.toISOString()
			}}
		>
			<Datagrid rowClick={(id) => {
				if(permissions && permissions.includes(`${Permissions.mission.feature}_${Permissions.mission.subFeatures.rider.name}-${Permissions.mission.subFeatures.rider.actions.ReadOne}`)) {
					history.push(`/mission_rider/${id}/show`);
				} else {
					dispatch(showNotification("permission required", 'error'));
				}
			}}>
				<TextField label="Tipologia" source="type" />
				<TextField label="Luogo" source="place.name" />
				<TextField label="Punto di Vendita" source="sellingPoint.name" />
				<DateField
					showTime
					label="Data"
					source="date"
				/>
				<Comment label="Commento" />
			</Datagrid>
		</List>
	);
}

function Comment(props) {
	if(props && props.record && props.record.comment) {
		return <div dangerouslySetInnerHTML={{ __html: props.record.comment }} />
	}
    return null;
}
