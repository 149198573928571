import React, { useState, useEffect } from 'react';
import { Datagrid, List, TextField, Filter, AutocompleteArrayInput, AutocompleteInput, useDataProvider, LinearProgress, usePermissions, showNotification, useRefresh, ReferenceInput, BooleanInput, Loading } from 'react-admin';
import { Permissions } from '../../../../permissions/permissions';
import { Typography, Button } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PauseIcon from '@material-ui/icons/Pause';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DoneIcon from '@material-ui/icons/Done';
import { POST_OFF_RESOURCE } from '../../../../providers/nestjs_crud';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useFormState } from 'react-final-form';
import { transferTypesEligibleForDelivery } from '../';
import HistoryIcon from '@material-ui/icons/History';

function DeliveryMainUser() {
    const { values } = useFormState({ subscription: { values: true } });

    if(values['isNullDeliveryMainUserId'] === true) {
        return null;
    }

    return (
        <ReferenceInput
            allowEmpty
            label="Corriere"
            filterToQuery={searchText => {
                if(!searchText)
                    return {
                        "type": "RibesUser"
                    };

                return {
                    "label": searchText,
                    "type": "RibesUser"
                }
            }}
            source="deliveryMainUserId||eq"
            reference={`${Permissions.mainUser.feature}`}
        >
        <AutocompleteInput
            helperText={''}
            optionText={(record)=> {
                if(!record || !record.id) {
                    return 'X cancel';
                }
                return `${record ? record.id : ''} ${record ? record.label : ''}`
            }}
            optionValue="id" />
		</ReferenceInput>
    );
}

function CurrentOutboundLocation() {
    const { values } = useFormState({ subscription: { values: true } });

    if(values['isNullCurrentOutboundLocationId'] === true) {
        return null;
    }

    return (
        <ReferenceInput
            allowEmpty
            label="Location di Uscita"
            filterToQuery={searchText => {
                if(!searchText)
                    return {
                        "type": "RibesUser"
                    };
  
                return {
                    "label": searchText,
                    "type": "RibesUser"
                }
            }}
            source="currentOutboundLocationId||eq"
            reference={`${Permissions.outboundLocation.feature}`}
        >
            <AutocompleteInput
                helperText={''}
                optionText={(record)=> {
                    if(!record || !record.id) {
                        return 'X cancel';
                    }

                    if(['BACK_TO_PICKED_OUTBOUND_LOCATION', 'TO_CANCEL_OUTBOUND_LOCATION'].includes(record.id)) {
                        return record.label;
                    }

                    return `${record ? record.id : ''} ${record ? record.label : ''}`
                }}
                optionValue="id" />
        </ReferenceInput>
    );
}

function IsNullCurrentOutboundLocationId() {
    const { values } = useFormState({ subscription: { values: true } });

    if(values['currentOutboundLocationId||eq'] !== null && values['currentOutboundLocationId||eq'] !== undefined) {
        return null;
    }

    return (
        <BooleanInput label="Location uscita SCONOSCIUTA" source="isNullCurrentOutboundLocationId" /> 
    );
}

function IsNullDeliveryMainUserId() {
    const { values } = useFormState({ subscription: { values: true } });

    if(values['deliveryMainUserId||eq'] !== null && values['deliveryMainUserId||eq'] !== undefined) {
        return null;
    }

    return (
        <BooleanInput label="Corriere SCONOSCIUTO" source="isNullDeliveryMainUserId" /> 
    );
}

function TransferPickingListFilter(props) {
    return <Filter style={{marginTop: '16px'}} {...props}>
        <AutocompleteArrayInput
            alwaysOn
            label="Stato"
            source="status||in"
            choices={[
                {
                    name: 'TODO',
                    id: 'todo',
                },
                {
                    name: 'TO_FINISH',
                    id: 'to_finish',
                },
                {
                    name: 'PICKED',
                    id: 'picked',
                },
                {
                    name: 'TO_CANCEL',
                    id: 'to_cancel',
                }
            ]}
            translateChoice={false}
            allowEmpty={false}
        />
        <AutocompleteInput
            alwaysOn
            label="Posizione"
            source="outboundSortingStatus||eq"
            choices={[
                {
                    name: 'X cancel',
                    id: null,
                },
                {
                    name: 'sorted',
                    id: 'sorted',
                },
                {
                    name: 'toSort',
                    id: 'toSort',
                },
            ]}
            translateChoice={false}
            allowEmpty={false}
        />
        <IsNullCurrentOutboundLocationId alwaysOn /> 
        <CurrentOutboundLocation alwaysOn/>
        <IsNullDeliveryMainUserId alwaysOn />
        <DeliveryMainUser alwaysOn />
        <div alwaysOn style={{pointerEvents: 'none', opacity: 0.4}}>
            <ReferenceInput
                label="Magazzini"
                filterToQuery={searchText => {
                    if(!searchText)
                        return null;
                    return { "name": searchText }
                }}
                source="fromWarehouseLocationId||in"
                reference={`${Permissions.warehouseLocation.feature}`}
            >
                <AutocompleteArrayInput
                    helperText={''}
                    optionText={(record)=> {
                        if(!record || !record.id) {
                            return '';
                        }
                        return `${record.id} ${record.name}`
                    }}
                    optionValue="id" />
            </ReferenceInput>
        </div>
    </Filter>;
}

export function TransferPickingList(props) {
    const [status, setStatus] = useState('search');

    useEffect(() => {
        if('search') {
            let url;
            try {
                url = new URL(document.location.href.replace('/#/', '/'));
            } catch {}
            if(url && url.searchParams.get('filter')) {
                let filter;
                try {
                    filter = JSON.parse(url.searchParams.get('filter'));
                } catch {}
                if(filter) {
                    if(Array.isArray(filter['fromWarehouseLocationId||in']) && filter['fromWarehouseLocationId||in'].length > 0) {
                            localStorage.setItem('cache_picking_fromWarehouseLocationId', JSON.stringify(filter['fromWarehouseLocationId||in']));
                        }
                        setStatus('searched');
                    }
                } else {
                    setStatus('searched');
                }
            } else {
                setStatus('searched');
            }
	}, [])

    if(status === 'searched') {
        let filterDefaultValues = {
            'status||in': ['todo', 'to_finish', 'picked', 'to_cancel'],
        }
        if(localStorage.getItem('cache_picking_fromWarehouseLocationId')) {
            let cache_picking_fromWarehouseLocationId;
            try {
                cache_picking_fromWarehouseLocationId = JSON.parse(localStorage.getItem('cache_picking_fromWarehouseLocationId'));
            } catch {}
            if(cache_picking_fromWarehouseLocationId) {
                filterDefaultValues['fromWarehouseLocationId||in'] = cache_picking_fromWarehouseLocationId;
            }
        }
        return (
            <>
                <List {...props}
                    title="Lista Transfer Picking"
                    bulkActionButtons={false}
                    actions={null}
                    filters={<TransferPickingListFilter />}
                    pagination={null}
                    perPage={50}
                    filterDefaultValues={filterDefaultValues}
                >
                    <Datagrid rowClick={null}>
                        <TextField label="#" source="id" sortable={false} />
                        <TextField label="A" source="toWarehouseLocation.name" sortable={false} />
                        <TextField label="Da" source="fromWarehouseLocation.name" sortable={false} />
                        <Position label="Posizione" sortable={false} />
                        <TextField label="External Document" source="externalDocument" sortable={false}/>
                        <TextField label="Commento" source="comment" sortable={false}/>
                        <TextField label="Stato" source="transferStateMachine.status" sortable={false} />
                        <Actions label="Azioni" sortable={false}/>
                    </Datagrid>
                </List>
            </>
        );
    }

    return <Loading />;
}

function Position({ record }) {
    if(!transferTypesEligibleForDelivery.includes(record.type)) {
        return null;
    }

    if(record.outboundSortingStatus === 'sorted' || record.transferStateMachine.status === 'to_cancel') {
        return <Typography align="center" variant="body2">{record.currentOutboundLocationLabel}</Typography>;
    } else if(record.outboundSortingStatus === 'toSort') {
        return <Typography align="center" style={{backgroundColor: '#e57373'}} variant="body2">{`${record.currentOutboundLocationLabel || 'Sconosciuta'} → ${record.deliveryMainUser ? record.deliveryMainUser.label : 'Sconosciuta'}`}</Typography>;
    }
}

function Actions({ record }) {
    const { permissions } = usePermissions();
    const [status, setStatus] = useState('initial');
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();
    const refresh = useRefresh();
    const history = useHistory();

    useEffect(() => {
        if(status.includes('REQUEST_')){
            const action = status.split('_')[1];
            if(![
                'start',
                'continue',
                'sort',
                'back-to-finish',
                'delivery',
                'complete',
                'revert'
            ].includes(action)) {
                setStatus('initial');
                return null;
            }
            setStatus(`REQUESTING_ACTION`);
            dataProvider(POST_OFF_RESOURCE, `${Permissions.transferPicking.feature}/${action}`, {
                data: {
                    transfer: {
                        id: record.id
                    }
                }
            })
                .then(({ data }) => {
                    if(data.success && ['refresh-data', 'go-to-picking'].includes(data.data.action)) {
                        if(data.data.action === 'refresh-data') {
                            refresh();
                        } else if(data.data.action === 'go-to-picking') {
                            history.push(`/picking/${record.id}`);
                        }
                    } else {
                        setStatus('initial');
                        if(data.errors && Array.isArray(data.errors) && data.errors.length > 0) {
                            dispatch(showNotification(data.errors.map(({constraints}) => {
                                return constraints[Object.keys(constraints)[0]];
                            }).join(','), 'error'));
                        } else {
                            dispatch(showNotification("Errore durante l'invio della richiesta", 'error'));
                        }
                    }
                })
                .catch(() => {
                    dispatch(showNotification("Errore durante l'invio della richiesta", 'error'));
                    setStatus('initial');
                });
        }
    }, [status, dataProvider, record, dispatch, history, refresh]);

    if(['REQUESTING_ACTION'].includes(status)) {
        return <LinearProgress />;
    }

    if(record && record.transferStateMachine && record.transferStateMachine.status && permissions) {
        switch (record.transferStateMachine.status) {
            case 'todo':
                if(!permissions.includes(`${Permissions.transferPicking.feature}-${Permissions.transferPicking.actions.Start}`)) {
                    return null;
                }
                return <Button
                    style={{marginRight: '4px'}}
                    variant="outlined"
                    onClick={() => {
                        setStatus('REQUEST_start');
                    }}
                    color="primary"
                    startIcon={<PlayArrowIcon />}>
                    INIZIA
                </Button>;
            case 'wip':
                if(!permissions.includes(`${Permissions.transferPicking.feature}-${Permissions.transferPicking.actions.Continue}`)) {
                    return null;
                }
                return <Button
                    style={{marginRight: '4px'}}
                    variant="outlined"
                    onClick={() => {
                        setStatus('REQUEST_continue');
                    }}
                    color="primary"
                    startIcon={<PlayArrowIcon />}>
                    CONTINUA
                </Button>;
            case 'picked':
                let pickedActions = {};

                if(permissions.includes(`${Permissions.transferPicking.feature}-${Permissions.transferPicking.actions.Sort}`) && record.outboundSortingStatus === 'toSort' && transferTypesEligibleForDelivery.includes(record.type)) {
                    pickedActions["button-sort"] = <Button
                        style={{marginRight: '4px'}}
                        variant="outlined"
                        onClick={() => {
                            setStatus('REQUEST_sort');
                        }}
                        color="primary"
                        startIcon={<PlaylistAddCheckIcon />}>
                        SORT
                    </Button>;
                }

                if(permissions.includes(`${Permissions.transferPicking.feature}-${Permissions.transferPicking.actions.BackToFinish}`)) {
                    pickedActions["button-back-to-finish"] = <Button
                        style={{marginRight: '4px'}}
                        variant="outlined"
                        onClick={() => {
                            setStatus('REQUEST_back-to-finish');
                        }}
                        color="primary"
                        startIcon={<PauseIcon />}>
                        TO FINISH
                    </Button>
                }

                if(permissions.includes(`${Permissions.transferPicking.feature}-${Permissions.transferPicking.actions.Delivery}`) && record.outboundSortingStatus === 'sorted' && transferTypesEligibleForDelivery.includes(record.type)) {
                    pickedActions["button-delivery"] = <Button
                    style={{marginRight: '4px'}}
                    variant="outlined"
                    onClick={() => {
                        setStatus('REQUEST_delivery');
                    }}
                    color="primary"
                    startIcon={<LocalShippingIcon />}>
                        INIZIA DELIVERY
                    </Button>
                }

                if(permissions.includes(`${Permissions.transferPicking.feature}-${Permissions.transferPicking.actions.Complete}`) && !transferTypesEligibleForDelivery.includes(record.type)) {
                    pickedActions["button-complete"] = <Button
                    style={{marginRight: '4px'}}
                    variant="outlined"
                    onClick={() => {
                        setStatus('REQUEST_complete');
                    }}
                    color="primary"
                    startIcon={<DoneIcon />}>
                        COMPLETA
                    </Button>
                }

                if(Object.keys(pickedActions).length > 0) {
                    return Object.keys(pickedActions).map(pickedAction => {
                        return pickedActions[pickedAction];
                    })
                } else {
                    return null;
                }
            case 'to_finish':
                let toFinishActions = {};
                if(permissions.includes(`${Permissions.transferPicking.feature}-${Permissions.transferPicking.actions.Sort}`) && record.outboundSortingStatus === 'toSort' && transferTypesEligibleForDelivery.includes(record.type)) {
                    toFinishActions["button-sort"] = <Button
                        style={{marginRight: '4px'}}
                        variant="outlined"
                        onClick={() => {
                            setStatus('REQUEST_sort');
                        }}
                        color="primary"
                        startIcon={<PlaylistAddCheckIcon />}>
                        SORT
                    </Button>;
                }
                if(permissions.includes(`${Permissions.transferPicking.feature}-${Permissions.transferPicking.actions.Start}`)) {
                    toFinishActions["button-start"] =  <Button
                    style={{marginRight: '4px'}}
                    variant="outlined"
                    onClick={() => {
                        setStatus('REQUEST_start');
                    }}
                    color="primary"
                    startIcon={<PlayArrowIcon />}>
                    INIZIA
                </Button>;
                }
                if(Object.keys(toFinishActions).length > 0) {
                    return Object.keys(toFinishActions).map(toFinishAction => {
                        return toFinishActions[toFinishAction];
                    })
                } else {
                    return null;
                }
            case 'to_cancel':
                let toCancelActions = {};
                if(permissions.includes(`${Permissions.transferPicking.feature}-${Permissions.transferPicking.actions.Revert}`)) {
                    toCancelActions["button-start"] =  <Button
                    style={{marginRight: '4px'}}
                    variant="outlined"
                    onClick={() => {
                        setStatus('REQUEST_revert');
                    }}
                    color="primary"
                    startIcon={<HistoryIcon />}>
                    Conferma smontaggio
                </Button>;
                }
                if(Object.keys(toCancelActions).length > 0) {
                    return Object.keys(toCancelActions).map(toCancelAction => {
                        return toCancelActions[toCancelAction];
                    })
                } else {
                    return null;
                }
            default:
                return null;
        }
    }

    return null;
}
