import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin';

import fetchJson from './httpClient';
export const GET_OFF_RESOURCE = 'GET_OFF_RESOURCE';
export const POST_OFF_RESOURCE = 'POST_OFF_RESOURCE';
export const PATCH_OFF_RESOURCE = 'PATCH_OFF_RESOURCE';
export const DELETE_OFF_RESOURCE = 'DELETE_OFF_RESOURCE';
export const GET_OFF_RESOURCE_PLAIN = 'GET_OFF_RESOURCE_PLAIN';
const PLAIN_URL = 'PLAIN_URL';
export const GET_LIST_RAW = 'GET_LIST_RAW';
/*
Disables default sorting for List view by setting `sort` to `false`.
  <List sort={false} >
    // Your List components like <Datagrid>, <TextField>, etc.
  </List>
*/

export default (apiUrl, CustomConfigs, httpClient = fetchJson) => {
  const composeFilter = (paramsFilter) => {

    if (paramsFilter === '' || (typeof paramsFilter.q !== 'undefined' && paramsFilter.q === '')) {
      paramsFilter = {}
    }

    const flatFilter = fetchUtils.flattenObject(paramsFilter);
    const filter = Object.keys(flatFilter).map(key => {
      const splitKey = key.split('||');
      const ops = splitKey[1] ? splitKey[1] : 'cont';
      let field = splitKey[0];

      if (field.indexOf('_') === 0 && field.indexOf('.') > -1) {
        field = field.split(/\.(.+)/)[1];
      }
      if(flatFilter[key] === false)
        flatFilter[key] = 0;
      else if(flatFilter[key] === true)
        flatFilter[key] = 1;

      return { field, operator: ops, value: flatFilter[key] };
    });
    return filter;
  };

  const convertDataRequestToHTTP = (type, resource, params) => {
    
    if(type !== GET_OFF_RESOURCE_PLAIN) {
      resource = resource.replace('_', '/')
    }
    
    let url = '';
    const options = {};
    switch (type) {
      case GET_LIST_RAW:
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const filter = composeFilter(params.filter);
        const builder = RequestQueryBuilder
          .create({
            filter,
          })
          .setLimit(perPage)
          .setPage(page)
          .setOffset((page - 1) * perPage);

        if(params.sort && params.sort.field !== undefined &&  params.sort.order !== undefined) {
          builder.sortBy(params.sort);
        }

        if(CustomConfigs[resource] && CustomConfigs[resource][GET_LIST] && CustomConfigs[resource][GET_LIST].joins) {
          CustomConfigs[resource][GET_LIST].joins.forEach(join => {
            builder.setJoin({
              field: join
            })
          })
        }
        if(['raw-with-headers', 'raw-with-headers-with-duplicated-rows'].includes(params.type)) {
          url = `${apiUrl}/${resource}?type=${params.type}&${builder.query()}`;
        } else {
          url = `${apiUrl}/${resource}?${builder.query()}`;
        }

        break;
      }
      case GET_ONE: {
        let query;
        if(CustomConfigs[resource] && CustomConfigs[resource][GET_ONE] && CustomConfigs[resource][GET_ONE].joins && CustomConfigs[resource][GET_ONE].joins.length > 0) {
          const builder = RequestQueryBuilder.create()
          CustomConfigs[resource][GET_ONE].joins.forEach(join => {
            builder.setJoin({
              field: join
            })
          })
          query = builder.query();
        }

        url = `${apiUrl}/${resource}/${params.id}${query ? `?${query}` : ''}`;

        break;
      }
      case GET_OFF_RESOURCE: {
        url = `${apiUrl}/${resource}`;
        break;
      }
      case GET_OFF_RESOURCE_PLAIN: {
        url = `${apiUrl}/${resource}`;
        break;
      }
      case DELETE_OFF_RESOURCE: {
        url = `${apiUrl}/${resource}`;
        options.method = 'DELETE';
        break;
      }
      case PATCH_OFF_RESOURCE: {
        url = `${apiUrl}/${resource}`;
        options.method = 'PATCH';
        options.body = JSON.stringify(params.data);
        break;
      }
      case POST_OFF_RESOURCE: {
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        options.body = JSON.stringify(params.data);
        break;
      }
      case GET_MANY: {
        const query = RequestQueryBuilder
          .create()
          .setFilter({
            field: 'id',
            operator: CondOperator.IN,
            value: `${params.ids}`,
          })
          .query();

        url = `${apiUrl}/${resource}?${query}`;

        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        const filter = composeFilter(params.filter);

        filter.push({
          field: params.target,
          operator: CondOperator.EQUALS,
          value: params.id,
        });

        const builder = RequestQueryBuilder
          .create({
            filter,
          })
          .setLimit(perPage)
          .setOffset((page - 1) * perPage);

        if(params.sort && params.sort.field !== undefined &&  params.sort.order !== undefined) {
          builder.sortBy(params.sort);
        }

        const query = builder.query();

        url = `${apiUrl}/${resource}?${query}`;

        break;
      }
      case UPDATE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PATCH';
        options.body = JSON.stringify(params.data);
        break;
      }
      case CREATE: {
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        options.body = JSON.stringify(params.data);
        break;
      }
      case DELETE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  const convertHTTPResponse = (response, type, resource, params) => {
    const { json } = response;

    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        return {
          data: json.data.map(resource => ({ ...resource, id: resource.id }) ),
          total: json.total,
        };
      case CREATE:
        return { data: { ...params.data, id: json.id } };
      case GET_OFF_RESOURCE_PLAIN:
        return response.body;
      default:
        return { data: json };
    }
  };

  return (type, resource, params) => {
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })),
      )
        .then(responses => ({
          data: responses.map(response => response.json),
        }));
    }
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
        })),
      ).then(responses => ({
        data: responses.map(response => response.json),
      }));
    }
    if(type === PLAIN_URL) {
      return convertDataRequestToHTTP(
        GET_LIST,
        resource,
        params,
      );
    }
    const { url, options } = convertDataRequestToHTTP(
      type,
      resource,
      params,
    );
    return httpClient(url, options).then(
      response => convertHTTPResponse(response, type, resource, params),
    );
  };
};