import React from "react";
import { TextField, ShowButton, ReferenceField } from "react-admin";
import { ListPreview } from "../../../../../../layouts/ListPreview";
import { Permissions } from "../../../../../../permissions/permissions";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

export default function RecurringJobList(props) {
	const history = useHistory();
	return (
		<ListPreview
			title={"Servizi Missione"}
			basePath={`/${Permissions.recurringMissionJob.feature}`}
			resource={`${Permissions.recurringMissionJob.feature}`}
			filterList={{ "recurringMissionId||eq": props.id }}
			hasCreateModal={false}
			rowClick={false}
			reactAdminListProps={{
				bulkActionButtons: false,
				sort: { field: "created_at", order: "DESC" },
			}}
			actionTitle={"Aggiungi"}
			customAction={() => {
				history.push(
					`/mission-recurring/job/create?recurringMissionId=${props.id}`
				);
			}}
		>
			<TextField sortable={false} source="type" />
			<ReferenceField
				sortable={false}
				label="Selling Point"
				reference={`${Permissions.sellingPoint.feature}`}
				source="sellingPointId"
				link="show"
			>
				<TextField source="name" />
			</ReferenceField>
            <ReferenceField
				sortable={false}
				label="Warehouse Location"
				reference={`${Permissions.warehouseLocation.feature}`}
				source="warehouseLocationId"
				link="show"
			>
				<TextField source="name" />
			</ReferenceField>
			<TextField sortable={false} source="durationMinutes" />
			<TextField sortable={false} source="maxAdvanceMinutes" />
			<TextField
				sortable={false}
				label="Commento"
				source="executionComment"
			/>
			<RecurringJobEdit />
			<ShowButton />
		</ListPreview>
	);
}

function RecurringJobEdit(props) {
	const history = useHistory();
	return (
		<Button
			color="primary"
			variant="text"
			startIcon={<EditIcon color="primary" />}
			onClick={() => {
				history.push(`/mission-recurring/job/${props.record.id}/edit`);
			}}
		>
			Edit
		</Button>
	);
}
