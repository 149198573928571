import React from 'react';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import BaseEdit from '../inheritance/BaseEdit';

export function BusinessUnitEdit(props) {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="name" validate={[required()]} />
                <RichTextInput source="description" />
                <BaseEdit />
            </SimpleForm>
        </Edit>
    );
}