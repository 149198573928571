import React from 'react';
import { SimpleShowLayout, TextField, BooleanField, ShowController, ShowView, Loading } from 'react-admin';
import Aside from '../inheritance/BaseAside';

import { Grid } from '@material-ui/core';
import UnitOfMeasurementList from './unitOfMeasurement/UnitOfMeasurementList';
import ItemSaleableShow from './itemSaleable/ItemSaleableShow';
import ItemPurchasableShow from './itemPurchasable/ItemPurchasableShowPreview';
import ItemStorableShow from './itemStorable/ItemStorableShowPreview';
import ItemEdibleShow from './itemEdible/ItemEdibleShowPreview';
import { NoApiCallReferenceField } from '../../components/NoApiCallReferenceField';
import { Permissions } from '../../permissions/permissions';

export function ItemShow(props) {
  return (
    <ShowController {...props}>
      {controllerProps => !controllerProps.loaded ? <Loading /> :
        <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} xs={12}>
            <ShowView {...props} {...controllerProps} aside={<Aside props={props}></Aside>} >
              <SimpleShowLayout>
                <TextField label="ID" source="id" />
                <TextField label="Nome Interno" source="name" />
                <BooleanField label="Acquistabile" source="purchasable"></BooleanField>
                <BooleanField label="Vendibile" source="saleable"></BooleanField>
                <BooleanField label="Immagazzinabile" source="storable"></BooleanField>
                <BooleanField label="Edibile" source="edible"></BooleanField>
                <NoApiCallReferenceField
                  data={controllerProps.record.category}
                  label="Categoria Interna"
                  reference={Permissions.internalCategory.feature}
                  referenceIdentifier="id"
                  source="name" />
                <NoApiCallReferenceField
                  data={controllerProps.record.forecastCategory}
                  label="Categoria di previsione"
                  reference={Permissions.forecastCategory.feature}
                  referenceIdentifier="id"
                  source="name" />
                <TextField label="Photo URL" source="photoURL" />
                <TextField label="Legacy IDs" source="legacyIDs" />
                <TextField label="Barcode" source="barcode" />
                <TextField label="Note" source="note" multiline />
              </SimpleShowLayout>
            </ShowView>
          </Grid>
  
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
            <UnitOfMeasurementList itemId={props.id}></UnitOfMeasurementList>
          </Grid>

          {controllerProps.record.saleable &&
            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
              <ItemSaleableShow itemSaleable={controllerProps.record.itemSaleable} itemId={props.id}></ItemSaleableShow>
            </Grid>
          }
          {controllerProps.record.purchasable &&
            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
              <ItemPurchasableShow itemPurchasable={controllerProps.record.itemPurchasable} itemId={props.id}></ItemPurchasableShow>
            </Grid>
          }
          {controllerProps.record.storable &&
            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
              <ItemStorableShow itemStorable={controllerProps.record.itemStorable} itemId={props.id}></ItemStorableShow>
            </Grid>
          }
          {controllerProps.record.edible &&
            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
              <ItemEdibleShow itemEdible={controllerProps.record.itemEdible} itemId={props.id}></ItemEdibleShow>
            </Grid>
          }
        </Grid>
      }
    </ShowController>
  )
}