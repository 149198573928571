import React from 'react';
import { Create, SimpleForm, TextInput, AutocompleteInput, NumberInput } from 'react-admin';
import BaseCreate from '../../inheritance/BaseCreate';
import { required } from 'react-admin';
import { ALLERGEN_TYPE, DIET_TYPE, FRESH_RANGE, NUTRITIONAL_VALUE_SOURCE } from './enum';

export const ItemEdibleCreate = props => (
	<Create {...props}>
		<SimpleForm redirect={props.redirect}>
			<TextInput
				fullWidth
				label="Nome Etichetta"
				source="label"
				validate={[required("Il nome dell'etichetta è obbligatoria")]} />

			<TextInput
				fullWidth
				label="Nome Etichetta (Inglese)"
				source="englishLabel"
				validate={[required("Il nome dell'etichetta è obbligatoria")]} />
			<AutocompleteInput
				allowEmpty
				fullWidth
				label="Gamma fresco"
				source="storageType"
				options={{suggestionsContainerProps: {disablePortal: true}}}
				choices={FRESH_RANGE.map(freshRange => {
					return {
						id: freshRange,
						name: freshRange
					}
			})} />
			<NumberInput
				fullWidth
				label="Peso Lordo in grammi"
				helperText="di una unità di riferimento"
				source="grossWeight"
				validate={[required('Indicare il Peso Lordo è obbligatorio')]} />
			<NumberInput
				fullWidth
				label="Peso Netto in grammi"
				helperText="di una unità di riferimento"
				source="netWeight"
				validate={[required('Indicare il Netto Lordo è obbligatorio')]} />
			<NumberInput
				fullWidth
				label="Scarto (percentuale)"
				source="discard"
				validate={[required('Indicare lo scarto è obbligatorio')]} />
			<TextInput
				fullWidth
				label="Ingredienti Completi (da normativa)"
				source="ingredients"
			/>
			<TextInput
				fullWidth
				label="Ingredienti alla Foorban"
				source="ingredientsFoorbanFormat"
			/>
			<TextInput
				fullWidth
				label="Ingredienti alla Foorban (Inglese)"
				source="englishIngredientsFoorbanFormat"
			/>
			<AutocompleteInput
				fullWidth
				label={'Cereali contenenti glutine'}
				source={'cerealsContainingGluten'}
				options={{suggestionsContainerProps: {disablePortal: true}}}
				validate={[required('Campo obbligatorio')]}
				choices={ALLERGEN_TYPE.map(allergenType => {
					return {
						id: allergenType,
						name: allergenType
					}
				})} />
			<AutocompleteInput
				fullWidth
				label={'Crostacei'}
				source={'crustaceans'}
				options={{suggestionsContainerProps: {disablePortal: true}}}
				validate={[required('Campo obbligatorio')]}
				choices={ALLERGEN_TYPE.map(allergenType => {
					return {
						id: allergenType,
						name: allergenType
					}
				})} />
			<AutocompleteInput
				fullWidth
				label={'Uova'}
				source={'eggs'}
				options={{suggestionsContainerProps: {disablePortal: true}}}
				validate={[required('Campo obbligatorio')]}
				choices={ALLERGEN_TYPE.map(allergenType => {
					return {
						id: allergenType,
						name: allergenType
					}
				})} />
			<AutocompleteInput
				fullWidth
				label={'Pesce'}
				source={'fish'}
				options={{suggestionsContainerProps: {disablePortal: true}}}
				validate={[required('Campo obbligatorio')]}
				choices={ALLERGEN_TYPE.map(allergenType => {
					return {
						id: allergenType,
						name: allergenType
					}
				})} />
			<AutocompleteInput
				fullWidth
				label={'Arachidi'}
				source={'peanuts'}
				options={{suggestionsContainerProps: {disablePortal: true}}}
				validate={[required('Campo obbligatorio')]}
				choices={ALLERGEN_TYPE.map(allergenType => {
					return {
						id: allergenType,
						name: allergenType
					}
				})} />
			<AutocompleteInput
				fullWidth
				label={'Soia'}
				source={'soy'}
				options={{suggestionsContainerProps: {disablePortal: true}}}
				validate={[required('Campo obbligatorio')]}
				choices={ALLERGEN_TYPE.map(allergenType => {
					return {
						id: allergenType,
						name: allergenType
					}
				})} />
			<AutocompleteInput
				fullWidth
				label={'Latte e derivati'}
				source={'milkAndDairyProducts'}
				options={{suggestionsContainerProps: {disablePortal: true}}}
				validate={[required('Campo obbligatorio')]}
				choices={ALLERGEN_TYPE.map(allergenType => {
					return {
						id: allergenType,
						name: allergenType
					}
				})} />
			<AutocompleteInput
				fullWidth
				label={'Frutta a guscio'}
				source={'fruitsInShell'}
				options={{suggestionsContainerProps: {disablePortal: true}}}
				validate={[required('Campo obbligatorio')]}
				choices={ALLERGEN_TYPE.map(allergenType => {
					return {
						id: allergenType,
						name: allergenType
					}
				})} />
			<AutocompleteInput
				fullWidth
				label={'Sedano'}
				source={'celery'}
				options={{suggestionsContainerProps: {disablePortal: true}}}
				validate={[required('Campo obbligatorio')]}
				choices={ALLERGEN_TYPE.map(allergenType => {
					return {
						id: allergenType,
						name: allergenType
					}
				})} />
			<AutocompleteInput
				fullWidth
				label={'Senape'}
				source={'mustard'}
				options={{suggestionsContainerProps: {disablePortal: true}}}
				validate={[required('Campo obbligatorio')]}
				choices={ALLERGEN_TYPE.map(allergenType => {
					return {
						id: allergenType,
						name: allergenType
					}
				})} />
			<AutocompleteInput
				fullWidth
				label={'Semi di Sesamo'}
				source={'sesame'}
				options={{suggestionsContainerProps: {disablePortal: true}}}
				validate={[required('Campo obbligatorio')]}
				choices={ALLERGEN_TYPE.map(allergenType => {
					return {
						id: allergenType,
						name: allergenType
					}
				})} />
			<AutocompleteInput
				fullWidth
				label={'Solfiti'}
				source={'sulphur'}
				options={{suggestionsContainerProps: {disablePortal: true}}}
				validate={[required('Campo obbligatorio')]}
				choices={ALLERGEN_TYPE.map(allergenType => {
					return {
						id: allergenType,
						name: allergenType
					}
				})} />
			<AutocompleteInput
				fullWidth
				label={'Lupini'}
				source={'lupine'}
				options={{suggestionsContainerProps: {disablePortal: true}}}
				validate={[required('Campo obbligatorio')]}
				choices={ALLERGEN_TYPE.map(allergenType => {
					return {
						id: allergenType,
						name: allergenType
					}
				})} />
			<AutocompleteInput
				fullWidth
				label={'Molluschi'}
				source={'molluscs'}
				options={{suggestionsContainerProps: {disablePortal: true}}}
				validate={[required('Campo obbligatorio')]}
				choices={ALLERGEN_TYPE.map(allergenType => {
					return {
						id: allergenType,
						name: allergenType
					}
				})} />	

			<AutocompleteInput
				fullWidth
				label={'Fonte valori nutrizionali'}
				source={'nutritionalValueSource'}
				options={{suggestionsContainerProps: {disablePortal: true}}}
				validate={[required('Campo obbligatorio')]}
				choices={NUTRITIONAL_VALUE_SOURCE.map(nutritionalSource => {
					return {
						id: nutritionalSource,
						name: nutritionalSource
					}
				})} />
			<NumberInput
				fullWidth
				label='Kcal'
				source='kcal'
				helperText="Valori nutrizionali per 100gr di peso netto"
				validate={[required('Campo obbligatorio')]} />
			<NumberInput
				fullWidth
				label='Grassi'
				source='fats'
				helperText="Valori nutrizionali per 100gr di peso netto"
				validate={[required('Campo obbligatorio')]} />
			<NumberInput
				fullWidth
				label='Grassi Saturi'
				source='fatsSaturated'
				helperText="Valori nutrizionali per 100gr di peso netto"
				validate={[required('Campo obbligatorio')]} />
			<NumberInput
				fullWidth
				label='Carboidrati'
				source='carbohydrates'
				helperText="Valori nutrizionali per 100gr di peso netto"
				validate={[required('Campo obbligatorio')]} />
			<NumberInput
				fullWidth
				label='Zuccheri'
				source='sugar'
				helperText="Valori nutrizionali per 100gr di peso netto"
				validate={[required('Campo obbligatorio')]} />
			<NumberInput
				fullWidth
				label='Fibra'
				source='fibers'
				helperText="Valori nutrizionali per 100gr di peso netto"
				validate={[required('Campo obbligatorio')]} />
			<NumberInput
				fullWidth
				label='Proteine'
				source='proteins'
				helperText="Valori nutrizionali per 100gr di peso netto"
				validate={[required('Campo obbligatorio')]} />
			<NumberInput
				fullWidth
				label='Sale'
				source='salt'
				helperText="Valori nutrizionali per 100gr di peso netto"
				validate={[required('Campo obbligatorio')]} />

			<AutocompleteInput
				fullWidth
				label={'Dieta'}
				source={'diet'}
				options={{suggestionsContainerProps: {disablePortal: true}}}
				validate={[required('Campo obbligatorio')]}
				choices={DIET_TYPE.map(diet => {
					return {
						id: diet,
						name: diet
					}
				})} />
			<TextInput
				fullWidth
				label="Link scheda tecnica"
				source="dataSheet" />
			<TextInput
				fullWidth
				label="Nota"
				source="note" />

			<div style={{display: 'none' }}>
              <NumberInput disabled source="item" initialValue={props.itemId} />
          	</div>
			<BaseCreate />
		</SimpleForm>
	</Create>
);