import React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';

export function DeliveryManSkillCreate(props) {
    return (
        <Create {...props} >
            <SimpleForm redirect="list">
                <TextInput label="Name" source="name" validate={[required()]}/>
                <TextInput label="Codice tool planning" source="toolPlanningCode" validate={[required()]} />
                <TextInput label="Tech Key" source="techKey" validate={[required()]} />
                <BaseCreate />
            </SimpleForm>
        </Create>
    )
};