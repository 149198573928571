import React from 'react';
import { TextField, EditButton, useRefresh } from 'react-admin';
import { ListPreview } from '../../../../layouts/ListPreview';
import { Permissions } from '../../../../permissions/permissions';
import { RequestedItemTransferCreate } from './RequestedItemTransferCreate';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { ModalPhysicalItemTransferCreateCreate } from '../physicalItemTransfer/PhysicalItemTransferCreate';
import Typography from '@material-ui/core/Typography';
import BulkConvertRequest2Physical from '../components/BulkConvertRequest2Physical';
import { transferTypesEligibleForConvertRequest2Physical } from '../';

function ReservePhysicalItemTransferTrasfer({ record, status }) {
  const refresh = useRefresh();

  if(!status || status === 'draft' || status === 'cancelled' || (record && record.quantityReference === 0)) {
    return null;
  }

  return (
    <ModalPhysicalItemTransferCreateCreate
      onCreate={(basePath, id, data) => {
        refresh();
        return 'show'
      }}
      requestedItemTransfer={record}
      title="Reserve"
    />);
}

function ListPhysicalItemTransferTrasfer({ record }) {
  return (
    <Button
    color="primary"
    component={Link}
    to={{
      pathname: `/${Permissions.physicalItemTransfer.feature}`,
      search: `filter=${JSON.stringify({ "requestedItemTransferId||eq": record.id })}`,
    }}
  >
    Lista item fisici
  </Button>);
}

function QuantityCalculed({ record, transferStatus, status }) {
  if(!transferStatus || transferStatus === 'draft' || transferStatus === 'cancelled') {
    switch (status) {
      case "Completed":
      case "Reserved":
        return null;
      default:
        return <div>{record.quantityReference}</div>
    }
  }

  let quantityReserved = 0;
  let quantityComplete = 0;

  record.physicalItemTransfers.forEach(physicalItemTransfer => {
    if(physicalItemTransfer.status ===  "Reserved") {
      quantityReserved += physicalItemTransfer.quantityReference
    }
    if(physicalItemTransfer.status === "Completed") {
      quantityComplete += physicalItemTransfer.quantityReference
    }
  });

  switch (status) {
    case "Completed":
      return <span>{quantityComplete > 0 ? quantityComplete : null}</span>
    case "Reserved":
      return <div style={(quantityReserved + quantityComplete) !== record.quantityReference ? {backgroundColor: '#ff7961', textAlign: 'center'} : {textAlign: 'center'}}>{quantityReserved > 0 ? quantityReserved : null}</div>
    default:
      return <div style={(quantityReserved + quantityComplete) !== record.quantityReference ? {backgroundColor: '#ff7961', textAlign: 'center'} : {textAlign: 'center'}}>{record.quantityReference}</div>
  }
  
}

export function UOM({record}) {
  if(record && record.item && record.item.unitOfMeasurements) {
    const uom = record.item.unitOfMeasurements.find(unitOfMeasurement => unitOfMeasurement.default);
    if(uom) {
      return <Typography>{uom.name}</Typography>
    }
  }
  return null;
}

export default (props) => {
  const transferStateMachine = useSelector(state => state.admin.resources.transfer.data[props.transferId] ? state.admin.resources.transfer.data[props.transferId].transferStateMachine : null);
  const transfer = useSelector(state => state.admin.resources.transfer.data[props.transferId] ? state.admin.resources.transfer.data[props.transferId] : null);
  const disableAdd = transferStateMachine && (!['draft', 'todo', 'to_finish'].includes(transferStateMachine.status));

  const enableBulkConvertRequest2Physical = transferStateMachine && transferStateMachine.status === 'wip' && transferTypesEligibleForConvertRequest2Physical.includes(transfer.type);

  if(!props.transactionParentId)
    return null;
  
  return (
    <>
      <BulkConvertRequest2Physical enable={enableBulkConvertRequest2Physical} type="complete" transferId={props.transferId} transfer={transfer}/>
      <ListPreview
        title={"Trasferimenti pianificati"}
        basePath = {`/${Permissions.requestedItemTransfer.feature}`}
        resource = {`${Permissions.requestedItemTransfer.feature}`}
        filterList={{ "transactionParentId||eq": props.transactionParentId }}
        redirect={`/${Permissions.transfer.feature}/${props.transferId}/show`}
        hasCreateModal={!disableAdd}
        createProps={{transactionParentId: props.transactionParentId}}
        create={RequestedItemTransferCreate}
        rowClick={null}
        reactAdminListProps={{
          bulkActionButtons:  <BulkActionButtons enable={enableBulkConvertRequest2Physical} transferId={props.transferId}/>,
          sort: { field: 'created_at', order: 'DESC' }
        }}
      >
        <TextField label="Tipo di Oggetto da trasferire"  source="transferItemType"></TextField>
        <TextField label="Item" source="item.name"></TextField>
        <TextField label="Referenza esterna" source="transactionName"></TextField>
        <TextField label="Quantità esterna" source="quantityDocumentLinked"></TextField>
        <TextField label="UoM esterna" source="uomDocumentLinked"></TextField>
        <TextField label="Valore(€)" source="value"></TextField>
        <QuantityCalculed transferStatus={transferStateMachine ? transferStateMachine.status : null} label="Quantità pianificata"/>
        <QuantityCalculed transferStatus={transferStateMachine ? transferStateMachine.status : null} label="Quantità riservata" status="Reserved" />
        <QuantityCalculed transferStatus={transferStateMachine ? transferStateMachine.status : null} label="Quantità completata" status="Completed" />
        <UOM label="UoM"></UOM>
        {!disableAdd && <EditButton></EditButton>}
        {transferStateMachine && transferStateMachine.status === 'wip' && <ReservePhysicalItemTransferTrasfer status={transferStateMachine ? transferStateMachine.status : null}></ReservePhysicalItemTransferTrasfer>}
        <ListPhysicalItemTransferTrasfer></ListPhysicalItemTransferTrasfer>
      </ListPreview>
    </>
  )
}

function BulkActionButtons(props) {
  return (
      <>
        <BulkConvertRequest2Physical enable={props.enable} type="specific" transferId={props.transferId} selectedIds={props.selectedIds}  />
      </>
  );
}