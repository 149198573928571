import React from "react";
import BidirectionalForm from "../../../../../../components/BidirectionalForm/v2";
import { useParams } from "react-router-dom";

const config = {
	type: {
		dependsOn: [],
		type: "select",
		defaultLabel: "Service Type",
		search: false,
	},
	businessUnitId: {
		dependsOn: [],
		type: "select",
		defaultLabel: "Business Unit",
		search: true,
	},
	durationMinutes: {
		dependsOn: [],
		type: "number",
		defaultLabel: "Durata attesa del servizio in minuti",
	},
	maxAdvanceMinutes: {
		dependsOn: [],
		type: "number",
		defaultLabel: "Anticipo massimo standard in minuti",
	},
	inventoryExecution: {
		dependsOn: [],
		type: "select",
		defaultLabel: "Inventario",
	},
	deliveryManSkills: {
		dependsOn: [],
		type: "select",
		defaultLabel: "Skills",
		search: true,
		multiple: true,
	},
	inventoryLotCount: {
		dependsOn: ["inventoryExecution"],
		type: "select",
		defaultLabel: "InventoryLotCount",
	},
	inventoryCycleCount: {
		dependsOn: ["inventoryExecution"],
		type: "select",
		defaultLabel: "InventoryCycleCount",
	},
	loadTransferExecution: {
		dependsOn: [],
		type: "select",
		defaultLabel: "Esecuzione transfer di carico",
	},
	unLoadTransferExecution: {
		dependsOn: [],
		type: "select",
		defaultLabel: "Esecuzione transfer di scarico",
	},
	transferInputType: {
		dependsOn: ["type", "loadTransferExecution", "unLoadTransferExecution"],
		type: "select",
		defaultLabel: "Transfer",
	},
	loadTransferId: {
		type: "number",
		dependsOn: ["transferInputType", "type"],
		defaultLabel: "Transfer di carico",
	},
	unLoadTransferId: {
		type: "number",
		dependsOn: ["transferInputType", "type"],
		defaultLabel: "Transfer di scarico",
	},
	loadLotLogic: {
		dependsOn: ["type", "transferInputType"],
		type: "select",
		defaultLabel: "Lot Logic Transfer di carico",
	},
	loadPriority: {
		dependsOn: ["type", "transferInputType"],
		type: "select",
		defaultLabel: "Priority Transfer di carico",
	},
	loadReason: {
		dependsOn: ["type", "transferInputType"],
		type: "select",
		defaultLabel: "Reason Transfer di carico",
	},
	loadType: {
		dependsOn: ["type", "transferInputType"],
		type: "select",
		defaultLabel: "Type Transfer di carico",
	},
	unLoadLotLogic: {
		dependsOn: ["type", "transferInputType"],
		type: "select",
		defaultLabel: "Lot Logic Transfer di scarico",
	},
	unLoadPriority: {
		dependsOn: ["type", "transferInputType"],
		type: "select",
		defaultLabel: "Priority Transfer di scarico",
	},
	unLoadReason: {
		dependsOn: ["type", "transferInputType"],
		type: "select",
		defaultLabel: "Reason Transfer di scarico",
	},
	unLoadType: {
		dependsOn: ["type", "transferInputType"],
		type: "select",
		defaultLabel: "Type Transfer di scarico",
	},
	transferItems: {
		type: "form-google-sheet",
		dependsOn: ["type", "transferInputType"],
	},
	loadTransferComment: {
        type: 'text',
        dependsOn: ['transferInputType', 'loadTransferExecution'],
        defaultLabel: 'Commento per transfer di carico'
    },
    unLoadTransferComment: {
        type: 'text',
        dependsOn: ['transferInputType', 'unLoadTransferExecution'],
        defaultLabel: 'Commento per transfer di scarico'
    },
	executionComment: {
		type: "text",
		dependsOn: [],
		defaultLabel: "Commento per esecuzione",
	},
};

export default function RecurringJobEdit() {
	const { id } = useParams();
	return (
		<BidirectionalForm
			context={{
				operationType: "update-job",
				id,
				showTransferItems: true,
			}}
			addData={(data) => {
				data.missionId = data.recurringMissionId;
				data.deliveryManSkills = data.deliveryManSkills
					? data.deliveryManSkills.map((deliveryManSkill) => {
							return deliveryManSkill.value;
					  })
					: [];

				return data;
			}}
			config={config}
			src="recurringMissionJob"
			path="recurringMissionJob/optionsForField"
			buttonTitle="Modifica"
			title="Modifica Servizio Missione Ricorrente"
		/>
	);
}
