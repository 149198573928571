import React from 'react';
import { Redirect } from "react-router-dom";
import { Permissions } from '../../../../permissions/permissions';
import {TRANSFER_ID, getKey } from '../../../../utils/cross-store';

export const RequestedItemTransferList = props => {
    const id = getKey(TRANSFER_ID);

    if(!id) {
        return (<Redirect to={{pathname: `/${Permissions.transfer.feature}`}}/>);
    }

    return (<Redirect to={{pathname: `/${Permissions.transfer.feature}/${id}/show`}}/>);
}