import React from 'react';

import { ShowPreview } from '../../../layouts/ShowPreview';
import { ItemPurchasableCreate } from './ItemPurchasableCreate';
import { ItemPurchasableShow } from './ItemPurchasableShow';
import { Permissions } from '../../../permissions/permissions';

export default (props) => {
  return (
    <ShowPreview
      data={props.itemPurchasable}
      title={"Item Aquistabile"}
      basePath = {`/${Permissions.itemPurchasable.feature}`}
      resource = {`${Permissions.itemPurchasable.feature}`}
      redirect={`/item/${props.itemId}/show`}
      hasCreateModal={true}
      create={ItemPurchasableCreate}
      createProps={{itemId: props.itemId}}
      hasEditP={true}
      show={ItemPurchasableShow}
    >
    </ShowPreview>
  )
}