import React from 'react';
import { SimpleShowLayout, TextField, ReferenceField, ShowController, ShowView } from 'react-admin';
import Aside from '../../inheritance/BaseAside';
import CustomToolbar from '../ItemChildActionShow';

export const ItemPurchasableShow = (props) => {
    return  (
        <ShowController {...props} aside={<Aside props={props} record={props.record}></Aside>}>
            {controllerProps =>
                <ShowView {...props} {...controllerProps} actions={props.hideActions ? null : <CustomToolbar />}>
                    <SimpleShowLayout>
                        <ReferenceField label="Tassa di acquisto" source="taxId" reference="tax" link="show">
                            <TextField source="name" />
                        </ReferenceField>

                        <TextField
                            label="Produttore/Marchio"
                            source="brand" />

                        <TextField
                            label="Photo Clienti"
                            source="photoURL" />

                        <TextField
                            label="Nota"
                            source="note" />
                    </SimpleShowLayout>
                </ShowView>
            }
        </ShowController>
    );
}