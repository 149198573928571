import React from 'react';

import { TextField, DateField } from 'react-admin';
import { ListPreview } from '../../layouts/ListPreview';
import { Permissions } from '../../permissions/permissions';

export default (props) => {
	return (
		<ListPreview
			title={"History"}
			basePath = {`/${Permissions.stateMachineHistory.feature}`}
			resource = {`${Permissions.stateMachineHistory.feature}`}
			filterList={{ "stateMachineId||eq": props.stateMachineId }}
			hasCreateModal={false}
			reactAdminListProps={{sort: { field: 'created_at', order: 'DESC' }}}
			rowClick={false}
		>
			<DateField label="Date" source="created_at" showTime={true}/>
			<TextField label="Transizione" source="transition" />
			<TextField label="From" source="fromStatus" />
			<TextField label="To" source="toStatus" />
			<TextField label="Commenti" source="comment" />
			
		</ListPreview>
	);
}