import React from 'react';
import { Edit, SimpleForm, ReferenceInput, AutocompleteInput, DateTimeInput, required, FormDataConsumer } from 'react-admin';
import BaseEdit from '../../inheritance/BaseEdit';
import { Permissions } from '../../../permissions/permissions';
import RichTextInput from 'ra-input-rich-text';

export function MissionEdit(props) {
	return (
	<Edit {...props}>
		<SimpleForm redirect="show">
			<DateTimeInput
				fullWidth
				source="date"
				label="data e ora"
                validate={[required("campo obbligatorio")]}
            />
			<FormDataConsumer>
				{({ formData }) => {
					if(['smartfridge', 'coffee'].includes(formData.type)) {
						return (
							<AutocompleteInput
								fullWidth
								defaultValue="mandatory"
								label="Inventario"
								source="inventory"
								choices={[
									'mandatory',
									'optional',
								].map(missioType => {
									return {
										id: missioType,
										name: missioType.charAt(0).toUpperCase() + missioType.slice(1)
									}
								})} />
						);
					} else {
						return null;
					}
				}}
			</FormDataConsumer>
			<ReferenceInput
				fullWidth
				allowEmpty
				emptyText="Non specificato"
				filter={{"authType||eq": "GOOGLE"}}
				filterToQuery={searchText => {
					if(!searchText)
						return null;

					return {
						"email": searchText,
						authType: "GOOGLE"
					}
				}}
				source="assigneeId"
				reference={`${Permissions.user.feature}`}
			>
				<AutocompleteInput
					optionText="email"
					optionValue="id" />
			</ReferenceInput>
			<RichTextInput
				label="Commento"
				source="comment" />
			<BaseEdit />
		</SimpleForm>
	</Edit>
)};