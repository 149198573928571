import React from 'react';
import { Toolbar, SaveButton, DeleteButton, Edit, SimpleForm, TextInput, required, NumberInput, ReferenceInput, SelectInput, AutocompleteInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';
import { connect } from 'react-redux';

import BaseEdit from '../inheritance/BaseEdit';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const CustomToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
        {false && <DeleteButton redirect={props.redirect} />}
    </Toolbar>
);

const ItemSaleableEditComponent = props => {
    return (
        <Edit {...props}>
            <SimpleForm redirect={() => (`/item/${props.itemId}/show`)} toolbar={<CustomToolbar data={props.data} redirect={() => (`/item/${props.itemId}/show`)}/>}>
                <TextInput
                    label="Nome per Clienti Finali"
                    source="name"
                    validate={[required('Il nome è obbligatorio')]} />

                <TextInput
                    label="Nome per Clienti Finali (Inglese)"
                    source="englishName"
                    validate={[required('Il nome è obbligatorio')]} />

                <TextInput
                    label="Photo Clienti"
                    source="photoURL"
                    validate={[required('La foto è obbligatoria')]} />

                <TextInput
                    label="Photo Clienti scontornata"
                    source="photoURL_NO_BACKGROUND"
                    validate={[required('La foto scontornata è obbligatoria')]}
                />

                <TextInput
                    label="Short Description"
                    source="shortDescription"
                    validate={[required('La short description è obbligatoria')]} />

                <RichTextInput
                    label="Descrizione"
                    source="description"
                    validate={[required('La descrizione è obbligatoria')]} />

                <TextInput
                    label="Short Description (Inglese)"
                    source="englishShortDescription"
                    validate={[required('La short description è obbligatoria')]} />

                <RichTextInput
                    label="Descrizione (Inglese)"
                    source="englishDescription"
                    validate={[required('La descrizione è obbligatoria')]} />

                <NumberInput
                    label="Prezzo di default con IVA"
                    source="price"
                    validate={[required('Il prezzo è obbligatorio')]} />

                <ReferenceInput
                    label="Unità di misura"
                    source="unitOfMeasurementId"
                    reference="unitOfMeasurement"
                    filter={{ "itemId||eq": props.itemId }}
                    validate={[required('L\'unità di misura è obbligatoria')]}>
                        <SelectInput optionText="name" />
                </ReferenceInput>

                <ReferenceInput
                    label="Tasse"
                    source="taxId"
                    reference="tax"
                    validate={[required('Indicare la tassazione è obbligatorio')]}>
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <ReferenceInput
                    filterToQuery={searchText => {
                        if (!searchText)
                            return null;

                        return {
                            internalName: searchText
                        }
                    }}
                    label="Categoria Ecommerce"
                    source="clientCategoryId"
                    reference="clientCategory"
                    validate={[required('Indicare la categoria ecommerce è obbligatorio')]}>
                    <AutocompleteInput optionText="internalName" />
                </ReferenceInput>
                <TextInput
                    label="Tag"
                    source="tag"/>
                <NumberInput
                    label="Tempo microonde in secondi"
                    source="microwaveTime"
                    helperText="0 se va consumato Freddo"
                />
                <BaseEdit />
            </SimpleForm>
        </Edit>
    )
};

export const ItemSaleableEdit = connect((state, props) => {
    return {
        itemId: state.admin.resources.itemSaleable && state.admin.resources.itemSaleable.data[props.id] ? state.admin.resources.itemSaleable.data[props.id].item.id : null
    }
})(ItemSaleableEditComponent)