import React from 'react';
import { Datagrid, List, TextField, TextInput} from 'react-admin';
import ListActions from '../../components/ListActions';
import Filter from '../inheritance/BaseFilter';
const ForecastCategoryFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name" alwaysOn />
        <TextInput label="Codice tool planning" source="toolPlanningCode" />
        <TextInput label="Tech Key" source="techKey" alwaysOn />
    </Filter>
);

export function DeliveryManSkillList(props) {
    return (
        <List {...props} actions={<ListActions />} filters={<ForecastCategoryFilter />}>
            <Datagrid rowClick="show">
                <TextField label="Name" source="name" />
                <TextField label="Codice tool planning" source="toolPlanningCode"  />
                <TextField label="Tech Key" source="techKey"  />
            </Datagrid> 
        </List>
    );
}

