import React from 'react';
import { Edit, SimpleForm, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import BaseEdit from '../../../inheritance/BaseCreate';
import { required } from 'react-admin';
import Input, { ReferenceInputType, ReferenceInputChoicesType, TextInputType} from '../components/Input';
import { Permissions, TransferActions } from '../../../../permissions/permissions';
import { TransferItemTypeLot } from './type';
import NewInputSelectRest, { TextInputType as CommonTextInputType } from '../commonComponents/InputRest';

const fieldOptions = `${Permissions.transfer.feature}/${Permissions.requestedItemTransfer.feature}/${TransferActions.fieldOptions}`;

export const RequestedItemTransferEdit = props => {
	return (
		<Edit {...props}>
			<SimpleForm redirect={props.redirect}>
				<TextInput
					disabled={true}
					label="Tipo di Trasferimento" source="transferType" />
				<TextInput
					disabled={true}
					label="Tipo di Oggetto da trasferire" source="transferItemType" />
				<ReferenceInput
					validate={[required('campo obbligatorio')]}
                    filterToQuery={searchText => {
						if(!searchText)
							return {};

							return { "name": searchText }
					}}
                    label="Item"
					source="itemId"
					reference={`${Permissions.item.feature}`}
				>
                    <AutocompleteInput
                        options={{suggestionsContainerProps: {disablePortal: true}}}
                        optionText={(record)=> `${record ? record.id : ''} ${record ? record.name : ''}`}
                        optionValue="id" />
                </ReferenceInput>
				<Input
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							},
							disabled: true
						}
					}
					dependsOnValues={{
						transferItemType: [TransferItemTypeLot],
						itemStorableId: []
					}}
					type={ReferenceInputType}
					validate={[required('campo obbligatorio')]}
					filterToQuery={(searchText, props) => {
						if(!searchText)
							return null;

						return {
							"name": searchText,
							"itemId||eq": props.formData.itemId
						}
					}}
					label="Lotto"
					source="lotId"
					reference={`${Permissions.lot.feature}`}
					shouldRenderSuggestions={val => val && val.length >= 2 }
					optionText={(record) => {
						if (!record || !record.id) {
							return '';
						}

						let optionText = `${record.name} - ${record.id}`;
						
						if (record.compliantStatus === 'not-compliant') {
							optionText += ' - non conforme';
						}
						
						return optionText;
                    }}
				/>
				<Input
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							},
							disabled: true
						}
					}
					dependsOnValues={{
						itemId: []
					}}
					type={ReferenceInputChoicesType}
					validate={[required('campo obbligatorio')]}
					filterToQuery={(props) => {
						return {
							"itemId||eq": props.formData.itemId
						}
					}}
					label="UoM Input"
					source="uomInputId"
					reference={`${Permissions.unitOfMeasurement.feature}`}
					optionText="name"
					optionValue="id"
				/>
				<Input
					dependsOnValues={{
						uomInputId: []
					}}
					type={TextInputType}
					validate={[required('campo obbligatorio')]}
					label="Quantità Input"
					source="quantityInput"
				/>
				<NewInputSelectRest
					type={CommonTextInputType}
					fieldOptions={fieldOptions}
					label="Nome transazione" source="transactionName" dependOn="transferType" />
				<NewInputSelectRest
					type={CommonTextInputType}
					fieldOptions={fieldOptions}
					label="Quantità in unità documento collegato" source="quantityDocumentLinked" dependOn="transferType" />
				<NewInputSelectRest
					type={CommonTextInputType}
					fieldOptions={fieldOptions}
					label="Unità di misura documento collegato" source="uomDocumentLinked" dependOn="transferType" />
				<NewInputSelectRest
					type={CommonTextInputType}
					fieldOptions={fieldOptions}
					label="Valore" source="value" dependOn="quantityDocumentLinked" />
				<BaseEdit />
			</SimpleForm>
		</Edit>
	);
};