import React, { useState, Fragment } from 'react';

import { FormDataConsumer, AutocompleteInput, SelectInput, DateInput, TextInput, ReferenceInput } from 'react-admin';
import { useForm } from 'react-final-form';

export const AutocompleteInputType = "AutocompleteInput";
export const SelectInputType = "SelectInput";
export const DateInputType = "DateInput";
export const TextInputType = "TextInput";
export const ReferenceInputType = "ReferenceInput";
export const ReferenceInputChoicesType = "ReferenceInputChoicesType";

const InputComponent = (props) => {
    const [dependOn, setDependOn] = useState(null);
    const [dependsOn, setDependsOn] = useState({});

    const form = useForm();

    if(props.dependsOnValues) {
        let checks = [];
        Object.keys(props.dependsOnValues).forEach(singleKey => {
            if(!form.getFieldState(singleKey) || (form.getFieldState(singleKey) && form.getFieldState(singleKey).value === undefined) ) {
                checks.push(singleKey);
            }
        });
        if(checks.length > 0) {
            return null;
        }

        Object.keys(props.dependsOnValues).forEach(singleKey => {
            if(props.dependsOnValues[singleKey].length > 0 && form.getFieldState(singleKey) && !props.dependsOnValues[singleKey].includes(form.getFieldState(singleKey).value)) {
                checks.push(singleKey);
            }
        });
    
        if(checks.length > 0) {
            return null;
        }

        Object.keys(props.dependsOnValues).forEach(singleKey => {
            if(!dependsOn[singleKey] && form.getFieldState(singleKey)) {
                setDependsOn({
                    ...dependsOn,
                    [singleKey]: form.getFieldState(singleKey).value
                });
            }
            else if(dependsOn[singleKey] && form.getFieldState(singleKey) && dependsOn[singleKey] !== form.getFieldState(singleKey).value) {
                setDependsOn({
                    ...dependsOn,
                    [singleKey]: form.getFieldState(singleKey).value
                });
                props.formData[props.source] = undefined;
            }
        });
    }

    if(props.dependOn) {
        if(!form.getFieldState(props.dependOn) || (form.getFieldState(props.dependOn) && form.getFieldState(props.dependOn).value === undefined) ) {
            return null;
        }

        if(props.dependOnValues && !props.dependOnValues.includes(form.getFieldState(props.dependOn).value)) {
            return null;
        }

        if(!dependOn) {
            setDependOn(form.getFieldState(props.dependOn).value);
        } else if(dependOn && dependOn !== form.getFieldState(props.dependOn).value) {
            setDependOn(form.getFieldState(props.dependOn).value);
            props.formData[props.source] = undefined;
        }
    }

    if(props.customizeProps) {
        props = props.customizeProps(props);
    }

    let content = null;

    switch (props.type) {
        case AutocompleteInputType:
            content = <AutocompleteInput emptyText={props.emptyText} allowEmpty={props.allowEmpty} initialValue={props.initialValue} label={props.label} source={props.source} choices={props.choices} validate={props.validate}/>
            break;
        case SelectInputType:
            content = <SelectInput emptyText={props.emptyText} allowEmpty={props.allowEmpty} initialValue={props.initialValue} label={props.label} source={props.source} choices={props.choices} validate={props.validate}/>
            break;
        case DateInputType:
            content = <DateInput disabled={props.disabled} initialValue={props.initialValue} allowEmpty={props.allowEmpty} label={props.label} source={props.source} validate={props.validate}></DateInput>
            break;
        case ReferenceInputType:
            content = <ReferenceInput
                        validate={props.validate}
                        filterToQuery={(searchText) => {
                            if(!searchText)
                                return null;

                            return props.filterToQuery(searchText, props);
                        }}
                        label={props.label}
                        source={props.source}
                        reference={props.reference}>
                            <AutocompleteInput
                                options={props.options}
                                shouldRenderSuggestions={props.shouldRenderSuggestions}
                                optionText={props.optionText}
                                optionValue={props.optionValue} />
                        </ReferenceInput>;
                break;
            case ReferenceInputChoicesType:
                content = <ReferenceInput
                            validate={props.validate}
                            filterToQuery={() => {
                                return props.filterToQuery(props);
                            }}
                            label={props.label}
                            source={props.source}
                            reference={props.reference}>
                                <SelectInput optionText="name" />
                            </ReferenceInput>;
                break;
        case TextInputType:
        default:
            content = <TextInput disabled={props.disabled} initialValue={props.initialValue} label={props.label} source={props.source} validate={props.validate}></TextInput>
            break;
    }

	return (
        <Fragment>
            {content}
        </Fragment>
	);
}

export default function Input(props) {
    return (
        <FormDataConsumer>
            {(form) => <InputComponent
                {...form}
                {...props}/>
            }
        </FormDataConsumer>
    );
}