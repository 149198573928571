import React from 'react';
import { Datagrid, List, TextField, TextInput } from 'react-admin';
import Filter from '../inheritance/BaseFilter';
import ListActions from '../../components/ListActions';

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nome" source="name" alwaysOn />
        <TextInput label="Email" source="email" alwaysOn />
    </Filter>
);

export const UserList = props => (
    <List {...props} actions={<ListActions />}  filters={<UserFilter />} >
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <TextField label="Nome" source="name" />
            <TextField label="Email" source="email" />
        </Datagrid>
    </List>
);