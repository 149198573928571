import React from 'react';

import { TextField, DateField, ReferenceField  } from 'react-admin';
import { ListPreview } from '../../../layouts/ListPreview';
import { Permissions } from '../../../permissions/permissions';

export default (props) => {
	return (
		<ListPreview
			title={"History"}
			basePath = {`/${Permissions.opsStateMachineHistory.feature}`}
			resource = {`${Permissions.opsStateMachineHistory.feature}`}
			filterList={{ "stateMachineId||eq": props.stateMachineId }}
			hasCreateModal={false}
			reactAdminListProps={{sort: { field: 'created_at', order: 'DESC' }}}
			rowClick={false}
		>
			<DateField label="Date" source="created_at" showTime={true}/>
			<TextField label="Transizione" source="transition" />
			<TextField label="From" source="fromStatus" />
			<TextField label="To" source="toStatus" />
			<TextField label="Commenti" source="comment" />
			<Author {...props} label="Utente" />
		</ListPreview>
	);
}


function Author (props) {
	if(
		props.record && props.record.creator_type === 'main-user'
	){
		return <ReferenceField reference={Permissions.mainUser.feature} source='creator_id'>
			<TextField source="label" />
		</ReferenceField>
	}
	return <TextField  source="creator_id" />
}
