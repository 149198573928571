import React from 'react';
import { Datagrid, List, TextField, TextInput, ReferenceInput, AutocompleteInput, ReferenceField } from 'react-admin';
import Filter from '../../inheritance/BaseFilter';
import ListActions from '../../../components/ListActions';

import { Permissions } from '../../../permissions/permissions';

const PurchasePointFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nome" source="name" alwaysOn />
        <ReferenceInput
				filterToQuery={searchText => {
					if(!searchText)
						return {};

					return { "name": searchText }
				}}
				label="Luogo" source="placeId||eq" reference={Permissions.place.feature}>
            <AutocompleteInput
                optionText="name"
                optionValue="id" />
        </ReferenceInput>
    </Filter>
);

export const PurchasePointList = props => (
    <List {...props} actions={<ListActions />}  filters={<PurchasePointFilter />}>
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <TextField label="Nome" source="name" />
            <ReferenceField
				label="Luogo"
				source="placeId"
                reference={Permissions.place.feature}
                link={"show"}
				>
				<TextField source="name" />
			</ReferenceField>
        </Datagrid>
    </List>
);