import React, { useEffect, useState } from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField, useDataProvider, useShowController, usePermissions, ReferenceInput, AutocompleteInput, SimpleForm, FunctionField, TextInput, TopToolbar, EditButton } from 'react-admin';
import { Grid, Typography, Link, Card, Toolbar, AppBar, Button, makeStyles, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { Permissions } from '../../../permissions/permissions';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import { useHistory } from "react-router-dom";
import CoffeeIcon from '@material-ui/icons/LocalCafe';
import CircularProgress from '@material-ui/core/CircularProgress';

const style = makeStyles(() => ({
	root: {
		flexGrow: 1,
	},
	title: {
		flexGrow: 1,
	},
	dialog: {
		minWidth: '60vw',
	}
}));

const ShowActions = (props) => {
	const { permissions } = usePermissions();
	let history = useHistory();

	return (
		<TopToolbar>
			<EditButton basePath={props.basePath} record={props.data} />
			{permissions && permissions.includes(`${Permissions.coffeeMachine.feature}-${Permissions.coffeeMachine.actions.dispense}`) && props.data && props.data.model === 'Cimbali-SSeries' && <Button size="small" color="primary" onClick={() => { history.push(`?action=dispense-beverage`); }}>
			<CoffeeIcon size="small"/>
				Eroga
			</Button>}
		</TopToolbar>
	);
}

export function CoffeeMachineShow(props) {

	const [status, setStatus] = useState('INITIAL');
	const [dispenseStatus, setDispenseStatus] = useState('AWAIT_RECIPE_ID');
	const [specificMachine, setSpecificMachine] = useState(null);
	const dataProvider = useDataProvider();
	const { permissions } = usePermissions();
	const classes = style();
	let history = useHistory();
	const params = new URLSearchParams(props.location.search);
	const action = params.get('action');
	const [recipeId, setRecipeId] = useState('');
	const [name, setName] = useState('');

	const {
		error,
		loaded,
		record,
	} = useShowController(props);

	useEffect(() => {
		if (loaded && status === 'INITIAL') {
			setStatus('FETCH_DATA')
		}
	}, [loaded, status]);

	useEffect(() => {
		if (error || status === 'ERROR_SPECIFIC_MACHINE_LINKED') {
			if (window.confirm("Errore caricamento, riprova ?")) {
				setStatus('INITIAL');
			}
		}
	}, [error, status]);

	useEffect(() => {
		if (status === 'FETCH_DATA' && permissions && record) {
			switch (record.model) {
				case 'Cimbali-SSeries':
					if (permissions && permissions.includes(`${Permissions.coffeeMachineCimbaliS.feature}-${Permissions.coffeeMachineCimbaliS.actions.ReadAll}`)) {
						dataProvider('GET_OFF_RESOURCE',
							`${Permissions.coffeeMachineCimbaliS.feature}?filter=${encodeURIComponent([`machineId||$eq||${record.id}`])}`)
							.then(({ data }) => {
								if (data.length === 0) {
									setStatus('NO_SPECIFIC_MACHINE_LINKED')
									setSpecificMachine({
										id: null,
										model: record.model
									});
								} else {
									setStatus('SPECIFIC_MACHINE_FOUND');
									setSpecificMachine({
										id: data[0].id,
										model: record.model
									});
								}
							})
							.catch((e) => {
								setStatus('ERROR_SPECIFIC_MACHINE_LINKED')
							});
					}
					break;
				default:
					break;
			}
		}
	}, [dataProvider, record, status, permissions]);
	
	const onCloseModal = () => {
		setDispenseStatus('AWAIT_RECIPE_ID')
		setRecipeId('');
		history.goBack();
	}

	return (
		<Grid container spacing={3}>
			<Grid item xl={12} lg={12} md={12} xs={12}>
				<Show {...props}  actions={<ShowActions />}>
					<SimpleShowLayout>
						<TextField source="name" />
						<TextField source="model" />
						<ReferenceField source="placeId" reference="place" link="show">
							<TextField source="name" />
						</ReferenceField>
						<TextField label="Numero di matricola" source='serialNumber' />
					</SimpleShowLayout>
				</Show>
			</Grid>
			<Grid item xl={12} lg={12} md={12} xs={12}>
				{status === 'ERROR_SPECIFIC_MACHINE_LINKED' && <Typography variant="h6">
					Errore durante caricamento macchina specifica
				</Typography>}
				{
					(status === 'SPECIFIC_MACHINE_FOUND' || status === 'NO_SPECIFIC_MACHINE_LINKED') && specificMachine && specificMachine.model === 'Cimbali-SSeries' &&
					<Card>
						<AppBar position="static">
							<Toolbar>
								<Typography variant="h6" className={classes.title}>
									Cimbali S Series
								</Typography>
								{!specificMachine.id && permissions && permissions.includes(`${Permissions.coffeeMachineCimbaliS.feature}-${Permissions.coffeeMachineCimbaliS.actions.CreateOne}`) && <Button color="inherit" onClick={() => { history.push(`/${Permissions.coffeeMachineCimbaliS.feature}/create?parentId=${record.id}`); }}>
									Aggiungi
									<AddIcon />
								</Button>}
								{specificMachine.id && permissions && permissions.includes(`${Permissions.coffeeMachineCimbaliS.feature}-${Permissions.coffeeMachineCimbaliS.actions.UpdateOne}`) && <Button color="inherit" onClick={() => { history.push(`/${Permissions.coffeeMachineCimbaliS.feature}/${specificMachine.id}`); }}>
									Modifica
									<EditIcon />
								</Button>}
							</Toolbar>
						</AppBar>
						{specificMachine.id && permissions && permissions.includes(`${Permissions.coffeeMachineCimbaliS.feature}-${Permissions.coffeeMachineCimbaliS.actions.ReadOne}`) && <Show
							title=" "
							{...{
								permissions: [],
								basePath: Permissions.coffeeMachineCimbaliS.feature,
								resource: Permissions.coffeeMachineCimbaliS.feature,
								id: specificMachine.id,
								options: {},
								hasList: false,
								hasEdit: false,
								hasShow: true,
								hasCreate: false
							}}
						>
							<SimpleShowLayout>
								<TextField source="id" />
								<TextField source="thingName" />
								<TextField source="ip" />
								<TextField source="port" />
								<TextField source="serial" />
								<TextField source="model" />
								<TextField source="solubleState" />
								<TextField source="soluble" />
								<TextField source="milkState" />
								<TextField source="milk" />
								<TextField source="gramsFor100Impulse" />
								<FunctionField
									label="Grinder 1 coffeeType"
									render={record => {
										if(!record.grinder1coffeeType) {
											return '';
										}
										return <Link style={{cursor: 'pointer'}} onClick={() => history.push(`/${Permissions.coffeeType.feature}/${record.grinder1coffeeType.id}/show`)}>
										{record.grinder1coffeeType.name}
									</Link>
									}}
								/>
								<TextField source="grinder1.gramsForSecond" />
								<TextField source="grinder1.state" />
								<FunctionField
									label="Grinder 2 coffeeType"
									render={record => {
										if(!record.grinder2coffeeType) {
											return '';
										}
										return <Link style={{cursor: 'pointer'}} onClick={() => history.push(`/${Permissions.coffeeType.feature}/${record.grinder2coffeeType.id}/show`)}>
										{record.grinder2coffeeType.name}
									</Link>
									}}
								/>
								<TextField source="grinder2.gramsForSecond" />
								<TextField source="grinder2.state" />
								<FunctionField
									label="Grinder 3 coffeeType"
									render={record => {
										if(!record.grinder3coffeeType) {
											return '';
										}
										return <Link style={{cursor: 'pointer'}} onClick={() => history.push(`/${Permissions.coffeeType.feature}/${record.grinder3coffeeType.id}/show`)}>
										{record.grinder3coffeeType.name}
									</Link>
									}}
								/>
								<TextField source="grinder3.gramsForSecond" />
								<TextField source="grinder3.state" />
								<TextField source="soluble"/>
								<TextField source="waterGramsForSolubleForSecond"/>
								<TextField source="solubleState"/>
								<TextField source="soluble1GramsForSecond"/>
								<ReferenceField source="solubleType1Id" reference="solubleType" link="show">
									<TextField source="internalName" />
								</ReferenceField>
								<TextField source="solubleState2"/>
								<TextField source="soluble2GramsForSecond"/>
								<ReferenceField source="solubleType2Id" reference="solubleType" link="show">
									<TextField source="internalName" />
								</ReferenceField>
							</SimpleShowLayout>
						</Show>}
					</Card>
				}
			</Grid>
			<Dialog
				open={action === 'dispense-beverage'}
				onClose={onCloseModal}
				scroll={'paper'}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
				maxWidth='lg'
			>
				<DialogTitle id="scroll-dialog-title">Seleziona la ricetta da erogare</DialogTitle>
				<DialogContent className={classes.dialog}>
					<SimpleForm style={{
						width: '100%',
						backgroundColor: dispenseStatus === 'SENDING_RECIPE_REQUEST' ? 'rgba(0, 0, 0, .3)' : ''}}toolbar={false} record={{
							recipeId: '',
							name: ''
						}}>
						<TextInput
							fullWidth
							onChange={(e) => {
								setName(e.target.value);
							}}
							label="Nome"
							source="name"
						/>
						<ReferenceInput
							onChange={(e) => {
								setRecipeId(e);
							}}
							fullWidth
							filterToQuery={searchText => {
								if (!searchText)
									return null;

								return {
									'item.name': searchText
								}
							}}
							label="Ricetta"
							source="recipeId"
							reference={`${Permissions.coffeeRecipe.feature}`}
						>
							<AutocompleteInput
								allowEmpty
								options={{ suggestionsContainerProps: { disablePortal: true } }}
								optionText={(record) => record ? `${record.item.name}` : ''}
								optionValue="id" />
						</ReferenceInput>
					</SimpleForm>
					{dispenseStatus === 'RECIPE_UNAVAILABLE' && <Typography>Ricetta non disponibile</Typography>}
					{dispenseStatus === 'ERROR_SENDING_RECIPE_REQUEST' && <Typography>Errore Erogazione ricetta</Typography>}
					{dispenseStatus === 'RECIPE_DISPENSED' && <Typography>Ricetta erogata</Typography>}
				</DialogContent>
				<DialogActions>
					<Button onClick={onCloseModal}>Annulla</Button>
					<Button disabled={dispenseStatus === 'SENDING_RECIPE_REQUEST' || !recipeId} onClick={() => {
						setDispenseStatus('SENDING_RECIPE_REQUEST')
						dataProvider('POST_OFF_RESOURCE', `coffeeMachine/${record.id}/recipe/${recipeId}/dispense`, {
							data: {
								userName: name,
							}
						})
						.then(data => {
							setDispenseStatus('RECIPE_DISPENSED');
						})
						.catch((e) => {
							if(e === 'Recipe Unavailable') {
								setDispenseStatus('RECIPE_UNAVAILABLE');
							} else {
								setDispenseStatus('ERROR_SENDING_RECIPE_REQUEST');
							}
						});
					}}>
						{dispenseStatus === 'SENDING_RECIPE_REQUEST'? <CircularProgress style={{width: '28px', height: '28px'}} color="inherit" /> : <CoffeeIcon />} Eroga
					</Button>
				</DialogActions>
			</Dialog>
		</Grid>
	)
}
