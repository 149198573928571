import React, { useEffect, useState } from "react";
import { useDataProvider, usePermissions, showNotification } from 'react-admin';
import { Container, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DvrIcon from '@material-ui/icons/Dvr';
import { useDispatch } from 'react-redux';

export function rawDataWithHeader2SpreadsheetFormat(data) {
    const rows = [];

    if (!data.header || !data.raw || !data.raw.length === 0 || !Object.keys(data.header).length === 0) {
        return rows;
    }

    const headerKeys = Object.keys(data.header);
    const headerRow = headerKeys.map(headerKey => data.header[headerKey])

    rows.push(headerRow);

    for (let i = 0; i < data.raw.length; i++) {
        const row = [];
        for (let h = 0; h < headerKeys.length; h++) {
            row.push(data.raw[i][headerKeys[h]]);
        }
        rows.push(row);
    }

    return rows.map(row => row.join('\t')).join('\n');
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
    }
}));

export default function ImportDataFromFoorban() {
    const [status, setStatus] = useState('INITIAL');
    const [value, setValue] = useState();
    const classes = useStyles();
    const { permissions } = usePermissions();
    const dataProvider = useDataProvider();
    const dispatch = useDispatch()

    useEffect(() => {
        if (status === 'FETCH_DATA') {
            setStatus('FETCHING_DATA');
            try {
                const parsedUrl = new URL(value);

                const searchParams = new URLSearchParams(parsedUrl.search);
               
                searchParams.set('type', 'raw-with-headers');

                dataProvider('GET_OFF_RESOURCE', `${parsedUrl.pathname.substring(1)}?${searchParams.toString()}`)
                    .then(({ data }) => {
                        const rawText = rawDataWithHeader2SpreadsheetFormat(data);

                        navigator.clipboard.writeText(rawText).then(() => {
                            dispatch(showNotification("Dati copiati"));
                            setStatus('INITIAL');
                        });
                    })
                    .catch(() => {
                        dispatch(showNotification("Errore durante la generazione dei dati", 'error'));
                        setStatus('INITIAL');
                    });
            }
            catch (error) {
                dispatch(showNotification("url fornita non corretta", 'error'));
                setStatus('INITIAL');
            }


        }
    }, [dataProvider, dispatch, status, value]);

    if (!permissions || !permissions.includes(`utils-importDataFromFoorban`)) {
        return null;
    }

    return (
        <Container maxWidth="sm" className={classes.root}>
            <TextField helperText="https://api.foorban.business/tax?limit=1000&page=1&offset=0&sort%5B0%5D=id%2CASC" disabled={['FETCH_DATA', 'FETCHING_DATA'].includes(status)} fullWidth variant='filled' value={value} onChange={(e) => setValue(e.target.value)}></TextField>
            <Button
                style={{ marginTop: 10 }}
                size="small"
                color="primary"
                variant="contained"
                disabled={['FETCH_DATA', 'FETCHING_DATA'].includes(status) || !value || value.length === 0}
                onClick={() => {
                    setStatus('FETCH_DATA');
                }}
                startIcon={<DvrIcon />}
            >
                Spreadsheet
            </Button>
        </Container>
    );
}