import React from 'react';
import { Edit, SimpleForm, TextInput, AutocompleteInput } from 'react-admin';
import BaseEdit from '../../inheritance/BaseEdit';
import { required } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { TypeList } from './';

export function CoffeeTypeEdit(props) {
	return (
		<Edit {...props}>
			<SimpleForm>
				<AutocompleteInput fullWidth label="Tipo" validate={[required()]} source="type" choices={TypeList.map(type => {
					return {
						id: type,
						name: type
					}
				})} />
				<TextInput fullWidth validate={[required()]} source="name" />
				<TextInput fullWidth validate={[required()]} source="clientName" />
				<RichTextInput
					fullWidth
					source="description"
				/>
				<BaseEdit />
			</SimpleForm>
		</Edit>
	);
}
