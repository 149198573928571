import React from 'react';
import { Datagrid, List, TextField, TextInput } from 'react-admin';
import Filter from '../inheritance/BaseFilter';
import ListActions from '../../components/ListActions';

const GatewayFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nome" source="name" alwaysOn />
        <TextInput label="MainUserId" source="mainUserId" alwaysOn />
    </Filter>
);

export const GatewayList = props => (
    <List {...props} actions={<ListActions />}  filters={<GatewayFilter />}>
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <TextField label="Nome" source="name" />
            <TextField label="MainUserId" source="mainUserId" />
        </Datagrid>
    </List>
);