import React from 'react';
import { SimpleShowLayout, TextField, DateField, ShowController, ShowView, ReferenceField } from 'react-admin';
import Aside from '../../../inheritance/BaseAsideWithUser';
import { Permissions } from '../../../permissions';
import StateMachineReferenceField from '../../../../../components/StateMachineReferenceField';
import  { StateMachineFieldWithActions } from '../../../../../components/StateMachineFieldWithActions';
import { Box, Typography, InputLabel } from '@material-ui/core'; 
import { DeliveryManProvidedData } from './components/DeliveryManProvidedData'
import { ProviderData } from './components/ProviderData'

export function JobTaskShow(props) {
    return (
        <ShowController  {...props}>
            {controllerProps => <ShowView actions={null} {...props} {...controllerProps} aside={<Aside props={props} record={props.record}></Aside>} >
                <SimpleShowLayout>
                    <TextField label="Type" source="label" />
                    <TextField label="Executor Main User ID" source="executorMainUserId" />
                    <JobTaskDuration label='Duration' />
                    <DateField locales="it-IT" label="Start Date" source="startDate" showTime />
                    <TextField label="Output Label" source="ouputLabel" />
                    <TextField label="Job Stage Name" source="jobStageName" />
                    <TextField label="Job Stage Sub-Stage" source="jobStageSubStage" />
                    <TextField label="Job Stage Inter-Stage Order" source="jobStageInterStageOrder" />
                    <ProviderData />
                    <DeliveryManProvidedData />
                    <TextField label="Execution" source="execution" />
                    {controllerProps && controllerProps.record && controllerProps.record.taskConfigId && <ReferenceField
                        link="show"
                        label="Task config"
                        source="taskConfigId"
                        reference={Permissions.taskConfig.feature}
                    >
                        <TextField source="label" />
                    </ReferenceField>}
                    <StateMachineReferenceField
                        label="Stato"
                        source="stateMachine"
                        reference={`${Permissions.jobTask.feature}_${Permissions.jobTask.subFeatures.stateMachine.name}`}>
                    </StateMachineReferenceField>
                    <StateMachineFieldWithActions label="Actions" reference={`${Permissions.jobTask.feature}_${Permissions.jobTask.subFeatures.stateMachine.name}`} source='stateMachineId' />
                </SimpleShowLayout>
            </ShowView>}
        </ShowController>
    )
}


export function JobTaskDuration(props) {
	const seconds = props.record.duration
	const minutes = Math.floor(seconds / 60) % 60;
	const hours = Math.floor(seconds / 3600);
	const remainingSeconds = seconds % 60;
	return (
		<Box>
			<InputLabel >
				<Typography variant='subtitle2'>Duration</Typography>
			</InputLabel>
			<Typography variant='subtitle2' >{`${hours}h ${minutes}m ${remainingSeconds}s`}</Typography>
		</Box> 
	);
}