import React from 'react';
import { Toolbar, SaveButton, DeleteButton, Edit, SimpleForm, required, ReferenceInput, BooleanInput, AutocompleteInput, DateInput, TextInput } from 'react-admin';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Permissions } from '../../permissions/permissions';
import { DAYS } from '../../utils/enum/Common';
import BaseEdit from '../inheritance/BaseEdit';
import RichTextInput from 'ra-input-rich-text';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const CustomToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
        <DeleteButton redirect={props.redirect} />
    </Toolbar>
);

const SellingPointImpulseEditComponent = props => {
    return (
        <Edit {...props}>
            <SimpleForm redirect={() => (`/sellingPoint/${props.sellingPointId}/show`)} toolbar={<CustomToolbar redirect={() => (`/sellingPoint/${props.sellingPointId}/show`)}/>}>
                <ReferenceInput
                    validate={[required("Il listino di vendita è obbligatorio")]}
                    filterToQuery={searchText => {
                        if(!searchText)
                            return {};

                        return { name: searchText }
                    }}
                    label="Listino di vendita"
                    source="priceListId"
                    reference={`${Permissions.priceList.feature}`}
                >
                    <AutocompleteInput
                        shouldRenderSuggestions={val => val && val.length >= 2 }
                        optionText="name"
                        optionValue="id" />
                </ReferenceInput>
                <BooleanInput label="Aperto" source="open"></BooleanInput> 
                <BooleanInput label="SME" source="sme"></BooleanInput> 
                <DateInput
                    validate={(value) => {
                        if(!value) {
                            return null;
                        }

                        const now = new Date();
                        now.setHours(0,0,0,0);

                        const date = new Date(value);
                        if (date < now) {
                            return 'deliveryDate deve essere nel futuro';
                        }
                        return null;
                    }}
					label="Data di Consegna"
					source="deliveryDate" />
                <ReferenceInput
                    allowEmpty
                    label="Locker"
                    source="lockerId"
                    reference={Permissions.locker.feature}
                    filterToQuery={searchText => {
                        if(!searchText)
                            return {};

                        return { name: searchText }
                    }}
                >
                    <AutocompleteInput
                        shouldRenderSuggestions={val => val && val.length >= 2 }
                        optionText="name"
                        optionValue="id" />
                </ReferenceInput>
                <TextInput
                    label="Frase di chiusura"
                    helperText="default: Negozio momentaneamente chiuso"
                    source="closePhrase" />
                <AutocompleteInput
                    label="Giorno di inizio offerta"
                    source="dayOfWeekStartOffer"
                    choices={DAYS} />
                <TextInput
                    type="time"
                    label="Orario di apertura"
                    source="openHour"/>
                <TextInput
                    type="time"
                    label="Orario di chiusura"
                    source="closeHour"/>
                <AutocompleteInput
                    label="Stato Banner"
                    source="bannerStatus"
                    choices={[
                        {
                            id: 'ONLY-TITLE',
                            name: 'ONLY-TITLE',
                        },
                        {
                            id: 'ON',
                            name: 'ON',
                        },
                        {
                            id: 'OFF',
                            name: 'OFF',
                        }
                    ]} />
                <TextInput
                    label="Titolo Banner"
                    source="bannerTitle"/>
                <AutocompleteInput
                    label="Style Banner"
                    source="bannerStyle"
                    choices={[
                        {
                            id: 'LIGHT_ORANGE',
                            name: 'LIGHT_ORANGE',
                        },
                        {
                            id: 'LIGHT_GREEN',
                            name: 'LIGHT_GREEN',
                        },
                        {
                            id: 'DARK_ORANGE',
                            name: 'DARK_ORANGE',
                        }
                    ]} />                 
                <RichTextInput
                    fullWidth
                    label="Contenuto Banner"
                    source="bannerContent" />
                <RichTextInput
                    fullWidth
                    label="Messaggio di completamento ordine personalizzato"
                    source="customSuccessMessage" />   
                <BaseEdit />
            </SimpleForm>
        </Edit>
    )
};

export const SellingPointImpulseEdit = connect((state, props) => {
    return {
        sellingPointId: state.admin.resources.sellingPointImpulse && state.admin.resources.sellingPointImpulse.data[props.id] ? state.admin.resources.sellingPointImpulse.data[props.id].sellingPoint.id : null,
    }
})(SellingPointImpulseEditComponent)