import React from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { SimpleShowLayout, TextField, DateField, BooleanField, ReferenceField } from 'react-admin';
import { Permissions } from "../../permissions/permissions";
export default (props) => {
    return (
        <Card style={{ width: 300, marginLeft: '1em' }}>
            <CardHeader title="Dettagli" />
            <CardContent>
                <SimpleShowLayout {...props} >
                    <BooleanField label="Attivo" source="active" />
                    <ReferenceField link='show' label="Creato da" source="creation_user" reference={Permissions.mainUser.feature}>
                        <TextField source="label" />
                    </ReferenceField>
                    <DateField showTime locales="it-IT" label="Creato il" source="created_at" />
                    <ReferenceField link='show' label="Aggiornato da" source="last_update_user" reference={Permissions.mainUser.feature}>
                        <TextField source="label" />
                    </ReferenceField>
                    <DateField showTime locales="it-IT"  label="Aggiornato il" source="updated_at" />
                </SimpleShowLayout>
            </CardContent>
        </Card>
    )
};