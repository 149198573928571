import React from 'react';
import { Show, SimpleShowLayout, TextField, BooleanField, DateField } from 'react-admin';
import Typography from '@material-ui/core/Typography';

export function Preview({ record }) {

	if(record.type === 'image' && record.fileName) {
		return <>
			<Typography variant="body2">Preview</Typography>
			<img style={{width: '100%', maxWidth: '300px'}} alt={record.alt} src={`${process.env.REACT_APP_FILES_CDN}/${record.fileName}`} />
			<Typography>{process.env.REACT_APP_FILES_CDN}/{record.fileName}</Typography>
		</>
	}

	return null;
}


export const FileShow = (props) => {
	return (
	<Show  {...props}>
		<SimpleShowLayout>
			<Preview />
			<TextField label="ID" source="id" />
			<TextField label="Type" source="type" />
			<TextField label="Titolo" source="title" />
			<TextField label="Alt" source="alt" />
			<TextField label="File Name" source="fileName"></TextField>
			<TextField label="Original Name" source="originalName"></TextField>
			<BooleanField label="Caricato" source="uploaded" />
			<BooleanField label="Attivo" source="active" />
			<DateField label="Creato il" source="created_at" />
			<DateField label="Aggiornato il" source="updated_at" />
		</SimpleShowLayout>
	</Show>
)};
