export const WarehouseLocationType_Warehouse = "Warehouse"
export const WarehouseLocationType_WarehouseLocation = "WarehouseLocation"

export const WarehouseLocationType = [
    WarehouseLocationType_Warehouse,
    WarehouseLocationType_WarehouseLocation
];

export const WarehouseLocationUsageView = "View";
export const WarehouseLocationUsageStorage = "Storage";
export const WarehouseLocationUsageClient = "Client";
export const WarehouseLocationUsageSupplier = "Supplier";
export const WarehouseLocationUsageProduction = "Production";
export const WarehouseLocationUsageTransit = "Transit";
export const WarehouseLocationUsageInventory = "Inventory";
export const WarehouseLocationUsageWaste = "Waste";

export const WarehouseLocationUsage = [
    WarehouseLocationUsageView,
    WarehouseLocationUsageStorage,
    WarehouseLocationUsageClient,
    WarehouseLocationUsageSupplier,
    WarehouseLocationUsageProduction,
    WarehouseLocationUsageTransit,
    WarehouseLocationUsageInventory,
    WarehouseLocationUsageWaste
];