import React from 'react';
import { SimpleForm, Edit, FormDataConsumer, ReferenceInput, SelectInput, required, TextInput } from 'react-admin';
import BaseEdit from '../../../inheritance/BaseCreate';
import { Permissions, TransferActions } from '../../../../permissions/permissions';
import { useHistory } from 'react-router-dom';
import { LotCreate } from './PhysicalItemTransferCreate';
import NewInputSelectRest, { AutocompleteInputType, TextInputType as CommonTextInputType } from '../commonComponents/InputRest';

export const PhysicalItemTransferEdit = props => {
    let history = useHistory();
    const fieldOptions = `${Permissions.transfer.feature}/${Permissions.physicalItemTransfer.feature}/${TransferActions.fieldOptions}`;

	return (
        <Edit {...props}>
            <SimpleForm redirect={(basePath, id, data) => {
                history.goBack();
               //history.replace(`/${Permissions.physicalItemTransfer.feature}?filter=${JSON.stringify({ "requestedItemTransferId||eq": data.requestedItemTransferId})}`);
            }}>
                <div style={{ display: 'none' }}>
					<TextInput disable={true} source="id" />
					<TextInput source={'requestedItemTransferId'} />
                </div>
                <ReferenceInput
					fullWidth
					label="Item"
					source="itemId"
					reference={`${Permissions.item.feature}`}>
					<SelectInput options={{disabled: true}} optionText="name" />
				</ReferenceInput>
				<NewInputSelectRest
					type={AutocompleteInputType}
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							}
						}
					}
					fieldOptions={fieldOptions}
					label="Lotto"
					source="lotId"
					dependOn="itemId"
					validate={[required('campo obbligatorio')]} />
				<FormDataConsumer>
					{(form) => <LotCreate
						{...form}
						{...props}/>
					}
				</FormDataConsumer>
				<NewInputSelectRest
					type={AutocompleteInputType}
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							}
						}
					}
					fieldOptions={fieldOptions}
					label="From"
					reference={`${Permissions.warehouseLocation.feature}`}
					source="fromWarehouseLocationId"
					dependOn="requestedItemTransferId" />
				<NewInputSelectRest
					type={AutocompleteInputType}
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							}
						}
					}
					fieldOptions={fieldOptions}
					label="To"
					source="toWarehouseLocationId"
					dependOn="fromWarehouseLocationId"
					reference={`${Permissions.warehouseLocation.feature}`}
					validate={[required('campo obbligatorio')]} />
				<NewInputSelectRest
					type={CommonTextInputType}
					fieldOptions={fieldOptions}
					label="Quantità"
					source="quantityReference"
					dependOn="requestedItemTransferId" />
				<NewInputSelectRest
					type={CommonTextInputType}
					fieldOptions={fieldOptions}
					label="Valore"
					source="value"
					dependOn="quantityReference" />
				<BaseEdit />
			</SimpleForm>
		</Edit>
	);
};