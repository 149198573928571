import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useHistory, useParams, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  media: {
    height: '100%',
  },
  itemContent: {
    minHeight: '160px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flex: 1,
    overflow: 'scroll',
    padding: theme.spacing(0, 1),
  },
  itemContainer: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    display: 'flex',
    height: '100%',
    padding: theme.spacing(7, 0, 0),
    margin: theme.spacing(-7, 0),
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      margin: theme.spacing(-6, 0),
      padding: theme.spacing(6, 0, 0),
    },
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(-8, 0),
      padding: theme.spacing(8, 0, 0),
    },
  },
  paginationContainer: {
    padding: theme.spacing(0, 1, 1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paginationButton: {
    width: '100%'
  },
  paginationText: {
    width: '80%' 
  },
  terminateButton: {
    width: '100%',
    margin: theme.spacing(0, 1, 1),
  },
  terminateButtonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

export default function Item(props) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const params = new URLSearchParams(location.search);
  const item = (params.get('item') === null || params.get('item') === undefined) ? 1 : (params.get('item') > 0 ? params.get('item') : 1);

  return (
    <Container className={classes.itemContainer} disableGutters>
      <CardMedia
        className={classes.media}
        image={props.item.image}
        title={props.item.title}
      />
      <div className={classes.itemContent}>
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
        }}>
        <Typography variant="h6">
        ID <strong>{props.item.id}</strong>
        </Typography>
        <Typography variant="h6">
        UoM <strong>{props.item.uom}</strong>
        </Typography>
        </div>
        <Typography>
          {props.item.title}
          {props.requestedItemTransfer && props.requestedItemTransfer.transactionName && <><br></br>{props.requestedItemTransfer.transactionName}</>}
        </Typography>
        <Lot requestedItemTransfer={props.requestedItemTransfer}/>
        <Quantity requestedItemTransfer={props.requestedItemTransfer}/>
      </div>
      <div className={classes.paginationContainer}>
        <Button style={{marginRight: '8px'}} disabled={Number.parseInt(item) <= 1} variant="outlined" color="primary" onClick={props.returnTodo}>{'<<'}</Button>
        <Button className={classes.paginationButton} disabled={Number.parseInt(item) <= 1} variant="outlined" color="primary" onClick={() => {
          history.replace(`/picking/${id}?item=${Number.parseInt(item) - 1}`)
        }}>Precedente</Button>
        <Typography align="center" className={classes.paginationText}>
          {item} di {props.itemLenght}
        </Typography>
        <Button className={classes.paginationButton} disabled={(props.itemLenght === 1) || (item && Number.parseInt(item) === props.itemLenght)} variant="outlined" color="primary" onClick={() => {
          history.replace(`/picking/${id}?item=${Number.parseInt(item) + 1}`)
        }}>Successivo</Button>
        <Button style={{marginLeft: '8px'}} disabled={(props.itemLenght === 1) || (item && Number.parseInt(item) === props.itemLenght)} variant="outlined" color="primary" onClick={props.goTodo}>{'>>'}</Button>
      </div>
      <div className={classes.terminateButtonContainer} >
        <Button className={classes.terminateButton} size='large' variant="outlined" color="primary" onClick={props.terminate}>Termina</Button>
        <Button className={classes.terminateButton} size='large' variant="outlined" color="primary" onClick={props.todo}>Metti in ToDo</Button>
      </div>
    </Container>
  );
}

function Lot({requestedItemTransfer}) {
  if(requestedItemTransfer.transferItemType ==='lot' &&
  requestedItemTransfer.lot &&
  (requestedItemTransfer.lot.expirationDate || requestedItemTransfer.lot.supplierLot)) {
    let expirationDate = null;
    if (requestedItemTransfer.lot.expirationDate) {
      let date = new Date(requestedItemTransfer.lot.expirationDate);
      expirationDate = `${(date.getDate()).toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
    }
    return (
      <Typography variant="h6">
        <small>Lot</small>{expirationDate && ` Exp ${expirationDate}`}
        {requestedItemTransfer.lot.supplierLot && ` Cod ${requestedItemTransfer.lot.supplierLot}`}
      </Typography>
    );
  }

  return null;
}

function Quantity({requestedItemTransfer}) {
  let picked = 0;
  if(requestedItemTransfer.physicalItemTransfers && Array.isArray(requestedItemTransfer.physicalItemTransfers)) {
    requestedItemTransfer.physicalItemTransfers.forEach(physicalItemTransfer => {
      if(physicalItemTransfer.status === "Reserved" && physicalItemTransfer.done) {
        picked += physicalItemTransfer.quantityReference;
      }
    });
  }
  return (
    <Typography align="center" variant="h4" style={picked < requestedItemTransfer.quantityReference ? {backgroundColor: 'red'} : {}}>
      <small>Picked</small><strong> {picked} </strong><small>of</small><strong> {requestedItemTransfer.quantityReference}</strong>
    </Typography>
  );
}