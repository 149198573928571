import React, { useEffect, useState } from "react";
import {
	AutocompleteArrayInput,
	SelectInput,
	showNotification,
	useDataProvider,
	usePermissions,
} from "react-admin";
import { Permissions } from "../../../../permissions";
import { GET_OFF_RESOURCE } from "../../../../../../providers/nestjs_crud";
import { useDispatch } from "react-redux";

/**
 *
 * @param {{label: string, source: string, multiple?: boolean}} props
 * @returns a component to filter a list based on the type
 */
export function TypeInput(props) {
	const [state, setState] = useState("INITIAL");
	const [types, setTypes] = useState([]);
	const dataProvider = useDataProvider();
	const { permissions } = usePermissions();
	const dispatch = useDispatch();

	useEffect(() => {
		if (state === "INITIAL") {
			setState("FETCHING_DATA");
			dataProvider(
				GET_OFF_RESOURCE,
				Permissions.missionJob.feature + "/types"
			)
				.then((message) => {
					setState("FETCHED");
					setTypes(message.data);
				})
				.catch((error) => {
					setState("ERROR_FETCHING_DATA");
					dispatch(showNotification("Errore durante la fetch dei tipi", 'error'));
				});
		}
	}, [dataProvider, dispatch, state]);

	if (
		!permissions ||
		!permissions.includes(
			`${Permissions.missionJob.feature}-${Permissions.missionJob.actions.ReadAll}`
		)
	) {
		return null;
	}

	if (!props.label || !props.source) {
		console.error("TypeFilter: label and source must be defined");
		return null;
	}

	if(state !== "FETCHED") {
		return null;
	}

	if (props.multiple) {
		return (
			<AutocompleteArrayInput
				translateChoice={false}
				allowEmpty={false}
				parse={(value) => {
					return value.includes(null) ? [] : value;
				}}
				{...{ ...props, choices: types }}
			/>
		);
	} else {
		return (
			<SelectInput
				{...{...props, choices:types}}
			/>
		);
	}
}
