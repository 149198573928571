import React from "react"
import { TextField, ShowButton } from 'react-admin';
import { ListPreview } from '../../../../layouts/ListPreview';
import { Permissions } from '../../../../permissions/permissions';
import { useHistory } from "react-router-dom";
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

export default function JobList (props) {
    const history = useHistory();
    return (
        <ListPreview
            title={"Servizi Missione"}
            basePath = {`/${Permissions.missionJob.feature}`}
            resource = {`${Permissions.missionJob.feature}`}
            filterList={{ "missionId||eq": props.id}}
            hasCreateModal={false}
            rowClick={false}
            reactAdminListProps={{
                bulkActionButtons: false,
                sort: { field: 'created_at', order: 'DESC' }
            }}
            actionTitle={"Aggiungi"}
            customAction={() => {
                history.push(`/mission/job/create?missionId=${props.id}`);
            }}
        >
            <TextField sortable={false} source="type" />
            <TextField sortable={false} source="sellingPointLabel" />
            <TextField sortable={false} source="warehouseLocationLabel" />
            <TextField sortable={false} source="durationMinutes" />
            <TextField sortable={false} source="maxAdvanceMinutes" />
            <TextField sortable={false} label='Commento' source="executionComment" />
            <TextField sortable={false} label='STATO' source="stateMachine.status" />
            <MissionJobEdit />
            <ShowButton />
        </ListPreview>
    )
}

function MissionJobEdit(props) {
    const history = useHistory();
    return (<Button color="primary" variant="text" startIcon={<EditIcon color="primary" />} onClick={() => {
        history.push(`/mission/job/${props.record.id}/edit`);
    }}>
        Edit
    </Button>
    );
}
