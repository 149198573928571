import React from 'react';
import { Edit, SimpleForm, ReferenceInput, AutocompleteInput, NumberInput, FormDataConsumer } from 'react-admin';
import BaseEdit from '../../inheritance/BaseEdit';
import { required } from 'react-admin';
import { RecipeTypeList } from '.';
import { Permissions } from '../../../permissions/permissions';

export function CoffeeRecipeEdit(props) {
	return (
		<Edit {...props}>
			<SimpleForm>
			<ReferenceInput
					fullWidth
					validate={[required()]}
                    filterToQuery={searchText => {
                        if(!searchText)
							return {
								saleable: true,
							}
                        return {
							saleable: true,
                            name: searchText
                        }
                    }}
                    label="Item"
                    source="itemId"
                    reference={`${Permissions.item.feature}`}
                >
                    <AutocompleteInput
                        optionText={(record) => record ? `${record.id} - ${record.name}` : ''}
                        optionValue="id" />
                </ReferenceInput>
				<AutocompleteInput fullWidth label="Tipo" validate={[required()]} source="type" choices={RecipeTypeList.map(RecipeType => {
					return {
						id: RecipeType,
						name: RecipeType
					}
				})} />
				<FormDataConsumer>
					{({ formData }) => {
						if([
							'coffee',
							'coffee-milk',
							'coffee-soluble',
							'coffee-milk-soluble'
						].includes(formData.type)) {
							return (
								<>
									<h4>Caffè</h4>
									<ReferenceInput
										fullWidth
										validate={[required()]} 
										filterToQuery={searchText => {
											if(!searchText)
												return null;

											return {
												name: searchText
											}
										}}
										label="Coffee Type"
										source="coffeeTypeId"
										reference={`${Permissions.coffeeType.feature}`}
									>
										<AutocompleteInput
											optionText={(record) => record ? `${record.id} - ${record.name}` : ''}
											optionValue="id" />
									</ReferenceInput>			
									<NumberInput fullWidth validate={[required()]} source="coffeeGrams" />
									<NumberInput fullWidth validate={[required()]} source="waterGrams" />
									<NumberInput fullWidth validate={[required()]} source="extraWaterGrams" />
								</>
							);
						} else {
							return null;
						}
					}}
				</FormDataConsumer>
				<FormDataConsumer>
					{({ formData }) => {
						if(['milk','coffee-milk','milk-soluble', 'coffee-milk-soluble'].includes(formData.type)) {
							return (
								<>
									<h4>Latte</h4>
									<NumberInput fullWidth validate={[required()]} source="hotMilkGrams" />
									<NumberInput fullWidth validate={[required()]} source="hotFrothedMilkGrams" />
									<NumberInput fullWidth validate={[required()]} source="frothPercentage" />
								</>
							);
						} else {
							return null;
						}
					}}
				</FormDataConsumer>
				<FormDataConsumer>
					{({ formData }) => {
						if(['milk-soluble','coffee-soluble', 'coffee-milk-soluble', 'soluble'].includes(formData.type)) {
							return (
							<>
									<h4>Solubile</h4>
									<ReferenceInput
										allowEmpty emptyText="Non specificato"
										fullWidth
										filterToQuery={searchText => {
											if(!searchText)
												return null;

											return {
												internalName: searchText
											}
										}}
										label="Soluble Type 1"
										source="solubleType1Id"
										reference={`${Permissions.solubleType.feature}`}
									>
										<AutocompleteInput
											optionText="internalName"
											optionValue="id" />
									</ReferenceInput>
									<NumberInput fullWidth source="soluble1WaterGrams" />
									<NumberInput fullWidth source="soluble1Grams" />
									<ReferenceInput
											allowEmpty emptyText="Non specificato"
											fullWidth
											filterToQuery={searchText => {
												if(!searchText)
													return null;

												return {
													internalName: searchText
												}
											}}
											label="Soluble Type 2"
											source="solubleType2Id"
											reference={`${Permissions.solubleType.feature}`}
									>
										<AutocompleteInput
											optionText="internalName"
											optionValue="id" />
									</ReferenceInput>
									<NumberInput fullWidth source="soluble2WaterGrams" />
									<NumberInput fullWidth source="soluble2Grams" />
							</>
							);
						} else {
							return null;
						}
					}}
				</FormDataConsumer>
				<BaseEdit />
			</SimpleForm>
		</Edit>
	);
}
