import React, { useEffect, useState, Fragment } from 'react';
import { POST_OFF_RESOURCE } from '../../../../providers/nestjs_crud';
import { useDataProvider, Loading, FormDataConsumer, AutocompleteInput, ReferenceInput, SelectInput } from 'react-admin';
import { Permissions, TransferActions } from '../../../../permissions/permissions';
import { useForm } from 'react-final-form';
import { Typography } from '@material-ui/core';

const STATUS_FETCH_DATA = "fetch_data";
const STATUS_FETCHING_DATA = "fetching_data";
const STATUS_DATA_FETCHED = "data_fetched";
const STATUS_FETCHING_DATA_ERROR = "fetching_data_error";
const STATUS_REALOAD_CHOICE_DATA = "reaload_choice_data";

const Select = (props) => {
    const [disabled, setDisabled] = useState(props.disabled ? true : false);
    const [status, setStatus] = useState(STATUS_FETCH_DATA);
    const [choices, setDataChoices] = useState([]);
    const [dependOn, setDependOn] = useState(null);
    const [initialValue, setInitialValue] = useState(undefined);
    const [value, setValue] = useState(null);
    const form = useForm();
    const dataProvider = useDataProvider();

    useEffect(() => {
        if(status === STATUS_REALOAD_CHOICE_DATA) {
            let fieldOptions = '';

            if(!props.fieldOptions) {
                fieldOptions = `${Permissions.transfer.feature}/${TransferActions.fieldOptions}`;
            }
            else {
                fieldOptions = props.fieldOptions;
            }

            dataProvider(POST_OFF_RESOURCE, `${fieldOptions}/${props.source}`, {
                data: form.getState().values
            })
                .then(({data}) => {
                    setDataChoices(data);
                })
                .catch((e) => {
                    setStatus(STATUS_FETCHING_DATA_ERROR)
                });
        }
    })

    useEffect(() => {
        if((((props.dependOn === undefined || props.dependOn === null) || (dependOn !== undefined && dependOn !== null)) && status === STATUS_FETCH_DATA)) {
            setStatus(STATUS_FETCHING_DATA);

            let fieldOptions = '';

            if(!props.fieldOptions) {
                fieldOptions = `${Permissions.transfer.feature}/${TransferActions.fieldOptions}`;
            }
            else {
                fieldOptions = props.fieldOptions;
            }

            let dataForm = form.getState().values;

            if(!dataForm[props.source] && props.initialValue !== undefined) {
                dataForm = {
                    ...dataForm,
                    [props.source]: props.initialValue
                }
            }

            dataProvider(POST_OFF_RESOURCE, `${fieldOptions}/${props.source}`, {
                data: dataForm
            })
                .then(({data}) => {
                    /*
                    to do: rivedere BE - FE choices location
                    */
                    if(Array.isArray(data)) {
                        data.forEach((singleData) => {
                            if(singleData.selected) {
                                setInitialValue(singleData.id);
                            }
                        });
                        setDataChoices(data);
                    }
                    else if(Array.isArray(data.choices)) {
                        if(data.disabled && !props.initialValue && data.choices.length === 0) {
                            setStatus(STATUS_FETCHING_DATA_ERROR);
                            return;
                        }

                        if(data.disabled && !props.initialValue) {
                            setDisabled(true);
                        }
                        else if(data.disabled && props.reference) {
                            setDisabled(true);
                        }

                        data.choices.forEach((singleData) => {
                            if(singleData.selected) {
                                setInitialValue(singleData.id);
                            }
                        });

                        setDataChoices(data.choices);
                    }            
                    setStatus(STATUS_DATA_FETCHED)
                })
                .catch(() => {
                    setStatus(STATUS_FETCHING_DATA_ERROR)
                });
        }
    }, [setDataChoices, setStatus, setDisabled, dataProvider, form, status, dependOn, props.dependOn, props.source, props.fieldOptions, props.initialValue, props.reference])

    if(props.dependOn !== undefined && props.dependOn !== null) {
        if(!form.getFieldState(props.dependOn) || (form.getFieldState(props.dependOn) && form.getFieldState(props.dependOn).value === undefined) ) {
            return null;
        }

        if((dependOn === undefined || dependOn === null) || ((dependOn !== undefined && dependOn !== null) && form.getFieldState(props.dependOn) && dependOn !== form.getFieldState(props.dependOn).value)) {
            setDependOn(form.getFieldState(props.dependOn).value);
            if(status !== STATUS_FETCH_DATA) {
                props.formData[props.source] = undefined;
                setStatus(STATUS_FETCH_DATA);
            }
        }
    }
    if (status === STATUS_FETCHING_DATA_ERROR){
        return (<Typography> Errore durante il caricamento {props.source}</Typography>);
    }

    if (status !== STATUS_DATA_FETCHED && status !== STATUS_REALOAD_CHOICE_DATA) {
        return <Loading />;
    }

    if(disabled) {
        return (
            <ReferenceInput
                initialValue={initialValue}
                label={props.label}
                source={props.source}
                reference={props.reference}>
                <SelectInput options={{disabled: true}} optionText={props.optionText ? props.optionText: "name"} />
            </ReferenceInput>
        );
    }

    if(value === null && form.getFieldState(props.source) && form.getFieldState(props.source).value) {
        setValue(form.getFieldState(props.source).value);
    }
    else if(value && form.getFieldState(props.source) && form.getFieldState(props.source).value && form.getFieldState(props.source).value !== value) {
        setValue(form.getFieldState(props.source).value);

        const dataCheck = choices.filter(dataSingle => {
            return dataSingle.id === form.getFieldState(props.source).value
        });
        if(dataCheck.length === 0) {
            setStatus(STATUS_REALOAD_CHOICE_DATA)
        }
    }

	return (
        <Fragment>
            <AutocompleteInput
                emptyText="Non specificato"
                allowEmpty={props.allowEmpty}
                options={props.options}
                initialValue={initialValue}
                label={props.label}
                source={props.source}
                choices={choices}
                validate={props.validate} />
        </Fragment>
	);
}

export default function InputSelectRest(props) {
    return (
        <FormDataConsumer>
            {(form) => <Select {...form} {...props} />}
        </FormDataConsumer>
    );
}