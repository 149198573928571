import React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import BaseCreate from '../../inheritance/BaseCreate';

export function CoffeeRecipeListCreate(props) {
	return (
		<Create {...props}>
			<SimpleForm redirect="show">
				<TextInput fullWidth validate={[required()]} source="name" />
				<BaseCreate />
			</SimpleForm>
		</Create>
	);
}