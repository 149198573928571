import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Permissions } from '../../../../permissions/permissions';
import { showNotification, useDataProvider, usePermissions, useRefresh } from "react-admin";
import { useDispatch, useStore } from "react-redux";
import { POST_OFF_RESOURCE } from "../../../../providers/nestjs_crud";
import { Button } from '@material-ui/core';
import DirectionsIcon from '@material-ui/icons/Directions';


const style = makeStyles(() => ({
    bulkAction:{
        padding: "4px 5px",
        fontSize: "0.8125rem",
    },
}));

export function SendToRoutingButton(props) {
	const classes = style();
	const { permissions } = usePermissions();
	const [state, setState] = useState("INITIAL");
	const dataProvider = useDataProvider();
	const dispatch = useDispatch();
	const refresh = useRefresh();
    const store = useStore();

    // props.multiple is used for the bulk action button
    const data2send = props.multiple ? props.selectedIds : [props.record?.id]

	useEffect(() => {
		if (state === "SEND") {
            setState('SENDING');
			dataProvider(
				POST_OFF_RESOURCE,
				`${Permissions.missionV2.feature}/${Permissions.missionV2.actions.RoutingToolCreate}`,
				{
					data: data2send,
				}
			)
				.then((data) => {
					if (data.data.success) {
						setState("INITIAL");
                        dispatch(
                            showNotification(
                                `Invio al tool di routing completato`,
                            )
                        );
					}else{
                        dispatch(
                            showNotification(
                                `Errore durante l'invio dei dati al routing: ${data.data.error}`,
                                "error"
                            )
                        );
                        setState("SEND_ERROR");
                    }
                    refresh();
				})
				.catch((error) => {
					setState("SEND_ERROR");
					dispatch(
						showNotification(
							`Errore durante l'invio dei dati al routing: ${error}`,
							"error"
						)
					);
				});
		}
	}, [data2send, dataProvider, dispatch, refresh, state]);

	if (
		!permissions ||
		!permissions.includes(
			`${Permissions.missionV2.feature}-${Permissions.missionV2.actions.RoutingToolSync}`
		)
	) {
		return null;
	}

	const hubSelected = sessionStorage.getItem("hubSelected") ? true : false;
	const handleSendToRouting = (e) => {
		e.stopPropagation();
		setState("SEND");
	};

    if(props.multiple){
        if (hubSelected) {
            const missions = store.getState().admin.resources.missionV2.data;
            const canBeSent = props.selectedIds.every(
                (id) => (missions[id]?.stateMachine?.status === "TO_BE_PLANNED" && missions[id]?.duration > 0)
            );
    
            if (canBeSent) {
                return (
                    <Button
                        className={`${classes.bulkAction}`}
                        color="primary"
                        onClick={handleSendToRouting}
                        startIcon={<DirectionsIcon/>}
                        disabled={state === "SENDING"}
                    >
                        Manda al Routing
                    </Button>
                );
            }
        }
    } else {
        if (hubSelected && props.record?.stateMachine?.status === "TO_BE_PLANNED" && props.record?.duration > 0) {
            return (
                <Button
                    color="primary"
                    onClick={handleSendToRouting}
                    startIcon={<DirectionsIcon />}
                    disabled={state === "SENDING"}
                >
                    Manda al Routing
                </Button>
            );
        }
    }	
	return null;
}