import React, { useEffect, useState } from "react";
import { useDataProvider, showNotification } from "react-admin";
import { useDispatch } from "react-redux";
import {
  AppBar,
  Toolbar,
  Card,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";

import { Permissions } from "../../../../../permissions/permissions";
import { POST_OFF_RESOURCE } from "../../../../../providers/nestjs_crud";

export function FormDropdownTask(props) {
  const { task, config } = props;
  const [selectedItem, setSelectedItem] = useState(
    config && config.selectedItem ? config.selectedItem : null
  );
  const [state, setState] = useState("INITIAL");
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();

  useEffect(() => {
    if (state === "SEND_DATA") {
      setState("SENDING_DATA");
      dataProvider(
        POST_OFF_RESOURCE,
        `${Permissions.deliveryManFormDropdownTask.feature}/${task.id}`,
        {
          data: { selectedItem: selectedItem },
        }
      )
        .then((rawResponse) => {
          const response = rawResponse.data;
          if (response.success) {
            props.onDone && props.onDone();
            dispatch(showNotification(`Azione: ${task.type} completata`));
          } else {
            let message = "Errore sconosciuto.";

            if (response.errors) {
              message = response.errors
                .map(({ constraints }) => {
                  return Object.keys(constraints)
                    .map((key) => constraints[key])
                    .join(", ");
                })
                .join(", ");
            }
            dispatch(showNotification(message, "error"));
          }
          setState("INITIAL");
        })
        .catch((error) => {
          setState("INITIAL");

          dispatch(
            showNotification(`Errore durante azione: ${error}`, "error")
          );
        });
    }
  }, [dataProvider, dispatch, selectedItem, props.onDone, state, task.id, task.type, props]);

  return (
    <Card>
      <AppBar color="default" position="static">
        <Toolbar>
          <FormControl fullWidth>
            <InputLabel>{task.label}</InputLabel>
            <Select
              fullWidth
              disabled={["SENDING_DATA", "SEND_DATA"].includes(state)}
              value={selectedItem ? selectedItem.techKey : null}
              onChange={(e) => {
                setSelectedItem(
                  config.dropdownItems.find((item) => {
                    return item.techKey === e.target.value;
                  })
                );
                setState("SEND_DATA");
              }}
            >
              {config.dropdownItems?.map((item) => {
                return (
                  <MenuItem key={item.techKey} value={item.techKey}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Toolbar>
      </AppBar>
    </Card>
  );
}
