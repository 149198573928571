import { green, yellow, blue, red, grey, amber } from '@material-ui/core/colors'

export function getColorForJobStatus(action) {
    switch (action) {
        case 'TO_DO':
            return yellow;
        case 'IN_PROGRESS':
            return blue;
        case 'PAUSED':
            return amber;
        case 'DONE':
            return green;
        case 'CANCELLED':
            return red; 
        default:
            return ''; 
    }
}


export function getColorForTaskStatus(action) {
    switch (action) {
        case 'TO_DO':
            return yellow;
        case 'WIP':
            return blue;
        case 'DONE':
            return green;
        case 'CANCELLED':
            return red; 
        default:
            return grey; 
    }
}