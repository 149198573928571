import React from "react";
import { Button, Typography, AppBar, Toolbar, Card } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from "react-router-dom";
import { Permissions } from '../../../../../permissions/permissions';
import { GET_OFF_RESOURCE_PLAIN } from '../../../../../providers/nestjs_crud';
import {  useDataProvider, showNotification } from 'react-admin';
import { useDispatch } from 'react-redux';
export const InventoryTask = (props) => {
    const { task } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();

    const doAction = (action) => {
        dataProvider(GET_OFF_RESOURCE_PLAIN, `${Permissions.deliveryManInventoryTask.feature}/${task.id}/do-action/${action}`)
            .then((rawResponse) => {
                const response = JSON.parse(rawResponse);
                if (response.success) {
                    history.push(`/inventory/${response.data.warehouseInventoryId}`);
                } else {
                    if (response.data?.code === '20141_pending_inventory') {
                        history.push(`/inventory/${response.data.inventoryId}`);
                    } else if(response.errors) {
                        const message = response.errors.map(({constraints}) => {
                            return Object.keys(constraints).map(key => constraints[key]).join(', ');
                        }).join(', ');

                        throw new Error(message)
                    } else {
                        throw new Error('unknown error')
                    }
                }
            })
            .catch((error) => {
                dispatch(showNotification(`Errore durante azione: Inventory. ${error}`, 'error'));
            });
    };
   
    return (
        <Card>
            <AppBar color="default" position="static">
                <Toolbar>
                    <div style={{
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center'
                    }}>
                    <Typography variant="h5">
                        Inventario
                    </Typography>
                    <Button
                        style={{margin: '0 0 0 8px'}}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => doAction('INVENTORY-FULL')}>COMPLETO</Button>
                    </div>
                    <Button
                        endIcon={<AddIcon /> } 
                        size="small"
                        style={{margin: '0 8px'}}
                        color="primary" 
                        variant='outlined'
                        onClick={() => {
                            history.push(`/create-warehouse-acc-account/${task.config.warehouseLocation.id}`);
                        }}>Aggiungi
                    </Button>
                    <Button
                        size="small"
                        variant="contained" 
                        color="primary"
                        onClick={() => doAction('INVENTORY-PARTIAL')}>INVENTARIO
                    </Button>
                </Toolbar>
            </AppBar>
        </Card>
    )

}