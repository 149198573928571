import React from 'react';
import { Datagrid, List, TextField, TextInput, DateField, NumberField, ReferenceField, BooleanField, BooleanInput } from 'react-admin';
import Filter from '../../inheritance/BaseFilter';
import ListActions from '../../../components/ListActions';
import { Permissions } from '../../../permissions/permissions';

function AccAccountFilter(props) {
	return (
		<Filter {...props}>
			<TextInput source="id" />
			<TextInput source="ownerId||eq" />
			<TextInput source="accAccountTypeId||eq" />
			<BooleanInput source="reserved" defaultValue={true} />
		</Filter>
	);
}

export const AccAccountList = props => (
	<List {...props} sort={{ field: 'created_at', order: 'DESC' }} actions={<ListActions />} filters={<AccAccountFilter />}>
		<Datagrid rowClick="show">
			<TextField source="id" />
			<DateField source="created_at" showTime />
			<NumberField source="balance"/>
			<TextField source="ownerId" />
			<ReferenceField source="accAccountTypeId" reference={`${Permissions.walletAccAccountType.feature}`} link="show">
				<TextField source="name" />
			</ReferenceField>
			<BooleanField source="reserved"></BooleanField> 
		</Datagrid>
	</List>
);