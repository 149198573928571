import React from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { SimpleShowLayout, DateField, BooleanField } from 'react-admin';
import { ActivateButton } from '../ops/inheritance/BaseAsideWithUser';

export default (props) => {
    return (
        <Card style={{ width: 300, marginLeft: '1em' }}>
            <CardHeader title="Dettagli" />
            <CardContent>
                <SimpleShowLayout {...props} >
                    <BooleanField label="Attivo" source="active" />
                    <DateField showTime locales="it-IT" label="Creato il" source="created_at" />
                    <DateField showTime locales="it-IT" label="Aggiornato il" source="updated_at" />
                    <ActivateButton/>
                </SimpleShowLayout>
            </CardContent>
        </Card>
    )
};