import React,  { useState } from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, BooleanInput, AutocompleteInput, FormDataConsumer, NumberInput } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { required } from 'react-admin';
import { Permissions } from '../../permissions/permissions';

export const ItemCreate = props => {
	const [unitOfMeasurementName, setUnitOfMeasurementName] = useState();

	return (
		<Create {...props}>
			<SimpleForm redirect="show">
				<TextInput label="Nome Interno" source="name" validate={[required("Il nome è obbligatorio")]} />
				<BooleanInput label="Acquistabile" source="purchasable"></BooleanInput>
				<BooleanInput label="Vendibile" source="saleable"></BooleanInput>
				<BooleanInput label="Immagazzinabile" source="storable"></BooleanInput>
				<BooleanInput label="Edibile" source="edible"></BooleanInput>
				<ReferenceInput
				filterToQuery={searchText => {
					if(!searchText)
						return {};

					return { name: searchText }
				}}
				label="Categoria Interna" source="categoryId" reference={`${Permissions.internalCategory.feature}`} validate={[required("La categoria è obbligatoria")]}>
				<AutocompleteInput
					shouldRenderSuggestions={val => val && val.length >= 2 }
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							}
						}
					}
					optionText="name"
					optionValue="id" />
				</ReferenceInput>
				<ReferenceInput
                    filterToQuery={searchText => {
                        if (!searchText)
                            return {};

                        return {
                            "name": searchText,
                        }
                    }}
                    label="Categoria di previsione"
                    source="forecastCategoryId"
                    reference={Permissions.forecastCategory.feature}
					allowEmpty
					emptyText="Non specificato"
					>
                    <AutocompleteInput
                        optionText="name"
                        optionValue="id" />
                </ReferenceInput>
				<TextInput label="Photo URL" source="photoURL" />
				<TextInput label="Legacy IDs" helperText="(comma separated)" source="legacyIDs" />
				<TextInput label="Barcode" source="barcode" />

				<AutocompleteInput
					label="Nome standard  unità di misura" 
					source="unitOfMeasurementNameList"
					choices={[
						{id: 'KG', name: 'KG'},
						{id: 'GR', name: 'GR'},
						{id: 'L', name: 'L'},
						{id: 'PZ', name: 'PZ'},
						{id: 'altro', name: 'altro'}
					]}
					helperText="selezione altro per specificare un'unità di misura diversa"
					validate={[required('Seleziona un tipo')]}
				/>

				<FormDataConsumer>
					{({ formData, ...rest }) => {

						if(formData.unitOfMeasurementNameList && !unitOfMeasurementName) {
							setUnitOfMeasurementName(formData.unitOfMeasurementNameList)
						}
						else if(formData.unitOfMeasurementNameList && unitOfMeasurementName !== formData.unitOfMeasurementNameList) {
							formData.unitOfMeasurementName = null;
							setUnitOfMeasurementName(formData.unitOfMeasurementNameList)
						}
						else if(formData.unitOfMeasurementNameList && unitOfMeasurementName === formData.unitOfMeasurementNameList && unitOfMeasurementName !== 'altro') {
							formData.unitOfMeasurementName = formData.unitOfMeasurementNameList;
						}

						return (
							<div style={{display: formData.unitOfMeasurementNameList === 'altro' ? 'block' : 'none'}}>
								<TextInput { ...rest} label="Nome unità di misura" source="unitOfMeasurementName" validate={[required('Il nome dell\'unità di misura è obbligatorio')]} />
							</div>
						);
					}}
				</FormDataConsumer>

				<NumberInput label="Arrotondamento unità di misura" source="unitOfMeasurementRounding" validate={[required('La conversione è obbligatoria')]}  helperText="ex. con  un valore di 0.1, 2.16 viene salvato come 2.2, 2.14 viene salvato come 2.1 / con un valore di 6, 11 viene salvato come 12" />
				<TextInput label="Note" source="note" multiline />
				<BaseCreate />
			</SimpleForm>
		</Create>
	);
}