import React, { useState, useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { POST_OFF_RESOURCE } from '../../../../providers/nestjs_crud';
import { AutocompleteInput, useDataProvider, Loading,ReferenceInput, SelectInput, TextInput, DateInput, NumberInput } from 'react-admin';
import { Typography } from '@material-ui/core';

export const AutocompleteInputType = "AutocompleteInput";
export const DateInputType = "DateInput";
export const TextInputType = "TextInput";
export const NumberInputType = "NumberInputType";

const STATUS_FETCH_DATA = "fetch_data";
const STATUS_FETCHING_DATA = "fetching_data";
const STATUS_DATA_FETCHED = "data_fetched";
const STATUS_FETCHING_DATA_ERROR = "fetching_data_error";
const STATUS_REALOAD_CHOICE_DATA = "reaload_choice_data";

export default function InputRest(props) {
	const form = useForm();
	const { values } = useFormState({ subscription: { values: true } });
	const dataProvider = useDataProvider();

	const [status, setStatus] = useState((props.dependOn === undefined || props.dependOn === null) ? STATUS_FETCH_DATA : STATUS_DATA_FETCHED);
	const [choices, setDataChoices] = useState([]);
	const [dependOn, setDependOn] = useState(undefined);
	const [currentValue, setValue] = useState(undefined);
	const [disabled, setDisabled] = useState(false);
	const [label, setLabel] = useState(props.label);

	useEffect(() => {
        if(status === STATUS_REALOAD_CHOICE_DATA) {
			setStatus(STATUS_FETCHING_DATA);
            dataProvider(POST_OFF_RESOURCE, `${props.fieldOptions}/${props.source}`, {
                data: form.getState().values
            })
                .then(({data}) => {
					setDataChoices(data);
					setStatus(STATUS_DATA_FETCHED);
                })
                .catch((e) => {
                    setStatus(STATUS_FETCHING_DATA_ERROR)
                });
        }
	}, [setStatus, dataProvider,form, props.fieldOptions, props.source, status])
	useEffect(() => {
		if(currentValue !== values[props.source]) {
			if(props.type === AutocompleteInputType) {
				const dataCheck = choices.filter(dataSingle => {
					return dataSingle.id === values[props.source]
				});
				if(dataCheck.length === 0) {
					setStatus(STATUS_REALOAD_CHOICE_DATA)
				}
			}
			setValue(values[props.source]);
		}
		
		if(values[props.dependOn] !== dependOn && props.dependOn !== undefined && props.dependOn !== null) {
			if(values[props.dependOn] === undefined || values[props.dependOn] === null) {
				form.change(props.source, undefined);
			}
			else {
				setDependOn(values[props.dependOn]);
				setStatus(STATUS_FETCH_DATA);
			}
		}
		return () => {};
	}, [currentValue, values, props.source, props.dependOn, props.type, dependOn, choices, form])

	useEffect(() => {
		if(status === STATUS_FETCH_DATA) {
			const snapshotBeforeFetchingData_dependOn = values[props.dependOn];
			setStatus(STATUS_FETCHING_DATA);
            dataProvider(POST_OFF_RESOURCE, `${props.fieldOptions}/${props.source}`, {
                data: values
            })
                .then(({data}) => {
					if(
						props.dependOn === undefined || props.dependOn === null || (
							props.dependOn !== undefined && props.dependOn !== null && form.getFieldState(props.dependOn) && snapshotBeforeFetchingData_dependOn === form.getFieldState(props.dependOn).value
						)
					) {
						if(Array.isArray(data) || Array.isArray(data.choices)) {
							let choices = Array.isArray(data) ? data : data.choices;
							setDataChoices(choices);
							choices.forEach((singleData) => {
								if(singleData.selected) {
									form.change(props.source, singleData.id);
								}
							});
						}

						if(data.disabled) {
							setDisabled(true);
						}

						if(data.label) {
							setLabel(data.label)
						}

						if(data.prefill) {
							form.change(props.source, data.initialValue);
						}

						setStatus(STATUS_DATA_FETCHED)
					}
                })
                .catch(() => {
                    setStatus(STATUS_FETCHING_DATA_ERROR)
				});
			
		}
		return () => {};
	}, [status, values, props.dependOn, props.fieldOptions, props.source, dataProvider, form])

    if (status === STATUS_FETCHING_DATA_ERROR) {
        return (<Typography>Errore durante il caricamento {props.source}</Typography>);
    }

    if (status !== STATUS_DATA_FETCHED && status !== STATUS_REALOAD_CHOICE_DATA) {
        return <Loading />;
    }

	switch (props.type) {
		case DateInputType:
			return (
				<DateInput
					fullWidth
					disabled={disabled}
					label={label}
					source={props.source}
					validate={props.validate}
				/>
			);
		case AutocompleteInputType:
			if(disabled && props.reference) {
				return (
					<ReferenceInput
						fullWidth
						label={label}
						source={props.source}
						reference={props.reference}>
						<SelectInput options={{disabled: true}} optionText={props.optionText ? props.optionText: "name"} />
					</ReferenceInput>
				);
			}
			else if(disabled) {
				return (
					<TextInput
						fullWidth
						disabled={disabled}
						label={label}
						source={props.source}
						validate={props.validate}
					/>
				);
			}
	
			return (
				<AutocompleteInput
					fullWidth
					emptyText="Non specificato"
					allowEmpty={props.allowEmpty}
					options={props.options}
					validate={props.validate}	
					source={props.source}
					label={label}
					choices={choices}
				/>
			);
		case NumberInputType:
			return (
				<NumberInput
					fullWidth
					disabled={disabled}
					label={label}
					source={props.source}
					validate={props.validate}
				/>
			);
		case TextInputType:
        default:
			return (
				<TextInput
					fullWidth
					disabled={disabled}
					label={label}
					source={props.source}
					validate={props.validate}
				/>
			);
	}
};