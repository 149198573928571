import React from 'react';
import { Fragment } from 'react';
import { useForm } from 'react-final-form';
import { useDataProvider } from 'react-admin';
import Button from '@material-ui/core/Button';
import TuneIcon from '@material-ui/icons/Tune';
import { FinalQuantity } from './HelperState';

export default ({ formData, ...rest }) => {
    const form = useForm();
    const dataProvider = useDataProvider();

    return (
        <Fragment>
            <Button
                onClick={() => {
                    FinalQuantity(form, dataProvider);
                }}
                variant="contained"
                color="primary"
                size="small"
                endIcon={<TuneIcon/>}
            >
                Calcola
            </Button>
        </Fragment>
    );
};