import React, { useRef, useState } from 'react';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { POST_OFF_RESOURCE } from '../../../providers/nestjs_crud';
import { Permissions, UploadActions } from '../../../permissions/permissions';
import { FILES_TYPE_TO_ACCEPT, FILE_TYPE_TO_EXTENSION} from '../';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';

const STATUS_INITIAL = "INITIAL";
const STATUS_REQUESTING_PRESIGNED_POST = "REQUESTING_PRESIGNED_POST";
const STATUS_UPLOADING_TO_S3 = "UPLOADING_TO_S3";
const STATUS_UPDATING_FILE = "UPDATING_FILE";
const STATUS_ERROR = "ERROR";

export default function Upload({record}) {
	const inputEl = useRef(null);
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const refresh = useRefresh();
	const [status, setStatus] = useState(STATUS_INITIAL);
	
	if(STATUS_ERROR === 'error') {
		return (
			<Button
				onClick={() => {
					setStatus(STATUS_INITIAL)
				}}
				variant="contained"
				color="default"
				startIcon={<ErrorIcon />}>
				RIPROVA
			</Button>
		)
	}

	return <>
		<input
			onChange={(event) => {
				const fileExtension  = FILE_TYPE_TO_EXTENSION[event.target.files[0].type];

				if(!fileExtension) {
					alert("estensione del file non corretta");
					setStatus(STATUS_INITIAL);
					return;
				}

				const fileName = `${record.id}${fileExtension}`;
				const file = event.target.files[0];
				
				setStatus(STATUS_REQUESTING_PRESIGNED_POST)

				dataProvider(POST_OFF_RESOURCE, `${Permissions.files.feature}/${UploadActions.presignLink}/${record.id}`, {
					data: {
						fileName,
						fileType: file.type
					}
				})
				.then(({ data }) => {
					var formData = new FormData();

					Object.keys(data.presignedPost.fields).forEach(key => {
						formData.append(key, data.presignedPost.fields[key]);
					})

					data.headers.forEach((header) => {
						Object.keys(header).forEach(key => {
							formData.append(key, header[key]);
						});		
					})

					formData.append("file", file, fileName);
					
					setStatus(STATUS_UPLOADING_TO_S3);
					
					return fetch(data.presignedPost.url, {
						method: 'POST',
						body: formData
					});
				})
				.then(() => {
					setStatus(STATUS_UPDATING_FILE);
					return dataProvider
						.update(Permissions.files.feature, { id: record.id, data: { ...record, uploaded: true, fileName: fileName, originalName: file.name } })
				})
				.then(() => {
					setStatus(STATUS_INITIAL)
					refresh();
					notify('Caricamento completato', 'success');
				})
				.catch((e) => {
					setStatus(STATUS_ERROR)
					notify('Errore durante il caricamento', 'warning');
				});
			}}
			style={{display: 'none'}}
			ref={inputEl}
			type='file'
			accept={FILES_TYPE_TO_ACCEPT[record.type]}
		/>
		<Button
			disabled={status !== STATUS_INITIAL}
			onClick={() => {
				inputEl.current.click();
			}}
			variant="contained"
			color="default"
			startIcon={status === STATUS_INITIAL ? <CloudUploadIcon /> : null}>
			{(status === STATUS_REQUESTING_PRESIGNED_POST || status === STATUS_UPLOADING_TO_S3 || status === STATUS_UPDATING_FILE) && <CircularProgress style={{width: '28px', height: '28px'}} color="inherit"></CircularProgress>}
			{status === STATUS_INITIAL && 'CARICA'}
		</Button>
	</>
}