import React from "react";
import {
	Edit,
	SimpleForm,
	NumberInput,
	TextInput,
	ReferenceInput,
	required,
	AutocompleteInput,
	DateInput,
} from "react-admin";
import BaseEdit from "../../../../inheritance/BaseEdit";
import { Permissions } from "../../../../../permissions/permissions";
import { RepeatOptionInput } from "../Components/RepeatOptionInput";
import moment from "moment/moment";
import { TimeInput } from "../Components/TimeInput";

export function RecurringMissionEdit(props) {
	return (
		<Edit debounce={1000} {...props}>
			<SimpleForm redirect="show">
				<NumberInput label="Luoghi" source="placeId" disabled />
				<DateInput
					source="startDate"
					label="Data inizio ricorrenza"
					validate={required("Campo obbligatorio")}
					parse={(value) => moment.utc(value).format("YYYY-MM-DD")}
					format={(value) => moment.utc(value).format("YYYY-MM-DD")}
				/>
				<DateInput
					source="endDate"
					label="Data fine ricorrenza"
					parse={(value) =>
						value ? moment.utc(value).format("YYYY-MM-DD") : null
					}
					format={(value) =>
						value ? moment.utc(value).format("YYYY-MM-DD") : null
					}
				/>
				<NumberInput
					label="Stima tempi morti in minuti"
					source="downtimeDuration"
					defaultValue={0}
          			validate={[required("campo obbligatorio")]}
				/>
				<TimeInput
					label="Orario massimo esecuzione"
					source="maxExecutionTime"
				/>
				<RepeatOptionInput />
				<NumberInput
					defaultValue={0}
					validate={[required("campo obbligatorio")]}
					label="Valore missione"
					source="value"
				/>
				<TextInput label="Commento" source="comment" />
				<ReferenceInput
					enableGetChoices={(val) => {
						return !!(
							val &&
							val["mainUser.label"] &&
							val["mainUser.label"].length >= 3
						);
					}}
					filterToQuery={(searchText) => {
						if (!searchText) return {};

						return {
							"mainUser.label": searchText,
						};
					}}
					label="Corriere suggerito"
					source="suggestedDeliveryManId"
					reference={Permissions.deliveryMan.feature}
					allowEmpty
				>
					<AutocompleteInput
						shouldRenderSuggestions={(val) => {
							return val && val.length >= 3;
						}}
						optionText={(record) => {
							if (!record || !record.id) {
								return "cancel";
							}
							return `${
								record
									? `${record.mainUser.id} - ${record.mainUser.label}`
									: ""
							}`;
						}}
						optionValue="id"
					/>
				</ReferenceInput>
				<BaseEdit />
			</SimpleForm>
		</Edit>
	);
}
