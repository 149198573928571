import React from 'react';
import { Datagrid, List, TextField, TextInput, BooleanField, BooleanInput } from 'react-admin';
import Filter from '../inheritance/BaseFilter';
import ListActions from '../../components/ListActions';

const B2CUserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Email" source="email" alwaysOn />
        <TextInput label="Nome" source="name" />
        <TextInput label="firebaseUUID" source="firebaseUUID" />
        <TextInput label="Cognome" source="surname" />
        <TextInput label="Numero di telefono" source="phoneNumber" />
        <BooleanInput label="Abilitati" source="enable" defaultValue={true}></BooleanInput>
        <TextInput source="mainUserId||eq" />
    </Filter>
);

export const B2CUserList = props => (
    <List {...props} actions={<ListActions />} filters={<B2CUserFilter />}>
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <TextField label="Email" source="email" />
            <BooleanField label="Abilitato" source="enable"></BooleanField> 
            <TextField label="firebaseUUID" source="firebaseUUID"  />
            <TextField label="Nome" source="name" />
            <TextField label="Cognome" source="surname" />
            <TextField label="Numero di telefono" source="phoneNumber" />
            <TextField source="mainUserId" />
        </Datagrid>
    </List>
);