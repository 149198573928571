import React from 'react';

import { ShowPreview } from '../../../layouts/ShowPreview';
import { SellingOrderImpulseShow } from '../../transaction/sellingOrderImpulse/SellingOrderImpulseShow';
import { Permissions } from '../../../permissions/permissions';

export default (props) => {
  return (
    <ShowPreview
      data={props.sellingOrderImpulse}
      title={"Dati Vendita Impuso"}
      basePath = {`/${Permissions.sellingOrderImpulse.feature}`}
      resource = {`${Permissions.sellingOrderImpulse.feature}`}
      show={SellingOrderImpulseShow}
    >
    </ShowPreview>
  )
}