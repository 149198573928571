import React, { Fragment } from 'react';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    icon: { minWidth: theme.spacing(5) },
    sidebarIsOpen: {
        paddingLeft: 25,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    sidebarIsClosed: {
        paddingLeft: 0,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
}));

const WarehouseAdminMenu = props => {
    const classes = useStyles();

    return (
        <Fragment>
            <MenuItem dense={props.dense} button onClick={props.handleToggle}>
                <Typography variant="h6" color="textPrimary">
                    {props.name}
                </Typography>
            </MenuItem>
            <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
                <List
                    dense={props.dense}
                    component="div"
                    disablePadding
                    className={
                        props.sidebarIsOpen
                            ? classes.sidebarIsOpen
                            : classes.sidebarIsClosed
                    }
                >
                    {props.children}
                </List>
                <Divider />
            </Collapse>
        </Fragment>
    )
    
}

export default WarehouseAdminMenu;