import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDataProvider, Loading, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { POST_OFF_RESOURCE } from '../../../../providers/nestjs_crud';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(8, 0, 0),
    },
}));

export default function InventoryCompleteWarehouseAccAccount(props) {

    let { id } = useParams();
    const dataProvider = useDataProvider();
    const [state, setState] = useState('AWAIT_CONFIRMATION');
    const classes = useStyles();
    let history = useHistory();
    const notify = useNotify();
    const [open, setOpen] = useState(false);
    const [inventoryId, setInventoryId ] = useState(null);
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    useEffect(() => {
        if (state === 'CREATE_INVENTORY' && id) {
            setState('CREATING_INVENTORY');
            dataProvider(POST_OFF_RESOURCE, `warehouseInventory/init-from-location/${id}`, {
                data: JSON.parse(decodeURIComponent(params.get('json_data')))
            })
            .then(({ data }) => {
                if(data.success) {
                    history.replace(`/inventory/${data.data.warehouseInventoryId}`);
                } else if(data.data && data.data.code === '20141_pending_inventory') {
                    setInventoryId(data.data.inventoryId);
                    setOpen(true);
                } else if(data.errors.find(error => {
                    return error.constraints.arrayNotEmpty;
                })) {
                    setState('ERROR_CREATING_INVENTORY_ERROR_01');
                } else {
                    throw new Error();
                }
            })
            .catch(error => {
                if(error.code === '20141_pending_inventory') {
                    setInventoryId(error.inventoryId);
                    setOpen(true);
                }
                setState('ERROR_CREATING_INVENTORY');
                notify("Errore durante la creazione dell'inventario", 'warning');
            })
        }
    }, [dataProvider, state, id, history, notify, params, props]);

    const handleClose = () => {
        history.goBack()
    };

    return (
        <>
        <Dialog fullScreen open={true} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar  className={classes.appBar} >
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title} >
                        Inventorio Completo
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container className={classes.container} maxWidth="md" disableGutters>
        
            {state === 'CREATING_INVENTORY' && <Loading loadingPrimary="Creazione Inventario in corso" loadingSecondary=""/>}
            {state === 'AWAIT_CONFIRMATION' && 
            <>
                <Typography variant="h6">
                    Prcedere con inventario completo ?
                </Typography>
                <Button style={{marginTop: '8px'}} size='large' variant="outlined" color="primary" onClick={() => { setState('CREATE_INVENTORY'); }} startIcon={<ErrorOutlineIcon/>}>PROCEDI</Button>
                <Button style={{marginTop: '8px'}} size='small' variant="contained" color="default" onClick={handleClose}>Annulla</Button>
            </>}
            {['ERROR_CREATING_INVENTORY', 'ERROR_CREATING_INVENTORY_ERROR_01'].includes(state) &&  <>
                <Typography variant="h6">
                    Errore durante la creazione
                </Typography>
                {state === 'ERROR_CREATING_INVENTORY_ERROR_01' && <Typography>
                    In base alle regole di cycleCounting non ci sono prodotti da inventariare.
                </Typography>}
                <Button style={{marginTop: '8px'}} size='large' variant="outlined" color="primary" onClick={() => { setState('CREATE_INVENTORY'); }} startIcon={<ErrorOutlineIcon/>}>RIPROVA</Button>
                <Button style={{marginTop: '8px'}} size='small' variant="contained" color="default" onClick={handleClose}>Annulla</Button>
            </>}
            </Container>
        </Dialog>
        <Dialog
        fullScreen={false}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Per questa location è gia presente un inventario?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annulla
          </Button>
          <Button onClick={() => {
            inventoryId && history.replace(`/inventory/${inventoryId}`);
          }} color="primary">
            Continua Inventario
          </Button>
        </DialogActions>
      </Dialog>
        </>
    );
}

