import React, { useEffect, useState } from 'react';
import { Toolbar, SaveButton, DeleteButton, Edit, SimpleForm, TextInput, required, NumberInput, useDataProvider, Loading } from 'react-admin';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { getItemDefaultUoMFrom } from './UnitOfMeasurementUtils';
import BaseEdit from '../inheritance/BaseEdit';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const CustomToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
        {props.data && !props.data.default && <DeleteButton redirect={props.redirect} />}
    </Toolbar>
);

function UnitOfMeasurementEditComponent(props) {
    const [status, setStatus ] = useState('FETCH_UoM');
    const [UoMDefault, setUoMDefault ] = useState();
    const dataProvider = useDataProvider();
  
    useEffect(() => {
      if(status === 'FETCH_UoM' && props.itemId) {
        setStatus('FETCHING_UoM')
        getItemDefaultUoMFrom(dataProvider, props.itemId)
        .then(({ data }) => {
          setUoMDefault(data[0])
          setStatus('UoM_DEFAULT_READY')
        })
        .catch(() => {
          setStatus('ERROR_FETCHING_UoM')
        });
      }
    }, [status, props.itemId, dataProvider])
  
    if(status === 'ERROR_FETCHING_UoM') {
      return <SnackbarContent style={{ backgroundColor: '#f44336'}} message="Errore durante la ricerca della UoM di default" />;
    }
  
    if (status === 'UoM_DEFAULT_READY') {
    return (
        <Edit {...props}>
            <SimpleForm redirect={() => (`/item/${props.itemId}/show`)} toolbar={<CustomToolbar data={props.data} redirect={() => (`/item/${props.itemId}/show`)}/>}>
                <TextInput
                    fullWidth
                    label="Nome"
                    source="name"
                    validate={[required('Il nome è obbligatorio')]} />
                {props.data && !props.data.default && <NumberInput
                    fullWidth
                    label="Conversione in unità di riferimento" helperText={`Quanti ${UoMDefault ? UoMDefault.name : '\'#nome unita di misura di referenza\''} contiene questa unità?`}
                    source="conversionValue"
                    validate={[required('La conversione è obbligatoria')]} />}
                <NumberInput
                    fullWidth
                    label="Arrotondamento in questa unità" helperText="ex. con  un valore di 0.1, 2.16 viene salvato come 2.2, 2.14 viene salvato come 2.1 / con un valore di 6, 11 viene salvato come 12"
                    source="rounding"
                    validate={[required('L\'arrotondamento è obbligatorio')]} />
                <BaseEdit />
            </SimpleForm>
        </Edit>
    );
    }
    return <Loading />;
};

export const UnitOfMeasurementEdit = connect((state, props) => {
    return {
        itemId: state.admin.resources.unitOfMeasurement && state.admin.resources.unitOfMeasurement.data[props.id] ? state.admin.resources.unitOfMeasurement.data[props.id].itemId : null,
        data: state.admin.resources.unitOfMeasurement && state.admin.resources.unitOfMeasurement.data[props.id] ? state.admin.resources.unitOfMeasurement.data[props.id] : null
    }
})(UnitOfMeasurementEditComponent)