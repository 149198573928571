import React, { useRef } from 'react';
import { Datagrid, List, TextField, AutocompleteInput, TextInput, EditButton, ShowButton, BooleanInput } from 'react-admin';
import Filter from '../inheritance/BaseFilter';
import ListActions from '../../components/ListActions';
import { FILES_TYPE } from './';
import UploadComponent  from './components/Upload';
import { Button } from '@material-ui/core';
import { useNotify } from 'ra-core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Avatar from '@material-ui/core/Avatar';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { BulkDeleteButton } from 'react-admin';

export function Upload({ record }) {
	const notify = useNotify();
	const textArea = useRef(null);

	if(!record.uploaded) {
		return  (
			<UploadComponent record={record}/>
		);
	}

	return (
		<>
		<div style={{
			position: "absolute",
			top: "-9999px",
			left: "-9999px"
		}}>
		<textarea
			ref={textArea}
			type="text"
		/>
		</div>
		<Button
			startIcon={<FileCopyIcon />}
			onClick={() => {
				const value = `${process.env.REACT_APP_FILES_CDN}/${record.fileName}`;
				textArea.current.value = value;
				textArea.current.select();

				try {
					const success = document.execCommand("copy")
					if(success) {
						notify(value);
					}
				} catch (err) {}
			}}
		>
			Copy	
		</Button>
		</>
	);
}

export function Preview({ record }) {
	if(record.type === 'image' && record.fileName) {
		return <Avatar alt={record.alt} src={`${process.env.REACT_APP_FILES_CDN}/${record.fileName}`} variant="square" />;
	}

	return (
		<Avatar alt={record.alt} variant="square">
			<AttachFileIcon />
		</Avatar>
	)
}

const FileFilter = (props) => (
	<Filter {...props}>
		<TextInput label="Titolo" source="title"  alwaysOn />
		<TextInput label="Alt" source="alt" />
		<TextInput label="Original Name" source="originalName"></TextInput>
		<AutocompleteInput label="Tipologia" source="type" choices={FILES_TYPE.map(type => {
				return {
					id: type,
					name: type
				}
		})} />
		<BooleanInput label="Caricato" source="uploaded"></BooleanInput>
	</Filter>
);


function NonUndoableBulkActionButtons(props) {
    return (
        <React.Fragment>
        {props.buttons}
        <BulkDeleteButton {...props} undoable={false} />
        </React.Fragment>
    );
}

export const FileList = props => (
	<List {...props}
		bulkActionButtons={<NonUndoableBulkActionButtons buttons={props.bulkActionButtons} />}
		sort={{ field: 'created_at', order: 'DESC' }} actions={<ListActions />}  filters={<FileFilter />}>
		<Datagrid rowClick={null}>
			<Preview />
			<TextField label="Original Name" source="originalName"></TextField>
			<TextField label="Titolo" source="title"></TextField>
			<TextField label="Alt" source="alt"></TextField>
			<TextField label="Tipologia" source="type"></TextField>
			<Upload></Upload>
			<EditButton></EditButton>
			<ShowButton></ShowButton>
		</Datagrid>
	</List>
);