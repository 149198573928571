import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import Aside from '../inheritance/BaseAside';

export const DeliveryManSkillShow = (props) => {
    return (
        <Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
            <SimpleShowLayout>
                <TextField label="Name" source="name" />
                <TextField label="Codice tool planning" source="toolPlanningCode"  />
                <TextField label="Tech Key" source="techKey"  />
            </SimpleShowLayout>
        </Show>
    )
};

