export const SELLING_POINT_TYPE_IMPULSE = 'Impulso';
export const SELLING_POINT_TYPE_CORPORATE = 'Corporate';
export const SELLING_POINT_TYPE_DELIVERY = 'Delivery';
export const SELLING_POINT_TYPE_FRESHWALL = 'Freshwall';
export const SELLING_POINT_TYPE_CATERING = 'Catering';
export const SELLING_POINT_TYPE_COFFEE = 'Coffee';
export const SELLING_POINT_TYPE_WATER = 'Water';

export default [ 
    SELLING_POINT_TYPE_IMPULSE, 
    SELLING_POINT_TYPE_CORPORATE, 
    SELLING_POINT_TYPE_DELIVERY, 
    SELLING_POINT_TYPE_FRESHWALL, 
    SELLING_POINT_TYPE_CATERING, 
    SELLING_POINT_TYPE_COFFEE,
    SELLING_POINT_TYPE_WATER
];

export const TYPOLOGIES_NO_IMPULSE = [
    SELLING_POINT_TYPE_CORPORATE,
    SELLING_POINT_TYPE_DELIVERY,
    SELLING_POINT_TYPE_FRESHWALL,
    SELLING_POINT_TYPE_CATERING,
    SELLING_POINT_TYPE_COFFEE,
]

export const SELLING_ORDER_TYPOLOGY_B2C_LOCKER = 'B2CLocker';

export const ORDER_TYPOLOGIES = [
    SELLING_POINT_TYPE_IMPULSE,
    SELLING_POINT_TYPE_CORPORATE,
    SELLING_POINT_TYPE_DELIVERY,
    SELLING_POINT_TYPE_FRESHWALL,
    SELLING_POINT_TYPE_CATERING,
    SELLING_ORDER_TYPOLOGY_B2C_LOCKER,
    SELLING_POINT_TYPE_COFFEE
]