import React from 'react';
import { Show, SimpleShowLayout, TextField, FunctionField, NumberField } from 'react-admin';
import { Container, Grid, makeStyles } from '@material-ui/core';

import Aside from '../../inheritance/BaseAside';
import SupplierPriceListListPreview from '../supplierPriceList/SupplierPriceListListPreview';
import { Permissions } from '../../../permissions/permissions';
import MainUser from '../../../components/MainUserField';
import { CustomArrayField } from '../../../components/CustomArrayField';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(1)
	},
	title: {
		flexGrow: 1,
	},
	rootSaleable: {
		flexGrow: 1,
	}
}));

export const SupplierShow = (props) => {
	const classes = useStyles();

	return (
	<Container maxWidth="xl" className={classes.root}>
		<Grid container spacing={3}>
			<Grid item xl={12} lg={12} md={12} xs={12}>
				<Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
					<SimpleShowLayout>
						<TextField label="Nome" source="name"></TextField>
						<TextField label="Ragione sociale" source="businessName"></TextField>
						<TextField label="Partita IVA" source="vatNumber"></TextField>
						<TextField label="Metodo di pagamento" source="paymentMethod"></TextField>
						<TextField label="Email di invio Ordini Acquisto" source="email"></TextField>
						<TextField label="Telefono" source="telephone"></TextField>
						<TextField label="Cellulare" source="phone"></TextField>
						<TextField label="Città" source="city"></TextField>
						<CustomArrayField label="Lista Luoghi consegna serviti" source="places" subLabel="name" reference={Permissions.place.feature} />
						<NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Minimo d’Ordine (€) per consegna gratuita" source="minimumOrderForFreeDelivery"></NumberField>
						<NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }}label="Costo consegna €" source="deliveryCost"></NumberField>
						<NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Minimo d’Ordine (€) assoluto" source="minimumOrder"></NumberField>
						<NumberField label="Tempo di consegna in giorni" source="deliveryDays"></NumberField>
						<FunctionField label="Giorni di consegna" source="deliveryWeekDays" render={record => {
							return <ul>
								{record.deliveryWeekDays ? record.deliveryWeekDays.map(
									day => {
										return (<li>{{
											'monday': 'Lunedì',
											'tuesday': 'Martedí',
											'wednesday': 'Mercoledí',
											'thursday': 'Giovedì',
											'friday': 'Venerdì',
											'saturday': 'Sabato',
											'sunday': 'Domenica'
										}[day]}</li>);
									}
								) : '-'}
							</ul>
						}} />
						<TextField label="Note" source="note"></TextField>
						<MainUser title="Anagrafica Fornitore" />
					</SimpleShowLayout>
				</Show>
			</Grid>
		</Grid>
		<Grid container spacing={3}>
			<Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
				<SupplierPriceListListPreview supplierId={props.id}></SupplierPriceListListPreview>
			</Grid>
		</Grid>
	</Container>
)};
