import React from "react";
import { Box, Tooltip } from '@material-ui/core';
import DirectionsIcon from '@material-ui/icons/Directions';


export function RoutingErrorField(props) {
	if (!props.record?.routingError) {
		return null;
	}

    return (
        <Box display="flex" justifyContent="center">
            <Tooltip title={<span style={{fontSize:"0.9rem"}}>{props.record.routingError}</span>} >
                <DirectionsIcon color="error"/>
            </Tooltip>
        </Box>
    )
}