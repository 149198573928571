import React from 'react';
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';
import BaseEdit from '../inheritance/BaseEdit';
import { required } from 'react-admin';
import { SELLING_POINT_TYPE_IMPULSE } from '../../utils/enum/Typology';

export const SellingOrderEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="Nome" source="name" />
            <ReferenceInput filter={{ "typology||ne": SELLING_POINT_TYPE_IMPULSE }} label="Punto di Vendita" source="sellingPointId" reference="sellingPoint" validate={[required("Il Punto di Vendita è obbligatorio")]} >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <BaseEdit />
        </SimpleForm>
    </Edit>
);