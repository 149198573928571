import React , { useState, useEffect } from 'react';
import {useDataProvider, useRefresh, showNotification } from 'react-admin';
import { Button, Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { GET_OFF_RESOURCE } from '../providers/nestjs_crud';

const useStyles = makeStyles((theme) => ({
    contatiner: {
        '& > *': {
            marginRight: theme.spacing(1),
        },
    }
}));

export const StateMachineFieldWithActions = (props) => {
    const refresh = useRefresh();
    const dispatch = useDispatch()
    const dataProvider = useDataProvider();
    const [dataState, setDataState] = useState([]);
    const [loading, setLoading] = useState(true);
    const classes = useStyles();

    const id = props.record ? props.record[props.source] : null;


    useEffect(() => {
        if(id) {
            dataProvider(GET_OFF_RESOURCE, `${props.reference}/${id}/transitions`)
            .then(({ data }) => {
                setDataState(data.actions);
                setLoading(false);
            })
            .catch(error => {
                dispatch(showNotification("error", 'error'));
                setLoading(false);
            })
        }
        
    }, [dataProvider, setDataState, dispatch, props.reference, id]);

    
    if (loading) {
        return (
            <Box >
                <CircularProgress size={25} />
            </Box>
        );
    }

    if(!id) return null;

    return (
        <div className={classes.contatiner}>
        <span>{props.label}</span>
        {
            dataState && dataState.map(state => 
                <Button onClick={() => {
                    setLoading(true);
                    dataProvider(GET_OFF_RESOURCE, `${props.reference}/${id}/transition/${state}`)
                        .then(({ data }) => {
                            dispatch(showNotification(`transazione: ${state} completata`));
                            refresh();
                            return dataProvider(GET_OFF_RESOURCE, `${props.reference}/${id}/transitions`)
                                .then(({ data }) => {
                                    setDataState(data.actions);
                                    setLoading(false);
                                })
                        })
                        .catch(error => {
                            dispatch(showNotification(typeof error === 'string' ? error : JSON.stringify(error), 'warning'));
                            setLoading(false);
                        })
                }} key={state} variant="contained" color="primary" component="span">
                    {state}
                </Button>)
        }
        {
            dataState && dataState.length === 0 && <span>-</span>
        }
        </div>
    );
}

