import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { SimpleShowLayout, DateField, BooleanField, useDataProvider, Error, useRefresh, usePermissions, useNotify } from 'react-admin';
import { Permissions } from "../../../permissions/permissions";
import { Typography, Box, Button, CircularProgress } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { PATCH_OFF_RESOURCE } from '../../../providers/nestjs_crud';
export default (props) => {
    return (
        <Card style={{ width: 300, marginLeft: '1em' }}>
            <CardHeader title="Dettagli" />
            <CardContent>
                <SimpleShowLayout {...props} >
                    <BooleanField label="Attivo" source="active" />
                    <Author label="Creato da" type='creator' />
                    <DateField showTime locales="it-IT" label="Creato il" source="created_at" />
                    <Author label="Aggiornato da" type='editor' />
                    <DateField showTime locales="it-IT"  label="Aggiornato il" source="updated_at" />
                    <ActivateButton/>
                </SimpleShowLayout>
            </CardContent>
        </Card>
    )
};

export function Author(props) {
    const dataProvider = useDataProvider();
    const [state, setState] = useState('INITIAL')
    const [author, setAuthor] = useState();
    const history = useHistory();
    useEffect(() => {
        let isMounted = true;
    
        const fetchData = async () => {
            try {
                const response = await dataProvider.getOne(
                    Permissions.mainUser.feature,
                    { id: props.record[`${props.type}_id`] }
                );
    
                if (isMounted) {
                    setAuthor(response.data);
                    setState('DATA_FETCHED');
                }
            } catch (error) {
                if (isMounted) {
                    setState('ERROR');
                }
            }
        };
    
        if (props.record && props.record[`${props.type}_id`]) {
            if (props.record[`${props.type}_type`] === 'main-user') {
                setState('FETCHING_DATA');
                fetchData();
            } else {
                setState('DATA_FETCHED');
            }
        }
    
        return () => {
            isMounted = false;
        };
    
    }, [props.record, props.type, dataProvider]);
    

    if (state === 'FETCHING_DATA' || state === 'INITIAL' ) {
        return (
            <Box textAlign='center'>
                <CircularProgress size={25} />
            </Box>
        )
    } 

    if (state === 'ERROR') return <Error />;
    
    if(state === 'DATA_FETCHED') {
        return (
            <Box display="flex" flexDirection="column" alignItems="flex-start">
               {
                <Typography variant="caption">
                    {props.label}
                </Typography>
               } 
                {author ?
                    <Button
                    size='small'
                    color="primary"
                    onClick={() => {
                        history.push(`/${Permissions.mainUser.feature}/${author.id}/show`);
                    }}
                    >{author.label}</Button> : 
                    <Button
                        size='small'
                        color="primary"
                        disabled={true}
                    > {props.record[`${props.type}_id`]} </Button>
                }
            </Box>
        );
    }

    return null;
    
}

export function ActivateButton(props) {
	const dataProvider = useDataProvider();
	const refresh = useRefresh();
    const [status, setStatus] = useState('INITIAL');
	const { permissions } = usePermissions();
    const notify = useNotify();

    useEffect(() => {
      if(status === 'SEND_REQUEST') {
          setStatus('SENDING_REQUEST');
          dataProvider(
			PATCH_OFF_RESOURCE,
			Permissions[props.resource].feature +
				"/" +
				props.record.id +
				"/activate/",
			{}
		)
			.then((data) => {
				refresh();
			})
			.catch((error) => {
				console.log(error);
                setStatus('INITIAL');
                notify("Errore nell'attivazione", 'warning');
                
			});
      }
    }, [dataProvider, notify, props, refresh, status])
    

	if (
		!props.record ||
		props.record.active ||
		!permissions ||
		!permissions.includes(
			`${Permissions[props.resource].feature}-${
				Permissions[props.resource].actions.ActivateOne
			}`
		)
	) {
		return null;
	}

	return (
		<Button
			size="medium"
			style={{ marginTop: "12px" }}
			onClick={() => setStatus('SEND_REQUEST')}
			variant="contained"
			color="primary"
            disabled={status !== 'INITIAL'}
		>
			Attiva
		</Button>
	);
}
