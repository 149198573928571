import { Permissions as OpsPermissions } from "../resources/ops/permissions"

export const CrudActions = {
    ReadAll: "Read-All",
    ReadOne: "Read-One",
    CreateOne: "Create-One",
    CreateMany: "Create-Many",
    UpdateOne: "Update-One",
    ReplaceOne: "Replace-One",
    DeleteOne: "Delete-One",
    Export: "Export",
    ActivateOne: 'Activate-One',
}

const StateMachineActions = {
    get: "get",
    getTransitions: "getTransitions",
    doTransition: "doTransition"
}

const ConvertActions = {
    doConversion: "doConversion"
}
const LockActions = {
    open: "open",
    forceShadowUpdate: 'force-shadow-update',
    forceShadowReport: 'force-shadow-report',
    bulkUpdateVersion: 'bulk-update-version'
}
const WalletAccAccountTypeConfig = {
    config: "config"
}
const TimeSlotRuleActions = {
    generateTimeSlots: 'generateTimeSlots',
}

export const UploadActions = {
    presignLink: "presignLink"
}

export const TransferActions = {
    fieldOptions: "fieldOptions"
}

export const WalletManualTransferActions = {
    fieldOptions: 'fieldOptions',
}

export const B2CUserWalletActions = {
    balance: 'balance',
}

export const CondionActions = {
    fieldOptions: 'fieldOptions',
}

export const PromotionActions = {
    publish: 'publish',
}

const DangerousActions = {
    enableDangerous: 'enableDangerous',
    heavyReadAll: 'heavyReadAll',
}

const BulkActions = {
    BulkComplete: 'BulkComplete',
    BulkCommit: 'BulkCommit',
    BulkCancel: 'BulkCancel',
}

export const MissionRiderActions = {
    startUnload: 'startUnload',
    continueUnload: 'continueUnload',
    completeUnload: 'completeUnload',
    completeLoad: 'completeLoad',
    deliverUnload: 'deliverUnload',
}

export const CorporateCreditActions = {
    publish: 'publish',
}

export const CorporateCreditCondionActions = {
    fieldOptions: 'fieldOptions',
}

export const CorporateCreditHistoryActions = {
    Cancel: 'Cancel',
}

export const Permissions = {
    user: {
        feature: "user",
        actions: { ...CrudActions }
    },
    role: {
        feature: "role",
        actions: { ...CrudActions }
    },
    auth: {
        feature: "auth",
        actions: {
            listPermissions: "listPermissions"
        }
    },
    tax: {
        feature: "tax",
        actions: { ...CrudActions }
    },
    sellingPoint: {
        feature: "sellingPoint",
        actions: { ...CrudActions }
    },
    sellingPointImpulse: {
        feature: "sellingPointImpulse",
        actions: { ...CrudActions }
    },
    clientCategory: {
        feature: "clientCategory",
        actions: { ...CrudActions }
    },
    internalCategory: {
        feature: "internalCategory",
        actions: { ...CrudActions }
    },
    priceList: {
        feature: "priceList",
        actions: { ...CrudActions }
    },
    itemSaleable: {
        feature: "itemSaleable",
        actions: { ...CrudActions }
    },
    item: {
        feature: "item",
        actions: { ...CrudActions }
    },
    place: {
        feature: "place",
        actions: { ...CrudActions }
    },
    unitOfMeasurement: {
        feature: "unitOfMeasurement",
        subFeatures: {
            convert: {
                name: "convert",
                actions: { ...ConvertActions }
            }
        },
        actions: { ...CrudActions }
    },
    itemSaleablePriceList: {
        feature: "itemSaleablePriceList",
        actions: { ...CrudActions }
    },
    impulse: {
        feature: "impulse",
        actions: {
            listItems: "listItems",
            createOrder: "createOrder",
            updateOrder: "updateOrder"
        }
    },
    sellingOrder: {
        feature: "sellingOrder",
        subFeatures: {
            orderStateMachine: {
                name: "orderStateMachine",
                actions: { ...StateMachineActions }
            }
        },
        actions: { ...CrudActions }
    },
    sellingOrderRow: {
        feature: "sellingOrderRow",
        actions: { ...CrudActions }
    },
    sellingOrderImpulse: {
        feature: "sellingOrderImpulse",
        subFeatures: {
            orderStateMachine: {
                name: "orderStateMachine",
                actions: { ...StateMachineActions }
            }
        },
        actions: {
            ...CrudActions,
        },
    },
    itemStorable: {
        feature: "itemStorable",
        actions: { ...CrudActions }
    },
    itemPurchasable: {
        feature: "itemPurchasable",
        actions: { ...CrudActions }
    },
    itemEdible: {
        feature: "itemEdible",
        actions: { ...CrudActions }
    },
    supplier: {
        feature: "supplier",
        actions: { ...CrudActions }
    },
    supplierPriceList: {
        feature: "supplierPriceList",
        actions: { ...CrudActions }
    },
    stateMachineHistory: {
        feature: "stateMachineHistory",
        actions: {
            ReadAll: CrudActions.ReadAll,
            ReadOne: CrudActions.ReadOne,
            Export: CrudActions.Export,
        }
    },
    purchasePoint: {
        feature: "purchasePoint",
        actions: { ...CrudActions }
    },
    purchaseOrder: {
        feature: "purchaseOrder",
        subFeatures: {
            orderStateMachine: {
                name: "orderStateMachine",
                actions: { ...StateMachineActions }
            }
        },
        actions: { ...CrudActions }
    },
    purchaseOrderRow: {
        feature: "purchaseOrderRow",
        subFeatures: {
            convert: {
                name: "convert",
                actions: { ...ConvertActions }
            }
        },
        actions: { ...CrudActions }
    },
    lock: {
        feature: "lock",
        actions: {
            ...CrudActions,
            ...LockActions,
        }
    },
    code: {
        feature: "code",
        actions: { ...CrudActions }
    },
    locker: {
        feature: "locker",
        actions: { ...CrudActions }
    },
    B2CUser: {
        feature: "B2CUser",
        subFeatures: {
            wallet: {
                name: 'wallet',
                actions: { ...B2CUserWalletActions },
            },
        },
        actions: { ...CrudActions }
    },
    lockerSellingOrder: {
        feature: "lockerSellingOrder",
        subFeatures: {
            orderStateMachine: {
                name: "orderStateMachine",
                actions: { ...StateMachineActions }
            }
        },
        actions: { ...CrudActions }
    },
    payment: {
        feature: "payment",
        subFeatures: {
            paymentStateMachine: {
                name: "paymentStateMachine",
                actions: { ...StateMachineActions }
            }
        },
        actions: { ...CrudActions }
    },
    paymentAttempt: {
        feature: "paymentAttempt",
        subFeatures: {
            paymentAttemptStateMachine: {
                name: "paymentAttemptStateMachine",
                actions: { ...StateMachineActions }
            }
        },
        actions: { ...CrudActions }
    },
    lot: {
        feature: "lot",
        actions: { ...CrudActions }
    },
    warehouseLocation: {
        feature: "warehouseLocation",
        actions: { ...CrudActions }
    },
    transferPicking: {
        feature: "transferPicking",
        actions: {
            ReadAll: "Read-All",
            Start: 'Start',
            Continue: 'Continue',
            BackToFinish: 'Back-To-Finish',
            Delivery: 'Delivery',
            Complete: 'Complete',
            Sort: 'Sort',
            Revert: 'Revert',
        }
    },
    transfer: {
        feature: "transfer",
        subFeatures: {
            form: {
                name: "form",
                actions: { ...TransferActions }
            },
            transferStateMachine: {
                name: "transferStateMachine",
                actions: { ...StateMachineActions }
            },
            requestedItemTransfer: {
                name: "requestedItemTransfer",
                actions: { ...CrudActions }
            },
            physicalItemTransfer: {
                name: "physicalItemTransfer",
                actions: {
                    ...CrudActions,
                    heavyReadAll: DangerousActions.heavyReadAll,
                    BulkCommit: BulkActions.BulkCommit,
                    BulkCancel: BulkActions.BulkCancel,
                }
            }
        },
        actions: {
            ...CrudActions,
            move2: 'move2',
        }
    },
    requestedItemTransfer: {
        feature: "requestedItemTransfer",
        actions: { ...CrudActions }
    },
    physicalItemTransfer: {
        feature: "physicalItemTransfer",
        actions: {
            ...CrudActions, heavyReadAll: DangerousActions.heavyReadAll
        }
    },
    domains: {
        feature: "domains",
        actions: { ...CrudActions }
    },
    corporateCustomers: {
        feature: "corporateCustomers",
        actions: { ...CrudActions }
    },
    files: {
        feature: "files",
        subFeatures: {
            upload: {
                name: "upload",
                actions: { ...UploadActions }
            }
        },
        actions: { ...CrudActions }
    },
    accrWarehouseItems: {
        feature: "accrWarehouseItems",
        actions: { ...CrudActions }
    },
    walletAccAccount: {
        feature: 'walletAccAccount',
        actions: { ...CrudActions },
    },
    walletAccAccountType: {
        feature: 'walletAccAccountType',
        subFeatures: {
            config: {
                name: 'config',
                actions: { ...WalletAccAccountTypeConfig },
            },
        },
        actions: { ...CrudActions }
    },
    walletTransfer: {
        feature: 'walletTransfer',
        actions: {
            ...CrudActions,
            heavyReadAll: DangerousActions.heavyReadAll,
            BulkComplete: BulkActions.BulkComplete,
            BulkCancel: BulkActions.BulkCancel
        },
    },
    walletManualTransfer: {
        feature: 'walletManualTransfer',
        subFeatures: {
            form: {
                name: 'form',
                actions: { ...WalletManualTransferActions },
            },
        },
        actions: { ...CrudActions },
    },
    gateway: {
        feature: 'gateway',
        actions: { ...CrudActions },
    },
    walletRecharge: {
        feature: 'walletRecharge',
        subFeatures: {
            walletRechargeStateMachine: {
                name: "walletRechargeStateMachine",
                actions: { ...StateMachineActions }
            }
        },
        actions: {
            ReadOne: CrudActions.ReadOne,
            ReadAll: CrudActions.ReadAll,
            Export: CrudActions.Export,
        },
    },
    walletRechargeConfig: {
        feature: 'walletRechargeConfig',
        actions: { ...CrudActions },
    },
    promotion: {
        feature: 'promotion',
        subFeatures: {
            ruleEngine: {
                name: 'ruleEngine',
                actions: { ...PromotionActions },
            },
        },
        actions: { ...CrudActions, enableDangerous: DangerousActions.enableDangerous },
    },
    condition: {
        feature: 'condition',
        subFeatures: {
            form: {
                name: 'form',
                actions: { ...CondionActions },
            },
        },
        actions: { ...CrudActions },
    },
    warehouseAccAccount: {
        feature: 'warehouseAccAccount',
        actions: {
            ReadAll: CrudActions.ReadAll,
            ReadOne: CrudActions.ReadOne,
            Export: CrudActions.Export,
        },
    },
    warehouseInventory: {
        feature: 'warehouseInventory',
        subFeatures: {
            warehouseInventoryStateMachine: {
                name: "warehouseInventoryStateMachine",
                actions: { ...StateMachineActions }
            }
        },
        actions: {
            Init: 'Init',
            Terminate: 'Terminate',
            SubmitItem: 'SubmitItem',
            RefreshItem: 'RefreshItem',
            ReadOne: CrudActions.ReadOne,
            ReadAll: CrudActions.ReadAll,
            Export: CrudActions.Export,
        },
    },
    warehouseInventoryItem: {
        feature: 'warehouseInventoryItem',
        actions: {
            ReadAll: CrudActions.ReadAll,
            Export: CrudActions.Export,
        },
    },
    hub: {
        feature: 'hub',
        actions: { ...CrudActions },
    },
    mission: {
        feature: 'mission',
        subFeatures: {
            rider: {
                name: "rider",
                actions: {
                    ReadOne: CrudActions.ReadOne,
                    StartUnload: MissionRiderActions.startUnload,
                    ContinueUnload: MissionRiderActions.continueUnload,
                    CompleteUnload: MissionRiderActions.completeUnload,
                    CompleteLoad: MissionRiderActions.completeLoad,
                    DeliverUnload: MissionRiderActions.deliverUnload,
                },
            }
        },
        actions: { ...CrudActions },
    },
    corporateCredit: {
        feature: 'corporateCredit',
        subFeatures: {
            ruleEngine: {
                name: 'ruleEngine',
                actions: { ...CorporateCreditActions },
            },
        },
        actions: { ...CrudActions, enableDangerous: DangerousActions.enableDangerous },
    },
    corporateCreditCondition: {
        feature: 'corporateCreditCondition',
        subFeatures: {
            form: {
                name: 'form',
                actions: { ...CorporateCreditCondionActions },
            },
        },
        actions: { ...CrudActions },
    },
    corporateCreditHistory: {
        feature: 'corporateCreditHistory',
        actions: {
            ReadAll: CrudActions.ReadAll,
            Cancel: CorporateCreditHistoryActions.Cancel,
            Export: CrudActions.Export,
        },
    },
    coffeeRecipe: {
        feature: 'coffeeRecipe',
        actions: { ...CrudActions },
    },
    coffeeMachine: {
        feature: 'coffeeMachine',
        actions: {
            ...CrudActions,
            dispense: 'dispense',
        },
    },
    coffeeType: {
        feature: 'coffeeType',
        actions: { ...CrudActions },
    },
    coffeeMachineCimbaliS: {
        feature: 'coffeeMachineCimbaliS',
        actions: { ...CrudActions },
    },
    coffeeRecipeList: {
        feature: 'coffeeRecipeList',
        actions: { ...CrudActions },
    },
    coffeeSellingPoint: {
        feature: 'coffeeSellingPoint',
        actions: { ...CrudActions },
    },
    coffeeSellingOrder: {
        feature: 'coffeeSellingOrder',
        subFeatures: {
            orderStateMachine: {
                name: 'orderStateMachine',
                actions: { ...StateMachineActions },
            },
        },
        actions: {
            ReadAll: CrudActions.ReadAll,
            ReadOne: CrudActions.ReadOne,
            Export: CrudActions.Export,
        },
    },
    coffeeRecipeListItem: {
        feature: 'coffeeRecipeListItem',
        actions: { ...CrudActions },
    },
    businessUnit: {
        feature: 'businessUnit',
        actions: { ...CrudActions },
    },
    mainUser: {
        feature: 'mainUser',
        actions: {
            ReadAll: CrudActions.ReadAll,
            ReadOne: CrudActions.ReadOne,
            UpdateOne: CrudActions.UpdateOne,
            Export: CrudActions.Export,
        },
    },
    ddt: {
        feature: 'ddt',
        actions: {
            ReadAll: CrudActions.ReadAll,
            Export: CrudActions.Export,
        },
    },
    solubleType: {
        feature: 'solubleType',
        actions: { ...CrudActions },
    },
    transaction: {
        feature: 'transaction',
        actions: { ...CrudActions },
    },
    timeSlotRule: {
        feature: 'timeSlotRule',
        actions: {
            ...CrudActions,
            [TimeSlotRuleActions.generateTimeSlots]:
                TimeSlotRuleActions.generateTimeSlots,
        },
    },
    deliveryMan: {
        feature: 'deliveryMan',
        actions: { ...CrudActions },
    },
    forecastCategory: {
        feature: 'forecastCategory',
        actions: { ...CrudActions },
    },
    clientCluster: {
        feature: 'clientCluster',
        actions: { ...CrudActions },
    },
    B2BClient: {
        feature: 'B2BClient',
        actions: { ...CrudActions },
    },
    layout: {
        feature: 'layout',
        actions: { ...CrudActions },
    },
    layoutPhoto: {
        feature: 'layoutPhoto',
        actions: { ...CrudActions },
    },
    deliveryManSkill: {
        feature: 'deliveryManSkill',
        actions: { ...CrudActions },
    },
    ...OpsPermissions,
    utils: {
        feature: 'utils',
        actions: {
            importDataFromFoorban: 'importDataFromFoorban',
        },
    },
    outboundLocation: {
        feature: 'outboundLocation',
        actions: {
          ReadAll: 'Read-All',
        },
    },
}

export const getPermissions = () => {
    let flatPermissions = [];

    Object.keys(Permissions).forEach(feature => {
        Object.keys(Permissions[feature].actions).forEach(action => {
            flatPermissions.push({
                feature: Permissions[feature].feature,
                subFeature: "",
                action: Permissions[feature].actions[action],
                permission: `${Permissions[feature].feature}-${Permissions[feature].actions[action]}`
            })
        })
        if (Permissions[feature].subFeatures) {
            Object.keys(Permissions[feature].subFeatures).forEach(subFeature => {
                Object.keys(Permissions[feature].subFeatures[subFeature].actions).forEach(action => {
                    flatPermissions.push({
                        feature: Permissions[feature].feature,
                        subFeature: Permissions[feature].subFeatures[subFeature].name,
                        action: Permissions[feature].subFeatures[subFeature].actions[action],
                        permission: `${Permissions[feature].feature}_${Permissions[feature].subFeatures[subFeature].name}-${Permissions[feature].subFeatures[subFeature].actions[action]}`
                    })
                })
            })
        }
    })

    return flatPermissions;
}

export const getPermissionsKeys = () => {
    let permissions = getPermissions();

    return permissions.map(({ permission }) => {
        return permission;
    });
}