import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput } from 'react-admin';
import BaseEdit from '../inheritance/BaseEdit';
import { required } from 'react-admin';

export const ClientCategoryEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="Nome Interno" source="internalName" validate={[required('Il nome interno è obbligatorio')]} />
            <TextInput label="Nome Cliente" source="clientName" validate={[required('Il nome cliente è obbligatorio')]} />
            <TextInput label="URL Icona" source="iconURL" />
            <TextInput label="Photo URL" source="photoURL" />
            <NumberInput label="Ordine" helperText="numero da 1 a x" source="order" />
            <BaseEdit />
        </SimpleForm>
    </Edit>
);