import { useDataProvider } from "react-admin";
import React, { useRef } from "react";
import { Button } from "@material-ui/core";
import { uploadFormPhotos } from "./utils";
import CloudUpload from "@material-ui/icons/CloudUpload";

export function UploadPhotoCard(props) {
  const inputEl = useRef(null);
  const dataProvider = useDataProvider();

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        startIcon={<CloudUpload/>}
        onClick={(e) => {
          e.stopPropagation();
          inputEl.current.click();
        }}
      >
        {"Carica Foto"}
      </Button>
      <input
        onChange={(e) => {
          uploadFormPhotos(
            e.target.files[0],
            e.target.files[0].type,
            dataProvider,
            (fileName) => {
              props.onUrlsReady(fileName);
            },
            (error) => {
              props.onError(error);
            }
          );
        }}
        style={{ display: "none" }}
        ref={inputEl}
        type="file"
        accept={".jpg,.jpeg,.png"}
      />
    </>
  );
}
