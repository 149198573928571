import React, { useEffect, useState } from 'react';
import { Create, SimpleForm, TextInput, NumberInput, useDataProvider, Loading, required } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { getItemDefaultUoMFrom } from './UnitOfMeasurementUtils';

export function UnitOfMeasurementCreate(props) {
  const [status, setStatus ] = useState('FETCH_UoM');
  const [UoMDefault, setUoMDefault ] = useState();
  const dataProvider = useDataProvider();

  useEffect(() => {
    if(status === 'FETCH_UoM' && props.itemId) {
      setStatus('FETCHING_UoM')
      getItemDefaultUoMFrom(dataProvider, props.itemId)
      .then(({ data }) => {
        setUoMDefault(data[0])
        setStatus('UoM_DEFAULT_READY')
      })
      .catch(() => {
        setStatus('ERROR_FETCHING_UoM')
      });
    }
  }, [status, props.itemId, dataProvider])

  if(status === 'ERROR_FETCHING_UoM') {
    return <SnackbarContent style={{ backgroundColor: '#f44336'}} message="Errore durante la ricerca della UoM di default" />;
  }

  if (status === 'UoM_DEFAULT_READY') {
    return (
      <Create {...props}>
          <SimpleForm redirect={props.redirect}>
              <div style={{display: 'none' }}>
                <NumberInput
                  fullWidth
                  disabled
                  source="itemId"
                  initialValue={parseInt(props.itemId)}/>
              </div>
              <TextInput
                fullWidth
                label="Nome"
                source="name"
                validate={[required('Il nome è obbligatorio')]} />
              <NumberInput
                fullWidth
                label="Conversione in unità di riferimento" helperText={`Quanti ${UoMDefault ? UoMDefault.name : '\'#nome unita di misura di referenza\''} contiene questa unità?`}
                source="conversionValue"
                validate={[required('La conversione è obbligatoria')]} />
              <NumberInput
                fullWidth
                label="Arrotondamento in questa unità" helperText="ex. con  un valore di 0.1, 2.16 viene salvato come 2.2, 2.14 viene salvato come 2.1 / con un valore di 6, 11 viene salvato come 12"
                source="rounding"
                validate={[required('L\'arrotondamento è obbligatorio')]} />
              <BaseCreate />
          </SimpleForm>
      </Create>
    );
  }

  return <Loading />;
};