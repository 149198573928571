import React from 'react';
import { Edit, SimpleForm, DeleteButton, Toolbar, TextInput, SaveButton } from 'react-admin';
import BaseEdit from '../../inheritance/BaseEdit';
import { required } from 'react-admin';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

function CustomToolbar(props) {
    let history = useHistory();

    return (
        <Toolbar {...props} classes={useStyles()}>
            <SaveButton />
            <DeleteButton mutationMode="undoable" redirect={() => {
                history.goBack();
                return null;
            }} />
        </Toolbar>
    );
}

export function Accr_WarehouseItemsEditComponent(props) {
    let history = useHistory()

    return (
        <Edit {...props}>
            <SimpleForm toolbar={<CustomToolbar />} redirect={() => {
                    history.goBack();
                    return null;
                }}>
                <TextInput type="tel" autoFocus label="Quantità" source="quantity" validate={[required('quantità obbligatoria')]} />
                <BaseEdit />
            </SimpleForm>
        </Edit>
    );
}

export const accr_WarehouseItemsEdit = connect((state, props) => {
    return {
        warehouseLocationId: state.admin.resources.accrWarehouseItems && state.admin.resources.accrWarehouseItems.data[props.id] ? state.admin.resources.accrWarehouseItems.data[props.id].warehouseLocationId : null
    }
})(Accr_WarehouseItemsEditComponent)