import React from 'react';
import { Create, SimpleForm, SelectInput, required, TextInput, NumberInput } from 'react-admin';
import BaseCreate from '../../inheritance/BaseCreate';
import { Permissions, WalletManualTransferActions } from '../../../permissions/permissions';
import NewInputSelectRest, { AutocompleteInputType } from '../../../components/InputRest';

const fieldOptions = `${Permissions.walletManualTransfer.feature}/${WalletManualTransferActions.fieldOptions}`;

export function WalletManualTransferCreate({title, redirect, ...rawProps }) {
	let props = {
		...rawProps,
		basePath: `/${Permissions.walletManualTransfer.feature}`,
        resource: `${Permissions.walletManualTransfer.feature}`,
		title: title ? title: "Aggiungi",
		redirect
    };

	return (
		<Create
			title=" "
            basePath={`/${Permissions.walletManualTransfer.feature}`}
            resource={`${Permissions.walletManualTransfer.feature}`}
		>
			<CreateForm {...props}/>
		</Create>
	);
}

function CreateForm(props) {
	return (
		<SimpleForm {...props}>
				<TextInput fullWidth disabled={true} initialValue={props.ownerId} source={'mainUserId'} />
				<SelectInput
					fullWidth
					initialValue={props.type ? props.type : 'credit'}
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							}
						}
					}
					label="Tipo"
					source="type"
					choices={[
						{
							id: 'credit', 
							name: 'Carica'
						},
						{
							id: 'debit', 
							name: 'Svuota'
						},
					]} />
				<NewInputSelectRest
					type={AutocompleteInputType}
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							}
						}
					}
					fieldOptions={fieldOptions}
					label="Conto da Caricare/Svuotare"
					source="accAccountTypeId"
					_dependsOn={["type"]}
					validate={[required('campo obbligatorio')]} />
				<NewInputSelectRest
					type={AutocompleteInputType}
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							}
						}
					}
					fieldOptions={fieldOptions}
					label="Tipo di Operazione"
					source="transferCategoryKey"
					_dependsOn={["type", "accAccountTypeId"]}
					validate={[required('campo obbligatorio')]} />
				<NewInputSelectRest
					type={AutocompleteInputType}
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							}
						}
					}
					fieldOptions={fieldOptions}
					label="Conto di sorgente/destinazione"
					source="otherAccAccountTypeId"
					_dependsOn={["type", "transferCategoryKey"]}
					validate={[required('campo obbligatorio')]} />
				<NewInputSelectRest
					type={AutocompleteInputType}
					options={
						{
							suggestionsContainerProps: {
								disablePortal: true
							}
						}
					}
					fieldOptions={fieldOptions}
					label="Gateway"
					source="otherMainUserId"
					_dependsOn={["type", "otherAccAccountTypeId"]}/>
				<NumberInput
					validate={[required('campo obbligatorio')]}
              		fullWidth
              		label="Importo"
					source="amount"
					helperText="importo con virgola e non in centesimi (SI: 12,36 euro)" />
				<TextInput
					validate={[required('campo obbligatorio')]}
              		fullWidth
              		label="Commento"
              		source="comment"/>
			<BaseCreate />
		</SimpleForm>
	);
}