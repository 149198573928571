import React, { useState } from "react";
import { Button, Typography, AppBar, Toolbar, Card, Box, CircularProgress } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';  
import {  useDataProvider, showNotification } from 'react-admin';
import { useDispatch } from 'react-redux';
import { Permissions } from '../../../../../permissions/permissions';
import { GET_OFF_RESOURCE_PLAIN } from '../../../../../providers/nestjs_crud';

const useStyles = makeStyles((theme) => ({
    title: {
		flexGrow: 1
	}
}));

export const DoUnloadTask = (props) => {
    const { task } = props;
	const [state, setState] = useState('INITIAL')
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
    const dataProvider = useDataProvider();

	function doAction (action) {
		setState('FETCHING_DATA')
		dataProvider(GET_OFF_RESOURCE_PLAIN, `${Permissions.deliveryManTransferTask.feature}/${task.id}/do-action/${action}`)
		.then((rawResponse) => {
			const response = JSON.parse(rawResponse)
			if(response.success) {
				dispatch(showNotification(`azione: complete-load completata`));
				history.push(`/picking/${task.config.transfer.id}`);
			} else if(response.errors) {
				const message = response.errors.map(({constraints}) => {
					return Object.keys(constraints).map(key => constraints[key]).join(', ');
				}).join(', ');

				throw new Error(message)
			} else {
				throw new Error('unknown error')
			}
		})
		.catch(error => {
			setState('INITIAL')
			dispatch(showNotification(`Errore durante azione: complete-load ${error}`, 'error'));
		})
	}

	if (state === 'FETCHING_DATA'){
		return ( 
		<Box textAlign='center'>
			<CircularProgress size={25} />
		</Box>  
		)
	}

    return (
        <Card>
            <AppBar color="default" position="static">
                <Toolbar>
                    <Typography variant="h5" className={classes.title}>
                        Scarico
                    </Typography>
                    {task.config.transfer && task.config.transfer.transferStateMachine &&
                        <UnloadTransfer
                            startUnload={() => doAction('start-unload')}
                            continueUnload={() => doAction('continue-unload')}
                            status={task.config.transfer.transferStateMachine.status}/>}
                </Toolbar>
            </AppBar>
        </Card>
    )

}

function UnloadTransfer(props) {
	switch (props.status) {
		case 'todo':
			return (
				<Button
					size="large"
					onClick={props.startUnload}
					variant="contained"
					color="primary">
					COMINCIA
                </Button>
			);
		case 'to_finish':
			return (
				<Button
					size="large"
					onClick={props.startUnload}
					variant="contained"
					color="primary">
					CONTINUA
				</Button>
			);
		case 'wip':
			return (
				<Button
					size="large"
					onClick={props.continueUnload}
					variant="contained"
					color="primary">
					CONTINUA
				</Button>
			);
		case 'delivering':
		case 'picked':
		case 'completed':
			return (
				<Typography variant="subtitle1">
					Completato
				</Typography>
			);
		default:
			return (
				<Typography variant="subtitle1">
					Errore contattare Hub
				</Typography>
			);
	}
}
