import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField, NumberField } from 'react-admin';
import Aside from '../inheritance/BaseAside';
import { Permissions } from '../../permissions/permissions';

export const SellingOrderRowShow = (props) => {
    return (
    <Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
        <SimpleShowLayout>
            <ReferenceField label="Ordine di vendita" source="sellingOrderId" reference={`${Permissions.sellingOrder.feature}`} link="show">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Item" source="itemSaleableId" reference={`${Permissions.itemSaleable.feature}`} link="show">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Unità" source="unitOfMeasurementId" reference={`${Permissions.unitOfMeasurement.feature}`} link="show">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Tassa" source="taxId" reference={`${Permissions.tax.feature}`} link="show">
                <TextField source="name" />
            </ReferenceField>
            <TextField label="ID" source="id" />
            <NumberField label="Quantità" source="quantity" />
            <NumberField label="Conversione InputToReference" source="conversionInputToReference" />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Prezzo unitario" source="unitPriceWithTax" />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Prezzo unitario (no-tax)" source="unitPriceNoTax" />
            <TextField label="Nome Aggiustamento"source="adjustmentName" />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Aggiustamento" source="adjustmentWithTax" />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Aggiustamento (no-tax)" source="adjustmentNoTax" />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Sub Totale prima Aggiustamento" source="subTotalBeforeAdjustmentWithTax" />    
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Sub Totale prima Aggiustamento (no-tax)" source="subTotalBeforeAdjustmentNoTax" />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Tasse su Sub Totale finale" source="subTotalBeforeAdjustmentTax" />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Totale finale" source="totalWithTax" />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Totale finale (no-tax)" source="totalNoTax" />
            <NumberField locales="it-IT" options={{ style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }} label="Tasse su totale finale" source="totalTax" />
        </SimpleShowLayout>
    </Show>
)};
