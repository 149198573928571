import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, FormDataConsumer, AutocompleteInput } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { required } from 'react-admin';
import { TYPOLOGIES_NO_IMPULSE } from '../../utils/enum/Typology';

export const SellingOrderCreate = props => {
    const [typologyState, setTypologyState] = React.useState(null);

    return (
        <Create {...props}>
            <SimpleForm redirect="show">
                <TextInput label="Nome" source="name" />
                <AutocompleteInput label="Tipologia" source="typology" choices={TYPOLOGIES_NO_IMPULSE.map(typology => {
                    return {
                        id: typology,
                        name: typology
                    }
                })} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        if(!formData.typology) {
                            return;
                        }

                        if(!typologyState) {
                            setTypologyState(formData.typology);
                        }
                        else if (typologyState !== formData.typology ){
                            setTypologyState(formData.typology);    
                            formData.sellingPointId = null;
                            return null;
                        }

                        return (
                            <ReferenceInput  {...rest} filter={{ "typology||eq": formData.typology }} label="Punto di Vendita" source="sellingPointId" reference="sellingPoint" validate={[required("Il Punto di Vendita è obbligatorio")]} >
                                <SelectInput optionText="name" />
                            </ReferenceInput>
                        );
                    }}
                </FormDataConsumer>
                <BaseCreate />
            </SimpleForm>
        </Create>
    )};