import React from 'react';
import { SelectInput, FormDataConsumer, required } from 'react-admin';
import { STAGES_MAP } from '../config'

export function JobStageSubStage(props) {
    return <FormDataConsumer>
        {({ formData }) => {
            if (formData[props.stageSource] && STAGES_MAP[formData[props.stageSource]] && STAGES_MAP[formData[props.stageSource]].length > 0) {
                return (
                    <SelectInput
                        source={props.source}
                        label={props.label}
                        choices={STAGES_MAP[formData[props.stageSource]].map((stage) => ({ id: stage, name: stage }))}
                        validate={[required()]}
                    />
                );
            } else {
                return null;
            }
        }}
    </FormDataConsumer>
}