import React from "react";
import {
  Datagrid,
  List,
  TextField,
  TextInput,
  NumberInput,
  useNotify,
  useDataProvider,
  useRefresh,
  TopToolbar,
  usePermissions,
} from "react-admin";
import Filter from "../../inheritance/BaseFilter";
import { Permissions } from "../../../permissions/permissions";
import DangerousIcon from "@material-ui/icons/Warning";
import { POST_OFF_RESOURCE } from "../../../providers/nestjs_crud";
import { isUuid } from "../../../utils/validation/regex";
import Button from "@material-ui/core/Button";
import DateTimeFilter from "../../../components/DateTimeFilter";

const WalletTransferFilter = (props) => (
  <Filter {...props}  >
    <TextInput label="bulkUUID (IDX_f144ae3c0f9465c308aea5e2e9)" source="bulkUUID||eq" alwaysOn />
    <NumberInput label="transactionParentId (FK_df3d9ea23585893b3367ab3c6b0)" source="transactionParentId||eq" />
    <TextInput label="category" source="category||eq" />
    <DateTimeFilter source={`created_at||gte`} label={`data da`} />
    <DateTimeFilter source={`created_at||lte`} label={`data a`} />
    <TextInput
      label="Account debited ID (FK_3ce6c52aa374abe90f236b87d32)"
      source="accountDebitedId||eq"
    />
    <TextInput
      label="Account credited ID (FK_dbe5d7ab77d3671fb0603a65597)"
      source="accountCreditedId||eq"
    />
    <TextInput label="Status" source="status||eq" />
  </Filter>
);

function WalletTransferActions(props) {
  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <TopToolbar>
      {permissions && permissions.includes(`${Permissions.walletTransfer.feature}-${Permissions.walletTransfer.actions.BulkComplete}`) && <Button
        variant="text"
        color="primary"
        endIcon={<DangerousIcon />}
        onClick={() => {
          let bulkUuid = prompt("Enter UUID", "");

          if(bulkUuid === null) {
            return null;
          }

          if (bulkUuid === "") {
            alert("Empty UUID");
            return;
          }

          if (!isUuid.test(bulkUuid)) {
            alert("Invalid UUID");
            return;
          }

          if (bulkUuid && isUuid.test(bulkUuid)) {
            var confirm = window.confirm(
              "Sei sicuro di voler effettuare un bulk complete??"
            );
            if (confirm) {
              dataProvider(
                POST_OFF_RESOURCE,
                `${Permissions.walletTransfer.feature}/${Permissions.walletTransfer.actions.BulkComplete}/${bulkUuid}`,
                {
                  data: {},
                }
              )
                .then(({ data }) => {
                  notify(`Bulk complete effettuato`);
                  refresh();
                })
                .catch((error) => {
                  const message = `${error && error.code ? `${error.code}-` : ''}${error && error.message  ? error.message : "errore durante la richiesta"}`;
                  alert(message);
                });
            }
          }
        }}
      >
        Complete
      </Button>}

      {permissions && permissions.includes(`${Permissions.walletTransfer.feature}-${Permissions.walletTransfer.actions.BulkCancel}`) && <Button
        variant="text"
        color="primary"
        endIcon={<DangerousIcon />}
        onClick={() => {
          let bulkUuid = prompt("Enter UUID", "");

          if(bulkUuid === null) {
            return null;
          }

          if (bulkUuid === "") {
            alert("Empty UUID");
            return;
          }

          if (!isUuid.test(bulkUuid)) {
            alert("Invalid UUID");
            return;
          }

          var confirm = window.confirm(
            "Sei sicuro di voler effettuare un bulk cancel??"
          );
          if (confirm) {
            dataProvider(
              POST_OFF_RESOURCE,
              `${Permissions.walletTransfer.feature}/${Permissions.walletTransfer.actions.BulkCancel}/${bulkUuid}`,
              {
                data: {},
              }
            )
              .then(({ data }) => {
                notify(`Bulk cancel effettuato`);
                refresh();
              })
              .catch((error) => {
                const message = `${error && error.code ? `${error.code}-` : ''}${error && error.message  ? error.message : "errore durante la richiesta"}`;
                alert(message);
              });
          }
        }}
      >
        Cancel
      </Button>}
    </TopToolbar>
  );
}

export function WalletTransferList(props) {
  return (
    <List
      {...props}
      actions={<WalletTransferActions />}
      filters={<WalletTransferFilter />}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid rowClick={null}>
        <TextField label="bulkUUID" source="bulkUUID" sortable={false} />
        <TextField label="category" source="category" sortable={false} />
        <TextField label="status" source="status" sortable={false} />
        <TextField
          label="accountDebitedId"
          source="accountDebitedId"
          sortable={false}
        />
        <TextField
          label="accountCreditedId"
          source="accountCreditedId"
          sortable={false}
        />
        <TextField label="amount" source="amount" sortable={false} />
        <TextField
          label="completedAmount"
          source="completedAmount"
          sortable={false}
        />
      </Datagrid>
    </List>
  );
}
