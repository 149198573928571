import React from 'react';
import { Show, SimpleShowLayout, TextField, BooleanField, DateField, ReferenceField } from 'react-admin';
import { ButtonList } from './CorporateCreditList';
import { Permissions } from '../../permissions/permissions';

export function CorporateCreditShow(props) {
	return (
		<Show  {...props}>
			<SimpleShowLayout>
				<TextField label="ID" source="id" />
				<TextField source="name" />
				<TextField source="credit" />
				<TextField source="enable" />
				<TextField source="status" />
				<TextField source="timeIntervalType" />
				<TextField source="mainUserPayingId" />
				<ReferenceField label="CorporateCustomer" source="corporateCustomerId" reference={`${Permissions.corporateCustomers.feature}`} link={'show'}>
                    <TextField source="name" />
                </ReferenceField>
				<ReferenceField label="WalletAccountTypePaying" source="walletAccountTypePayingId" reference={`${Permissions.walletAccAccountType.feature}`} link={'show'}>
                    <TextField source="name" />
                </ReferenceField>
				<DateField label="Creato il"  source="created_at" />
				<BooleanField label="Attivo" source="active" />
				<DateField label="Creato il" source="created_at" />
				<DateField label="Aggiornato il" source="updated_at" />
				<ButtonList />
			</SimpleShowLayout>
		</Show>
	)
}
