import React, { useState } from 'react';
import { Datagrid, List, TextField, DateField, useDataProvider, useRefresh, showNotification } from 'react-admin';
import { Button, Typography, CircularProgress, Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { GET_OFF_RESOURCE_PLAIN } from '../../../../providers/nestjs_crud';
import { Permissions } from '../../../../permissions/permissions';  
import { getColorForStatus } from './utils'
export const DeliveryManMissionList = props => (
    <List {...props} actions={false} bulkActionButtons={false} pagination={false}  >
        <Datagrid rowClick="show">
            <TextField label='Luogo' source="place.name" sortable={false} />
            <DateField locales="it-IT" showTime label="Data e ora prevista" source="estimatedStartDate" sortable={false} />
            <Status label="Stato" source="status"  sortable={false}/>
            <Actions label="Azioni" sortable={false} />
        </Datagrid>
    </List>
);

function Actions (props) {
    const [state, setState] = useState('INITIAL')
    const refresh = useRefresh();
    const dispatch = useDispatch();
    const dataProvider = useDataProvider();
    if(props.record && props.record.actions) {
        if (state === 'FETCHING_DATA'){
            return ( 
            <Box textAlign='center'>
                <CircularProgress size={25} />
            </Box>  
            )
        }

        const {id, actions } = props.record 
        return (
            <React.Fragment>
                {actions.map((action, index) => (
                    <Button 
                        style={{marginRight: '5px'}} 
                        size='medium' 
                        variant='contained' 
                        color='primary' 
                        key={index}
                        onClick={(event) => {
                            setState('FETCHING_DATA')
                            event.stopPropagation();
                            dataProvider(GET_OFF_RESOURCE_PLAIN, `${Permissions.deliveryManMission.feature}/${id}/do-action/${action.key}`)
                            .then((rawResponse) => {
                                const response = JSON.parse(rawResponse)
                                if(response.success) {
                                    dispatch(showNotification(`azione: ${action.label} completata`));
                                    refresh();
                                } else if(response.errors) {
                                    const message = response.errors.map(({constraints}) => {
                                        return Object.keys(constraints).map(key => constraints[key]).join(', ');
                                    }).join(', ');

                                    throw new Error(message)
                                } else {
                                    throw new Error('unknown error')
                                }
                                
                            })
                            .catch(error => {
                                setState('INITIAL')
                                dispatch(showNotification(`Errore durante azione: ${action.label}. ${error}`, 'error'));
                            })
                        }}
                        >
                        {action.label}
                    </Button>
                ))}
            </React.Fragment>
        );
    }
    return null;
}

function Status (props) {
    if(props.record && props.record.status) {
        const backgroundColor = getColorForStatus(props.record.status);
        return  (
            <Typography
                style={{ backgroundColor: backgroundColor[300], textAlign: 'center'}}>
                {props.record.status}
            </Typography>
        )
    }
    return null
}