import React , { useState, useEffect } from 'react';
import { Edit, SimpleForm, TextInput, AutocompleteArrayInput, BooleanInput, useDataProvider, Loading, required } from 'react-admin';
import { GET_OFF_RESOURCE } from '../../providers/nestjs_crud';
import BaseEdit from '../inheritance/BaseEdit';

export const RoleEdit = props => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dataProvider(GET_OFF_RESOURCE, 'auth/permissions')
            .then(({ data }) => {
                setData(data.map(res => {
                    return {
                        id: res.permission,
                        ...res
                    }
                }));
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })
    }, [dataProvider]);

    if (loading) return <Loading />;

    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput label="Nome" source="name" validate={[required('Il nome è obbligatorio')]} />
                <BooleanInput label="ADMIN" source="admin"></BooleanInput>
                <AutocompleteArrayInput label="Permissions" source="permissions" choices={data} optionText={choice => choice ? `${choice.permission}` : ''}/>
                <BaseEdit />
            </SimpleForm>
        </Edit>
    );
}