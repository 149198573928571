import React from 'react';
import { Page, Document, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import PurchaseOrderRows from './PurchaseOrderRows';
import logo from './logo.png';

const styles = StyleSheet.create({
  body: {
    padding: 10
  },
  title: {
    fontSize: 10,
    fontWeight: 500,
    textAlign: 'right',
    paddingTop: 5
  },
  text: {
    fontSize: 10
  },
  textTitle: {
    fontSize: 11,
    fontWeight: 'bold',
    marginBottom: 3
  },
  textRow: {
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 15
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    paddingBottom: 10
  },
  textRowContent: {
    flex: 1
  },
  logo: {
    width: 100,
    marginLeft: 'auto',
    marginRight: 'auto'
}
});

export default (props) => {
  const updated_at = new Intl.DateTimeFormat('it', { year: 'numeric',  month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }).format(new Date(props.purchaseOrder.updated_at));
  let deliveryDate;

if(props.purchaseOrder.requiredDeliveryDate) {
    deliveryDate = new Intl.DateTimeFormat('it', { year: 'numeric',  month: 'numeric', day: 'numeric'}).format(new Date(props.purchaseOrder.requiredDeliveryDate));
  }

  return (
  <Document>
    <Page style={styles.body}>
      <View style={styles.row}>
        <View style={styles.textRowContent}>
          <Image style={styles.logo} src={logo} />
        </View>
        <View style={styles.textRowContent}>
          <Text style={styles.title}>ORDINE ACQUISTO</Text>
        </View>
      </View>
      <View style={styles.textRow}>
          <View style={styles.textRowContent}>
              <Text style={styles.text}>
              Foorban Srl
              </Text>
              <Text style={styles.text}>
              P.zza Castello 26
              </Text>
              <Text style={styles.text}>
              20121 Milano (MI)
              </Text>
          </View>
          <View style={styles.textRowContent}>
            <Text style={styles.text}>
            Numero Ordine d'Acquisto: {props.purchaseOrder.id}
            </Text>
            <Text style={styles.text}>
            Data ultimo aggiornamento: {updated_at}
            </Text>
          </View>
      </View>
      <View style={styles.textRow}>
        <View style={styles.textRowContent}>
            <Text style={styles.text}>
              Ufficio Acquisto: {props.purchaseOrder.purchasePoint.name}
            </Text>
        </View>
      </View>
      <View style={styles.textRow}>
          <View style={styles.textRowContent}>
              <Text style={styles.textTitle}>
              A:
              </Text>
              <Text style={styles.text}>
              {props.purchaseOrder.supplier.businessName}
              </Text>
              <Text style={styles.text}>
              {props.purchaseOrder.supplier.city}
              </Text>
              <Text style={styles.text}>
              P.IVA: {props.purchaseOrder.supplier.vatNumber}
              </Text>
          </View>
          <View style={styles.textRowContent}>
              <Text style={styles.textTitle}>
              Spedire a:
              </Text>
              <Text style={styles.text}>
              {props.purchaseOrder.place.address}
              </Text>
              <Text style={styles.text}>
              Data Consegna Prevista: {deliveryDate}
              </Text>
              {props.purchaseOrder.maxDeliveryHour && <Text style={styles.text}>
              Tassativamente entro le:  {props.purchaseOrder.maxDeliveryHour}
              </Text>}
          </View>
      </View>
      <View style={styles.textRow}>
        <View style={styles.textRowContent}>
          <Text style={styles.text}>
            Termini di Pagamento: {props.purchaseOrder.supplier.paymentMethod}
          </Text>
          {props.purchaseOrder.supplierNote && <Text style={styles.text}>
            Note: {props.purchaseOrder.supplierNote}
          </Text>}
          </View>
      </View>
      <PurchaseOrderRows purchaseOrder={props.purchaseOrder}></PurchaseOrderRows>
    </Page>
  </Document>
);}

