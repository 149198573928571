import React, { useState, useEffect } from "react";
import { useDataProvider, usePermissions } from "react-admin";
import { CrudActions, Permissions } from "../../../permissions/permissions";
import { transactionToObjectLinkMap } from "./utils";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

/*
  Link to a detail view of a specific object, based on props.transactionId.
  The object is determined from the fetched transaction type.

  props:
  - transactionId
*/
export function LinkToTransactionObject(props) {
  const [status, setStatus] = useState("FETCH_DATA");
  const [linkAttributes, setLinkAttributes] = useState(null);
  const { permissions } = usePermissions();
  const dataProvider = useDataProvider();
  const history = useHistory();

  useEffect(() => {
    if (
      status === "FETCH_DATA" &&
      permissions &&
      permissions.includes(
        `${Permissions.transaction.feature}-${Permissions.transaction.actions.ReadOne}`
      ) &&
      props.transactionId
    ) {
      // Get transaction details
      setStatus("FETCHING_DATA");
      dataProvider
        .getOne(Permissions.transaction.feature, {
          id: props.transactionId,
        })
        .then(({ data }) => {
          if (transactionToObjectLinkMap[data.type]) {
            const linkAttr = transactionToObjectLinkMap[data.type];
            if (
              permissions.includes(`${linkAttr.feature}-${CrudActions.ReadAll}`)
            ) {
              setLinkAttributes(linkAttr);
              setStatus("DATA_FETCHED");
            } else {
              setStatus("ERROR_MISSING_PERMISSION_FOR_LINK");
            }
          } else {
            setStatus("ERROR_TRANSACTION_TYPE_NOT_MAPPED");
          }
        })
        .catch(() => {
          setStatus("ERROR_FETCHING_DATA");
        });
    }
  }, [status, permissions, dataProvider, props]);

  return (
    <>
      {props.transactionId && (
        <>
          {status === "DATA_FETCHED" && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(
                  `/${linkAttributes.feature}?filter={"transactionId||eq":"${props.transactionId}"}`
                );
              }}
            >
              {`Vai a ${linkAttributes.objectLabel}`}
            </Button>
          )}
        </>
      )}
    </>
  );
}
