import React from 'react';
import { Create, SimpleForm, ReferenceInput, AutocompleteInput, DateInput, required, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { Permissions } from '../../permissions/permissions';

export function DeliveryManCreate(props) {
    return (
        <Create {...props} >
            <SimpleForm redirect="list">
                <ReferenceInput
                    validate={[required('campo obbligatorio')]}
                    enableGetChoices={val => {
                        return !!(val && val.name && val.name.length >= 2);
                    }}
                    filterToQuery={searchText => {
                        if (!searchText)
                            return {};

                        return {
                            "name": searchText,
                        }
                    }}
                    label="Hub di riferimento"
                    source="hubId"
                    reference={Permissions.hub.feature}>
                    <AutocompleteInput
                        optionText="name"
                        optionValue="id" />
                </ReferenceInput>
                <DateInput
                    label="Data assunzione"
                    source="assumptionDate" />
                <DateInput
                    label="Data scadenza contratto"
                    source="contractExpiryDate" />
                <ReferenceInput
                    validate={[required('campo obbligatorio')]}
                    enableGetChoices={val => {
                        return !!(val && val.label && val.label.length >= 2);
                    }}
                    filterToQuery={searchText => {
                        if (!searchText)
                            return {
                                "type": "RibesUser",
                                "planner||eq": true
                            }

                        return {
                            "label": searchText,
                            "type": "RibesUser",
                            "planner||eq": true
                        }
                    }}
                    label="Planner"
                    source="plannerMainUserId"
                    reference={Permissions.mainUser.feature}>
                    <AutocompleteInput
                        optionText={(record) => {
                            if (!record || !record.id) {
                                return 'cancel';
                            }
                            return `${record ? `${record.id} - ${record.label}` : ''}`
                        }}
                        optionValue="id" />
                </ReferenceInput>
                <ReferenceInput
                    validate={[required('campo obbligatorio')]}
                    enableGetChoices={val => {
                        return !!(val && val.label && val.label.length >= 2);
                    }}
                    filterToQuery={searchText => {
                        if (!searchText)
                            return {
                                "type": "RibesUser",
                                "deliveryMan||eq": true
                            }

                        return {
                            "label": searchText,
                            "type": "RibesUser",
                            "deliveryMan||eq": true
                        }
                    }}
                    label="Main User"
                    source="mainUserId"
                    reference={Permissions.mainUser.feature}>
                    <AutocompleteInput
                        optionText={(record) => {
                            if (!record || !record.id) {
                                return 'cancel';
                            }
                            return `${record ? `${record.id} - ${record.label}` : ''}`
                        }}
                        optionValue="id" />
                </ReferenceInput>
                <ReferenceArrayInput filterToQuery={searchText => {
					if(!searchText)
						return null;

					return {
						name: searchText,
                        
					}
				}}  source="skillsIds" reference={Permissions.deliveryManSkill.feature}>
                    
                    <AutocompleteArrayInput  optionText="name"/>
                        
                </ReferenceArrayInput>
                <BaseCreate />
            </SimpleForm>
        </Create>
    )
};