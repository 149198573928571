import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { required } from 'react-admin';
import { Permissions } from '../../permissions/permissions';

export const InternalCategoryCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Nome" source="name" validate={[required('Il nome è obbligatorio')]} />
            <ReferenceInput
				filterToQuery={searchText => {
					if(!searchText)
						return {};

					return { name: searchText }
				}}
                label="Categoria Padre"
                source="parentCategoryId"
                reference={`${Permissions.internalCategory.feature}`}
                allowEmpty
            >
				<AutocompleteInput
					shouldRenderSuggestions={val => val && val.length >= 2 }
					optionText="name"
					optionValue="id" />
			</ReferenceInput>
            <BaseCreate />
        </SimpleForm>
    </Create>
);