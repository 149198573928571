import React, { useEffect, useState} from "react";
import { useHistory, useParams } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useDataProvider, showNotification } from 'react-admin';
import { Permissions } from '../../../../permissions/permissions';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Container from '@material-ui/core/Container'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop : "64px"
  },
 
  container: {
    marginTop: theme.spacing(2),
  },
  media: {
    height: 200,
  },
  fullImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  colseIcon: {
    position: "absolute", 
    right: "0",
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-focusVisible": { backgroundColor: theme.palette.grey[200] }
  
  }
}));

function MediaCard(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (<>
    <Card className={classes.rootCard} onClick={handleOpenDialog}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={props.image}
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
           {props.commnet}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

    <Dialog open={open} onClose={handleCloseDialog} maxWidth="xl">
          <IconButton className={classes.colseIcon} edge="start" color='default' onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        <img src={props.image} alt="Full Size" className={classes.fullImage} />
    </Dialog>
    </>
  );
}

function LayoutList (props) {
    const history = useHistory();
    const { id } = useParams();
    const [status, setStatus] = useState("FETCH_DATA")
    const [data, setData] = useState([])
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleClose = () => {
        history.goBack()
    };

	useEffect(() => {
		if(status === "FETCH_DATA") {
			setStatus("FETCHING_DATA")
			dataProvider.getOne(Permissions.layout.feature, {id: id})
			.then((response) => {
        const { layoutPhotos, ...rest } = response.data;

        const sortedLayoutPhotos = layoutPhotos
          .sort((a, b) => a.order - b.order)
          .filter((layoutPhoto) => layoutPhoto.active)
            
        setData({ ...rest, layoutPhotos: sortedLayoutPhotos });
				setStatus("DATA_FETCHED")
			})
			.catch(() => {
				setStatus("ERROR_DATA_FETCHING")
				dispatch(showNotification("Errore caricamento dati", "error"));
			})
		}

	}, [dataProvider, dispatch, id, status])

  if(["FETCHING_DATA", 'FETCH_DATA'].includes(status)) 
    return <Typography>Caricamento del Layout Photo...</Typography>
  

	if(status === "ERROR_DATA_FETCHING")
		return <Typography>Errore caricamento Layout Photo!</Typography>

    return (
            <Dialog fullScreen open={true} onClose={handleClose}>
            <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" >
                    {data.name}
                </Typography>
            </Toolbar>
            </AppBar>
            <Container maxWidth="xl" fullWidth={"lg"} className={classes.root}>
            <Grid container className={classes.container} spacing={3}>
                {data.layoutPhotos && data.layoutPhotos.map((layoutPhoto) => {
                    return (
                        <Grid key={layoutPhoto.id} item xs={6} lg={3} >
                            <MediaCard image={layoutPhoto.url} commnet={layoutPhoto.comment} />
                        </Grid>      
                    );
                })}
            </Grid>
            </Container>
            
        </Dialog>  
    );

}

export default LayoutList;