import React from "react";
import { Typography, Box } from "@material-ui/core";
import { Permissions } from "../../../../../../../permissions/permissions";
import { LinkToTransactionChildObjectList } from "../../../../../../../resources/transaction/components/LinkToTransactionChildObjectList";

export function InventoryDeliveryManProvidedData(props) {
  return (
    <>
      {props.data ? (
        <Box>
          <Typography style={{marginBotton: "0.5em"}} variant="caption">
            <i>{`ID inventari`}</i>
            <br />

            {props.data.inventoryIds ? (
              <span>{`${props.data.inventoryIds.join(",")}`}</span>
            ) : (
              "-"
            )}
          </Typography>
          <br />
          {props.data.missionJobTransactionId ? (
            <LinkToTransactionChildObjectList
              transactionId={props.data.missionJobTransactionId}
              childType={`${Permissions.warehouseInventory.feature}`}
            />
          ) : (
            "-"
          )}
        </Box>
      ) : (
        "-"
      )}
    </>
  );
}
