import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

export function SolubleTypeShow(props) {
	return (
		<Show  {...props}>
			<SimpleShowLayout>
			<TextField source="id" />
			<TextField source="externalName" />
			<TextField source="internalName" />
			</SimpleShowLayout>
		</Show>
	)
}
