import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import BaseEdit from '../../inheritance/BaseEdit';

import { Permissions, TransferActions } from '../../../permissions/permissions';
import NewInputSelectRest, { AutocompleteInputType, DateInputType, TextInputType } from './commonComponents/InputRest';

export const TransferEdit = props => (
    <Edit {...props}>
        <SimpleForm redirect="show">
            <div style={{ display: 'none' }}>
				<TextInput disable={true} source="id" />
			</div>
            <NewInputSelectRest
                type={DateInputType}
                fieldOptions={`${Permissions.transfer.feature}/${TransferActions.fieldOptions}`}
                label="Data prevista" source="expectedDate" />
            <NewInputSelectRest
                type={TextInputType}
                fieldOptions={`${Permissions.transfer.feature}/${TransferActions.fieldOptions}`}
                label="External document" source="externalDocument" />
            <NewInputSelectRest
                type={AutocompleteInputType}
                fieldOptions={`${Permissions.transfer.feature}/${TransferActions.fieldOptions}`}
                label="Assignee (backendUser)" source="assigneeId" />
            <BaseEdit />
        </SimpleForm>
    </Edit>
);