import React from 'react';
import { Datagrid, List, TextField, ReferenceField, TextInput, BooleanField, ReferenceInput, AutocompleteInput, BooleanInput, NumberInput } from 'react-admin';
import Filter from '../inheritance/BaseFilter';
import ListActions from '../../components/ListActions';
import { Permissions } from '../../permissions/permissions';

const ItemFilter = (props) => (
    <Filter {...props}>
        <NumberInput label="ID" source='id||eq' alwaysOn/>
        <TextInput label="Nome Interno" source="name" alwaysOn />
        <BooleanInput label="Acquistabile" source="purchasable" defaultValue={true} />
        <BooleanInput label="Vendibile" source="saleable" defaultValue={true} />
        <BooleanInput label="Immagazzinabile" source="storable" defaultValue={true} />
        <ReferenceInput
            filterToQuery={searchText => {
                if(!searchText)
                    return {};

                return { name: searchText }
            }}
            label="Categoria"
            source="categoryId||eq"
            reference={`${Permissions.internalCategory.feature}`}
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val && val.length >= 2 }
                optionText="name"
                optionValue="id" />
        </ReferenceInput>
        <ReferenceInput
            filterToQuery={searchText => {
                if(!searchText)
                    return {};

                return { name: searchText }
            }}
            label="Categoria di previsione"
            source="forecastCategoryId||eq"
            reference={`${Permissions.forecastCategory.feature}`}
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val && val.length >= 2 }
                optionText="name"
                optionValue="id" />
        </ReferenceInput>
        <TextInput label="Legacy IDs" helperText="(comma separated)" source="legacyIDs" />
    </Filter>
);

export const ItemList = props => (
    <List {...props} actions={<ListActions />}  sort={{ field: 'created_at', order: 'DESC' }}  filters={<ItemFilter />} >
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <TextField label="Nome Interno" source="name" />
            <BooleanField label="Acquistabile" source="purchasable"></BooleanField>
            <BooleanField label="Vendibile" source="saleable"></BooleanField>
            <BooleanField label="Immagazzinabile" source="storable"></BooleanField>
            <BooleanField label="Edibile" source="edible"></BooleanField>
            <ReferenceField label="Categoria Interna" source="categoryId" reference="internalCategory" link="show">
                <TextField source="name" />
            </ReferenceField>
        </Datagrid>
    </List>
);