import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  cardDone: {
    backgroundColor: '#bef67a'
  },
  cardToDo: {
    backgroundColor: '#ffc107'
  },
  root: {
    width: '100%'
  },
  action: {
    backgroundColor: 'white',
    width: '100%'
  },
  quantity: {
    padding: theme.spacing(1),
    width: '100%',
  }
}));

export default function LotCard({
  physicalItemTransfer,
  cancel,
  confirm,
  toDo
}) {
  const classes = useStyles();
  let expirationDate = null;
  if(physicalItemTransfer.lot && physicalItemTransfer.lot.expirationDate) { 
    const date = new Date(physicalItemTransfer.lot.expirationDate);
    expirationDate = `${(date.getDate()).toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  }

  return (
    <Card className={`${classes.root} ${physicalItemTransfer.done ? classes.cardDone : classes.cardToDo}`} variant="outlined">
      <CardContent style={{padding: '8px 8px 0px'}}>
        <div>
        {physicalItemTransfer.fromWarehouseLocation && physicalItemTransfer.fromWarehouseLocation.name && <Typography  variant="h6">
          <small>Provenienza: </small><strong>{physicalItemTransfer.fromWarehouseLocation.name}</strong>
        </Typography>}
        {expirationDate && <Typography  variant="h6">
          <small>Scadenza: </small><strong>{expirationDate}</strong>
        </Typography>}
        {physicalItemTransfer.lot && 
        physicalItemTransfer.lot.compliantStatus === "not-compliant" && 
        <Typography variant="h6">
          <span style={{backgroundColor: "red", fontWeight: "bold", padding: "8px"}}>Lotto non conforme</span>
        </Typography>}
        {physicalItemTransfer.lot && physicalItemTransfer.lot.supplierLot && <Typography  variant="h6">
          <small>Lotto Fornitore: </small><strong>{physicalItemTransfer.lot.supplierLot}</strong>
        </Typography>}
        <Typography className={classes.quantity} align="center" variant="h4">
          {physicalItemTransfer.quantityReference}
        </Typography>
        </div>
      </CardContent>
      <CardActions>
        <Button className={classes.action} variant="contained" color="default" onClick={() => cancel(physicalItemTransfer.id)}>Cancella</Button>
        {!physicalItemTransfer.done && <Button className={classes.action} variant="contained" color="default" onClick={() => confirm(physicalItemTransfer.id)}>Conferma</Button>}
        {physicalItemTransfer.done && <Button className={classes.action} variant="contained" color="default" onClick={() => toDo(physicalItemTransfer.id)}>Segna da fare</Button>}
      </CardActions>
    </Card>
  );
}
