import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import BaseEdit from '../inheritance/BaseEdit';
import { required } from 'react-admin';

export const FileEdit = props => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput label="Titolo" source="title" validate={[required('campo obbligatorio')]} />
			<TextInput label="Alt" source="alt" validate={[required('campo obbligatorio')]} />
			<BaseEdit />
		</SimpleForm>
	</Edit>
);