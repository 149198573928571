import React from 'react';
import BaseEdit from '../inheritance/BaseEdit';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';
import { validateStringLength } from './validate';
export const LayoutEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput lable="Name" source="name" validate={[required()]}/>
            <TextInput lable="Commento" source="comment" validate={[validateStringLength]} />
            <BaseEdit />
        </SimpleForm>
    </Edit>
);