import React from 'react';
import { Datagrid, List, TextField, TextInput} from 'react-admin';
import ListActions from '../../components/ListActions';
import Filter from '../inheritance/BaseFilter';

const LayoutFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name" alwaysOn />
        <TextInput label="Commento" source="comment" />
    </Filter>
);

export function LayoutList(props) {
    return (
        <List {...props} actions={<ListActions />} filters={<LayoutFilter />}>
            <Datagrid rowClick="show">
                <TextField label="Name" source="name" />
                <TextField label="Commento" source="comment" />
            </Datagrid> 
        </List>
    );
}

