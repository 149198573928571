import React from 'react';
import { SimpleShowLayout, TextField, ReferenceField, ShowController, ShowView, useShowController } from 'react-admin';
import Aside from '../inheritance/BaseAside';
import { Typography } from '@material-ui/core';
import CustomToolbar from '../item/ItemChildActionShow';

export const ItemSaleableShow = (props) => {
    const myUseShowController = useShowController;

    return  (
        <ShowController {...props} aside={<Aside props={props} record={props.record}></Aside>}>
            {controllerProps =>
                <ShowView {...props} {...controllerProps} actions={props.hideActions ? null : <CustomToolbar />}>
                    <SimpleShowLayout>
                        <TextField
                            label="Nome per Clienti Finali"
                            source="name" />

                        <TextField
                            label="Nome per Clienti Finali (Inglese)"
                            source="englishName" />

                        <TextField
                            label="Photo Clienti"
                            source="photoURL" />

                        <TextField
                            label="Photo Clienti scontornata"
                            source="photoURL_NO_BACKGROUND" />

                        <TextField
                            label="Short Description"
                            source="shortDescription" />

                        <TextField
                            label="Short Description (Inglese)"
                            source="englishShortDescription" />

                        <TextField
                            label="Prezzo di default con IVA"
                            source="price" />

                        <ReferenceField
                            link="show"
                            label="Unità di misura"
                            source="unitOfMeasurementId"
                            reference="unitOfMeasurement"
                            basePath="/unitOfMeasurement"
                            record={myUseShowController(props)}>
                            <TextField
                                source="name"
                                component="span"
                                gutterBottom
                            />
                        </ReferenceField>

                        <ReferenceField
                            link="show"
                            label="Tasse"
                            source="taxId"
                            reference="tax"
                            basePath="/tax"
                            record={myUseShowController(props)}>
                            <TextField
                                source="name"
                                component="span"
                                gutterBottom
                            />
                        </ReferenceField>

                        <ReferenceField
                            link="show"
                            label="Categoria Ecommerce"
                            source="clientCategoryId"
                            reference="clientCategory"
                            basePath="/clientCategory"
                            record={myUseShowController(props)}>
                            <TextField
                                source="internalName"
                                component="span"
                                gutterBottom
                            />
                        </ReferenceField>
                        <TextField
                            label="Tag"
                            source="tag" />
                        <TextField
                            label="Tempo microonde in secondi (0 se va consumato Freddo)"
                            source="microwaveTime"/>
                        <hr></hr>
                        <Typography variant="h6">
                        Descrizione
                        </Typography>
                        <div dangerouslySetInnerHTML={{ __html: controllerProps.record ? controllerProps.record.description : "" }} />
                        <hr></hr>
                        <Typography variant="h6">
                        Descrizione (Inglese)
                        </Typography>
                        <div dangerouslySetInnerHTML={{ __html: controllerProps.record ? controllerProps.record.englishDescription : "" }} />
                    </SimpleShowLayout>
                </ShowView>
            }
        </ShowController>
    );
}