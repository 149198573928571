import React, {useState} from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput, FormDataConsumer, AutocompleteInput } from 'react-admin';
import BaseCreate from '../inheritance/BaseCreate';
import { required } from 'react-admin';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Permissions } from '../../permissions/permissions';

const useStyles = makeStyles({
    form: {
        display: 'flex',            
        flexGrow: 'colums',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
});

const SellingOrderRowCreateComponent = props => {
    const classes = useStyles();
    const [itemSaleableId, setItemSaleableId] = useState(0);

    return (
    <Create {...props}>
        <SimpleForm redirect={props.redirect}>
            <div style={{display: 'none' }}>
              <ReferenceInput disabled source="sellingOrderId" reference={`${Permissions.sellingOrder.feature}`} initialValue={props.sellingOrderId} >
                  <SelectInput optionText="name" />
              </ReferenceInput>
            </div>
            <ReferenceInput
                filterToQuery={searchText => {
                    if(!searchText)
                        return {};

                    return { name: searchText }
                }}
                label="Item Vendibile" source="itemSaleableId" reference={`${Permissions.itemSaleable.feature}`} validate={[required("Indicare l'item è obbligatorio")]}>
                <AutocompleteInput
                    shouldRenderSuggestions={val => val && val.length >= 2 }
                    options={
                        {
                            suggestionsContainerProps: {
                                disablePortal: true
                            }
                        }
                    }
                    optionText="name"
                    optionValue="id" />
            </ReferenceInput>
            <FormDataConsumer>
            {({ formData, ...rest }) => {
                if(!formData.itemSaleableId)
                    return null;

                if(formData.itemSaleableId === 0) {
                    setItemSaleableId(formData.itemSaleableId);
                }
                else if(formData.itemSaleableId !== 0 && formData.itemSaleableId !== itemSaleableId) {
                    formData.unitOfMeasurementId = undefined;
                    formData.taxId = undefined;
                    formData.unitPriceWithTax = undefined;

                    setItemSaleableId(formData.itemSaleableId);

                    return null;
                }

                const itemSaleable = props.itemSaleableList.data[formData.itemSaleableId];

                if(!itemSaleable)
                    return null;

                return <div className={classes.form}>
                    <ReferenceInput key={`tax`} {...rest} initialValue={itemSaleable.taxId}
                        label="tassa" source="taxId"
                        reference={`${Permissions.tax.feature}`}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput key={`uom`} {...rest} initialValue={itemSaleable.unitOfMeasurementId}
                        label="unità di misura di vendita"
                        source="unitOfMeasurementId"
                        reference={`${Permissions.unitOfMeasurement.feature}`}
                        filter={{"itemId||eq": itemSaleable.itemId}} >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <NumberInput key={`price`} {...rest} initialValue={itemSaleable.price}
                        label="prezzo unitario con tasse"
                        source="unitPriceWithTax" />
                    </div>
                }
            }
            </FormDataConsumer>
            <NumberInput label="quantità in unità di vendità" source="quantity" validate={[required('Indicare una quantità è obbligatorio')]} />
            <TextInput label="nome aggiustamento" source="adjustmentName" />
            <NumberInput label="sconti/aggiustamenti con tasse" source="adjustmentWithTax" />
            <BaseCreate />
        </SimpleForm>
    </Create>
)};

export const SellingOrderRowCreate = connect((state, props) => {
    return {
        itemSaleableList: state.admin.resources.itemSaleable
    }
})(SellingOrderRowCreateComponent)