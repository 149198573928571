import React from 'react';
import { Show, SimpleShowLayout, ReferenceField, TextField, DateField, BooleanField, NumberField, usePermissions } from 'react-admin';
import { Permissions, CrudActions } from '../../../permissions/permissions';
import StateMachineReferenceField from '../../../components/StateMachineReferenceField';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import { Typography } from '@material-ui/core';

export const LockerSellingOrderShow = (props) => {
	return (
	<Show {...props}>
		<SimpleShowLayout>
			<StateMachineReferenceField
				label="Stato"
				source="orderStateMachine"
				reference={`${Permissions.lockerSellingOrder.feature}_${Permissions.lockerSellingOrder.subFeatures.orderStateMachine.name}`}>
			</StateMachineReferenceField>
			<CustomTextField />
			<ReferenceField label="Utente" source="b2cUserId" reference={`${Permissions.B2CUser.feature}`} link="show">
				<TextField source="email" />
			</ReferenceField>
			<BooleanField label="SME" source="sme" /> 
            <DateField label="Data di Consegna" source="deliveryDate" />
			<TextField label="CorporateCredit" source="bulkCorporateCreditId" />
			<TextField label="WalletTransfer" source="bulkWalletTransferId" />
			<NumberField options={{ style: 'currency', currency: 'EUR' }} label="Dal Buono Aziendale" source="totalToPayFromCorporateCredit" />
			<NumberField options={{ style: 'currency', currency: 'EUR' }} label="Dal Wallet" source="totalToPayFromWallet" />
			<NumberField options={{ style: 'currency', currency: 'EUR' }} label="Pagato" source="totalToPay" />
		</SimpleShowLayout>
	</Show>
)};

function CustomTextField({ record = {} }) {
	const history = useHistory();
	const { permissions } = usePermissions();

	if(!record.paymentUUID || (permissions && !permissions.includes(`${Permissions.payment.feature}-${CrudActions.ReadOne}`))) {
		return null;
	}

    return (
		<>
			<Typography>Pagamento</Typography>
			<Button variant="text" onClick={() => {
				history.push(`/payment?filter={"uuid||eq":"${record['paymentUUID']}"}`);
			}}>{record['paymentUUID']}</Button>
		</>
    );
};


export default CustomTextField;