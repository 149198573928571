import React from 'react';
import { Show, SimpleShowLayout, TextField, BooleanField, ReferenceField } from 'react-admin';
import { Permissions } from '../../../permissions/permissions';

export function CoffeeSellingPointShow(props) {
	return (
		<Show  {...props}>
			<SimpleShowLayout>
			<TextField source="id" />
			<TextField source="publicName" />
			<BooleanField source="open" />
			<ReferenceField
				source="sellingPointId"
				reference={Permissions.sellingPoint.feature}
				link="show">
				<TextField source="name" />
			</ReferenceField>
			<ReferenceField
				source="coffeeMachineId"
				reference={Permissions.coffeeMachine.feature}
				link="show">
				<TextField source="name" />
			</ReferenceField>
			<ReferenceField
				source="coffeeRecipeListId"
				reference={Permissions.coffeeRecipeList.feature}
				link="show">
				<TextField source="name" />
			</ReferenceField>
			<TextField source="note" />
			</SimpleShowLayout>
		</Show>
	)
}
