import React from 'react';
import { Create, SimpleForm, TextInput, AutocompleteInput, ReferenceInput } from 'react-admin';
import BaseCreate from '../../inheritance/BaseCreate';
import { required } from 'react-admin';
import { Models } from './';
import { Permissions } from '../../../permissions/permissions';

export function CoffeeMachineCreate(props) {
	return (
		<Create {...props}>
			<SimpleForm redirect="show">
				<ReferenceInput
					fullWidth
					validate={[required()]}
					filterToQuery={searchText => {
						if (!searchText)
							return null;

						return {
							name: searchText
						}
					}}
					label="Luogo"
					source="placeId"
					reference={`${Permissions.place.feature}`}
				>
					<AutocompleteInput
						optionText={(record) => record ? `${record.id} - ${record.name}` : ''}
						optionValue="id" />
				</ReferenceInput>
				<TextInput fullWidth validate={[required()]} source="name" />
				<AutocompleteInput fullWidth validate={[required()]} source="model" choices={Models.map(Model => {
					return {
						id: Model,
						name: Model
					}
				})} />
				<TextInput fullWidth label="Numero di matricola" source='serialNumber' />
				<BaseCreate />
			</SimpleForm>
		</Create>
	);
}