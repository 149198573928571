import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput } from 'react-admin';
import BaseCreate from '../../inheritance/BaseCreate';
import { required } from 'react-admin';

export const ItemPurchasableCreate = props => (
    <Create {...props}>
      <SimpleForm redirect={props.redirect}>
          <ReferenceInput
              fullWidth
              label="Tassa di acquisto"
              source="taxId"
              reference="tax"
              validate={[required('Indicare la tassazione è obbligatorio')]}>
              <SelectInput optionText="name" />
          </ReferenceInput>

          <TextInput
              fullWidth
              label="Produttore/Marchio"
              source="brand" />

          <TextInput
              fullWidth
              label="Nota"
              source="note" />

          <div style={{display: 'none' }}>
            <NumberInput disabled source="itemId" initialValue={props.itemId} />
          </div>
          <BaseCreate />
      </SimpleForm>
    </Create>
);