import React from 'react';
import { Datagrid, List, TextField, TextInput, ReferenceField } from 'react-admin';
import Filter from '../../../inheritance/BaseFilter';
import ListActions from '../../../../components/ListActions';
import { Permissions } from '../../../../permissions/permissions';

const WalletRechargeConfigFilter = (props) => (
    <Filter {...props}>
        <TextInput label="id" source="id" alwaysOn />
    </Filter>
);

export const WalletRechargeConfigList = props => (
    <List {...props} actions={<ListActions />}  filters={<WalletRechargeConfigFilter />}>
        <Datagrid rowClick="edit">
            <TextField label="ID" source="id" />
            <TextField label="provider" source="provider" />
            <TextField label="integration" source="integration" />
            <TextField label="category" source="category" />
			<ReferenceField source="gatewayId" reference={`${Permissions.gateway.feature}`} link="show">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="gatewayMainUserId" />
			<ReferenceField source="accountTypeGatewayId" reference={`${Permissions.walletAccAccountType.feature}`} link="show">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="accountTypeUserId" reference={`${Permissions.walletAccAccountType.feature}`} link="show">
                <TextField source="name" />
            </ReferenceField>
        </Datagrid>
    </List>
);