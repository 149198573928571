import React from 'react';
import { Show, SimpleShowLayout, TextField, BooleanField, ReferenceField, useShowController, usePermissions, FunctionField, DateField, SelectField, useNotify } from 'react-admin';
import Aside from '../inheritance/BaseAside';
import { Permissions } from '../../permissions/permissions';
import Typography from '@material-ui/core/Typography';
import { DAYS } from '../../utils/enum/Common';
import { Link, Button } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

export const SellingPointImpulseShow = (props) => {
    const { permissions } = usePermissions();
	const {
		loaded,
		record,
	} = useShowController(props);
    return (
    <Show aside={<Aside props={props} record={props.record}></Aside>}  {...props}>
        <SimpleShowLayout>
            <CustomUUID />
            <BooleanField label="Aperto" source="open"></BooleanField>
            <BooleanField label="SME" source="sme"></BooleanField>
            <ReferenceField
                link="show"
                label="Listino di vendita"
                resource="priceList"
                reference="priceList"
                source="priceListId"
                basePath="/priceList"
                record={record}>
                <TextField
                    source="name"
                    component="span"
                    gutterBottom
                />
            </ReferenceField>
            <DateField label="Data di Consegna" source="deliveryDate" />
            {(permissions && permissions.includes(`${Permissions.locker.feature}-${Permissions[Permissions.locker.feature].actions.ReadOne}`)) && 
                <ReferenceField
                    link="show"
                    label="Locker"
                    resource="locker"
                    reference="locker"
                    source="lockerId"
                >
                    <FunctionField render={(record) => {
                        return <Typography>{record.id} {record.name}</Typography>
                    }} />
                </ReferenceField>
            }
            <TextField
                label="Frase di chiusura"
                source="closePhrase" />
            <SelectField
                label="Giorno di inizio offerta"
                source="dayOfWeekStartOffer"
                choices={DAYS} />
            <TextField
                label="Orario di apertura"
                source="openHour"/>
            <TextField
                label="Orario di chiusura"
                source="closeHour"/>
            <TextField
                label="Stato Banner"
                source="bannerStatus"/>
            <TextField
                label="Titolo Banner"
                source="bannerTitle"/>
            <TextField
                label="Style Banner"
                source="bannerStyle"/>
            {loaded && record.bannerContent && <Typography variant="caption">Contenuto Banner</Typography>}
            {loaded && record.bannerContent && <div style={{borderStyle: 'groove', padding: '4px'}} dangerouslySetInnerHTML={{ __html: record.bannerContent }} />}
			{loaded && record.customSuccessMessage && <Typography variant="caption">Messaggio completamento ordine</Typography>}
			{loaded && record.customSuccessMessage && <div style={{borderStyle: 'groove', padding: '4px'}} dangerouslySetInnerHTML={{ __html: record.customSuccessMessage.replaceAll('amp;', '')}} />}
        </SimpleShowLayout>
    </Show>
)};

function CustomUUID(props) {
    const notify = useNotify();
    if(!props.record) {
        return null;
    }
    return <div>
        <Typography>Punto di Vendita</Typography>
        <Link href={`https://foorban.business/selling-area/${props.record.uuid}`} target="_blank">{props.record.uuid}</Link>
        <Button
            onClick={() => {
                navigator.clipboard.writeText(`/selling-area/${props.record.uuid}`).then(() => {
                    notify(`/selling-area/${props.record.uuid}`);
                  }, () => {
                    notify(`error during copy`);
                  });
            }}
            label="Copy"
        >
            <FileCopyIcon color="primary"/>
        </Button>
    </div>
}
